import {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonToast,
    IonIcon,
    IonSpinner,
    isPlatform,
} from '@ionic/react';
import { useRef, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { signUpUser, loginUser } from '../../firebase/AuthCollections';
import Header from '../../components/Header';
import { createUser } from '../../firebase/UserCollection';
import useTrack from '../../hooks/useTrack';
import BMLogo from './BMLogo';
import { chevronBackOutline } from 'ionicons/icons';
import './index.css';
import AppleLogin from '../login/AppleLogin';

type UserSignUpData = {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
};

const SignUp: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastData, setToastData] = useState<{}>({});

    const { hitAnalytics } = useTrack();
    const { register, handleSubmit, watch, errors } = useForm<UserSignUpData>();
    const history = useHistory();
    const password = useRef({});
    password.current = watch('password', '');

    let { email }: { email: string } = useParams();

    const onSubmit = ({ password, name }: UserSignUpData) => {
        if (loading) {
            return;
        }
        setLoading(true);

        signUpUser(email, password)
            ?.then((res: any) => {
                createUser({ email, name, id: res.user.uid });
                hitAnalytics('Sign up', {
                    id: res.user.uid,
                    email,
                    name,
                    method: 'email',
                });
                loginUser(email, password);
                setLoading(false);
                setToastData((toastData) => {
                    return {
                        ...toastData,
                        color: 'dark',
                        message: 'Successfully saved',
                        duration: 4000,
                    };
                });
                setShowToast(true);
            })
            .catch((e) => {
                setLoading(false);
                setToastData((toastData) => {
                    return {
                        ...toastData,
                        color: 'danger',
                        message: e.message,
                        duration: 4000,
                    };
                });
                setShowToast(true);
            });
    };

    const isMobileWeb = isPlatform('mobileweb');
    const isDesktop = isPlatform('desktop');

    return (
        <IonPage>
            <Header
                title=""
                ionToolBarClasses={'header-border-zero'}
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        icon={chevronBackOutline}
                        onClick={() => history.goBack()}
                    />
                }
            />
            <IonContent className="ion-padding" scrollY={false}>
                <div className="bm-logo-wrapper">
                    <BMLogo />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        <IonLabel position="floating">Name </IonLabel>
                        <IonInput
                            autocapitalize="on"
                            name="name"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a name',
                            })}
                        />
                    </IonItem>
                    {errors.name && (
                        <IonText color="danger">
                            <small>{errors.name.message}</small>
                        </IonText>
                    )}

                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            autocapitalize="on"
                            name="password"
                            type="password"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a password',
                                minLength: {
                                    value: 6,
                                    message:
                                        'Password must have at least 6 characters',
                                },
                            })}
                        />
                    </IonItem>
                    {errors.password && (
                        <IonText color="danger">
                            <small>{errors.password.message}</small>
                        </IonText>
                    )}

                    <IonButton
                        className="ion-margin on-board-sig-up-btn"
                        size="default"
                        color="primary"
                        expand="block"
                        type="submit"
                    >
                        {loading === true && (
                            <IonSpinner slot="start" name="lines-small" />
                        )}
                        Sign up
                    </IonButton>
                    {!isMobileWeb && !isDesktop && <AppleLogin />}
                    <IonText color="dark" className="ion-text-center">
                        <h5>
                            Already have a account?{' '}
                            <Link to="/login">Login</Link>
                        </h5>
                    </IonText>
                    <IonToast
                        isOpen={showToast}
                        onDidDismiss={() => setShowToast(false)}
                        {...toastData}
                    />
                </form>
            </IonContent>
        </IonPage>
    );
};

export default SignUp;
