import * as React from 'react';

function CoffeeCupSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16.337 8.745a1.916 1.916 0 001.905-1.915V4.915A1.916 1.916 0 0016.337 3a1.916 1.916 0 00-1.905 1.915V6.83c0 1.053.838 1.915 1.905 1.915z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M16.337 8.745a1.916 1.916 0 001.905-1.915V4.915A1.916 1.916 0 0016.337 3a1.916 1.916 0 00-1.905 1.915V6.83c0 1.053.838 1.915 1.905 1.915zM22.05 8.745a1.916 1.916 0 001.906-1.915V4.915A1.916 1.916 0 0022.05 3a1.916 1.916 0 00-1.905 1.915V6.83c0 1.053.838 1.915 1.905 1.915z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M22.05 8.745a1.916 1.916 0 001.906-1.915V4.915A1.916 1.916 0 0022.05 3a1.916 1.916 0 00-1.905 1.915V6.83c0 1.053.838 1.915 1.905 1.915zM27.767 8.745a1.916 1.916 0 001.905-1.915V4.915A1.916 1.916 0 0027.767 3a1.916 1.916 0 00-1.906 1.915V6.83c0 1.053.839 1.915 1.905 1.915z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M27.767 8.745a1.916 1.916 0 001.905-1.915V4.915A1.916 1.916 0 0027.767 3a1.916 1.916 0 00-1.906 1.915V6.83c0 1.053.839 1.915 1.905 1.915zM31.578 10.66H12.526a1.916 1.916 0 00-1.905 1.915v1.916H8.773C5.59 14.49 3 17.076 3 20.236v3.83c0 3.16 2.572 5.745 5.716 5.745h2.248c.78 2.221 2.876 3.83 5.372 3.83h11.431c3.144 0 5.716-2.586 5.716-5.745v-15.32a1.916 1.916 0 00-1.905-1.915zM6.81 24.067v-3.83c0-1.035.896-1.915 1.963-1.915h1.848v7.66H8.716a1.916 1.916 0 01-1.906-1.915zm20.957 5.745h-11.43a1.916 1.916 0 01-1.906-1.915V14.49h15.241v13.405a1.916 1.916 0 01-1.905 1.915z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M31.578 10.66H12.526a1.916 1.916 0 00-1.905 1.915v1.916H8.773C5.59 14.49 3 17.076 3 20.236v3.83c0 3.16 2.572 5.745 5.716 5.745h2.248c.78 2.221 2.876 3.83 5.372 3.83h11.431c3.144 0 5.716-2.586 5.716-5.745v-15.32a1.916 1.916 0 00-1.905-1.915zM6.81 24.067v-3.83c0-1.035.896-1.915 1.963-1.915h1.848v7.66H8.716a1.916 1.916 0 01-1.906-1.915zm20.957 5.745h-11.43a1.916 1.916 0 01-1.906-1.915V14.49h15.241v13.405a1.916 1.916 0 01-1.905 1.915z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default CoffeeCupSvg;
