import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    IonAvatar,
    IonCheckbox,
    IonChip,
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSearchbar,
    useIonAlert,
} from '@ionic/react';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import Verify from './Verify';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import AddUserSvg from '../../svg/AddUserSvg';
import EmptyProfilePictureSvg from '../../svg/EmptyProfilePictureSvg';
import AddNewMember from './AddNewMember';
import UserOutlineSvg from '../../svg/UserOutlineSvg';
import { closeCircle } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import { GroupInterface } from '../../types/GroupInterface';
import { TransactionInterface } from '../../types/TransactionInterface';
import { UserInterface } from '../../types/UserInterface';
import { fetchUsers } from '../../firebase/UserCollection';
import { TemporaryContactCollectionInterface } from '../../types/TemporaryContactInterface';
import { setUserList } from '../../redux/slices/useSlice';
import MobileContactList from './MobileContactList';
import { Contact } from '@capacitor-community/contacts';

import './index.css';

interface ContactListInterface {
    closeModal: () => void;
    group: GroupInterface;
    fetchData: () => void;
    transactions: TransactionInterface[];
    groupMembers: UserInterface[];
    screen: String;
}

const ADD_NEW_CONTACT = 1;
const VERIFY_USERS = 2;

const ContactList: FC<ContactListInterface> = ({
    closeModal,
    group,
    fetchData,
    transactions,
    groupMembers,
    screen,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [members, setMembers] = useState<UserInterface[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [modalType, setModalType] = useState<number>(VERIFY_USERS);
    const [selectedContacts, setSelectedContacts] = useState<
        Array<UserInterface>
    >([]);
    const [mobileContacts, setMobileContacts] = useState<Contact[]>([]);
    const [newAddedUsers, setNewAddedUsers] = useState<
        Array<TemporaryContactCollectionInterface>
    >([]);
    const [removedContacts, setRemovedContacts] = useState<UserInterface[]>([]);

    const { querySnapshot } = useQuerySnapshot();
    const dispatch = useDispatch();
    const [present] = useIonAlert();

    const { users, auth } = useSelector((state: RootReducerInterface) => state);
    const { userList } = users;
    const { authUser } = auth;

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        groupMembers.forEach(function (item, i) {
            if (item.id === authUser?.id) {
                groupMembers.splice(i, 1);
                groupMembers.unshift(item);
            }
        });
        setSelectedContacts(groupMembers);
        return () => setSelectedContacts([]);
    }, [group, userList]);

    const getUsers = async () => {
        try {
            var snapshot = await fetchUsers();
            querySnapshot(snapshot, (payload: Array<UserInterface>) => {
                dispatch(setUserList(payload));
            });
        } catch (error) {
            //@ts-ignore
            throw new Error(error);
        }
    };

    const searchContact = useCallback(() => {
        var text = searchText.toUpperCase();
        const filteredContacts: UserInterface[] = [];
        // if (!text) {
        //     setMembers(userList);
        // }
        for (let index = 0; index < userList.length; index++) {
            const element = userList[index];
            if (element.name && element.name.toUpperCase().indexOf(text) > -1) {
                filteredContacts.push(element);
            }
        }

        setMembers(() => [...filteredContacts]);
    }, [searchText, userList]);

    useEffect(() => {
        if (userList.length && searchText.length >= 3) {
            searchContact();
        } else {
            setMembers([]);
        }
    }, [searchText, userList]);

    const hasUserInvolvedTnx = (user: UserInterface) => {
        return !!transactions.find(({ involvedUsers }) => {
            return !!involvedUsers?.find((i) => i === user.id);
        });
    };
    const toggleContact = (user: UserInterface, isSelect: boolean) => {
        if (isSelect) {
            setSelectedContacts(() => [...selectedContacts, user]);
        } else {
            if (hasUserInvolvedTnx(user)) {
                present({
                    header: 'You cannot remove this user',
                    message: `They are already involved in transactions in the group`,
                    buttons: [
                        {
                            text: 'Got it',
                        },
                    ],
                    onDidDismiss: (e) => {},
                });
                return;
            }
            setRemovedContacts(() => [...removedContacts, user]);
            setSelectedContacts(() => {
                return selectedContacts.filter(({ id }) => id !== user.id);
            });
        }
    };

    const renderModalContent = () => {
        switch (modalType) {
            case VERIFY_USERS:
                return (
                    <Verify
                        group={group}
                        closeModal={() => {
                            if (
                                screen !== 'GROUPS_LIST_SCREEN' ||
                                screen !== 'TRANSACTIONS_SCREEN'
                            ) {
                                setShowModal(false);
                            }
                        }}
                        selectedContacts={selectedContacts}
                        newContacts={newAddedUsers}
                        goBack={() => closeModal()}
                        fetchData={fetchData}
                        mobileContacts={mobileContacts}
                        removedContacts={removedContacts}
                    />
                );
            case ADD_NEW_CONTACT:
                return (
                    <AddNewMember
                        group={group}
                        closeModal={() => setShowModal(false)}
                        addNewUsers={addNewUsers}
                    />
                );
            default:
                return <></>;
        }
    };

    const addNewUsers = (user: TemporaryContactCollectionInterface) => {
        setNewAddedUsers(() => [...newAddedUsers, user]);
    };
    const removeNewUsers = (user: TemporaryContactCollectionInterface) => {
        const newUserList = newAddedUsers.filter((userObj) => {
            if (user.email) {
                return userObj.email !== user.email;
            } else if (user.phoneNumber) {
                return userObj.phoneNumber !== user.phoneNumber;
            }
        });
        setNewAddedUsers(() => [...newUserList]);
    };

    return (
        <IonPage>
            <Header
                title="Add group members"
                leftComponent={
                    <HeaderActionButton
                        slot="start"
                        title="Cancel"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
                rightComponent={
                    <HeaderActionButton
                        title="Next"
                        color="secondary"
                        onClick={() => {
                            setModalType(VERIFY_USERS);
                            setShowModal(true);
                        }}
                    />
                }
            ></Header>
            <IonContent fullscreen>
                <IonSearchbar
                    value={searchText}
                    onIonChange={(e) => {
                        if (e.detail.value) {
                            setSearchText(e.detail.value);
                        } else {
                            setSearchText('');
                        }
                    }}
                />

                {(!!selectedContacts.length || !!newAddedUsers) && (
                    <div className="ion-chip-list">
                        {selectedContacts.map(
                            (i: UserInterface, index: number) => (
                                <IonChip key={index}>
                                    <IonAvatar>
                                        <UserOutlineSvg
                                            width={25}
                                            height={25}
                                        />
                                    </IonAvatar>
                                    <IonLabel>{i.name}</IonLabel>
                                    {authUser && authUser.id !== i.id && (
                                        <IonIcon
                                            onClick={() =>
                                                toggleContact(i, false)
                                            }
                                            icon={closeCircle}
                                        />
                                    )}
                                </IonChip>
                            )
                        )}
                        {newAddedUsers.map(
                            (
                                i: TemporaryContactCollectionInterface,
                                index: number
                            ) => (
                                <IonChip key={index}>
                                    <IonAvatar>
                                        <UserOutlineSvg
                                            width={25}
                                            height={25}
                                        />
                                    </IonAvatar>
                                    <IonLabel>{i.name}</IonLabel>
                                    <IonIcon
                                        onClick={() => removeNewUsers(i)}
                                        icon={closeCircle}
                                    />
                                </IonChip>
                            )
                        )}
                    </div>
                )}
                <IonList>
                    <IonItem
                        onClick={() => {
                            setModalType(ADD_NEW_CONTACT);
                            setShowModal(true);
                        }}
                    >
                        <IonAvatar slot="start">
                            <AddUserSvg />
                        </IonAvatar>
                        <IonLabel>
                            {!members.length && searchText
                                ? `Add “${searchText}” to BillMagic`
                                : `Add new contact to BillMagic`}
                        </IonLabel>
                    </IonItem>
                </IonList>

                <IonList>
                    <IonItemDivider className="divider">
                        <IonLabel>Your friends on BillMagic</IonLabel>
                    </IonItemDivider>
                    {members.map((user: UserInterface, index: number) => {
                        const { phoneNumber, name, email } = user;
                        if (authUser?.id === user.id) {
                            return <Fragment key={index} />;
                        }
                        return (
                            <IonItem key={index}>
                                <IonAvatar slot="start">
                                    <EmptyProfilePictureSvg />
                                </IonAvatar>
                                <IonLabel>
                                    <h2>{name}</h2>
                                    <h3>{phoneNumber ? phoneNumber : email}</h3>
                                </IonLabel>
                                <IonCheckbox
                                    disabled={hasUserInvolvedTnx(user)}
                                    color="dark"
                                    slot="end"
                                    checked={
                                        !!selectedContacts.find(
                                            ({ id }) => id === user.id
                                        )
                                    }
                                    onIonChange={(e) => {
                                        toggleContact(user, e.detail.checked);
                                    }}
                                />
                            </IonItem>
                        );
                    })}
                </IonList>
                <MobileContactList
                    searchText={searchText}
                    mobileContacts={mobileContacts}
                    onChangeList={(data: Contact[]) => {
                        setMobileContacts(() => [...data]);
                    }}
                />
            </IonContent>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                {renderModalContent()}
            </IonModal>
        </IonPage>
    );
};

export default ContactList;
