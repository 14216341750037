import auth from './auth';

/**
 * Login
 * @param email String
 * @param password String
 * @returns Promise<firebase.auth.UserCredential>
 */
export const loginUser = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
};

/**
 * Sign up
 * @param email String
 * @param password String
 * @returns Promise<firebase.auth.UserCredential>
 */
export const signUpUser = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
};

/**
 * Check authentication
 * @returns firebase.auth.Auth
 */
export const authCheck = () => {
    return auth;
};

/**
 * Sign out
 * @returns firebase.auth.Auth.signOut(): Promise<void>
 */
export const signOut = () => {
    return auth.signOut();
};

/**
 * Get auth token
 * @returns Promise<string>
 */
export const getAuthToken = () => {
    return auth.currentUser?.getIdToken(true);
};

export const deleteAccount = () => {
    const user = auth.currentUser;
    if (user) return user.delete();
    else return false;
};

export const sendResetEmail = (email: string) => {
    return auth.sendPasswordResetEmail(email);
};
