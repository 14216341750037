import * as React from 'react';

function USDSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={37}
            height={36}
            viewBox="0 0 37 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M22.25 16.895l-5.75-1.693a1.583 1.583 0 01-1.13-1.516c0-.873.704-1.58 1.572-1.58h3.53c.65 0 1.289.198 1.822.562a.826.826 0 001.038-.107l1.853-1.821c.378-.37.325-.986-.096-1.313a7.502 7.502 0 00-4.606-1.607V5.25a.857.857 0 00-.852-.857h-1.704a.857.857 0 00-.852.857V7.82h-.133c-3.393 0-6.12 2.93-5.805 6.407.224 2.47 2.098 4.479 4.463 5.175l5.458 1.607c.666.199 1.129.82 1.129 1.517 0 .873-.703 1.58-1.571 1.58h-3.53c-.65 0-1.29-.198-1.822-.563a.826.826 0 00-1.038.108l-1.854 1.821c-.378.37-.324.986.096 1.313a7.502 7.502 0 004.607 1.607v2.571c0 .471.383.857.852.857h1.704a.857.857 0 00.852-.857v-2.582c2.482-.048 4.809-1.532 5.629-3.895 1.145-3.3-.778-6.686-3.861-7.59z"
                fill="#B0B4C2"
            />
        </svg>
    );
}

export default USDSvg;
