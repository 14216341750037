import * as React from 'react';
import { SVGProps } from 'react';

const OthersSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#ra)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.349 24.315a4.602 4.602 0 0 1 3.255-1.348h7.31a4.602 4.602 0 0 1 4.603 4.602v1.828a.947.947 0 1 1-1.894 0V27.57a2.708 2.708 0 0 0-2.708-2.708h-7.311a2.709 2.709 0 0 0-2.71 2.708v1.828a.947.947 0 0 1-1.893 0V27.57c0-1.22.485-2.39 1.348-3.254Z"
            fill="url(#rb)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.172 24.138a4.853 4.853 0 0 1 3.431-1.421h7.312a4.853 4.853 0 0 1 4.852 4.852v1.828a1.197 1.197 0 1 1-2.394 0V27.57a2.458 2.458 0 0 0-2.458-2.458h-7.311a2.459 2.459 0 0 0-2.46 2.458v1.828a1.197 1.197 0 1 1-2.393 0V27.57c0-1.287.511-2.52 1.421-3.43Zm3.431-.921a4.353 4.353 0 0 0-4.352 4.352v1.828a.697.697 0 1 0 1.394 0V27.57a2.959 2.959 0 0 1 2.959-2.958h7.31a2.959 2.959 0 0 1 2.96 2.958v1.828a.697.697 0 0 0 1.393 0V27.57a4.353 4.353 0 0 0-4.352-4.352h-7.311Z"
            fill="url(#rc)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.406 16.602a4.853 4.853 0 1 1 9.705 0 4.853 4.853 0 0 1-9.705 0Zm4.853-2.458a2.459 2.459 0 1 0 0 4.917 2.459 2.459 0 0 0 0-4.917Z"
            fill="url(#rd)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.395 23.815a.947.947 0 0 1 1.153-.681 4.604 4.604 0 0 1 2.487 1.63c.625.805.965 1.793.966 2.81v1.823a.947.947 0 0 1-1.894 0v-1.821c0-.597-.2-1.176-.567-1.648a2.71 2.71 0 0 0-1.464-.96.947.947 0 0 1-.68-1.153Z"
            fill="url(#re)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.152 23.753a1.197 1.197 0 0 1 1.458-.861 4.854 4.854 0 0 1 2.622 1.72 4.832 4.832 0 0 1 1.018 2.963v1.822a1.197 1.197 0 0 1-2.394 0v-1.82m0 0a2.44 2.44 0 0 0-.514-1.496 2.46 2.46 0 0 0-1.329-.871 1.197 1.197 0 0 1-.86-1.457m1.332-.377a.697.697 0 0 0-.347 1.35 2.96 2.96 0 0 1 1.599 1.048c.4.516.619 1.15.62 1.802v1.821a.697.697 0 1 0 1.393 0v-1.822c0-.962-.322-1.897-.913-2.657a4.353 4.353 0 0 0-2.352-1.542Z"
            fill="url(#rf)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.496 12.786a1.197 1.197 0 0 1 1.457-.861 4.842 4.842 0 0 1 2.625 1.726 4.859 4.859 0 0 1-2.625 7.675 1.197 1.197 0 0 1-.595-2.319 2.448 2.448 0 0 0 1.327-.873 2.465 2.465 0 0 0-1.327-3.89 1.197 1.197 0 0 1-.862-1.458Z"
            fill="url(#rg)"
        />
        <defs>
            <linearGradient
                id="ra"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="rb"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="rc"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="rd"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="re"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="rf"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
            <linearGradient
                id="rg"
                x1={20.03}
                y1={13.421}
                x2={20.089}
                y2={30.595}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#57C5AA" />
                <stop offset={0.638} stopColor="#134591" />
            </linearGradient>
        </defs>
    </svg>
);

export default OthersSvg;
