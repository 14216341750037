import { TextFieldTypes } from '@ionic/core';
import { IonItem, IonAvatar, IonInput, IonText } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import USDSvg from '../../svg/USDSvg';
import { TransactionsType } from '../../types/enum';
import IonDecimalInput from '../IonDecimalInput';

type TnxAmountFieldType = {
    className: string;
    'data-testid': string;
    type?: TextFieldTypes;
    placeholder: string;
    value: string | number | null;
    onIonChange: (e: CustomEvent) => void;
    onPressRevert: (val: null | number) => void;
    isEdit?: boolean;
    readonly?: boolean;
};

const TnxAmountField = ({
    onPressRevert,
    isEdit,
    ...rest
}: TnxAmountFieldType) => {
    const { originalAmount, tnxType, amount } = useSelector(
        (state: RootState) => state.expenseForm
    );

    const inputField = <IonDecimalInput type="number" {...rest} />;

    const showRevertSection = () => {
        if (tnxType === TransactionsType.Bank) {
            if (originalAmount) {
                if (Number(rest.value) === Number(originalAmount)) {
                    return false;
                } else {
                    return true;
                }
            } else if (rest.value !== amount) {
                return true;
            }
        }
        return false;
    };

    return (
        <IonItem className="list-item custom-padding-item">
            <IonAvatar slot="start">
                <USDSvg />
            </IonAvatar>
            <div className="tnx-amount-input-wrapper">
                <div className="tnx-amount-input-field">{inputField}</div>
                {showRevertSection() && (
                    <div className="tnx-amount-input-revert">
                        <IonText className="original">
                            Original: ${originalAmount}
                        </IonText>
                        <br />
                        <IonText
                            color="primary"
                            onClick={() => onPressRevert(originalAmount)}
                        >
                            Revert
                        </IonText>
                    </div>
                )}
            </div>
        </IonItem>
    );
};

export default TnxAmountField;
