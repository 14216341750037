import { useEffect } from 'react';
import { useLocation } from 'react-router';

const usePageViews = () => {
    const location = useLocation();

    useEffect(() => {
        //@ts-ignore
        window.analytics.page(location.pathname);
    }, [location]);
};

export default usePageViews;
