import * as React from 'react';

function BankSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M33.105 30.286h-1.888V17.088h1.888c.812 0 1.53-.528 1.794-1.282.264-.773 0-1.621-.661-2.112L19.132 2.382a1.872 1.872 0 00-2.266 0L1.759 13.694c-.642.49-.925 1.34-.66 2.112a1.888 1.888 0 001.793 1.282h1.889v13.198H2.892a1.892 1.892 0 00-1.888 1.886c0 1.037.85 1.885 1.888 1.885h30.213a1.892 1.892 0 001.888-1.885c0-1.037-.85-1.886-1.888-1.886zM17.999 6.246l9.441 7.071H8.557L18 6.247zm-9.442 24.04V17.088h3.777v13.198H8.557zm7.553 0V17.088h3.777v13.198H16.11zm7.553 0V17.088h3.777v13.198h-3.777z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default BankSvg;
