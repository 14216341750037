import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/capacitor';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV !== 'local') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        // Set your release version, such as "getsentry@1.0.0"
        release: 'billMagic@1.0.0',
        // Set your dist version, such as "1"
        dist: '1',
        enableNative: false,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
