import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import SwipeableViews from 'react-swipeable-views';
import OnBoardMainButtons from '../../components/on-board/OnBoardMainButtons';
import { IonContent, IonPage } from '@ionic/react';
import Slide from '../../components/on-board/Slide';
import Pagination from '../../components/on-board/Pagination';
import useTrack from '../../hooks/useTrack';
import { useHistory } from 'react-router';
import { useLastLocation } from 'react-router-last-location';

import './index.css';
import {
    GROUP_LINK,
    LINK_TOKEN,
    LINK_TOKEN_TYPE,
    TEMPORARY_CONTACT_LINK,
} from '../../utils/constant';
const slides = [
    {
        title: 'Welcome to Bill Magic',
        subTitle: 'The easiest way to share expenses, like magic.',
        banner: 1,
    },
    {
        title: 'One and done',
        subTitle: ' Split expenses with friends with a single swipe.',
        banner: 2,
    },
    {
        title: 'Split costs automatically',
        subTitle: `Connect your bank account or credit card and never miss
        a shared expense again.`,
        banner: 3,
    },
];

const OnBoard = () => {
    const [current, setCurrent] = useState<number>(0);
    const { hitAnalytics } = useTrack();
    const history = useHistory();

    const lastLocation = useLastLocation();

    useEffect(() => {
        if (lastLocation) {
            var data = lastLocation.pathname.split('/');
            if (
                data[1] &&
                (data[1] === GROUP_LINK || data[1] === TEMPORARY_CONTACT_LINK)
            ) {
                localStorage.setItem(LINK_TOKEN_TYPE, data[1]);
                localStorage.setItem(LINK_TOKEN, data[2]);
            }
        }
    }, [lastLocation]);

    useEffect(() => {
        hitAnalytics(`loadOnboardingScreen${current + 1}`, { id: null });
    }, [current]);

    var contentClassProp: any = {};
    if (current === 1) {
        contentClassProp.className = 'slide-2';
    }

    const onClickSignUp = () => {
        hitAnalytics(`signUpOnboarding${current + 1}`, { id: null });
        if (current === 2) {
            history.push('/sign-up');
        } else {
            setCurrent(current + 1);
        }
    };
    const onClickLogin = () => {
        hitAnalytics(`loginOnboarding${current + 1}`, { id: null });
        history.push('/login');
    };

    return (
        <IonPage>
            <IonContent
                {...contentClassProp}
                scrollY={!Capacitor.isNativePlatform()}
            >
                <div className="on-board-content">
                    <div className="swipeable-wrapper">
                        <SwipeableViews
                            index={current}
                            style={{ overflowY: 'hidden' }}
                            onChangeIndex={(e: number) => setCurrent(e)}
                        >
                            {slides.map((slide) => (
                                <Slide {...slide} />
                            ))}
                        </SwipeableViews>
                    </div>
                    <div className="swipeable-footer">
                        <Pagination
                            current={Number(current)}
                            size={slides.length}
                        />
                        <OnBoardMainButtons
                            current={current}
                            onClickSignUp={onClickSignUp}
                            onClickLogin={onClickLogin}
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default OnBoard;
