import {
    cfaSignIn,
    mapUserToUserInfo,
    cfaSignInApple,
} from 'capacitor-firebase-auth';

import { createUser, fetchUser } from '../firebase/UserCollection';
import useTrack from './useTrack';

const useFirebaseAuth = () => {
    const { hitAnalytics } = useTrack();

    const cfaGoogleSignIn = () => {
        cfaSignIn('google.com')
            .pipe(mapUserToUserInfo())
            .subscribe((user) => {
                updateUserIsNew(user, 'Google');
            });
    };

    const cfaAppleSignIn = () => {
        cfaSignInApple().subscribe((user) => {
            updateUserIsNew(user, 'Apple');
        });
    };

    const updateUserIsNew = (user: any, event: string) => {
        fetchUser(user.uid).then((doc) => {
            const { email, uid: id, displayName: name } = user;
            if (!doc.exists) {
                hitAnalytics(`Sign up`, {
                    id,
                    name,
                    email,
                    method: event,
                });
                createUser({ email, name, id });
            } else {
                hitAnalytics(`Login`, { id, name, email, method: event });
            }
        });
    };

    return {
        cfaGoogleSignIn,
        cfaAppleSignIn,
    };
};

export default useFirebaseAuth;
