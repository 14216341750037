import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import firebase from 'firebase';
import {
    fetchTempContact,
    updateTempContact,
} from '../../firebase/TemporaryContactCollection';
import { RootState } from '../../redux/store';
import {
    assignUsersToGroup,
    fetchUserGroups,
    removeUsersFromGroup,
} from '../../firebase/GroupCollection';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import { updateTnxTempInvolvedUsers } from '../../firebase/TransactionCollection';
import { LINK_TOKEN } from '../../utils/constant';

type ParamsType = {
    id: string;
};

const TemporaryContactLink = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isUserAssign, setIsUserAssign] = useState<boolean>(false);
    let { id }: ParamsType = useParams();
    const { authUser } = useSelector((state: RootState) => state.auth);
    const { querySnapshot } = useQuerySnapshot();

    useEffect(() => {
        fetchTempContact(id).then(
            async (
                doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
            ) => {
                if (!doc.exists) {
                    localStorage.removeItem(LINK_TOKEN);
                    setIsUserAssign(true);
                    return;
                }
                const groupData = doc.data();
                let existingGroupIds: Array<string> = [];

                if (groupData) {
                    const { email, assignedGroupIds } = groupData;
                    if (authUser && authUser.email === email) {
                        const snapShot = await fetchUserGroups(authUser.id);

                        querySnapshot(snapShot, (groups) => {
                            existingGroupIds = groups.map(({ id }) => {
                                return id;
                            });
                        });

                        assignedGroupIds.forEach(async (groupId: string) => {
                            if (
                                !existingGroupIds.find((id) => id === groupId)
                            ) {
                                // Assign new user id
                                await assignUsersToGroup(groupId, authUser.id);
                                // Remove temporary user id
                                await removeUsersFromGroup(groupId, id);
                            }
                        });

                        // Update tnx involved user id
                        updateTnxTempInvolvedUsers(id, authUser.id);

                        // De activate temporary user
                        updateTempContact(id, {
                            isActive: false,
                        }).then(() => {
                            localStorage.removeItem(LINK_TOKEN);
                            // Redirect to groups list
                            setLoading(false);
                            setIsUserAssign(true);
                        });
                    }
                }
            }
        );
    }, [authUser]);

    if (isUserAssign) {
        return <Redirect to={`/groups`} />;
    }

    return loading ? (
        <IonPage>
            <IonContent>
                <IonLoading
                    isOpen={true}
                    onDidDismiss={() => {}}
                    message={'Please wait...'}
                ></IonLoading>
            </IonContent>
        </IonPage>
    ) : (
        <></>
    );
};

export default TemporaryContactLink;
