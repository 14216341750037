import { useIonToast } from '@ionic/react';
import GroupForm from './GroupForm';
import { updateTransactionsByAssignedGroupId } from '../../firebase/TransactionCollection';
import useSyncGroups from '../../hooks/useSyncGroups';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import { useSelector } from 'react-redux';
import useManageDefaultGroup from '../../hooks/useManageDefaultGroup';
import useLoading from '../../hooks/useLoading';
import { closeCircleOutline } from 'ionicons/icons';
import { GroupInterface } from '../../types/GroupInterface';
import { updateGroup } from '../../firebase/GroupCollection';

type EditGroupType = {
    onSuccess: () => void;
    closeModal: () => void;
    group: GroupInterface;
    onDeleteSuccess?: () => void;
};

const EditGroup = ({
    onSuccess,
    closeModal,
    group,
    onDeleteSuccess,
}: EditGroupType) => {
    const [present, dismiss] = useIonToast();

    const { syncGroups } = useSyncGroups();
    const { authUser } = useSelector(
        (state: RootReducerInterface) => state.auth
    );
    const {
        markAsDefaultGroup,
        unmarkedAsDefaultGroup,
    } = useManageDefaultGroup();
    const { isLoading, startLoading, stopLoading } = useLoading();

    const saveGroup = (data: any) => {
        try {
            const { isDefault, memberIds, created, ownerId, ...rest } = data;
            startLoading();

            updateGroup(group.id, rest).then(async () => {
                // Update tnx color if group color is updated
                if (rest.groupColor !== group.groupColor) {
                    updateTransactionsByAssignedGroupId(group.id, {
                        groupColor: rest.groupColor,
                    });
                }

                if (isDefault && group && group.id) {
                    markAsDefaultGroup(group.id);
                }
                if (!isDefault && group && group.id) {
                    unmarkedAsDefaultGroup(group.id);
                }
                await syncGroups();
                present({
                    color: 'success',
                    duration: 5000,
                    buttons: [
                        {
                            icon: closeCircleOutline,
                            handler: () => dismiss(),
                        },
                    ],
                    message: 'The group was successfully updated',
                });
                onSuccess();
                stopLoading();
            });
        } catch (error) {
            console.log('Error', error);
            stopLoading();
        }
    };

    return (
        <GroupForm
            createNewGroup={saveGroup}
            loading={isLoading}
            initialData={{
                name: group.name,
                description: group.description,
                groupColor: group.groupColor,
                isDefault: authUser?.defaultGroup === group?.id,
                groupIcon: group.groupIcon,
                id: group?.id,
            }}
            onDeleteSuccess={onDeleteSuccess}
            closeModal={closeModal}
        />
    );
};

export default EditGroup;
