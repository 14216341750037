import * as React from 'react';

function WrenchSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M33.59 26.681a1.98 1.98 0 01-1.975 1.975 1.98 1.98 0 01-1.974-1.975 2.958 2.958 0 00-2.961-2.962 2.958 2.958 0 00-2.962 2.962 2.958 2.958 0 002.962 2.962 1.98 1.98 0 011.974 1.974 1.98 1.98 0 01-1.974 1.975c-3.81 0-6.91-3.1-6.91-6.91 0-.652.118-1.265.276-1.857l-9.28-9.28c-.592.158-1.204.276-1.856.276-3.81 0-6.91-3.1-6.91-6.91a1.98 1.98 0 011.974-1.975A1.98 1.98 0 015.95 8.911a2.958 2.958 0 002.961 2.961c.178 0 .356-.02.533-.059.06 0 .1 0 .139-.02.158-.039.296-.079.434-.138a.593.593 0 00.217-.118 2.617 2.617 0 001.303-1.303.594.594 0 00.119-.218 2.8 2.8 0 00.138-.434c.02-.04.02-.079.02-.138.04-.178.059-.356.059-.533A2.958 2.958 0 008.91 5.949a1.98 1.98 0 01-1.974-1.975A1.98 1.98 0 018.91 2c3.81 0 6.91 3.1 6.91 6.91 0 .652-.118 1.264-.276 1.857l9.28 9.28c.592-.158 1.204-.277 1.856-.277 3.81 0 6.91 3.1 6.91 6.911z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default WrenchSvg;
