import * as React from 'react';

function FormGroupIconSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={29}
            height={29}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.972 18.244a5.506 5.506 0 013.894-1.612h8.746a5.507 5.507 0 015.506 5.506v2.187a1.133 1.133 0 01-2.265 0v-2.187a3.24 3.24 0 00-3.24-3.24H6.865a3.24 3.24 0 00-3.241 3.24v2.187a1.133 1.133 0 01-2.266 0v-2.187c0-1.46.58-2.86 1.613-3.894zM11.239 5.777a3.24 3.24 0 100 6.481 3.24 3.24 0 000-6.48zm-5.507 3.24a5.506 5.506 0 1111.013 0 5.506 5.506 0 01-11.013 0zM22.169 17.646c.156-.606.773-.97 1.38-.815 1.18.304 2.226.99 2.974 1.952a5.482 5.482 0 011.156 3.361v2.18a1.133 1.133 0 11-2.266 0v-2.178c0-.714-.239-1.408-.678-1.972a3.241 3.241 0 00-1.751-1.148 1.133 1.133 0 01-.815-1.38zM17.795 4.527a1.133 1.133 0 011.378-.816 5.494 5.494 0 012.978 1.96 5.514 5.514 0 010 6.75 5.494 5.494 0 01-2.977 1.958 1.133 1.133 0 01-.564-2.194 3.228 3.228 0 001.75-1.152 3.248 3.248 0 000-3.976 3.228 3.228 0 00-1.75-1.151 1.133 1.133 0 01-.815-1.38z"
                fill="#B0B4C2"
                stroke="#B0B4C2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default FormGroupIconSvg;
