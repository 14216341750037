import * as React from 'react';

function CreditCardSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={45}
            height={45}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.87 9.053h23.26a5.509 5.509 0 015.509 5.509v15.914a5.509 5.509 0 01-5.51 5.509H10.87a5.509 5.509 0 01-5.509-5.509V14.562a5.509 5.509 0 015.51-5.51zM34.13 11.5H10.87a3.06 3.06 0 00-3.06 3.06v.765h29.38v-.765a3.06 3.06 0 00-3.06-3.06zM7.81 30.476V19.917h29.38v10.559a3.06 3.06 0 01-3.06 3.06H10.87a3.06 3.06 0 01-3.06-3.06zm4.897-6.886h3.672a2.295 2.295 0 012.296 2.296v1.53a2.295 2.295 0 01-2.296 2.295h-3.672a2.295 2.295 0 01-2.296-2.295v-1.53a2.295 2.295 0 012.296-2.296z"
                fill="#64DDC0"
            />
        </svg>
    );
}

export default CreditCardSvg;
