import * as React from 'react';
import { SVGProps } from 'react';

const UpgradeAlertIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={61}
        height={37}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.47 28.571v-5.602h24.274v-9.336H.868V36.04h29.876v-7.469H6.47ZM30.744.562H.867v5.602h29.877V.562Z"
            fill="#777ED4"
        />
        <path
            d="M60.62.562H30.743v5.602H60.62V.562ZM30.744 22.97V36.04H60.62V13.632H30.744v9.336Zm11.203-1.868a4.66 4.66 0 0 1 3.735 1.867 4.668 4.668 0 1 1 0 5.602 4.668 4.668 0 1 1-3.735-7.47Z"
            fill="#ABB0EA"
        />
        <path d="M.867 6.164v7.47H60.62v-7.47H.867Z" fill="#2E4C6D" />
        <path d="M30.744 22.97H6.469v5.601h24.275V22.97Z" fill="#fff" />
        <path
            d="M41.947 30.439a4.661 4.661 0 0 0 3.735-1.868 4.648 4.648 0 0 1-.934-2.8c0-1.052.347-2.021.934-2.802a4.668 4.668 0 1 0-3.735 7.47Z"
            fill="#FFDA44"
        />
        <path
            d="M45.682 28.57a4.668 4.668 0 1 0 7.47-5.602 4.668 4.668 0 0 0-7.47 5.603Z"
            fill="#E736AC"
        />
    </svg>
);

export default UpgradeAlertIcon;
