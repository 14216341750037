import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import app from './slices/appSlice';
import auth from './slices/authSlice';
import bank from './slices/bankSlice';
import group from './slices/groupSlice';
import tnx from './slices/tnxSlice';
import users from './slices/useSlice';
import expenseForm from './slices/expenseFormSlice';

export const store = configureStore({
    reducer: {
        app,
        auth,
        tnx,
        group,
        bank,
        users,
        expenseForm,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
