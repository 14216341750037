import { IonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';

import './index.css';
import currency from 'currency.js';

interface ToastDataInterface {
    id: number;
    message: string;
    cssColor: string;
}

function get_random(list: Array<string>) {
    return list[Math.floor(Math.random() * list.length)];
}

const actions = [
    `just got paid back ${currency(Math.random() * 100, {
        fromCents: true,
        precision: 0,
    }).format()}`,
    `just saved ${currency(Math.random() * 100, {
        fromCents: true,
        precision: 0,
    }).format()} on split bills`,
    `just auto-split ${currency(Math.random() * 100, {
        fromCents: true,
        precision: 0,
    }).format()}`,
];

const data: ToastDataInterface[] = [...Array(100)].map((_, key) => {
    return {
        id: key + 1,
        message: `${faker.name.firstName()} ${get_random(actions)}`,
        cssColor: get_random(['color-1', 'color-2', 'color-3', 'color-4']),
    };
});

const OnBoardSocialProof = () => {
    const [show, setShow] = useState<boolean>(false);
    const [current, setCurrent] = useState<ToastDataInterface>(data[0]);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 1000);
    }, []);

    return (
        <IonToast
            duration={5000}
            onDidDismiss={() => {
                setShow(false);
                if (data.length !== current.id) {
                    setTimeout(() => {
                        setCurrent(
                            (previousCurrent) =>
                                data[previousCurrent.id] ?? data[0]
                        );
                        setShow(true);
                    }, 5000);
                }
            }}
            cssClass={`s-p ${current.cssColor}`}
            isOpen={show}
            message={current.message}
        />
    );
};

export default OnBoardSocialProof;
