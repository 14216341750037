import * as React from 'react';

function ShoppingTags(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.958 2c-.553 0-1.303.316-1.678.691L2.577 16.394c-.77.77-.77 2.014 0 2.784L16.398 33c.77.77 2.014.77 2.784 0l13.702-13.702c.395-.395.691-1.146.691-1.679V3.974A1.98 1.98 0 0031.601 2H17.958zm11.688 14.986L17.8 28.833 6.763 17.796 18.61 5.949h11.037v11.037z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <circle
                cx={24}
                cy={11}
                r={2}
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default ShoppingTags;
