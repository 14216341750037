import React from 'react';
import moment from 'moment';
import { addMailOpenTagToEmailAddressInAString } from '../../utils/common';

interface MessageInterface {
    data: any;
    isMine: boolean;
    startsSequence: boolean;
    endsSequence: boolean;
    showTimestamp: boolean;
}

function createMarkup(data: any) {
    return { __html: data };
}

export default function Message(props: MessageInterface) {
    const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;

    const friendlyTimestamp = moment(data.created.toDate()).format('LLLL');
    return (
        <div
            className={[
                'message',
                `${isMine ? 'mine' : ''}`,
                `${startsSequence ? 'start' : ''}`,
                `${endsSequence ? 'end' : ''}`,
            ].join(' ')}
        >
            {showTimestamp && (
                <div className="timestamp">{friendlyTimestamp}</div>
            )}

            <div className="bubble-container">
                <div
                    className="bubble"
                    title={friendlyTimestamp}
                    dangerouslySetInnerHTML={createMarkup(
                        addMailOpenTagToEmailAddressInAString(data.message)
                    )}
                ></div>
            </div>
        </div>
    );
}
