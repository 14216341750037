import firebase from 'firebase';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// var config = {
//     apiKey: 'AIzaSyBPTdaMQRhbGTsD3Ks8DzWz7LEL0ElPa9s',
//     authDomain: 'bill-magic-8ace1.firebaseapp.com',
//     databaseURL: 'https://bill-magic-8ace1-default-rtdb.firebaseio.com',
//     projectId: 'bill-magic-8ace1',
//     storageBucket: 'bill-magic-8ace1.appspot.com',
//     messagingSenderId: '993244934266',
//     appId: '1:993244934266:web:edc4f079e56be069aa4bc3',
// };

if (firebase.apps.length === 0) {
    firebase.initializeApp(config);
}

export default firebase;
