import * as React from 'react';
import { SVGProps } from 'react';

const DebitCardSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#aa)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.077 12.333H10.649a1.32 1.32 0 0 0-1.316 1.317v14.48a1.32 1.32 0 0 0 1.316 1.316h18.428a1.32 1.32 0 0 0 1.316-1.317V13.65a1.32 1.32 0 0 0-1.316-1.316Zm-1.316 14.48H11.966v-6.565H27.76v6.565Zm0-10.53H11.966v-1.317H27.76v1.316Zm-13.257 9.213h3.95c.368 0 .657-.29.657-.658v-1.316a.652.652 0 0 0-.658-.658h-3.949a.652.652 0 0 0-.658.658v1.316c0 .369.29.659.658.659Zm7.83.17h-2c-.187 0-.334-.293-.334-.666v-1.333c0-.374.147-.667.333-.667h2c.187 0 .334.293.334.667V25c0 .373-.147.667-.334.667Z"
            fill="url(#ab)"
        />
        <defs>
            <linearGradient
                id="aa"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F27171" />
                <stop offset={0.638} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="ab"
                x1={19.891}
                y1={13.851}
                x2={19.943}
                y2={29.447}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F27171" />
                <stop offset={0.638} stopColor="#FDC40C" />
            </linearGradient>
        </defs>
    </svg>
);

export default DebitCardSvg;
