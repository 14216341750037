import {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonProgressBar,
    IonToast,
    IonIcon,
    IonSpinner,
    isPlatform,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import {
    EMAIL_REGEX,
    GROUP_LINK,
    LINK_TOKEN,
    LINK_TOKEN_TYPE,
    TEMPORARY_CONTACT_LINK,
} from '../../utils/constant';
import { loginUser } from '../../firebase/AuthCollections';
import Header from '../../components/Header';
import GoogleLogin from './GoogleLogin';
import AppleLogin from './AppleLogin';
import useTrack from '../../hooks/useTrack';
import usePurchases from '../../hooks/usePurchases';
import { FIREBASE_AUTH_USER_NOT_FOUND } from '../../utils/errorCodes';
import '../sign-up/index.css';
import BMLogo from '../sign-up/BMLogo';
import { chevronBackOutline } from 'ionicons/icons';

type UserLoginData = {
    email: string;
    password: string;
};

const Login: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { register, handleSubmit, errors } = useForm<UserLoginData>();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastData, setToastData] = useState<{}>({});
    const { hitAnalytics } = useTrack();
    const history = useHistory();

    const lastLocation = useLastLocation();

    useEffect(() => {
        if (lastLocation) {
            var data = lastLocation.pathname.split('/');
            if (
                data[1] &&
                (data[1] === GROUP_LINK || data[1] === TEMPORARY_CONTACT_LINK)
            ) {
                localStorage.setItem(LINK_TOKEN_TYPE, data[1]);
                localStorage.setItem(LINK_TOKEN, data[2]);
            }
        }
    }, [lastLocation]);

    const onSubmit = ({ email, password }: UserLoginData) => {
        setLoading(true);
        hitAnalytics('Login', { email, method: 'email' });
        loginUser(email, password)
            ?.then((data) => {
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                setToastData((toastData) => {
                    return {
                        ...toastData,
                        color: 'danger',
                        message:
                            e.code === FIREBASE_AUTH_USER_NOT_FOUND
                                ? `There's no account with this email. Please check for typos or sign up.`
                                : e.message,
                        duration: 4000,
                    };
                });
                setShowToast(true);
            });
    };

    const isMobileWeb = isPlatform('mobileweb');
    const isDesktop = isPlatform('desktop');

    return (
        <IonPage>
            <Header
                title=""
                ionToolBarClasses={'header-border-zero'}
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        icon={chevronBackOutline}
                        onClick={() => history.goBack()}
                    />
                }
            />
            <IonContent className="ion-padding" scrollY={false}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bm-logo-wrapper">
                        <BMLogo />
                    </div>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            type="email"
                            name="email"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a email',
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                    </IonItem>
                    {errors.email && (
                        <p className="ion-text-center">
                            <IonText color="danger">
                                <small>{errors.email.message}</small>
                            </IonText>
                        </p>
                    )}
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            autocapitalize="on"
                            name="password"
                            type="password"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a password',
                                minLength: {
                                    value: 6,
                                    message:
                                        'Password must have at least 6 characters',
                                },
                            })}
                        />
                    </IonItem>
                    {errors.password && (
                        <p className="ion-text-center">
                            <IonText color="danger">
                                <small>{errors.password.message}</small>
                            </IonText>
                        </p>
                    )}
                    <IonButton
                        className="ion-margin on-board-sig-up-btn"
                        size="default"
                        color="primary"
                        expand="block"
                        type="submit"
                    >
                        {loading === true && (
                            <IonSpinner slot="start" name="lines-small" />
                        )}
                        Login
                    </IonButton>
                    {!isMobileWeb && !isDesktop && <AppleLogin />}
                    {/* <GoogleLogin /> */}
                    <IonText color="dark" className="ion-text-center">
                        <h5>
                            First time here? <Link to="/sign-up">Sign up</Link>
                        </h5>
                        <h5>
                            <Link to="/forget-password">Forgot password</Link>
                        </h5>
                    </IonText>
                </form>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    {...toastData}
                />
            </IonContent>
        </IonPage>
    );
};

export default Login;
