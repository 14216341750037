import * as React from 'react';

function CommentSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={22}
            height={18}
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.317 13.77c1.05-1.053 1.68-2.375 1.68-3.818 0-3.11-2.92-5.68-6.728-6.138C14.066 1.574 11.239 0 7.944 0 3.556 0 0 2.784 0 6.22c0 1.439.63 2.76 1.68 3.818a10.178 10.178 0 01-1.44 2.134.908.908 0 00-.167.972.88.88 0 00.81.544c2.043 0 3.692-.785 4.78-1.508.352.082.715.144 1.085.19 1.2 2.232 4.014 3.803 7.306 3.803.794 0 1.558-.094 2.284-.265 1.088.72 2.734 1.509 4.781 1.509a.884.884 0 00.81-.545.915.915 0 00-.168-.972 9.912 9.912 0 01-1.444-2.13zm-15-3.588l-.654.431a7.712 7.712 0 01-1.646.832c.103-.183.206-.377.306-.575l.592-1.21-.947-.952c-.516-.52-1.135-1.372-1.135-2.488 0-2.36 2.8-4.354 6.11-4.354 3.312 0 6.111 1.994 6.111 4.354 0 2.36-2.8 4.354-6.11 4.354-.63 0-1.26-.073-1.872-.217l-.756-.175zM19.03 12.44l-.943.95.592 1.208c.1.198.202.393.305.575a7.716 7.716 0 01-1.646-.832l-.653-.431-.76.179a8.177 8.177 0 01-1.87.217c-2.063 0-3.904-.78-5.015-1.932 3.868-.42 6.847-3.009 6.847-6.154 0-.132-.015-.26-.027-.389 2.46.564 4.305 2.209 4.305 4.121 0 1.116-.62 1.967-1.135 2.489z"
                fill="#fff"
            />
        </svg>
    );
}

export default CommentSvg;
