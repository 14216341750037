import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankInterface, BankReducerInterface } from '../../types/BankInterface';

const initialState: BankReducerInterface = {
    bankList: [],
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        setBankList: (state, action: PayloadAction<BankInterface[]>) => {
            state.bankList = action.payload;
        },
        removeBankList: (state) => {
            state.bankList = [];
        },
    },
});

export const { setBankList, removeBankList } = bankSlice.actions;

export default bankSlice.reducer;
