import { IonLabel, useIonAlert } from '@ionic/react';
import { FC } from 'react';
import { deleteTransaction } from '../../firebase/TransactionCollection';
import { SettlementInterface } from '../../types/GroupInterface';

interface TnxDeleteInterface {
    settlement: SettlementInterface;
    close: () => void;
}

const TnxDelete: FC<TnxDeleteInterface> = ({ settlement: { id }, close }) => {
    const [present] = useIonAlert();
    const onPressDelete = () => {
        if (!id) {
            return;
        }
        present({
            cssClass: 'my-css',
            header: 'Are you sure!',
            message: 'You want to delete this settlement?',
            buttons: [
                'Cancel',
                {
                    text: 'Ok',
                    handler: async (d) => {
                        await deleteTransaction(id);
                        close();
                    },
                },
            ],
        });
    };
    return (
        <div className="delete-wrapper-settled-form">
            <IonLabel onClick={onPressDelete} color="alert">
                Delete
            </IonLabel>
        </div>
    );
};

export default TnxDelete;
