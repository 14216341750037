import { IonIcon, IonRow, IonSkeletonText, IonText } from '@ionic/react';
import { GroupInterface } from '../../types/GroupInterface';
import { star, person, personAdd } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { fetchGroupTemporaryContactsByGroupId } from '../../firebase/TemporaryContactCollection';
import { fetchUsersByUserIds } from '../../firebase/UserCollection';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { UserInterface, UserType } from '../../types/UserInterface';
import { SentryExceptionHandler } from '../../utils/SentryException';

type GroupMembersCountType = {
    defaultGroup: GroupInterface | null;
    group: GroupInterface;
    onClickAddMemberBtn?: (event: any, groupId: string) => void;
};

const GroupMembersCount = ({
    defaultGroup,
    group,
    onClickAddMemberBtn,
}: GroupMembersCountType) => {
    const [members, setMembers] = useState<UserInterface[]>([]);
    const [isLoading, setLoading] = useState(true);
    const { querySnapshot: querySnapshotMembers } = useQuerySnapshot();
    const { authUser } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        const groupMembersSnapshot = fetchUsersByUserIds(group.memberIds);

        const groupTemporaryContact = fetchGroupTemporaryContactsByGroupId(
            group.id
        );

        Promise.all([groupMembersSnapshot, groupTemporaryContact])
            .then((values: Array<any>) => {
                querySnapshotMembers(values[0], (gMembers) => {
                    querySnapshotMembers(values[1], (temContacts) => {
                        var membersArray = [
                            ...gMembers,
                            ...temContacts.map((i) => {
                                return {
                                    ...i,
                                    useType: UserType.Temporary,
                                };
                            }),
                        ];
                        membersArray.forEach(function (item, i) {
                            if (item.id === authUser?.id) {
                                membersArray.splice(i, 1);
                                membersArray.unshift(item);
                            }
                        });

                        setMembers(membersArray);
                        setLoading(false);
                    });
                });
            })
            .catch(SentryExceptionHandler);
    }, [group.updatedAt]);

    if (!isLoading) {
        return (
            <IonRow>
                {members.length > 1 && (
                    <div className="group-item-member-count">
                        <IonIcon
                            className="group-item-member-count-icon"
                            icon={person}
                            color="primary"
                        ></IonIcon>
                        <IonText className="group-item-member-count-text">
                            {members.length}
                        </IonText>
                    </div>
                )}
                {members.length <= 1 && (
                    <div
                        className="group-item-member-add"
                        onClick={(event) =>
                            onClickAddMemberBtn &&
                            onClickAddMemberBtn(event, group.id)
                        }
                    >
                        <IonIcon
                            className="group-item-member-add-icon"
                            icon={personAdd}
                        ></IonIcon>
                        <IonText className="group-item-member-add-text">
                            Add
                        </IonText>
                    </div>
                )}
                {defaultGroup?.id === group.id && (
                    <IonText
                        color={group.groupColor ? group.groupColor : 'magenta'}
                        className="group-item-sub-title"
                    >
                        <IonIcon
                            className="group-item-member-star-icon"
                            icon={star}
                        ></IonIcon>
                        Default
                    </IonText>
                )}
            </IonRow>
        );
    } else {
        return (
            <IonRow>
                <IonSkeletonText animated style={{ height: '14px' }} />
            </IonRow>
        );
    }
};

export default GroupMembersCount;
