import * as React from 'react';

function CreditCardSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.616 5H3.974A1.98 1.98 0 002 6.974v21.72a1.98 1.98 0 001.974 1.974h27.642a1.98 1.98 0 001.974-1.974V6.974A1.98 1.98 0 0031.615 5zM29.64 26.72H5.95V14.872H29.64v11.846zm0-15.796H5.95V8.949H29.64v1.975z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.757 24.745h5.923a.978.978 0 00.987-.987v-1.975a.978.978 0 00-.987-.987h-5.923a.978.978 0 00-.988.987v1.975c0 .553.435.987.988.987z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default CreditCardSVG;
