import { IonButton, IonText } from '@ionic/react';

interface OnBoardMainButtonsProps {
    current: number;
    onClickSignUp: () => void;
    onClickLogin: () => void;
}

const OnBoardMainButtons = ({
    onClickSignUp,
    onClickLogin,
    current,
}: OnBoardMainButtonsProps) => (
    <div className="on-board-btn-wrapper">
        <IonButton
            className="on-board-sig-up-btn"
            expand="block"
            onClick={onClickSignUp}
        >
            {current === 2 ? 'Sign up' : 'Next'}
        </IonButton>

        <p className="ion-text-center">
            <IonText onClick={onClickLogin} color="primary">
                Login
            </IonText>
        </p>
    </div>
);

export default OnBoardMainButtons;
