import { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchGroup } from '../firebase/GroupCollection';
import { fetchGroupTemporaryContactsByGroupId } from '../firebase/TemporaryContactCollection';
import { fetchUsersByUserIds } from '../firebase/UserCollection';
import { RootState } from '../redux/store';
import { GroupReducerInterface } from '../types/GroupInterface';
import useQuerySnapshot from './useQuerySnapshot';

const useGroupMembers = () => {
    const { querySnapshot } = useQuerySnapshot();
    const { authUser } = useSelector((state: RootState) => state.auth);
    const [members, setMembers] = useState<Array<any>>([]);

    const setGroupMembers = async (id: string) => {
        const group: any = await fetchGroup(id);

        const groupMembersSnapshot = fetchUsersByUserIds(
            group.data().memberIds
        );
        const groupTemporaryContact = fetchGroupTemporaryContactsByGroupId(id);
        Promise.all([groupMembersSnapshot, groupTemporaryContact])
            .then((values: Array<any>) => {
                querySnapshot(values[0], (gMembers) => {
                    querySnapshot(values[1], (temContacts) => {
                        var membersArray = [...gMembers, ...temContacts];
                        membersArray.forEach(function (item, i) {
                            if (item.id === authUser?.id) {
                                membersArray.splice(i, 1);
                                membersArray.unshift(item);
                            }
                        });
                        setMembers(membersArray);
                    });
                });
            })
            .catch((error) => {
                throw new Error(error);
            });
    };
    return { setGroupMembers, members };
};

export default useGroupMembers;
