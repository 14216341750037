import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    GroupInterface,
    GroupReducerInterface,
} from '../../types/GroupInterface';

const initialState: GroupReducerInterface = {
    groupList: [],
    isFetching: null,
};

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupList: (state, action: PayloadAction<GroupInterface[]>) => {
            state.groupList = action.payload;
        },
        removeGroupList: (state) => {
            state.groupList = [];
        },
        setIsFetching: (state, action: PayloadAction<boolean | null>) => {
            state.isFetching = action.payload;
        },
    },
});

export const {
    setGroupList,
    removeGroupList,
    setIsFetching,
} = groupSlice.actions;
export default groupSlice.reducer;
