import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    UserInterface,
    UsersReducerInterface,
} from '../../types/UserInterface';

const initialState: UsersReducerInterface = {
    userList: [],
};

export const bankSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserList: (state, action: PayloadAction<UserInterface[]>) => {
            state.userList = action.payload;
        },
        removeUserList: (state) => {
            state.userList = [];
        },
    },
});

export const { setUserList, removeUserList } = bankSlice.actions;

export default bankSlice.reducer;
