import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useHistory } from 'react-router';
import Chat from './Chat';

const SupportChat = () => {
    const { authUser } = useSelector((state: RootState) => state.auth);
    const { goBack } = useHistory();
    return authUser ? <Chat authUser={authUser} goBack={goBack} /> : <></>;
};

export default SupportChat;
