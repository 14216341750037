import { useState } from 'react';
import firebase from 'firebase';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonLabel,
    IonModal,
    IonPage,
    IonToolbar,
} from '@ionic/react';
import { add, close } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useModal from '../../hooks/useModal';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import CreateGroup from '../group-form/CreateGroup';
import GroupList from '../group/GroupList';

import EmptyGroupImage from '../../assets/groups-empty.png';
import ContactList from '../contacts/ContactList';

import { UserInterface, UserType } from '../../types/UserInterface';
import { TransactionInterface } from '../../types/TransactionInterface';
import { GroupInterface } from '../../types/GroupInterface';

import { SentryExceptionHandler } from '../../utils/SentryException';
import { fetchUsersByUserIds } from '../../firebase/UserCollection';
import { fetchGroupTemporaryContactsByGroupId } from '../../firebase/TemporaryContactCollection';
import { fetchTransactionByAssignedGroupId } from '../../firebase/TransactionCollection';
import { fetchGroup } from '../../firebase/GroupCollection';

type SelectGroupView = {
    closeModal: () => void;
    screen: String;
};

const CREATE_GROUP = 1;
const CONTACT_LIST_MODAL = 2;

const SelectGroup = ({ closeModal, screen }: SelectGroupView) => {
    const {
        group: { groupList },
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const { showModal, setShowModal, modalType, setModalType } = useModal();
    const [group, setGroup] = useState<GroupInterface>();
    const [transactions, setTransactions] = useState<TransactionInterface[]>(
        []
    );
    const [groupMembers, setGroupMembers] = useState<UserInterface[]>([]);
    const { querySnapshot: querySnapshotMembers } = useQuerySnapshot();
    const { querySnapshot } = useQuerySnapshot();

    const onClickItem = (i: GroupInterface) => {
        fetchData(i.id);
        setGroup(i);
    };

    const onClickAddMember = (groupId: string) => {
        fetchData(groupId);
    };

    const fetchData = async (groupId: string) => {
        try {
            await fetchGroup(groupId)
                .then(
                    async (
                        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                    ) => {
                        if (!doc.exists) {
                            return;
                        }
                        let data = doc.data();

                        let memberIds: Array<string> = data?.memberIds;
                        const groupMembersSnapshot = fetchUsersByUserIds(
                            memberIds
                        );
                        const groupTemporaryContact = fetchGroupTemporaryContactsByGroupId(
                            groupId
                        );

                        Promise.all([
                            groupMembersSnapshot,
                            groupTemporaryContact,
                        ])
                            .then((values: Array<any>) => {
                                querySnapshotMembers(values[0], (gMembers) => {
                                    querySnapshotMembers(
                                        values[1],
                                        (temContacts) => {
                                            var membersArray = [
                                                ...gMembers,
                                                ...temContacts.map((i) => {
                                                    return {
                                                        ...i,
                                                        useType:
                                                            UserType.Temporary,
                                                    };
                                                }),
                                            ];
                                            membersArray.forEach(function (
                                                item,
                                                i
                                            ) {
                                                if (item.id === authUser?.id) {
                                                    membersArray.splice(i, 1);
                                                    membersArray.unshift(item);
                                                }
                                            });

                                            setGroupMembers(membersArray);
                                        }
                                    );
                                });
                            })
                            .catch(SentryExceptionHandler);
                        if (data) {
                            setGroup({
                                id: doc.id,
                                name: data.name,
                                ownerId: data.ownerId,
                                memberIds: data.memberIds,
                                ...data,
                            });
                            setModalType(CONTACT_LIST_MODAL);
                            setShowModal(true);
                        }
                    }
                )
                .catch(SentryExceptionHandler);

            var snapshot = await fetchTransactionByAssignedGroupId(groupId);
            querySnapshot(snapshot, (data) => {
                setTransactions(() => {
                    return data.map((d) => {
                        return { ...d };
                    });
                });
            });
        } catch (error) {
            SentryExceptionHandler(error);
        }
    };

    const renderModalContent = () => {
        switch (modalType) {
            case CREATE_GROUP:
                return showModal ? (
                    <CreateGroup
                        onSuccess={(id: string) => {
                            onClickAddMember(id);
                        }}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <></>
                );
            case CONTACT_LIST_MODAL:
                return group ? (
                    <ContactList
                        group={group}
                        closeModal={() => {
                            setShowModal(false);
                        }}
                        transactions={transactions}
                        fetchData={() => {}}
                        groupMembers={groupMembers}
                        screen={screen}
                    />
                ) : (
                    <></>
                );
            default:
                break;
        }
    };

    return (
        <IonPage>
            <div className="modal-header">
                <IonLabel className="modal-header-title">Select Group</IonLabel>
                <IonIcon
                    onClick={closeModal}
                    className="close-icon"
                    slot="end"
                    icon={close}
                />
            </div>
            <IonContent fullscreen>
                {!groupList.length ? (
                    <>
                        <div className="circle-wrapper">
                            <img src={EmptyGroupImage} />
                        </div>
                        <div className="group-modal-empty-message-wrapper">
                            Create your first group to start splitting expenses
                        </div>
                    </>
                ) : (
                    <GroupList
                        onClickListItem={onClickItem}
                        showSubtitle={false}
                        showRightArrow={false}
                    />
                )}
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <IonButton
                        className="form-button margin-top-zero"
                        size="default"
                        color="primary"
                        fill={groupList.length ? 'outline' : 'solid'}
                        expand="block"
                        onClick={() => {
                            setShowModal(true);
                            setModalType(CREATE_GROUP);
                        }}
                    >
                        <IonIcon icon={add}></IonIcon> Create Group
                    </IonButton>
                </IonToolbar>
            </IonFooter>
            {showModal && (
                <IonModal isOpen={showModal}>{renderModalContent()}</IonModal>
            )}
        </IonPage>
    );
};

export default SelectGroup;
