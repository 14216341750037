import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase';
import {
    IonButton,
    IonCol,
    IonFab,
    IonFabButton,
    IonIcon,
    IonModal,
    IonPage,
    IonRow,
    IonText,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import moment from 'moment';
import Balance from '../../components/settle/Balance';
import useTnxCalculate from '../../hooks/useTnxCalculate';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import ContactList from '../../components/contacts/ContactList';
import GroupSettings from '../../components/group/GroupSettings';
import GroupSkeleton from './GroupSkeleton';
import AvatarRaw from './AvatarRaw';
import { GroupInterface } from '../../types/GroupInterface';
import { fetchGroup } from '../../firebase/GroupCollection';
import { fetchTransactionByAssignedGroupId } from '../../firebase/TransactionCollection';
import { fetchUsersByUserIds } from '../../firebase/UserCollection';
import { UserInterface, UserType } from '../../types/UserInterface';
import { fetchGroupTemporaryContactsByGroupId } from '../../firebase/TemporaryContactCollection';
import { RootState } from '../../redux/store';
import useModal from '../../hooks/useModal';
import CreateTransaction from '../../components/expense/CreateTransaction';
import GroupTransactions from '../../components/group/GroupTransactions';
import GroupIconSVG from '../../svg/GroupIconSVG';
import GroupContentWrapper from '../../components/group/GroupContentWrapper';
import GroupSummaryCard from '../../components/group/GroupSummaryCard';
import { groupIcons } from '../../utils/constant';
import { TransactionsType } from '../../types/enum';
import CheckMarkSvg from '../../svg/CheckMarkSvg';
import usePageViews from '../../hooks/usePageViews';
import { TransactionInterface } from '../../types/TransactionInterface';
import EmptyGroup from './EmptyGroup';
import { SentryExceptionHandler } from '../../utils/SentryException';
import GroupTotals from '../../components/group/GroupTotals';
import useTrack from '../../hooks/useTrack';
import AddMemberImage from '../../assets/add-member.png';

interface GroupPropsInterface {
    id: string;
    goBack: () => void;
    router: HTMLIonRouterOutletElement | null;
}

const EXPENSES_MODAL = 1;
const STB_MODAL = 2;
const CONTACT_LIST_MODAL = 3;
const GROUP_SETTINGS_MODAL = 4;
export const TRANSACTION_CONTENT = 1;
export const TOTALS_CONTENT = 2;

const Group: FC<GroupPropsInterface> = ({ id, goBack, router }) => {
    usePageViews();
    const [groupInfo, setGroupInfo] = useState<GroupInterface>();
    const [loading, setLoading] = useState<boolean>(true);
    const [groupMembers, setGroupMembers] = useState<UserInterface[]>([]);
    const [transactions, setTransactions] = useState<TransactionInterface[]>(
        []
    );
    const [content, setContent] = useState(TRANSACTION_CONTENT);

    const { querySnapshot: querySnapshotMembers } = useQuerySnapshot();
    const { showModal, setShowModal, modalType, setModalType } = useModal();
    const { querySnapshot } = useQuerySnapshot();
    const { tnxDataArray, calculateTnx } = useTnxCalculate();
    const { authUser } = useSelector((state: RootState) => state.auth);
    const { hitAnalytics } = useTrack();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        calculateTnx(transactions, groupMembers);
    }, [transactions, groupMembers]);

    const fetchData = async () => {
        try {
            fetchGroup(id)
                .then(
                    async (
                        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                    ) => {
                        if (!doc.exists) {
                            return;
                        }
                        let data = doc.data();

                        let memberIds: Array<string> = data?.memberIds;
                        const groupMembersSnapshot = fetchUsersByUserIds(
                            memberIds
                        );
                        const groupTemporaryContact = fetchGroupTemporaryContactsByGroupId(
                            id
                        );

                        Promise.all([
                            groupMembersSnapshot,
                            groupTemporaryContact,
                        ])
                            .then((values: Array<any>) => {
                                querySnapshotMembers(values[0], (gMembers) => {
                                    querySnapshotMembers(
                                        values[1],
                                        (temContacts) => {
                                            var membersArray = [
                                                ...gMembers,
                                                ...temContacts.map((i) => {
                                                    return {
                                                        ...i,
                                                        useType:
                                                            UserType.Temporary,
                                                    };
                                                }),
                                            ];
                                            membersArray.forEach(function (
                                                item,
                                                i
                                            ) {
                                                if (item.id === authUser?.id) {
                                                    membersArray.splice(i, 1);
                                                    membersArray.unshift(item);
                                                }
                                            });

                                            setGroupMembers(membersArray);

                                            setLoading(false);
                                        }
                                    );
                                });
                            })
                            .catch(SentryExceptionHandler);
                        if (data) {
                            setGroupInfo({
                                id: doc.id,
                                name: data.name,
                                ownerId: data.ownerId,
                                memberIds: data.memberIds,
                                ...data,
                            });
                        }
                    }
                )
                .catch(SentryExceptionHandler);

            var snapshot = await fetchTransactionByAssignedGroupId(id);
            querySnapshot(snapshot, (data) => {
                setTransactions(() => {
                    return data.map((d) => {
                        return { ...d };
                    });
                });
            });
        } catch (error) {
            SentryExceptionHandler(error);
        }
    };

    const renderModalContent = () => {
        if (groupInfo) {
            switch (modalType) {
                case STB_MODAL:
                    return (
                        <Balance
                            closeModal={() => setShowModal(false)}
                            data={tnxDataArray}
                            group={groupInfo}
                            fetchTransactions={() => fetchData()}
                        />
                    );
                case EXPENSES_MODAL:
                    return authUser ? (
                        <CreateTransaction
                            authUser={authUser}
                            groupMembers={groupMembers}
                            group={groupInfo}
                            closeModal={() => setShowModal(false)}
                            fetchTransactions={() => fetchData()}
                        />
                    ) : (
                        <></>
                    );
                case CONTACT_LIST_MODAL:
                    return groupInfo ? (
                        <ContactList
                            screen={'GROUP_VIEW'}
                            group={groupInfo}
                            closeModal={() => {
                                setShowModal(false);
                                setModalType(0);
                            }}
                            transactions={transactions}
                            fetchData={() => fetchData()}
                            groupMembers={groupMembers}
                        />
                    ) : (
                        <></>
                    );
                case GROUP_SETTINGS_MODAL:
                    return groupInfo ? (
                        <GroupSettings
                            onDeleteSuccess={() => {
                                setShowModal(false);
                                setModalType(0);
                                goBack();
                            }}
                            group={groupInfo}
                            onClickAddMembers={() => {
                                if (authUser) {
                                    hitAnalytics('OPEN_INVITE_SCREEN', {
                                        id: authUser.id,
                                        email: authUser.email,
                                    });
                                }
                                setModalType(CONTACT_LIST_MODAL);
                            }}
                            closeModal={() => {
                                setShowModal(false);
                                setModalType(0);
                            }}
                            fetchData={() => fetchData()}
                        />
                    ) : (
                        <></>
                    );
                default:
                    return <></>;
            }
        }
        return <></>;
    };

    const isSettledGroup = () => {
        if (
            tnxDataArray.length === 0 &&
            !!transactions.find(({ type }) => type === TransactionsType.Settled)
        ) {
            return true;
        }
        return false;
    };

    const isEmptyGroup = !loading && !transactions.length;

    return (
        <IonPage>
            <GroupContentWrapper
                goBack={goBack}
                groupInfo={groupInfo}
                loading={loading}
                onPressHeaderMenu={() => {
                    setModalType(GROUP_SETTINGS_MODAL);
                    setShowModal(true);
                }}
            >
                {loading ? (
                    <div className="group-list-content-wrapper">
                        <GroupSkeleton />
                    </div>
                ) : (
                    <>
                        <div className="group-icon-wrapper">
                            <div className="group-icon-ellipse">
                                {groupInfo?.groupIcon ? (
                                    groupIcons({ width: 35, height: 35 }).find(
                                        ({ id }) => id === groupInfo.groupIcon
                                    )?.icon
                                ) : (
                                    <GroupIconSVG />
                                )}
                            </div>
                        </div>
                        <div className="group-created-date-wrapper">
                            <IonText color="light">
                                <h1>{groupInfo?.name ?? ''}</h1>
                            </IonText>

                            {groupInfo && (
                                <p>
                                    CREATED{' '}
                                    {moment(groupInfo.created.toDate()).format(
                                        'MMM YYYY'
                                    )}
                                </p>
                            )}
                        </div>
                        <AvatarRaw
                            groupInfo={groupInfo}
                            groupMembers={groupMembers}
                            onClickAddMembers={() => {
                                setModalType(CONTACT_LIST_MODAL);
                                setShowModal(true);
                            }}
                        />
                        <IonRow className="group-witch-btn-raw">
                            <IonCol className="ion-align-self-center">
                                <IonButton
                                    fill={
                                        content === TRANSACTION_CONTENT
                                            ? 'solid'
                                            : 'outline'
                                    }
                                    expand="block"
                                    size="small"
                                    className="group-witch-btn group-switch-btn"
                                    color="light"
                                    onClick={() =>
                                        setContent(TRANSACTION_CONTENT)
                                    }
                                >
                                    Transactions
                                </IonButton>
                            </IonCol>
                            <IonCol className="ion-align-self-center">
                                <IonButton
                                    fill={
                                        content === TOTALS_CONTENT
                                            ? 'solid'
                                            : 'outline'
                                    }
                                    color="light"
                                    expand="block"
                                    size="small"
                                    className="group-witch-btn group-switch-btn"
                                    onClick={() => setContent(TOTALS_CONTENT)}
                                >
                                    Totals
                                </IonButton>
                            </IonCol>
                        </IonRow>

                        {isSettledGroup() && content === TRANSACTION_CONTENT ? (
                            <>
                                <div className="group-main-divider-wrapper">
                                    <hr className="group-main-divider " />
                                </div>
                                <div className="settled-balance-wrapper">
                                    <div
                                        style={{
                                            flex: 1,
                                            textAlign: 'end',
                                        }}
                                    >
                                        <CheckMarkSvg
                                            width={36}
                                            height={36}
                                            fill="#FFFFFF"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            flex: 2,
                                            textAlign: 'start',
                                            marginTop: 10,
                                        }}
                                    >
                                        {' All Balances Settled! '}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {!isEmptyGroup && (
                                    <GroupSummaryCard
                                        content={content}
                                        groupMembers={groupMembers}
                                        tnxDataArray={tnxDataArray}
                                        transactions={transactions}
                                        recordPayment={() => {
                                            setModalType(STB_MODAL);
                                            setShowModal(true);
                                        }}
                                    />
                                )}
                            </>
                        )}

                        {groupInfo && (
                            <div className="group-list-content-wrapper">
                                {!isEmptyGroup ? (
                                    <>
                                        {content === TRANSACTION_CONTENT ? (
                                            <GroupTransactions
                                                fetchTransactions={() =>
                                                    fetchData()
                                                }
                                                groupInfo={groupInfo}
                                                groupMembers={groupMembers}
                                                transactions={transactions}
                                            />
                                        ) : (
                                            <GroupTotals
                                                groupInfo={groupInfo}
                                                groupMembers={groupMembers}
                                                transactions={transactions}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <EmptyGroup
                                        group={groupInfo}
                                        onClickAddMembers={() => {
                                            setModalType(CONTACT_LIST_MODAL);
                                            setShowModal(true);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton
                                className="add-memeber-fab-button"
                                size="small"
                                onClick={() => {
                                    setModalType(CONTACT_LIST_MODAL);
                                    setShowModal(true);
                                }}
                            >
                                <img
                                    className="add-memeber-image"
                                    src={AddMemberImage}
                                />
                            </IonFabButton>
                            <IonFabButton
                                className="add-transaction-fab-button"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    setModalType(EXPENSES_MODAL);
                                    setShowModal(true);
                                }}
                            >
                                <IonIcon icon={add} />
                            </IonFabButton>
                        </IonFab>
                        <IonModal
                            presentingElement={router || undefined}
                            swipeToClose={true}
                            isOpen={showModal}
                            onDidDismiss={() => setShowModal(false)}
                        >
                            {showModal ? renderModalContent() : <></>}
                        </IonModal>
                    </>
                )}
            </GroupContentWrapper>
        </IonPage>
    );
};

export default Group;
