import * as React from 'react';

function FilterSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={26}
            height={26}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.182 9.04H4.773a1.016 1.016 0 110-2.032h8.481a3.048 3.048 0 015.671 0h2.352a1.016 1.016 0 110 2.031h-2.28a3.048 3.048 0 01-5.815 0zm2.908-1.931a1.016 1.016 0 110 2.031 1.016 1.016 0 010-2.031zM9.96 20.313c1.29 0 2.39-.8 2.836-1.93h8.481a1.016 1.016 0 000-2.031h-8.409a3.048 3.048 0 00-5.815 0h-2.28a1.016 1.016 0 000 2.03h2.352a3.048 3.048 0 002.836 1.93zm0-2.031a1.016 1.016 0 111.016-1.037 1.01 1.01 0 00-.007.122v.021c-.06.504-.489.894-1.008.894z"
                fill="#1868DF"
            />
        </svg>
    );
}

export default FilterSvg;
