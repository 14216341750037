import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IonApp, IonButton, IonContent, IonPage, IonText } from '@ionic/react';
import { Provider } from 'react-redux';
import { store } from './redux/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables-s */
import './theme/variables.css';
/* Global styles */
import './theme/index.css';

import Routes from './Routes';
import { Purchases } from '@ionic-native/purchases';
import { REVENUE_CAT_API_KEY } from './utils/constant';
import useExperiment from './hooks/useExperiment';

function ErrorFallback({
    error,
    resetErrorBoundary,
}: {
    error: any;
    resetErrorBoundary: () => void;
}) {
    return (
        <IonPage>
            <IonContent fullscreen>
                <IonText color="danger">
                    <h1 style={{ textAlign: 'center' }}>
                        Something went wrong:
                    </h1>
                </IonText>
                <IonButton
                    onClick={resetErrorBoundary}
                    className="form-button"
                    expand="block"
                    color="danger"
                    size="default"
                >
                    Try again
                </IonButton>
            </IonContent>
        </IonPage>
    );
}

const appErrorHandler = (error: Error, info: { componentStack: string }) => {
    throw new Error(info.componentStack);
};

const App: React.FC = () => {
    const { syncExperiments } = useExperiment();
    useEffect(() => {
        Purchases.setDebugLogsEnabled(false);
        Purchases.setup(REVENUE_CAT_API_KEY);
        syncExperiments();
    }, []);
    return (
        <ErrorBoundary
            onError={appErrorHandler}
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <IonApp>
                <Provider store={store}>
                    <Routes />
                </Provider>
            </IonApp>
        </ErrorBoundary>
    );
};

export default App;
