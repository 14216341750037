import { IonApp, IonContent, IonItem, IonLabel, IonList } from '@ionic/react';
import { useState } from 'react';
import Header from '../components/Header';

const AppSticky = () => {
    const [showHeder, setShowHeader] = useState<boolean>(false);

    return (
        <IonApp>
            {showHeder && (
                <Header title="Sticky Header" color="magenta"></Header>
            )}
            <IonContent
                className="group-content"
                fullscreen
                scrollEvents={true}
                onIonScroll={(e) => {
                    if (e.detail.scrollTop > 0) {
                        setShowHeader(true);
                    } else {
                        setShowHeader(false);
                    }
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '45%',
                        position: 'sticky',
                        top: 0,
                        display: 'flex',
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        color: 'white',
                    }}
                >
                    <div></div>
                </div>

                <IonList>
                    {[...Array(24)].map((i, key) => (
                        <IonItem key={key}>
                            <IonLabel>
                                <h3>Hello</h3>
                                <p></p>
                                <p></p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonApp>
    );
};

export default AppSticky;
