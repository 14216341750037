import { useState } from 'react';
import firebase from 'firebase';

const useQuerySnapshot = () => {
    const [data, setData] = useState<Array<any>>([]);

    const querySnapshot = (
        snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
        callBack: (payload: Array<any>) => void | null | undefined = () => {}
    ) => {
        const dataArray: any = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc: any) => {
                const docData = doc.data();
                if (doc.exists && docData) {
                    dataArray.push({ ...docData, id: doc.id });
                }
            });
        }
        if (callBack) {
            callBack(dataArray);
        }
        setData([...dataArray]);
    };
    return { data, querySnapshot };
};

export default useQuerySnapshot;
