import * as React from 'react';
import { SVGProps } from 'react';

const MobilePaySvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#ba)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.867 10.333H14.133c-.806 0-1.466.686-1.466 1.524v18.286c0 .838.66 1.524 1.466 1.524h11.734c.806 0 1.466-.686 1.466-1.524V11.857c0-.838-.66-1.524-1.466-1.524Zm-5.134 19.81h-1.466c-.41 0-.734-.335-.734-.762v-1.524c0-.427.323-.762.734-.762h1.466c.411 0 .734.335.734.762v1.524a.75.75 0 0 1-.734.762ZM24.4 25.57h-8.8v-12.19h8.8v12.19Z"
            fill="url(#bb)"
        />
        <defs>
            <linearGradient
                id="ba"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5260FF" />
                <stop offset={0.476} stopColor="#3DC2FF" />
            </linearGradient>
            <linearGradient
                id="bb"
                x1={20.02}
                y1={12.225}
                x2={20.134}
                y2={31.667}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5260FF" />
                <stop offset={0.476} stopColor="#3DC2FF" />
            </linearGradient>
        </defs>
    </svg>
);

export default MobilePaySvg;
