import { IonButton, IonIcon } from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';

const GoogleLogin = () => {
    const { cfaGoogleSignIn } = useFirebaseAuth();
    return <></>;
    return (
        <IonButton
            className="ion-margin on-board-sig-up-btn"
            size="default"
            color="medium"
            expand="block"
            fill="outline"
            onClick={cfaGoogleSignIn}
        >
            <IonIcon slot="start" icon={logoGoogle} /> Sign up with Google
        </IonButton>
    );
};

export default GoogleLogin;
