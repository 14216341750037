import { useEffect, useState } from 'react';
import {
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon,
    IonModal,
    IonPage,
} from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import AddMemberImage from '../../assets/add-member.png';
import Header from '../../components/Header';
import GroupList from '../../components/group/GroupList';
import ContactList from '../../components/contacts/ContactList';
import CreateGroup from '../../components/group-form/CreateGroup';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import usePageViews from '../../hooks/usePageViews';
import useModal from '../../hooks/useModal';
import { GroupInterface } from '../../types/GroupInterface';
import { TransactionInterface } from '../../types/TransactionInterface';
import { UserInterface, UserType } from '../../types/UserInterface';
import { SentryExceptionHandler } from '../../utils/SentryException';

import { fetchGroupTemporaryContactsByGroupId } from '../../firebase/TemporaryContactCollection';
import { fetchGroup } from '../../firebase/GroupCollection';
import { fetchUsersByUserIds } from '../../firebase/UserCollection';
import firebase from 'firebase';

import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Group from './Group';
import './index.css';
import SelectGroup from '../../components/group/SelectGroup';

const CREATE_MODAL = 1;
const VIEW_MODAL = 2;
const CONTACT_LIST_MODAL = 3;
const GROUP_LIST = 4;

interface GroupsInterface {
    router: HTMLIonRouterOutletElement | null;
}

const Groups: React.FC<GroupsInterface> = (props) => {
    const { showModal, setShowModal, setModalType, modalType } = useModal();
    const [groupId, setGroupId] = useState<string | null>(null);
    const [groupInfo, setGroupInfo] = useState<GroupInterface | null>(null);
    const [groupMembers, setGroupMembers] = useState<UserInterface[]>([]);
    const [transactions, setTransactions] = useState<TransactionInterface[]>(
        []
    );

    const { querySnapshot: querySnapshotMembers } = useQuerySnapshot();
    const { authUser } = useSelector((state: RootState) => state.auth);
    const history = useHistory();
    usePageViews();
    useEffect(() => {
        return () => setShowModal(false);
    }, []);

    const onClickAddBtn = () => {
        setModalType(CREATE_MODAL);
        setShowModal(true);
    };

    const onClickAddMemberBtn = (event: any, groupId: string) => {
        event.stopPropagation();
        fetchData(groupId);
    };

    const onClickAddMember = (groupId: string) => {
        fetchData(groupId);
    };

    const fetchData = async (groupId: string) => {
        try {
            fetchGroup(groupId)
                .then(
                    async (
                        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                    ) => {
                        if (!doc.exists) {
                            return;
                        }
                        let data = doc.data();

                        let memberIds: Array<string> = data?.memberIds;
                        const groupMembersSnapshot = fetchUsersByUserIds(
                            memberIds
                        );
                        const groupTemporaryContact = fetchGroupTemporaryContactsByGroupId(
                            groupId
                        );

                        Promise.all([
                            groupMembersSnapshot,
                            groupTemporaryContact,
                        ]).then((values: Array<any>) => {
                            querySnapshotMembers(values[0], (gMembers) => {
                                querySnapshotMembers(
                                    values[1],
                                    (temContacts) => {
                                        var membersArray = [
                                            ...gMembers,
                                            ...temContacts.map((i) => {
                                                return {
                                                    ...i,
                                                    useType: UserType.Temporary,
                                                };
                                            }),
                                        ];
                                        membersArray.forEach(function (
                                            item,
                                            i
                                        ) {
                                            if (item.id === authUser?.id) {
                                                membersArray.splice(i, 1);
                                                membersArray.unshift(item);
                                            }
                                        });

                                        setGroupMembers(membersArray);
                                    }
                                );
                            });
                        });

                        if (data) {
                            setGroupInfo({
                                id: doc.id,
                                name: data.name,
                                ownerId: data.ownerId,
                                memberIds: data.memberIds,
                                ...data,
                            });
                            setModalType(CONTACT_LIST_MODAL);
                            setShowModal(true);
                        }
                    }
                )
                .catch((error) => {
                    console.log('error', error);
                    SentryExceptionHandler(error);
                });
        } catch (error) {
            console.log('error', error);
            SentryExceptionHandler(error);
        }
    };

    const renderModalContent = () => {
        switch (modalType) {
            case CREATE_MODAL:
                return (
                    <CreateGroup
                        onSuccess={async (id: string, name: string) => {
                            // history.push(`/group/${id}`);
                            onClickAddMember(id);
                        }}
                        closeModal={() => setShowModal(false)}
                    />
                );
            case VIEW_MODAL:
                return groupId ? (
                    <Group
                        {...props}
                        id={groupId}
                        goBack={() => {
                            setShowModal(false);
                            setGroupId(null);
                            setModalType(null);
                        }}
                    />
                ) : (
                    <></>
                );
            case CONTACT_LIST_MODAL:
                return groupInfo ? (
                    <ContactList
                        screen={'GROUP_LIST_SCREEN'}
                        group={groupInfo}
                        closeModal={() => {
                            setShowModal(false);
                            setModalType(null);
                        }}
                        transactions={transactions}
                        fetchData={() => {}}
                        groupMembers={groupMembers}
                    />
                ) : (
                    <></>
                );
            case GROUP_LIST:
                return (
                    <SelectGroup
                        closeModal={() => setShowModal(false)}
                        screen={'GROUP_LIST_SCREEN'}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            <IonPage>
                <Header
                    title="Groups"
                    rightComponent={
                        <IonIcon
                            color="primary"
                            size="large"
                            slot="end"
                            icon={addOutline}
                            onClick={onClickAddBtn}
                        />
                    }
                />
                <IonContent fullscreen>
                    <>
                        <GroupList
                            onClickAddBtn={onClickAddBtn}
                            onClickAddMemberBtn={onClickAddMemberBtn}
                            showSubtitle={true}
                            showRightArrow={true}
                            onClickListItem={({ id }) => {
                                setGroupId(id);
                                setModalType(VIEW_MODAL);
                                setShowModal(true);
                            }}
                        />
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton
                                className="add-memeber-fab-button"
                                size="small"
                                onClick={() => {
                                    setModalType(GROUP_LIST);
                                    setShowModal(true);
                                }}
                            >
                                <img
                                    className="add-memeber-image"
                                    src={AddMemberImage}
                                />
                            </IonFabButton>
                        </IonFab>
                    </>
                </IonContent>
            </IonPage>
            {showModal && (
                <IonModal
                    isOpen={showModal}
                    cssClass={
                        modalType === GROUP_LIST
                            ? 'select-group-modal'
                            : 'my-custom-class'
                    }
                    onDidDismiss={() => setShowModal(false)}
                >
                    {renderModalContent()}
                </IonModal>
            )}
        </>
    );
};

export default Groups;
