import { IonText } from '@ionic/react';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePurchases from '../../hooks/usePurchases';
import useTrack from '../../hooks/useTrack';
import { RootState } from '../../redux/store';
import UpgradeAlertIcon from '../../svg/UpgradeAlrtIcon';
import { shortByDate } from '../../utils/common';
import ConnectModal from '../tnx-empty/ConnectModal';

interface UpgradeAlertInterface {
    firstModalRef: any;
    router: any;
}

const UpgradeAlert: FC<UpgradeAlertInterface> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { checkUser, purchaseInfo } = usePurchases();
    const [isPro, setIsPro] = useState<boolean | null>(null);
    const {
        tnx: { transactions },
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const { hitAnalytics } = useTrack();

    const checkUserStatus = () => {
        checkUser()
            .then((res) => {
                setIsPro(res);
            })
            .catch((e) => {
                console.log('e', e);
            });
    };

    useEffect(() => {
        checkUserStatus();
        const firstItemCreatedDate =
            shortByDate(
                transactions.filter((t) => !!t.createdDate),
                'createdDate'
            )[0]?.createdDate || '';
        if (firstItemCreatedDate) {
            const expireDate = moment(firstItemCreatedDate)
                .add(14, 'days')
                .format('YYYY-MM-DD');
            setShowAlert(() => moment(moment()).isSameOrAfter(expireDate));
        }
    }, [transactions]);

    const onClick = () => {
        if (authUser) {
            const { email, name, id } = authUser;
            hitAnalytics('clickSubscribeButton', {
                email,
                name,
                id,
            });
            purchaseInfo(() => {
                checkUserStatus();
                hitAnalytics('Subscription activated', {
                    email,
                    name,
                    id,
                });
            });
        }
    };

    if (showAlert && isPro === false) {
        return (
            <>
                <div className="upgrade-alert">
                    <div>
                        <UpgradeAlertIcon />
                    </div>
                    <div className="upgrade-alert-message">
                        <IonText>Expenses have stopped syncing.</IonText>
                        <IonText
                            onClick={() => setShowModal(true)}
                            className="reconnect-link"
                            color="primary"
                        >
                            Upgrade to reconnect.
                        </IonText>
                    </div>
                </div>
                <ConnectModal
                    onClick={onClick}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    {...props}
                />
            </>
        );
    }
    return <></>;
};
export default UpgradeAlert;
