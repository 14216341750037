import { SVGProps } from 'react';

interface DiamondSvgProps extends SVGProps<SVGSVGElement> {
    stopColorOne: string;
    stopColorTwo: string;
}

const DiamondSvg = ({
    stopColorOne,
    stopColorTwo,
    ...rest
}: DiamondSvgProps) => (
    <svg
        width={20}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M0 9.544c4.192-.027 7.772-2.476 9.24-5.934A8.925 8.925 0 0 0 9.954.111c0 5.21 4.488 9.433 10.023 9.433-5.535 0-10.023 4.224-10.023 9.434 0-5.188-4.45-9.398-9.953-9.433Z"
            fill={`url(#a${stopColorOne})`}
        />
        <path
            d="M9.954 0a9.112 9.112 0 0 0-.002 0h.002Z"
            fill={`url(#b${stopColorTwo})`}
        />
        <defs>
            <linearGradient
                id={'a' + stopColorOne}
                x1={10.027}
                y1={1.685}
                x2={9.747}
                y2={20.4}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.267} stopColor={stopColorOne} />
                <stop offset={0.859} stopColor={stopColorTwo} />
            </linearGradient>
            <linearGradient
                id={'b' + stopColorTwo}
                x1={10.027}
                y1={1.685}
                x2={9.747}
                y2={20.4}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.267} stopColor={stopColorOne} />
                <stop offset={0.859} stopColor={stopColorTwo} />
            </linearGradient>
        </defs>
    </svg>
);

export default DiamondSvg;
