import firebase from 'firebase';
import { TransactionsType } from '../types/enum';
import { TransactionCollectionInterface } from '../types/TransactionInterface';
import db from './db';

const COLLECTION = 'transactions';

export function transactionsSnap(userId: string) {
    return db
        .collection(COLLECTION)
        .where('createdBy', '==', userId)
        .where('type', '==', TransactionsType.Bank)
        .orderBy('tnxFirestoreDate', 'desc')
        .limit(100);
}

/**
 * Fetch transaction by assigned group id
 * @param groupId String
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchTransactionByAssignedGroupId = async (groupId: string) => {
    const tnxRef = db
        .collection(COLLECTION)
        .where('assignedGroupId', '==', groupId)
        .orderBy('tnxFirestoreDate', 'desc');
    const snapshot = await tnxRef.get();
    return snapshot;
};

/**
 *
 * @param data TransactionCollectionInterface
 * @returns Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
 */
export const saveTransaction = (data: TransactionCollectionInterface) => {
    let tnxFirestoreDate = firebase.firestore.Timestamp.now();

    if (data.date) {
        tnxFirestoreDate = firebase.firestore.Timestamp.fromDate(
            new Date(data.date)
        );
    }

    const tnxRef = db.collection(COLLECTION).add({
        ...data,
        tnxFirestoreDate,
    });
    return tnxRef;
};

/**
 * Update transaction
 * @param id String
 * @param data Any
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const updateTransaction = async (id: string, data: any) => {
    var tnxRef = db.collection(COLLECTION).doc(id);
    return await tnxRef.update(data);
};

/**
 * Update transactions by assigned group id
 * @param groupId String
 * @param data Any
 * @returns firebase.firestore.WriteBatch
 */
export const updateTransactionsByAssignedGroupId = async (
    groupId: string,
    data: any
) => {
    var tnxRef = db
        .collection(COLLECTION)
        .where('assignedGroupId', '==', groupId);

    const tnxData: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await tnxRef.get();

    const batch = db.batch();

    tnxData.forEach((element) => {
        const tnx = db.collection(COLLECTION).doc(element.id);
        batch.update(tnx, data);
    });

    return batch.commit();
};

/**
 * Update involved user id
 * @param id String
 * @param newId String
 */
export const updateTnxTempInvolvedUsers = async (id: string, newId: string) => {
    var tnxRef = db
        .collection(COLLECTION)
        .where('involvedUsers', 'array-contains', id);

    var tnxData = await tnxRef.get();

    tnxData.forEach(
        async (
            element: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
        ) => {
            const tnxRef = db.collection(COLLECTION).doc(element.id);
            const doc = element.data();
            if (doc.groupSplittingValues) {
                const groupSplittingValues = doc.groupSplittingValues;
                groupSplittingValues.splitAmounts = doc.groupSplittingValues.splitAmounts.map(
                    (data: any) => {
                        if (data.userId === id) {
                            return {
                                ...data,
                                userId: newId,
                            };
                        }
                        return {
                            ...data,
                        };
                    }
                );
                await tnxRef.update({ groupSplittingValues });
            }

            await tnxRef.update({
                involvedUsers: firebase.firestore.FieldValue.arrayRemove(id),
            });
            await tnxRef.update({
                involvedUsers: firebase.firestore.FieldValue.arrayUnion(newId),
            });
        }
    );
};

export const unassignFromGroupCollection = async (id: string) => {
    const FieldValue = firebase.firestore.FieldValue;
    const tnxRef = db.collection(COLLECTION).doc(id);
    const res = await tnxRef.update({
        groupColor: FieldValue.delete(),
        assignedGroupId: FieldValue.delete(),
        groupSplittingValues: FieldValue.delete(),
    });
    return res;
};

export const unassignTransactionsByAssignedGroupId = async (
    groupId: string
) => {
    var tnxRef = db
        .collection(COLLECTION)
        .where('assignedGroupId', '==', groupId);
    const FieldValue = firebase.firestore.FieldValue;
    const tnxData: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = await tnxRef.get();

    const batch = db.batch();

    tnxData.forEach((element) => {
        const tnx = db.collection(COLLECTION).doc(element.id);
        batch.update(tnx, {
            groupColor: FieldValue.delete(),
            assignedGroupId: FieldValue.delete(),
            groupSplittingValues: FieldValue.delete(),
        });
    });

    return batch.commit();
};

export const deleteTransaction = (id: string) => {
    return db.collection(COLLECTION).doc(id).delete();
};
