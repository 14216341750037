import { useSelector, useDispatch } from 'react-redux';
import { updateGroup } from '../firebase/GroupCollection';
import { unassignFromGroupCollection } from '../firebase/TransactionCollection';
import { setTransactions } from '../redux/slices/tnxSlice';
import { GroupInterface } from '../types/GroupInterface';
import { RootReducerInterface } from '../types/RootReducerInterface';
import { TransactionInterface } from '../types/TransactionInterface';

const useAssignGroup = () => {
    const { transactions } = useSelector(
        (state: RootReducerInterface) => state.tnx
    );
    const dispatch = useDispatch();

    const assign = (
        group: GroupInterface | null,
        transaction: TransactionInterface,
        newParams: any = {}
    ) => {
        var newTnx = transactions.map((t: TransactionInterface) => {
            if (t.id === transaction.id) {
                const data = {
                    ...t,
                    ...newParams,
                };
                if (group) {
                    data.groupColor = group?.groupColor;
                    data.assignedGroupId = group?.id;
                }
                return data;
            }
            return { ...t };
        });
        if (group) {
            updateGroup(group.id, {});
        }
        dispatch(setTransactions(newTnx));
    };
    const removeFromGroup = (id: string) => {
        unassignFromGroupCollection(id);

        // Update the group updatedAt
        const updatedTnx = transactions.find(
            (t: TransactionInterface) => t.id === id
        );
        if (updatedTnx && updatedTnx.assignedGroupId) {
            updateGroup(updatedTnx.assignedGroupId, {});
        }

        var newTnx = transactions.map((t: TransactionInterface) => {
            if (t.id === id) {
                const {
                    groupColor,
                    assignedGroupId,
                    groupSplittingValues,
                    ...rest
                } = t;
                return {
                    ...rest,
                };
            }
            return { ...t };
        });
        //@ts-ignore
        dispatch(setTransactions(newTnx));
    };
    return { assign, removeFromGroup };
};

export default useAssignGroup;
