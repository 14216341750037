import * as React from 'react';

function StrokeSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={15}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.051 1.561c-.085-.577-.624-.978-1.223-.978H6.375c-.78 0-1.413.68-1.191 1.427l.66 2.22H3.734c-.912 0-1.57.918-1.063 1.704l4.468 6.927c.232.36.647.556 1.063.556.758 0 1.397-.65 1.2-1.396l-.979-3.709h2.217c.719 0 1.327-.578 1.223-1.282m0 0L11.05 1.56m-1.234.189l.802 5.396h-3.79l1.327 5.028-4.372-6.778h3.703L6.404 1.75h3.413z"
                fill="#FFB901"
            />
        </svg>
    );
}

export default StrokeSvg;
