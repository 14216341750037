import * as React from 'react';
import { SVGProps } from 'react';

const CreditCardSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#a)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.077 12.333H10.649a1.32 1.32 0 0 0-1.316 1.317v14.48a1.32 1.32 0 0 0 1.316 1.316h18.428a1.32 1.32 0 0 0 1.316-1.317V13.65a1.32 1.32 0 0 0-1.316-1.316Zm-1.316 14.48H11.966v-7.898H27.76v7.898Zm0-10.53H11.966v-1.317H27.76v1.316Z"
            fill="url(#b)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.838 25.496h3.948c.369 0 .658-.29.658-.658v-1.316a.652.652 0 0 0-.658-.658h-3.948a.652.652 0 0 0-.659.658v1.316c0 .369.29.658.659.658Z"
            fill="url(#c)"
        />
        <defs>
            <linearGradient
                id="a"
                x1={20.053}
                y1={3.547}
                x2={20.042}
                y2={40}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A62FD2" />
                <stop offset={0.526} stopColor="#7B61FF" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={19.891}
                y1={13.851}
                x2={19.887}
                y2={29.445}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A62FD2" />
                <stop offset={0.526} stopColor="#7B61FF" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={23.819}
                y1={23.097}
                x2={23.819}
                y2={25.496}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A62FD2" />
                <stop offset={0.526} stopColor="#7B61FF" />
            </linearGradient>
        </defs>
    </svg>
);

export default CreditCardSvg;
