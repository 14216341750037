import React from 'react';
import { IonText, useIonAlert } from '@ionic/react';
import { SKIPPED } from '../../utils/constant';
import { useHistory } from 'react-router';
import { isUserSkippedPro } from '../../utils/common';
import { Browser } from '@capacitor/browser';

const SkipSection = ({ onlySkip }: { onlySkip: boolean }) => {
    const [present] = useIonAlert();
    const history = useHistory();

    const onClickSkip = () => {
        present({
            cssClass: 'skip-alert',
            header: 'Are you sure?',
            message: `Most people like having accounts connected so that their expenses automatically appear in BillMagic. `,
            buttons: [
                {
                    text: 'Not Now',
                    handler: () => {
                        localStorage.setItem(SKIPPED, 'yes');
                        history.push('/groups');
                        return;
                    },
                },
                {
                    text: 'Add Account',
                    handler: () => {
                        return;
                    },
                },
            ],
            onDidDismiss: (e) => {},
        });
    };

    if (onlySkip) {
        return (
            <p className="ion-text-center">
                <IonText color="primary" onClick={onClickSkip}>
                    Skip
                </IonText>
            </p>
        );
    }

    return (
        <>
            <p className="empty-screen-onboard-sub-section-title">
                Not ready to sign up yet? <br />
                You can create manual expenses and use the rest of the app for
                free!
            </p>
            <div style={{ display: 'flex' }}>
                <p className="empty-screen-info-link">
                    <IonText
                        color="primary"
                        onClick={async () => {
                            await Browser.open({
                                url: 'https://www.billmagic.app/terms',
                            });
                        }}
                    >
                        Terms of Service
                    </IonText>
                </p>
                <p className="empty-screen-info-link">
                    <IonText
                        color="primary"
                        onClick={async () => {
                            await Browser.open({
                                url: 'https://www.billmagic.app/privacy',
                            });
                        }}
                    >
                        Privacy Policy
                    </IonText>
                </p>
            </div>

            {!isUserSkippedPro() && (
                <p className="ion-text-center">
                    <IonText color="primary" onClick={onClickSkip}>
                        Skip
                    </IonText>
                </p>
            )}
        </>
    );
};

export default SkipSection;
