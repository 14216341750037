import {
    IonButton,
    IonCheckbox,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { HOW_MANY_EXPENSES_DO_YOU_SHARE_PER_WEEK } from '../Main';
import { ResultInterface } from '../types';

interface Screen5Props {
    onSetResults: (data: ResultInterface) => void;
    QUESTION: ResultInterface;
}

const options = [
    {
        id: 1,
        name: '0-4',
    },
    { id: 2, name: '5-8' },
    { id: 3, name: '10+' },
    { id: 4, name: 'Not sure' },
];

const Screen5 = ({ onSetResults, QUESTION }: Screen5Props) => {
    const isChecked = (text: string) => {
        if (QUESTION) {
            return !!QUESTION.answers.find((answer) => answer === text);
        }
        return false;
    };
    return (
        <>
            <IonText className="ion-text-center screen-title">
                <h1>How many expenses do you share per week?</h1>
            </IonText>

            <IonList>
                {options.map(({ id, name }) => (
                    <IonItem
                        key={id}
                        lines="none"
                        className="screen-list-item"
                        onClick={() => {
                            QUESTION.answers = isChecked(name) ? [] : [name];
                            onSetResults(QUESTION);
                        }}
                    >
                        <IonLabel>
                            <h1>{name}</h1>
                        </IonLabel>
                        {isChecked(name) && (
                            <IonCheckbox
                                checked={isChecked(name)}
                                color="success"
                                slot="end"
                            />
                        )}
                    </IonItem>
                ))}
            </IonList>
        </>
    );
};
export default Screen5;
