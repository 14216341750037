import { IonButton, IonIcon, IonItemDivider, IonText } from '@ionic/react';
import { add, linkOutline } from 'ionicons/icons';
import useGroupLink from '../../hooks/useGroupLink';
import useNativeShare from '../../hooks/useNativeShare';
import { GroupInterface } from '../../types/GroupInterface';

type EmptyGroupType = {
    onClickAddMembers: () => void;
    group: GroupInterface;
};

const EmptyGroup = ({ onClickAddMembers, group }: EmptyGroupType) => {
    const { onShare } = useNativeShare();
    const { link } = useGroupLink(group.id);

    return (
        <>
            <div className="empty-screen-btn-wrapper">
                <IonButton
                    size="small"
                    fill="outline"
                    className="grp-empty-btn add-member-btn"
                    onClick={onClickAddMembers}
                >
                    <IonIcon className="grp-empty-btn-icon" ios={add} /> Add
                    members
                </IonButton>
                <IonButton
                    size="small"
                    fill="outline"
                    className="grp-empty-btn copy-link-btn"
                    onClick={() => {
                        onShare({
                            title: 'BillMagic group',
                            text: `Hi! Join the group ${group.name} on BillMagic to share expenses`,
                            url: link,
                            dialogTitle: 'Share with friends',
                        });
                    }}
                >
                    <IonIcon className="grp-empty-btn-icon" ios={linkOutline} />{' '}
                    Share invite link
                </IonButton>
            </div>
            <div className="group-divider-wrapper-empty-screen">
                <hr className="group-main-divider dark-divider" />
            </div>
            <div className="grp-empty-text-content-wrapper">
                <IonText>
                    Split expenses from your Expenses list will appear here.
                </IonText>
                <br />
                <br />

                <IonText>
                    To create a manual expense, click the blue Add button below.
                </IonText>
            </div>
        </>
    );
};

export default EmptyGroup;
