import * as React from 'react';

interface TransactionTabSvgInterface extends React.SVGProps<SVGSVGElement> {
    active: number;
}

function TransactionTabSvg(props: TransactionTabSvgInterface) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01"
                stroke={props.active ? '#1868DF' : '#B0B4C2'}
                strokeWidth={props.active ? 3 : 2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default TransactionTabSvg;
