import firebase from 'firebase';
import { GroupCollectionInterface } from '../types/GroupInterface';
import { groupIcons } from '../utils/constant';
import db from './db';

const COLLECTION = 'groups';

/**
 * Crete group
 * @param data GroupCollectionInterface
 * @returns Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
 */
export const saveGroup = (data: GroupCollectionInterface) => {
    const groupRef = db.collection(COLLECTION).add({
        ...data,
        created: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
    });
    return groupRef;
};

/**
 * Update group
 * @param id String
 * @param data Any
 * @returns Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
 */
export const updateGroup = async (id: string, data: any) => {
    const groupRef = db.collection(COLLECTION).doc(id);
    return await groupRef.update({
        ...data,
        updatedAt: firebase.firestore.Timestamp.now(),
    });
};

/**
 * Fetch group
 * @param id String
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const fetchGroup = async (id: string) => {
    const groupRef = db.collection(COLLECTION).doc(id);
    return groupRef.get();
};

/**
 * Fetch group by involved user id
 * @param userId String
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchUserGroups = async (userId: string) => {
    const groupRef = db
        .collection(COLLECTION)
        .where('memberIds', 'array-contains', userId)
        .orderBy('created', 'desc');
    const snapshot = await groupRef.get();
    return snapshot;
};

/**
 * Assign users to group
 * @param groupId String
 * @param userId String
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const assignUsersToGroup = (groupId: string, userId: string) => {
    const groupRef = db.collection(COLLECTION).doc(groupId);
    return groupRef.update({
        memberIds: firebase.firestore.FieldValue.arrayUnion(userId),
        updatedAt: firebase.firestore.Timestamp.now(),
    });
};

/**
 * Remove members from group
 * @param groupId String
 * @param userId String
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const removeUsersFromGroup = (groupId: string, userId: string) => {
    const groupRef = db.collection(COLLECTION).doc(groupId);
    return groupRef.update({
        memberIds: firebase.firestore.FieldValue.arrayRemove(userId),
        updatedAt: firebase.firestore.Timestamp.now(),
    });
};

// export const updateGrpBatch = async () => {
//     const tnxRef = db.collection(COLLECTION);
//     const snapshot = await tnxRef.get();
//     const batch = db.batch();
//     var index = 0;
//     snapshot.forEach((element) => {
//         if (index < 500) {
//             const tnx = db.collection(COLLECTION).doc(element.id);
//             batch.update(tnx, {
//                 groupIcon: groupIcons()[
//                     Math.floor(Math.random() * groupIcons().length)
//                 ].id,
//             });
//             index++;
//         }
//     });
//     return batch.commit().then((res) => {
//         console.log(`${index} are updated`);
//     });
// };

export const deleteGroup = (id: string) => {
    const groupRef = db.collection(COLLECTION).doc(id);
    return groupRef.delete();
};
