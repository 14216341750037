import { IonText } from '@ionic/react';
import Img from '../assets/onboarding-sc6a.png';
import Img2 from '../assets/onboarding-sc6b.png';

interface Screen6Props {}

const Screen6 = ({}: Screen6Props) => (
    <>
        <IonText className="ion-text-center screen-title">
            <h1>Never lose track.</h1>
        </IonText>
        <IonText className="ion-text-center screen-sub-title">
            <p>
                On average, people forget to <br /> split 25% of shared
                expenses.
            </p>
        </IonText>
        <div className="sc-4-img-wrapper">
            <img className="sc-6-a-img" src={Img} />
        </div>
        <div className="sc-4-img-wrapper">
            <img className="sc-6-b-img" src={Img2} />
        </div>
    </>
);

export default Screen6;
