import * as React from 'react';

function LogoApple(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={17}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.224 1.959c.705-.857 1.908-1.514 2.939-1.584.175 1.173-.304 2.316-.937 3.122-.656.847-1.78 1.509-2.879 1.509h-.082c-.206-1.118.32-2.27.959-3.047zm1.14 3.391c.589-.223 1.236-.469 2.18-.469l-.004.002c1.31 0 2.698.717 3.686 1.954-3.237 1.78-2.711 6.42.562 7.663-.447 1.004-.663 1.449-1.246 2.336-.81 1.233-1.948 2.771-3.366 2.786h-.026c-.566 0-.943-.173-1.348-.359-.473-.217-.985-.453-1.885-.453H8.89c-.913.006-1.44.242-1.925.459-.416.186-.8.358-1.37.358h-.025c-1.418-.015-2.5-1.403-3.309-2.641C0 13.527-.238 9.468 1.16 7.313c.996-1.528 2.563-2.426 4.032-2.426.787 0 1.42.226 2.024.44.547.195 1.07.381 1.658.381.549 0 1-.171 1.491-.358z"
                fill="#B0B4C2"
            />
        </svg>
    );
}

export default LogoApple;
