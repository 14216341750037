import { TransactionsType } from './enum';

export type SplitAmountsType = {
    userId: string;
    value: number;
};

export enum SplitMethodEnum {
    Equally = 'Equally',
    SplitSpecific = 'SplitSpecific',
    SplitPercentage = 'SplitPercentage',
}

export interface TransactionCollectionInterface {
    account_id?: string;
    name: string;
    merchant_name?: string;
    amount: number;
    createdBy: string;
    createdDate?: string;
    assignedGroupId: string;
    involvedUsers?: Array<string>;
    assignedDate?: string;
    groupColor?: string | null;
    groupIcon?: string;
    date: Date | string;
    type: TransactionsType;
    transaction_id?: string;
    iso_currency_code: string;
    groupSplittingValues?: TransactionSplittingValuesInterface;
    category?: Array<string>;
    originalAmount?: number;
    bankAccountId?: string;
    pending_transaction_id?: string;
    pending?: boolean;
    payment_meta?: any;
    memo?: string;
    hidden?: boolean;
    removed: boolean | null;
    missingSplit: boolean | null;
}

export interface TransactionSplittingValuesInterface {
    splitMethod?: SplitMethodEnum;
    splitAmounts?: Array<SplitAmountsType> | null;
    paidBy?: string;
    memo?: string;
}

export interface TransactionInterface extends TransactionCollectionInterface {
    id: string;
}

export interface TransactionReducerInterface {
    transactions: Array<TransactionInterface> | [];
    isSyncingStart: boolean;
    isEmpty: boolean | null;
    isFetching: boolean | null;
}

export interface FilterRefData {
    ref: string;
    refId: string;
}
