import * as React from 'react';

function HouseSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.65 11.478V4.567a.973.973 0 00-.968-.987h-1.974a.978.978 0 00-.987.987v2.962l-5.232-5.233a.978.978 0 00-1.402 0L.226 18.665c-.395.395-.257.69.296.69h3.455V32.19c0 .553.415.987.968.987H30.65c.553 0 .967-.434.967-.987V19.376h3.416c.553 0 .671-.316.276-.692l-7.66-7.206zm-5.923 17.77v-6.91a.978.978 0 00-.988-.988h-5.923a.978.978 0 00-.987.987v6.911H7.906V17.065l9.872-9.872 5.923 5.923.336.336 3.613 3.613v12.183h-5.923z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default HouseSvg;
