import * as React from 'react';

function SunSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11 4.123c.756 0 1.374-.619 1.374-1.375V1.374C12.374.618 11.756 0 11 0c-.755 0-1.373.618-1.373 1.374v1.374c0 .756.604 1.375 1.373 1.375zM15.848 6.13c.536.535 1.4.535 1.937 0l.975-.977a1.37 1.37 0 10-1.937-1.937l-.975.975a1.37 1.37 0 000 1.938zM17.866 10.994c0 .756.618 1.374 1.374 1.374h1.373c.755 0 1.373-.618 1.373-1.374 0-.756-.618-1.374-1.373-1.374H19.24c-.77 0-1.374.618-1.374 1.374zM15.848 15.859a1.37 1.37 0 000 1.937l.975.976c.536.536 1.401.536 1.95 0a1.369 1.369 0 000-1.938l-.975-.975c-.549-.536-1.414-.55-1.95 0zM11 17.865c-.755 0-1.373.618-1.373 1.374v1.374c0 .756.618 1.375 1.373 1.375.756 0 1.374-.619 1.374-1.375V19.24c0-.756-.618-1.374-1.374-1.374zM6.138 15.859a1.367 1.367 0 00-1.937 0l-.975.975a1.37 1.37 0 000 1.938c.536.536 1.4.536 1.95 0l.975-.975a1.382 1.382 0 00-.013-1.938zM4.12 10.994c0-.756-.619-1.374-1.374-1.374H1.372C.617 9.62 0 10.238 0 10.994c0 .756.618 1.374 1.373 1.374h1.374a1.36 1.36 0 001.373-1.374zM6.138 6.129a1.37 1.37 0 000-1.938l-.975-.975a1.367 1.367 0 00-1.937 0 1.386 1.386 0 000 1.951l.975.976c.536.536 1.401.536 1.937-.014zM10.986 5.497a5.494 5.494 0 00-5.494 5.497 5.494 5.494 0 1010.987 0 5.494 5.494 0 00-5.493-5.497zm0 8.245a2.756 2.756 0 01-2.747-2.748 2.756 2.756 0 012.747-2.748 2.756 2.756 0 012.746 2.748 2.747 2.747 0 01-2.746 2.748z"
                fill="#fff"
            />
        </svg>
    );
}

export default SunSvg;
