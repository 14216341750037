import { useState } from 'react';
import {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonProgressBar,
    IonToast,
    IonIcon,
    IonSpinner,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../utils/constant';
import Header from '../../components/Header';
import { sendResetEmail } from '../../firebase/AuthCollections';
import { chevronBackOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import BMLogo from '../sign-up/BMLogo';

type ForgotPasswordData = {
    email: string;
};

const ForgotPassword: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { register, handleSubmit, errors } = useForm<ForgotPasswordData>();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastData, setToastData] = useState<{}>({});
    const { goBack } = useHistory();

    const onSubmit = ({ email }: ForgotPasswordData) => {
        setLoading(true);
        sendResetEmail(email)
            .then((res) => {
                setToastData((toastData) => {
                    return {
                        ...toastData,
                        color: 'success',
                        message: 'Successfully sent link to your email',
                        duration: 4000,
                    };
                });
                setShowToast(true);
                setLoading(false);
                setTimeout(() => {
                    goBack();
                }, 4020);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    return (
        <IonPage>
            <Header
                title=""
                ionToolBarClasses={'header-border-zero'}
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        icon={chevronBackOutline}
                        onClick={() => goBack()}
                    />
                }
            />
            <IonContent className="ion-padding" scrollY={false}>
                <div className="bm-logo-wrapper">
                    <BMLogo />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            type="email"
                            name="email"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a email',
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                    </IonItem>
                    {errors.email && (
                        <p className="ion-text-center">
                            <IonText color="danger">
                                <small>{errors.email.message}</small>
                            </IonText>
                        </p>
                    )}

                    <IonButton
                        className="ion-margin on-board-sig-up-btn"
                        size="default"
                        color="primary"
                        expand="block"
                        type="submit"
                    >
                        {loading === true && (
                            <IonSpinner slot="start" name="lines-small" />
                        )}
                        Submit
                    </IonButton>
                </form>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    {...toastData}
                />
            </IonContent>
        </IonPage>
    );
};

export default ForgotPassword;
