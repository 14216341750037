import {
    IonContent,
    IonPage,
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonText,
    IonIcon,
    isPlatform,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { EMAIL_REGEX } from '../../utils/constant';
import GoogleLogin from '../login/GoogleLogin';
import AppleLogin from '../login/AppleLogin';
import BMLogo from './BMLogo';
import './index.css';
import Header from '../../components/Header';
import { chevronBackOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';

type UserSignUpData = {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
};

const SignUpMain: React.FC = () => {
    const { register, handleSubmit, watch, errors } = useForm<UserSignUpData>();

    const history = useHistory();

    const onSubmit = ({ email }: UserSignUpData) => {
        history.push(`/sign-up/${email}`);
    };

    const isMobileWeb = isPlatform('mobileweb');
    const isDesktop = isPlatform('desktop');

    return (
        <IonPage>
            <Header
                title=""
                ionToolBarClasses={'header-border-zero'}
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        icon={chevronBackOutline}
                        onClick={() => history.goBack()}
                    />
                }
            />
            <IonContent className="ion-padding" scrollY={false}>
                <div className="bm-logo-wrapper">
                    <BMLogo />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        <IonLabel position="floating">Email </IonLabel>
                        <IonInput
                            type="email"
                            name="email"
                            // @ts-ignore
                            ref={register({
                                required: 'You must specify a email',
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: 'Invalid email address',
                                },
                            })}
                        />
                    </IonItem>
                    {errors.email && (
                        <p className="ion-text-center">
                            <IonText color="danger">
                                <small>{errors.email.message}</small>
                            </IonText>
                        </p>
                    )}

                    <IonButton
                        className="ion-margin on-board-sig-up-btn"
                        size="default"
                        color="primary"
                        expand="block"
                        type="submit"
                    >
                        Sign Up with Email
                    </IonButton>
                    {!isMobileWeb && !isDesktop && <AppleLogin />}
                    <GoogleLogin />
                </form>
            </IonContent>
        </IonPage>
    );
};

export default SignUpMain;
