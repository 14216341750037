import * as React from 'react';
import { SVGProps } from 'react';

const WhyGoRroSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={71}
        height={150}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m18.856 51.332-2.8-4.851 21.022-12.137-4.668-8.085L6.536 41.197 17.74 60.602l25.873-14.939-3.734-6.468-21.023 12.137ZM25.874 14.938 0 29.876l2.801 4.852L28.675 19.79l-2.801-4.852Z"
            fill="#777ED4"
        />
        <path
            d="M51.747 0 25.874 14.938l2.8 4.852L54.549 4.852 51.748 0ZM37.078 34.344l2.8 4.851 3.735 6.469 25.874-14.939L58.284 11.32 32.41 26.26l4.668 8.085Zm8.769-7.22a4.661 4.661 0 0 1 4.167-.25 4.668 4.668 0 1 1 2.801 4.852 4.668 4.668 0 1 1-6.969-4.601Z"
            fill="#ABB0EA"
        />
        <path
            d="m2.801 34.728 3.735 6.469L32.41 26.259 58.283 11.32 54.55 4.852 28.675 19.79 2.801 34.728Z"
            fill="#2E4C6D"
        />
        <path
            d="M37.078 34.344 16.055 46.48l2.801 4.851L39.88 39.195l-2.801-4.851Z"
            fill="#fff"
        />
        <path
            d="M50.515 35.21a4.661 4.661 0 0 0 2.3-3.484 4.647 4.647 0 0 1-2.209-1.959 4.647 4.647 0 0 1-.592-2.892 4.668 4.668 0 1 0 .5 8.336Z"
            fill="#FFDA44"
        />
        <path
            d="M52.816 31.726a4.668 4.668 0 1 0 3.668-8.586 4.668 4.668 0 0 0-3.668 8.586Z"
            fill="#E736AC"
        />
        <path
            d="M41.957 79.745a9.88 9.88 0 1 1 9.84-9.85 9.83 9.83 0 0 1-9.84 9.85Zm3.54-12.4a3.16 3.16 0 0 0-1.83-2.77c-.58-.27-1.15-.45-1.21-1.29 0-.26-.6-.64-.94-.66a1.07 1.07 0 0 0-.82.67c0 .84-.59 1.11-1.17 1.47a3.12 3.12 0 0 0 .55 5.67c.685.204 1.383.36 2.09.47.83.19 1.64.52 1.46 1.54a1.66 1.66 0 0 1-1.89 1.3 1.71 1.71 0 0 1-1.79-1.29c-.12-.35-.73-.54-1.11-.8-.24.41-.68.82-.67 1.23a2.8 2.8 0 0 0 1.85 2.42c.42.22.67.73 1 1.08.33.35.64.8.88.75.24-.05.67-.5.94-.83.27-.33.42-.83.76-1 1.51-.92 2.2-2.11 1.93-3.52a3.52 3.52 0 0 0-3.2-2.78 4.28 4.28 0 0 1-1.51-.32c-.39-.22-.88-.74-.86-1.12a1.849 1.849 0 0 1 .83-1.29c1.09-.66 2.18-.11 2.76 1.09a1.53 1.53 0 0 0 1.12.74c.29 0 .63-.55.83-.74v-.02ZM12.957 101.745a9.88 9.88 0 1 1 9.84-9.85 9.83 9.83 0 0 1-9.84 9.85Zm3.54-12.4a3.16 3.16 0 0 0-1.83-2.77c-.58-.27-1.15-.45-1.21-1.29 0-.26-.6-.64-.94-.66a1.07 1.07 0 0 0-.82.67c0 .84-.59 1.11-1.17 1.47a3.12 3.12 0 0 0 .55 5.67c.685.204 1.383.36 2.09.47.83.19 1.64.52 1.46 1.54a1.66 1.66 0 0 1-1.89 1.3 1.71 1.71 0 0 1-1.79-1.29c-.12-.35-.73-.54-1.11-.8-.24.41-.68.82-.67 1.23a2.8 2.8 0 0 0 1.85 2.42c.42.22.67.73 1 1.08.33.35.64.8.88.75.24-.05.67-.5.94-.83.27-.33.42-.83.76-1 1.51-.92 2.2-2.11 1.93-3.52a3.52 3.52 0 0 0-3.2-2.78 4.28 4.28 0 0 1-1.51-.32c-.39-.22-.88-.74-.86-1.12a1.849 1.849 0 0 1 .83-1.29c1.09-.66 2.18-.11 2.76 1.09a1.53 1.53 0 0 0 1.12.74c.29 0 .63-.55.83-.74v-.02Z"
            fill="#E6C337"
        />
        <path
            d="M53.83 116.677c2.82-2.329 4.594-5.703 4.594-9.459 0-7.028-6.21-12.725-13.87-12.725-7.661 0-13.87 5.697-13.87 12.725 0 3.756 1.773 7.13 4.592 9.459-6.667 3.108-11.24 9.456-11.24 16.786 0 3.011.857 5.335 1.727 8.133.365 1.17.51 2.509.28 3.695-.29 1.479-.399 3.377 1.261 4.1 1.873.815 3.891-.12 5.842.207 2.541.425 4.943-.02 7.473.025 4.14.073 8.357.126 12.481-.155 2.579-.175 6.477 1.561 8.667-.502 1.91-1.802 1.053-5.511.579-7.697-.298-1.361-1.045-2.698-.747-4.083.29-1.337 1.273-2.535 1.618-3.907.366-1.447.4-3.01.224-4.515-.145-1.207-.227-2.814-.617-3.879-.444-1.22-1.591-2.461-2.445-3.404a19.994 19.994 0 0 0-3.17-2.833c-.345-.242-3.076-2.22-3.378-1.971Z"
            fill="#C4C8FF"
        />
        <path
            d="M60.266 126.556c-1.802-.668-3.08-3.618-2.17-5.354.753-1.447 2.776-2.152 3.648-3.528a14.016 14.016 0 0 0 1.928-5.292c.235-1.559-.416-2.802 1.177-3.763 1.28-.775 2.806-.416 4.008.335 2.316 1.447 1.107 3.473 1.081 5.763-.034 3.012-.105 8.116-2.467 10.088-1.616 1.336-5.052 2.549-7.205 1.751ZM34.331 101.225c-1.722 1.592-2.283 1.991-2.911-.869-.375-1.698-.109-3.568.527-5.077.756-1.8 2.399-4.852 4.503-5.22.5-.088 4.775 4.327 4.638 5.026-.388 2.088-5.341 4.82-6.757 6.14ZM54.431 101.225c1.724 1.591 2.285 1.991 2.913-.869.375-1.699.109-3.568-.526-5.078-.757-1.798-2.401-4.852-4.504-5.22-.5-.088-3.9 4.983-3.762 5.682.382 2.081 4.466 4.165 5.88 5.485Z"
            fill="#C4C8FF"
        />
        <path
            d="M44.61 111.132a1.967 1.967 0 1 0-.483-3.905 1.967 1.967 0 0 0 .483 3.905Z"
            fill="#C23193"
        />
        <path
            d="M37.892 106.408c1.132 0 2.05-.44 2.05-.984 0-.543-.918-.984-2.05-.984-1.131 0-2.049.441-2.049.984 0 .544.918.984 2.05.984ZM52.889 105.59c0 .542-.918.982-2.05.982-1.131 0-2.047-.434-2.047-.982 0-.549.916-.985 2.048-.985 1.131 0 2.049.44 2.049.985Z"
            fill="#2E4C6D"
        />
        <path
            d="M44.644 118.834c-2.804.271-6.177.644-8.785-.723a11.197 11.197 0 0 0-.994 4.775c0 5.748 4.548 10.408 10.157 10.408s10.156-4.66 10.156-10.408c0-2.557-.7-4.63-1.842-6.244-2.673 1.346-5.59 1.891-8.692 2.192Z"
            fill="#FFDA44"
        />
        <path
            d="M34.589 116.117s9.428 4.249 19.785-.266l1.107 1.871s-8.41 4.37-21.954-.012l1.062-1.593Z"
            fill="#C23193"
        />
        <path
            d="M47.215 121.753a2.22 2.22 0 0 1-2.652 2.176 2.223 2.223 0 0 1-1.742-1.743 2.22 2.22 0 1 1 4.394-.433Z"
            fill="#2E4C6D"
        />
        <path
            d="M32.044 144.667c2.108 2.4 3.717 4.431 7.322 3.682 2.638-.547 5.474-2.717 5.998-5.522.42-2.238-1.88-4.232-3.078-5.901-1.179-1.638-2.497-3.83-4.314-4.838-2.143-1.186-3.22-1.652-5.621-.764-2.605.967-4.942 4.135-4.514 7.009.378 2.542 2.602 4.506 4.207 6.334Z"
            fill="#C23193"
        />
        <path
            d="M32.996 117.608c-3.836 3.43-9.284 5.974-7.617 12.233.663 2.486 2.124 5.164 3.593 7.224.981 1.374 1.81 2.192 3.508 2.344 1.91.171 4.56-.899 3.74-3.09-.868-2.327-2.855-4.293-4.022-6.452-.909-1.68-1.394-2.366.412-3.486"
            fill="#C4C8FF"
        />
        <path
            d="m29.997 123.879 2.84 7.321.875-.219-2.512-7.648-1.203.546ZM49.229 110.414h-.201l.019-.983a20.206 20.206 0 0 0 2.758-.208c.666-.081 1.354-.167 2.043-.202l.05.983c-.653.033-1.325.114-1.975.194a22.5 22.5 0 0 1-2.694.216ZM54.117 112.133c-.678 0-1.369-.025-2.036-.048-.943-.032-1.92-.064-2.865-.035l-.03-.982c.976-.031 1.97 0 2.928.034.66.022 1.342.045 2.005.047l-.002.984ZM54.316 113.77c-1.08-.107-2.19-.145-3.262-.171a83.397 83.397 0 0 1-1.96-.074l.051-.983a80.03 80.03 0 0 0 1.938.074c1.09.032 2.217.065 3.328.175l-.095.979ZM40.892 110.422h.202l-.02-.982a20.08 20.08 0 0 1-2.749-.209c-.667-.081-1.356-.166-2.043-.201l-.05.983c.652.033 1.324.114 1.973.194.89.126 1.788.198 2.688.215ZM35.998 112.142c.68 0 1.37-.025 2.037-.048.943-.032 1.92-.064 2.865-.035l.03-.983c-.977-.03-1.968 0-2.929.035-.658.022-1.34.045-2.004.046l.002.985ZM35.806 113.778c1.08-.107 2.19-.145 3.262-.171a83.397 83.397 0 0 0 1.96-.074l-.052-.982a80.05 80.05 0 0 1-1.937.074c-1.09.031-2.216.065-3.329.173l.096.98Z"
            fill="#777ED4"
        />
    </svg>
);

export default WhyGoRroSvg;
