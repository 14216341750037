import {
    IonAvatar,
    IonCheckbox,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonToolbar,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import CashSvg from '../assets/svg-icons/CashSvg';
import CreditCardSvg from '../assets/svg-icons/CreditCardSvg';
import DebitCardSvg from '../assets/svg-icons/DebitCardSvg';
import MobilePaySvg from '../assets/svg-icons/MobilePaySvg';
import WalletSvg from '../assets/svg-icons/WalletSvg';
import { ResultInterface } from '../types';

interface Screen3Props {
    onSetResults: (data: ResultInterface) => void;
    QUESTION: ResultInterface;
}

const options = [
    {
        id: 1,
        name: 'Credit cards',
        icon: <CreditCardSvg />,
    },
    { id: 2, name: 'Debit cards', icon: <DebitCardSvg /> },
    { id: 3, name: 'Apple/Google Pay', icon: <MobilePaySvg /> },
    { id: 4, name: 'Cash', icon: <CashSvg /> },
    { id: 5, name: 'Others', icon: <WalletSvg /> },
];

const Screen3 = ({ onSetResults, QUESTION }: Screen3Props) => {
    const isChecked = (text: string) => {
        if (QUESTION) {
            return !!QUESTION.answers.find((answer) => answer === text);
        }
        return false;
    };
    return (
        <>
            <IonText className="ion-text-center screen-title">
                <h1>
                    What payment methods <br /> do you prefer?
                </h1>
            </IonText>

            <IonText className="ion-text-center screen-sub-title">
                <p className="screen-sub-title">
                    Select as many as you’d like.
                </p>
            </IonText>
            <IonList>
                {options.map(({ id, name, icon }) => (
                    <IonItem
                        key={id}
                        lines="none"
                        className="screen-list-item"
                        onClick={() => {
                            QUESTION.answers = isChecked(name)
                                ? QUESTION?.answers.filter(
                                      (answer) => answer !== name
                                  ) || []
                                : [...(QUESTION?.answers ?? []), name];
                            onSetResults(QUESTION);
                        }}
                    >
                        <IonAvatar slot="start">{icon ?? ''}</IonAvatar>
                        <IonLabel>
                            <h1>{name}</h1>
                        </IonLabel>
                        {isChecked(name) && (
                            <IonCheckbox
                                checked={isChecked(name)}
                                color="success"
                                slot="end"
                            />
                        )}
                    </IonItem>
                ))}
            </IonList>
        </>
    );
};
export default Screen3;
