import { Browser } from '@capacitor/browser';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
    IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { FC, useRef, useState } from 'react';
import DiamondSvg from './DiamondSvg';
import WhyGoRroSvg from './WhyGoProSvg';

const LIST_ITEMS = [
    {
        id: 1,
        text: 'Connect credit cards & bank accounts',
        stopColor: {
            stopColorOne: '#CB3896',
            stopColorTwo: '#162398',
        },
    },
    {
        id: 2,
        text: 'Import expenses automatically',
        stopColor: {
            stopColorOne: '#64DDC0',
            stopColorTwo: '#1F6453',
        },
    },
    {
        id: 3,
        text: 'Split expenses with one swipe',
        stopColor: {
            stopColorOne: '#F27171',
            stopColorTwo: '#FDC40C',
        },
    },
];

interface ConnectModalInterface {
    firstModalRef: any;
    router: any;
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    onClick: () => void;
}

const ConnectModal: FC<ConnectModalInterface> = ({
    firstModalRef,
    showModal,
    setShowModal,
    router,
    onClick,
}) => {
    return (
        <>
            <IonModal
                ref={firstModalRef}
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
                presentingElement={router}
                swipeToClose={true}
            >
                <IonContent scrollY={false}>
                    <IonToolbar>
                        <IonButtons
                            onClick={() => setShowModal(false)}
                            slot="end"
                        >
                            <IonIcon size="large" icon={closeOutline} />
                        </IonButtons>
                    </IonToolbar>

                    <div className="onboard-title-wrapper">
                        <p>
                            This is where the <br /> magic happens.
                        </p>
                    </div>
                    <div>
                        <p className="onboard-sub-title-wrapper ">
                            <IonText>
                                BillMagic works best when synched to your credit
                                card or bank account.
                            </IonText>
                        </p>
                    </div>
                    <div className="why-go-pro-green-box">
                        <div className="why-go-pro-header">
                            <div className="why-go-pro-main-icon">
                                <WhyGoRroSvg />
                            </div>
                            <div className="why-go-pro-list">
                                <h1>Why go pro</h1>
                                {LIST_ITEMS.map(({ id, text, stopColor }) => (
                                    <div className="item" key={id}>
                                        <div className="list-icon">
                                            <DiamondSvg {...stopColor} />
                                        </div>

                                        <p>{text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <IonButton
                            onClick={onClick}
                            expand="block"
                            shape="round"
                        >
                            Get BillMagic Pro
                        </IonButton>
                        <div>
                            <p className="price-text">
                                $4.99/month. Cancel any time.
                            </p>

                            <IonRow>
                                <IonCol>
                                    <IonText
                                        className="ion-text-center "
                                        color="primary"
                                        onClick={async () => {
                                            await Browser.open({
                                                url:
                                                    'https://www.billmagic.app/terms',
                                            });
                                        }}
                                    >
                                        <p className="empty-screen-info-link">
                                            Terms of Service
                                        </p>
                                    </IonText>
                                </IonCol>
                                <IonCol>
                                    <IonText
                                        className="ion-text-center"
                                        color="primary"
                                        onClick={async () => {
                                            await Browser.open({
                                                url:
                                                    'https://www.billmagic.app/privacy',
                                            });
                                        }}
                                    >
                                        <p className="empty-screen-info-link">
                                            Privacy Policy
                                        </p>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
};

export default ConnectModal;
