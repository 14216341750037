import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    IonAvatar,
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonText,
    useIonAlert,
    useIonToast,
} from '@ionic/react';
import Header from '../Header';
import { updateBankAccount } from '../../firebase/BankAccountsCollection';
import { BankInterface } from '../../types/BankInterface';
import { RootState } from '../../redux/store';
import useLoading from '../../hooks/useLoading';
import useSyncBanks from '../../hooks/useSyncBanks';
import usePurchases from '../../hooks/usePurchases';
import useTrack from '../../hooks/useTrack';
import { add, chevronBackOutline } from 'ionicons/icons';
import BankSvg from '../../svg/tnx-category-icons/BankSvg';
import PlaidLinkButton from '../../pages/plaid-o-auth/PlaidLinkButton';
import './index.css';
import { useHistory } from 'react-router';

type AccountsType = {
    closeModal: () => void;
};

interface LinkButtonInterface {
    onClickLink?: () => void;
}

type OnClickPropsType = {
    onClick?: () => void;
};

const LinkButton: FC<LinkButtonInterface> = ({ onClickLink }) => {
    const onClickProps: OnClickPropsType = {};
    if (onClickLink) {
        onClickProps.onClick = onClickLink;
    }
    return (
        <IonItem {...onClickProps} lines="none" className="account-list-item">
            <IonIcon
                icon={add}
                className="add-icon"
                slot="start"
                color="primary"
            />
            <IonLabel color="primary">
                <h1 className="add-new-title">Add Account</h1>
            </IonLabel>
        </IonItem>
    );
};

const Accounts = ({ closeModal }: AccountsType) => {
    const {
        bank: { bankList },
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const [present] = useIonAlert();
    const tost = useIonToast();
    const [showUnlinked, setShowUnlinked] = useState(false);
    const { startLoading, stopLoading, isLoading } = useLoading();
    const { purchaseInfo, checkUser } = usePurchases();
    const [isPro, setIsPro] = useState<boolean | null>(true);
    const { hitAnalytics } = useTrack();
    const { fetchBanks: getBankAccounts } = useSyncBanks();
    const { push } = useHistory();

    useEffect(() => {
        getBankAccounts();
        checkUserStatus();
    }, []);

    const checkUserStatus = () => {
        checkUser()
            .then((res) => {
                setIsPro(true);
            })
            .catch((e) => {
                console.log('e', e);
            });
    };

    const unLink = (account: BankInterface) => {
        startLoading();
        updateBankAccount({ softDelete: true }, account.id)
            .then(() => {
                tost[0]({
                    color: 'success',
                    message: 'Account has been unlinked.',
                    duration: 2000,
                });
                tost[1]();
                getBankAccounts();
                stopLoading();
            })
            .catch((e) => {
                stopLoading();
            });
    };

    const onClickUnLink = (account: BankInterface) => {
        present({
            header: `Unlink  ${account.name}?`,
            message: `Expenses will stop automatically importing. You will not lose any data that has already been synced. `,
            buttons: [
                {
                    role: 'ok',
                    text: 'Cancel',
                    handler: () => {},
                },
                {
                    text: 'Unlink account',
                    cssClass: 'unlink-button',
                    handler: () => {
                        unLink(account);
                    },
                },
            ],
            onDidDismiss: (e) => {},
        });
    };

    return (
        <IonPage>
            <Header
                title="Linked Accounts"
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        slot="start"
                        icon={chevronBackOutline}
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                {isLoading === true && (
                    <IonLoading
                        isOpen={true}
                        onDidDismiss={() => {}}
                        message={'Please wait...'}
                    />
                )}

                <IonList>
                    {isPro === true && (
                        <PlaidLinkButton
                            onSuccessCallBack={() => {
                                push('/transactions');
                            }}
                        >
                            <LinkButton />
                        </PlaidLinkButton>
                    )}
                    {isPro === false && (
                        <IonButton
                            color="primary"
                            className="account-get-bill-magic-pro-btn"
                            onClick={() => {
                                if (authUser) {
                                    const { email, name, id } = authUser;
                                    hitAnalytics('clickSubscribeButton', {
                                        email,
                                        name,
                                        id,
                                    });
                                    purchaseInfo(() => {
                                        checkUserStatus();
                                        hitAnalytics('Subscription activated', {
                                            email,
                                            name,
                                            id,
                                        });
                                    });
                                }
                            }}
                            expand="block"
                        >
                            Get BillMagic Pro
                        </IonButton>
                    )}
                    {bankList
                        .filter(({ softDelete }) => !softDelete)
                        .map((account: BankInterface) => {
                            const { name, id, lastUpdated } = account;
                            return (
                                <IonItem
                                    lines="none"
                                    className="account-list-item "
                                    key={id}
                                >
                                    <IonAvatar color="primary" slot="start">
                                        <BankSvg fill="#1868df" />
                                    </IonAvatar>
                                    <IonLabel>
                                        <h1>{name}</h1>
                                        {lastUpdated && (
                                            <h3>Last update {lastUpdated}</h3>
                                        )}
                                    </IonLabel>
                                    <IonText
                                        slot="end"
                                        color={'danger'}
                                        className="unlink-text"
                                        onClick={() => onClickUnLink(account)}
                                    >
                                        {'Unlink'}
                                    </IonText>
                                </IonItem>
                            );
                        })}
                </IonList>
                <div className="accounts-divider" />
                {!!bankList.filter(({ softDelete }) => !!softDelete).length && (
                    <IonLabel
                        onClick={() => setShowUnlinked(!showUnlinked)}
                        className="unlink-item-header"
                        color="dark"
                    >
                        {showUnlinked ? 'Hide' : 'Show'} unlinked accounts
                    </IonLabel>
                )}
                {showUnlinked && (
                    <IonList>
                        {bankList
                            .filter(({ softDelete }) => !!softDelete)
                            .map((account: BankInterface) => {
                                const { name, id, lastUpdated } = account;
                                return (
                                    <IonItem
                                        color="gray2"
                                        lines="none"
                                        className="unlink-item"
                                        key={id}
                                    >
                                        <IonAvatar slot="start">
                                            <BankSvg fill="#1868df" />
                                        </IonAvatar>
                                        <IonLabel>
                                            <h1>{name}</h1>
                                            {lastUpdated && (
                                                <h3>
                                                    Last update {lastUpdated}
                                                </h3>
                                            )}
                                        </IonLabel>
                                    </IonItem>
                                );
                            })}
                    </IonList>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Accounts;
