import * as React from 'react';

function CheckMarkSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.404 4.544l1.552 1.358L9.05 19.508l-6.009-6.009 1.459-1.458 4.45 4.45L19.403 4.544z"
                fill={props.fill ? props.fill : '#3DC2FF'}
            />
        </svg>
    );
}

export default CheckMarkSvg;
