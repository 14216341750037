import CoffeeCupSvg from '../../svg/tnx-category-icons/CoffeeCupSvg';
import BankSvg from '../../svg/tnx-category-icons/BankSvg';
import CocktailSvg from '../../svg/tnx-category-icons/CocktailSvg';
import { TransactionInterface } from '../../types/TransactionInterface';
import { tnxItemIconColor } from '../../utils/common';
import {
    BANK_FEES,
    BAR,
    BREWERIES,
    COMMUNITY,
    FOOD_ADN_DRINK,
    HOTEL_LOUNGE,
    SPORT_BAR,
    WINE_BAR,
    COFFEE_SHOP,
    TRAVEL,
    BOAT,
    GAS_STATIONS,
    HOME_IMPROVEMENT,
    COMPUTERS,
    OUTDOORS,
    SHOPS,
    INTERNET_SERVICES,
    INTERNET,
    AUTOMOTIVE,
    PAYMENT,
    TRANSFER,
    RECREATION,
    SETTLED,
    MANUAL,
    TAXI,
    CREDIT_CARD,
    TOTAL_SPENT,
} from '../../utils/plaidCategories';
import SuitcaseSvg from '../../svg/tnx-category-icons/SuitcaseSvg';
import AnchorSvg from '../../svg/tnx-category-icons/AnchorSvg';
import BuildingSvg from '../../svg/tnx-category-icons/BuildingSvg';
import GasPumpSvg from '../../svg/tnx-category-icons/GasPumpSvg';
import HouseSvg from '../../svg/tnx-category-icons/HouseSvg';
import LapTopSvg from '../../svg/tnx-category-icons/LapTopSvg';
import MountainSvg from '../../svg/tnx-category-icons/MountainSvg';
import ShoppingTags from '../../svg/tnx-category-icons/ShoppingTags';
import WifiSvg from '../../svg/tnx-category-icons/WifiSvg';
import WrenchSvg from '../../svg/tnx-category-icons/WrenchSvg';
import ForkKnife from '../../svg/tnx-category-icons/ForkKnifeSvg';
import DollarSignSvg from '../../svg/tnx-icons/DollarSignSvg';
import CarSvg from '../../svg/tnx-category-icons/CarSvg';
import ReceiptSvg from '../../svg/tnx-category-icons/ReceiptSvg';
import CreditCardSVG from '../../svg/tnx-category-icons/CreditCardSVG';
import ManualSvg from '../../svg/tnx-category-icons/ManualSvg';
import Person from '../../svg/tnx-category-icons/PersonSvg';

const TnxCategoryIcon = ({
    category: categories,
    groupColor,
}: TransactionInterface) => {
    const selectIcon = (categories: Array<string>) => {
        var item: any = '';

        [...categories].forEach((category) => {
            switch (category) {
                case BANK_FEES:
                case TRANSFER:
                    item = (
                        <BankSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case COMMUNITY:
                    item = (
                        <BuildingSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case FOOD_ADN_DRINK:
                    item = (
                        <ForkKnife
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case BAR:
                case WINE_BAR:
                case SPORT_BAR:
                case HOTEL_LOUNGE:
                case BREWERIES:
                    item = (
                        <CocktailSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case COFFEE_SHOP:
                    item = (
                        <CoffeeCupSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case TRAVEL:
                    item = (
                        <SuitcaseSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case BOAT:
                    item = (
                        <AnchorSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case GAS_STATIONS:
                    item = (
                        <GasPumpSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case HOME_IMPROVEMENT:
                case RECREATION:
                    item = (
                        <HouseSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case COMPUTERS:
                    item = (
                        <LapTopSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case OUTDOORS:
                    item = (
                        <MountainSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case SHOPS:
                    item = (
                        <ShoppingTags
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case INTERNET_SERVICES:
                case INTERNET:
                    item = (
                        <WifiSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case AUTOMOTIVE:
                    item = (
                        <WrenchSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case SETTLED:
                    item = (
                        <DollarSignSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case MANUAL:
                    item = (
                        <ManualSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case TAXI:
                    item = (
                        <CarSvg
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case CREDIT_CARD:
                    item = (
                        <CreditCardSVG
                            className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                case TOTAL_SPENT:
                    item = (
                        <Person
                            // className="tnx-icon"
                            {...tnxItemIconColor(groupColor)}
                        />
                    );
                    break;
                default:
                    break;
            }
        });

        return item;
    };

    const item = selectIcon(categories ? categories : []);

    return (
        <>
            {item ? (
                item
            ) : (
                <ReceiptSvg
                    className="tnx-icon"
                    {...tnxItemIconColor(groupColor)}
                />
            )}
        </>
    );
};

export default TnxCategoryIcon;
