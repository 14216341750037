import { IonHeader, IonIcon, IonText, IonToolbar } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import Img from '../assets/onboarding-sc4.png';

interface Screen4Props {}

const Screen4 = ({}: Screen4Props) => (
    <>
        <IonText className="ion-text-center screen-title">
            <h1>Let us do the work.</h1>
        </IonText>
        <IonText className="ion-text-center screen-sub-title">
            <p>
                No need to save receipts. BillMagic imports all your
                credit/debit/checking expenses into one convenient place.
            </p>
        </IonText>
        <div className="sc-4-img-wrapper">
            <img className="sc-4-img" src={Img} />
        </div>
    </>
);

export default Screen4;
