import moment from 'moment';
import ForkKnife from '../svg/ForkKnife';
import BoltSvg from '../svg/group-icons/BoltSvg';
import CommentSvg from '../svg/group-icons/CommentSvg';
import GroupPeopleSvg from '../svg/group-icons/GroupPeopleSvg';
import HandPaceSvg from '../svg/group-icons/HandPaceSvg';
import HandSpock from '../svg/group-icons/HandSpock';
import HeartSvg from '../svg/group-icons/HeartSvg';
import PaperPlaneSvg from '../svg/group-icons/PaperPlaneSvg';
import SunSvg from '../svg/group-icons/SunSvg';
import DollarSignSvg from '../svg/tnx-icons/DollarSignSvg';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FORM_MODAL = 'FORM_MODAL';
export const VIEW_MODAL = 'VIEW_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TODAY = moment().format(DATE_FORMAT);
export const LINK_TOKEN = 'LINK_TOKEN';
export const LINK_TOKEN_TYPE = 'LINK_TOKEN_TYPE';
export const GROUP_LINK = 'glink';
export const TEMPORARY_CONTACT_LINK = 'tc-link';

export const REVENUE_CAT_API_KEY =
    process.env.REACT_APP_REVENUE_CAT_API_KEY || '';

export const groupIcons = (props: any = {}) => [
    {
        id: 'PEOPLE',
        icon: <GroupPeopleSvg {...props} />,
    },
    {
        id: 'HAND_PACE',
        icon: <HandPaceSvg {...props} />,
    },
    {
        id: 'HEART',
        icon: <HeartSvg {...props} />,
    },
    {
        id: 'SUN',
        icon: <SunSvg {...props} />,
    },
    {
        id: 'PAPER_PLANE',
        icon: <PaperPlaneSvg {...props} />,
    },
    {
        id: 'HAND_SPOCK',
        icon: <HandSpock {...props} />,
    },
    {
        id: 'COMMENT',
        icon: <CommentSvg {...props} />,
    },
    {
        id: 'BOLT',
        icon: <BoltSvg {...props} />,
    },
];

export const transactionIcons = (props: any = {}) => [
    {
        id: 'FORK_KNIFE',
        icon: <ForkKnife {...props} />,
    },
    {
        id: 'DOLLAR_SIGN',
        icon: <DollarSignSvg {...props} />,
    },
];

export const groupColors = [
    'purple',
    'blue_aqua',
    'sherbert',
    'pink_coral',
    'marine',
    'lemon_lime',
    'teal_pink',
    'wine',
];

export const GROUP_LIST_IN_TNX = 1;
export const TNX_DETAILS_IN_TNX = 2;
export const HOW_TNX_WORKS = 3;
export const GROUP_LIST = 4;
export const HOW_TNX_WORKS_SKIPPED = 'HOW_TNX_WORKS_SKIPPED';

export const SKIPPED = 'SKIPPED';

export const EXP_ONBOARDING_QUESTIONS = 'EXP_ONBOARDING_QUESTIONS';
export const EXP_ACTIVE = 'ACTIVE';
export const EXP_ACTIVE_GROUP = 'ACTIVE';
export const EXP_CONTROL_GROUP = 'CONTROL';
export const EXP_INPROGRESS = 'INPROGRESS';
export const EXP_COMPLETED = 'COMPLETED';

export const EXPERIMENTS = [
    {
        id: EXP_ONBOARDING_QUESTIONS,
        status: EXP_ACTIVE,
    },
];
