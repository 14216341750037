import firebase from 'firebase';
import { TemporaryContactCollectionInterface } from '../types/TemporaryContactInterface';
import db from './db';

const COLLECTION = 'temporary_contacts';

/**
 * Create  temporary contacts
 * @param contact Array<TemporaryContactCollectionInterface>
 *
 */
export const createTemporaryContact = (
    contact: TemporaryContactCollectionInterface
) => {
    return db.collection(COLLECTION).add({
        ...contact,
        created: firebase.firestore.Timestamp.now(),
    });
};

/**
 * Create Batch temporary contacts
 * @param contacts Array<TemporaryContactCollectionInterface>
 * @returns firebase.firestore.WriteBatch
 */
export const createBatchTemporaryContact = (
    contacts: Array<TemporaryContactCollectionInterface>
) => {
    let batch = db.batch();
    const contactRef = db.collection(COLLECTION).doc();
    contacts.forEach((contact) => {
        batch.set(contactRef, {
            ...contact,
            created: firebase.firestore.Timestamp.now(),
        });
    });
    return batch.commit();
};

/**
 * Fetch group temporary contacts
 * @param id String
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchGroupTemporaryContactsByGroupId = async (id: string) => {
    const temporaryContacts = db
        .collection(COLLECTION)
        .where('assignedGroupIds', 'array-contains', id)
        .where('isActive', '!=', false);
    const snapshot = await temporaryContacts.get();
    return snapshot;
};

/**
 * Get temporary contact by doc id
 * @param id String
 * @returns firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
 */
export const fetchTempContact = async (id: string) => {
    const temporaryContact = db.collection(COLLECTION).doc(id);
    const snapshot = await temporaryContact.get();
    return snapshot;
};

/**
 * Update temporary contact collection
 * @param id String
 * @param data Object
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const updateTempContact = (id: string, data: any) => {
    const temporaryContactRef = db.collection(COLLECTION).doc(id);
    return temporaryContactRef.update({ ...data });
};
