import React, { FC, ReactNode } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';
import usePlaid from '../../hooks/usePlaid';
import useTrack from '../../hooks/useTrack';
import { setPlaidLinkToken } from '../../redux/slices/appSlice';
import { RootState } from '../../redux/store';

interface PlaidLinkButtonInterface {
    children: ReactNode;
    onSuccessCallBack?: () => void;
}

const PlaidLinkButton: FC<PlaidLinkButtonInterface> = ({
    children,
    onSuccessCallBack,
}) => {
    const { onSuccess } = usePlaid({ generateLinkToken: true });
    const { hitAnalytics } = useTrack();
    const {
        auth: { authUser },
        app: { plaidLinkToken },
    } = useSelector((state: RootState) => state);

    const onExit = (err: any, metadata: any) => {};
    const dispatch = useDispatch();

    const config: any = {
        token: plaidLinkToken ?? '',
        onExit,
        onSuccess: (public_token: any, metadata: any) => {
            onSuccess(public_token, metadata).then(() => {
                const {
                    name: institution,
                    institution_id,
                } = metadata.institution;
                hitAnalytics('Connect Bank', {
                    ...authUser,
                    institution,
                    institution_id,
                });
                if (onSuccessCallBack) {
                    onSuccessCallBack();
                }
                dispatch(setPlaidLinkToken(undefined));
            });
        },
    };
    const { open } = usePlaidLink(config);

    const onClickLink = () => {
        hitAnalytics('creditCardConnect', {
            id: authUser?.id,
        });
        open();
    };

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { onClickLink });
        }
        return child;
    });

    return <>{plaidLinkToken && childrenWithProps}</>;
};

export default PlaidLinkButton;
