import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthReducerInterface } from '../../types/AuthInterface';
import { GroupInterface } from '../../types/GroupInterface';
import { UserInterface } from '../../types/UserInterface';

const initialState: AuthReducerInterface = {
    authenticated: null,
    authUser: null,
    defaultGroup: null,
    isPro: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUser: (state, action: PayloadAction<UserInterface>) => {
            state.authUser = action.payload;
        },
        removeAuthUser: (state) => {
            state.authUser = null;
        },
        setAuthenticated: (state) => {
            state.authenticated = true;
        },
        setUnAuthenticated: (state) => {
            state.authenticated = false;
        },
        setSubscriptionStatus: (
            state,
            action: PayloadAction<boolean | null>
        ) => {
            state.isPro = action.payload;
        },
        setDefaultGroup: (
            state,
            action: PayloadAction<GroupInterface | null>
        ) => {
            state.defaultGroup = action.payload;
        },
    },
});

export const {
    setAuthUser,
    removeAuthUser,
    setAuthenticated,
    setUnAuthenticated,
    setDefaultGroup,
    setSubscriptionStatus,
} = authSlice.actions;

export default authSlice.reducer;
