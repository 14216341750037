import { useState, useEffect } from 'react';
import {
    IonContent,
    IonFooter,
    IonIcon,
    IonInput,
    IonPage,
    IonToolbar,
} from '@ionic/react';
import Header from '../Header';
import { chevronBackOutline, sendOutline } from 'ionicons/icons';
import { chatSnap, createChat } from '../../firebase/Chat';
import { UserInterface } from '../../types/UserInterface';
import MessageListWrapper from './MessageListWrapper';
import { getFirstName } from '../../utils/common';

type ChatType = {
    authUser: UserInterface;
    goBack: Function;
};

interface MessagesInterface {
    userId: string;
    message: string;
}

const Chat = ({ authUser, goBack }: ChatType) => {
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<MessagesInterface[]>([]);
    const [thread, setThread] = useState<any>(null);

    useEffect(() => {
        const unSub = chatSnap(authUser.id).onSnapshot((snapShot) => {
            var data = snapShot.data();

            if (snapShot.exists) {
                setMessages(data?.messages);
                setThread(data);
            } else if (snapShot.exists === false) {
                createChat({
                    docId: authUser.id,
                    threadName: getFirstName(authUser.name),
                    client: authUser.email,
                    message: `Hello ${getFirstName(authUser.name)}`,
                    userId: 'admin',
                    isNew: true,
                    name: 'Support',
                    email: 'support@billmagic.app',
                });

                setTimeout(() => {
                    createChat({
                        threadName: getFirstName(authUser.name),
                        client: authUser.email,
                        docId: authUser.id,
                        message: `Welcome to Bill Magic support. You can chat with us here or email us at support@billmagic.app.`,
                        userId: 'admin',
                        isNew: false,
                        name: 'Support',
                        email: 'support@billmagic.app',
                    });
                }, 2000);

                setTimeout(() => {
                    createChat({
                        threadName: getFirstName(authUser.name),
                        client: authUser.email,
                        docId: authUser.id,
                        message: `How can I help you today?`,
                        userId: 'admin',
                        isNew: false,
                        name: 'Support',
                        email: 'support@billmagic.app',
                    });
                }, 3000);

                setTimeout(() => {
                    createChat({
                        threadName: getFirstName(authUser.name),
                        client: authUser.email,
                        docId: authUser.id,
                        message: `We will try to get back to you asap, but it sometimes takes a few hours.`,
                        userId: 'admin',
                        isNew: false,
                        name: 'Support',
                        email: 'support@billmagic.app',
                    });
                }, 4000);
            }
        });
        return () => unSub();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        let content = document.querySelector('ion-content');
        if (content) {
            content.scrollToBottom(500);
        }
    };

    const submit = () => {
        if (!message) {
            return;
        }
        if (authUser) {
            setMessage('');
            createChat({
                docId: authUser.id,
                message,
                userId: authUser.id,
                isNew: messages.length === 0,
                name: authUser.name,
                email: authUser.email,
            });
        }
    };
    return (
        <IonPage>
            <Header
                title="Support Chat"
                leftComponent={
                    <IonIcon
                        size="large"
                        color="primary"
                        icon={chevronBackOutline}
                        onClick={() => goBack()}
                    />
                }
            />
            <IonContent>
                {thread && (
                    <div className="message-list-container">
                        <MessageListWrapper
                            thread={thread}
                            authUser={authUser}
                            messages={messages}
                        />
                    </div>
                )}
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonInput
                        autocapitalize="on"
                        multiple
                        onIonChange={(e: CustomEvent) =>
                            setMessage(e.detail.value)
                        }
                        value={message}
                        placeholder="Type here your message..."
                    />

                    <IonIcon
                        size="large"
                        color="primary"
                        onClick={() => submit()}
                        slot="end"
                        icon={sendOutline}
                    />
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

export default Chat;
