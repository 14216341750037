import * as React from 'react';
import { SVGProps } from 'react';

const HomeSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#xa)" />
        <path
            d="m11 18 9-7 9 7v11a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V18Z"
            stroke="url(#xb)"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17 31V21h6v10"
            stroke="url(#xc)"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="xa"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F27171" />
                <stop offset={0.638} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="xb"
                x1={20.024}
                y1={12.773}
                x2={20.106}
                y2={31.001}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F27171" />
                <stop offset={0.638} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="xc"
                x1={20.008}
                y1={21.887}
                x2={20.07}
                y2={31}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F27171" />
                <stop offset={0.638} stopColor="#FDC40C" />
            </linearGradient>
        </defs>
    </svg>
);

export default HomeSvg;
