import { IonText } from '@ionic/react';
import FirstBanner from './Onboarding1.jpg';
import SecondBanner from './Onboarding2.png';
import ThirdBanner from './Onboarding3.jpg';

interface SlideInterface {
    title: string;
    subTitle: string;
    banner: number;
}

const Slide = ({ title, subTitle, banner }: SlideInterface) => {
    const getImg = () => {
        switch (banner) {
            case 1:
                return FirstBanner;
            case 2:
                return SecondBanner;
            case 3:
                return ThirdBanner;
            default:
                return FirstBanner;
        }
    };
    return (
        <div className="slide-content">
            <div className="banner-wrapper">
                <img src={getImg()} />
            </div>

            <div className="slide-content-wrapper ion-text-center">
                <h1>{title}</h1>
                <p className="ion-text-center on-board-sub-title">
                    <IonText>{subTitle}</IonText>
                </p>
            </div>
        </div>
    );
};

export default Slide;
