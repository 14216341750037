import { useEffect } from 'react';
import {
    IonAvatar,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonSkeletonText,
    IonText,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import useSyncGroups from '../../hooks/useSyncGroups';
import { GroupInterface } from '../../types/GroupInterface';
import { add } from 'ionicons/icons';
import { colorMap } from '../../utils/common';
import { groupIcons } from '../../utils/constant';
import { setIsFetching } from '../../redux/slices/groupSlice';
import EmptyGroupImage from '../../assets/groups-empty.png';
import GroupMembersCount from './GroupMembersCount';

type GroupListType = {
    onClickListItem: (group: GroupInterface) => void;
    showSubtitle: boolean;
    showRightArrow: boolean;
    onClickAddBtn?: () => void;
    onClickAddMemberBtn?: (event: any, groupId: string) => void;
};

const GroupList = ({
    onClickListItem,
    showSubtitle,
    showRightArrow,
    onClickAddBtn,
    onClickAddMemberBtn,
}: GroupListType) => {
    const {
        auth: { authUser, defaultGroup },
        group: { groupList, isFetching },
    } = useSelector((state: RootReducerInterface) => state);
    const dispatch = useDispatch();
    const { syncGroups } = useSyncGroups();

    useEffect(() => {
        dispatch(setIsFetching(true));
        if (authUser) {
            syncGroups();
        }
    }, [authUser]);

    if (isFetching && !groupList.length) {
        return (
            <>
                <IonList>
                    {[...Array(20)].map((i, key) => (
                        <IonItem key={key}>
                            <IonAvatar className="tnx-item-avatar" slot="start">
                                <IonSkeletonText animated />
                            </IonAvatar>
                            <IonLabel>
                                <h3>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '60%' }}
                                    />
                                </h3>
                                <p>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '30%' }}
                                    />
                                </p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </>
        );
    }

    if (!isFetching && !groupList.length) {
        return (
            <>
                <>
                    <div className="circle-wrapper">
                        <img src={EmptyGroupImage} />
                    </div>
                    <div className="group-modal-empty-message-wrapper">
                        Create your first group to start <br /> splitting
                        expenses
                    </div>
                    <br />
                    <br />
                    <IonButton
                        className="form-button margin-top-zero"
                        color="primary"
                        fill={groupList.length ? 'outline' : 'solid'}
                        expand="block"
                        onClick={onClickAddBtn}
                    >
                        <IonIcon icon={add}></IonIcon> Create Group
                    </IonButton>
                </>
            </>
        );
    }

    return (
        <IonList>
            {groupList.map((i: GroupInterface) => {
                if (!i) {
                    return <></>;
                }

                const { name, groupColor, created } = i;
                var classNames = `grp-list-ellipse`;
                if (groupColor) {
                    classNames = `${classNames} ${colorMap(
                        groupColor
                    )}-linear-gradient-icon-bg`;
                } else {
                    classNames = `${classNames} purple-linear-gradient-icon-bg`;
                }
                var item: any = groupIcons()[
                    Math.floor(Math.random() * groupIcons().length)
                ].icon;
                if (i.groupIcon) {
                    item = groupIcons().find(({ id }) => id === i.groupIcon)
                        ?.icon;
                }

                return (
                    <IonItem
                        className="grp-list-item"
                        key={i.id}
                        onClick={(event) => {
                            event.stopPropagation();
                            onClickListItem(i);
                        }}
                    >
                        <IonAvatar slot="start">
                            <div className={classNames}>{item}</div>
                        </IonAvatar>
                        <IonLabel>
                            <h2 className="group-list-title">{name ?? name}</h2>
                            {showSubtitle && (
                                <>
                                    <IonText className="group-list-sub-title">
                                        {created &&
                                            moment(created).format('MMM YYYY')}
                                    </IonText>
                                    <br />
                                </>
                            )}
                            <GroupMembersCount
                                group={i}
                                defaultGroup={defaultGroup}
                                onClickAddMemberBtn={onClickAddMemberBtn}
                            />
                        </IonLabel>

                        {showRightArrow && (
                            <IonText slot="end">
                                <div className="grp-tnx-right-wrapper">
                                    <div>
                                        {/* {
                                        <>
                                            {' '}
                                            <IonText className="grp-tnx-right-msg">
                                                Dilan owes
                                            </IonText>
                                            <IonText color="green_one">
                                                <h3 className="grp-tnx-right-msg-price">
                                                    $12.00
                                                </h3>
                                            </IonText>
                                        </>
                                    } */}
                                    </div>
                                </div>
                            </IonText>
                        )}
                    </IonItem>
                );
            })}
        </IonList>
    );
};

export default GroupList;
