import { useEffect } from 'react';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import currency from 'currency.js';
import IonDecimalInput from '../IonDecimalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setExactSplittedMembers } from '../../redux/slices/expenseFormSlice';
import { SplitAmountsInterface } from '../../types/ExpenseFormInterface';

const sumFromArray = (propertyName: string, array: SplitAmountsInterface[]) => {
    let sum = 0;
    array.forEach((item: any) => {
        sum += Number(item[propertyName]) ?? 0;
    });
    return currency(sum);
};

const ExactSplit = ({
    setHasError,
}: {
    setHasError: (hasError: boolean) => void;
}) => {
    const { members, exactSplittedMembers, amount } = useSelector(
        (state: RootState) => state.expenseForm
    );

    const dispatch = useDispatch();

    const sumSplitAmounts = Number(sumFromArray('value', exactSplittedMembers));

    const leftUnsplit = (Number(amount) ? Number(amount) : 0) - sumSplitAmounts;

    useEffect(() => {
        setHasError(leftUnsplit !== 0);
    }, [leftUnsplit]);

    const onChangeVal = (uId: string, val: number) => {
        var membersValues: Array<SplitAmountsInterface> = [];

        if (!!exactSplittedMembers.find(({ id }) => id === uId)) {
            membersValues = [
                ...exactSplittedMembers.filter(({ id }) => uId !== id),
                {
                    id: uId,
                    value: val,
                },
            ];
        } else {
            membersValues = [
                ...exactSplittedMembers,
                {
                    id: uId,
                    value: val,
                },
            ];
        }
        dispatch(setExactSplittedMembers([...membersValues]));
    };

    const leftSplitColorObj: any = {};
    if (leftUnsplit < 0) {
        leftSplitColorObj.color = 'alert';
    }

    return (
        <>
            <div className="ion-text-center">
                <h3 data-testid="title">
                    ${Number(amount).toFixed(2)} of $
                    {sumSplitAmounts.toFixed(2)} allocated
                </h3>

                <IonText data-testid="sub-title" {...leftSplitColorObj}>
                    $ {Number(leftUnsplit).toFixed(2)} left unsplit
                </IonText>
            </div>
            <br />
            <br />
            {members.map(({ id, name }) => (
                <IonItem key={id} data-testid={`input-item-${id}`}>
                    <IonLabel position="floating">{name} </IonLabel>
                    <IonDecimalInput
                        data-testid={`input-${id}`}
                        value={
                            exactSplittedMembers.find(
                                ({ id: uId }) => uId === id
                            )?.value || ''
                        }
                        onIonChange={(e: CustomEvent) => {
                            onChangeVal(id, e.detail.value);
                        }}
                    />
                </IonItem>
            ))}
        </>
    );
};

export default ExactSplit;
