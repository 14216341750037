import { useState } from 'react';
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonText,
    useIonAlert,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import useModal from '../../hooks/useModal';
import { SplitMethodEnum } from '../../types/TransactionInterface';
import Header from '../Header';
import EquallySplit from './EquallySplit';
import ExactSplit from './ExactSplit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setSplitMethod } from '../../redux/slices/expenseFormSlice';

type SplitPros = {
    disabled: boolean;
};

const Split = ({ disabled }: SplitPros) => {
    const { setShowModal, showModal } = useModal();
    const [hasError, setHasError] = useState<boolean>(false);
    const [present] = useIonAlert();

    const { splitMethod } = useSelector(
        (state: RootState) => state.expenseForm
    );
    const dispatch = useDispatch();

    const renderSplitContent = () => {
        switch (splitMethod) {
            case SplitMethodEnum.Equally:
                return <EquallySplit setHasError={setHasError} />;
            case SplitMethodEnum.SplitSpecific:
                return <ExactSplit setHasError={setHasError} />;
            default:
                return <></>;
        }
    };

    const goBack = () => {
        if (!hasError) {
            setShowModal(false);
        } else {
            present({
                header:
                    splitMethod == SplitMethodEnum.SplitSpecific
                        ? 'Oops'
                        : 'Oops',
                message:
                    splitMethod == SplitMethodEnum.SplitSpecific
                        ? `Amounts entered must add up to the total cost. Please check the amounts entered again.`
                        : 'Please select at least one person',
                buttons: [
                    {
                        text: 'Got it',
                    },
                ],
                onDidDismiss: (e) => {},
            });
        }
    };

    return (
        <>
            <IonItem
                disabled={disabled}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <IonText className="list-item2-text">Split</IonText>
                <IonText className="list-item2-text" slot="end" color="primary">
                    {splitMethod === SplitMethodEnum.SplitSpecific
                        ? 'Exact Amount'
                        : splitMethod}
                </IonText>
            </IonItem>
            {showModal && (
                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonPage>
                        <Header
                            title="Split Options"
                            leftComponent={
                                <IonIcon
                                    slot="start"
                                    size="large"
                                    color="dark"
                                    icon={chevronBackOutline}
                                    onClick={goBack}
                                />
                            }
                        />
                        <IonContent fullscreen>
                            <div className="segment-wrapper">
                                <IonSegment
                                    value={splitMethod}
                                    onIonChange={(e: CustomEvent) => {
                                        dispatch(
                                            setSplitMethod(e.detail.value)
                                        );
                                    }}
                                >
                                    <IonSegmentButton
                                        value={SplitMethodEnum.Equally}
                                    >
                                        <IonLabel>Equally </IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton
                                        value={SplitMethodEnum.SplitSpecific}
                                    >
                                        <IonLabel>Exact Amounts </IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </div>
                            {renderSplitContent()}
                        </IonContent>
                    </IonPage>
                </IonModal>
            )}
        </>
    );
};

export default Split;
