import { IonRow, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import { colorMap } from '../../utils/common';
import { colors } from '../../theme/colors';
import { UserInterface } from '../../types/UserInterface';
import { GroupInterface } from '../../types/GroupInterface';

type AvatarRawProps = {
    groupMembers: UserInterface[];
    groupInfo?: GroupInterface;
    onClickAddMembers: () => void;
};

type AvatarList = Array<JSX.Element>;

const AvatarRaw = ({
    groupMembers,
    groupInfo,
    onClickAddMembers,
}: AvatarRawProps) => {
    var gradientColor: string = `purple-linear-gradient-icon-bg`;
    var activeColor: string = 'purple';
    if (groupInfo?.groupColor) {
        var groupColor = colorMap(groupInfo.groupColor);
        // @ts-ignore
        activeColor = colors[groupColor].hex;
        gradientColor = `${groupColor}-linear-gradient-icon-bg`;
    }

    const avatarList: AvatarList = [];

    const memberNames =
        groupMembers && groupMembers.map((member: any) => member.name);

    if (memberNames && memberNames.length > 0) {
        memberNames.map((name: string, key: number) => {
            if (key < 3) {
                avatarList.push(
                    <div
                        key={key}
                        className={'single-avatar-btn ' + gradientColor}
                    >
                        <p className="avatar-title">{name.charAt(0)}</p>
                    </div>
                );
            }
        });
    }

    avatarList.push(
        <div className="single-avatar-btn" onClick={onClickAddMembers}>
            <IonIcon
                className="avatar-add"
                style={{ color: activeColor }}
                icon={add}
            ></IonIcon>
        </div>
    );

    return <IonRow className="group-avatar-raw">{avatarList}</IonRow>;
};

export default AvatarRaw;
