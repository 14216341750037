import firebase from 'firebase';
import db from './db';

const COLLECTION = 'group_links';

/**
 * Get Group link
 * @param id String
 * @returns firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
 */
export const getGroupLink = async (id: string) => {
    const groupLink = db.collection(COLLECTION).doc(id);
    const snapshot = await groupLink.get();
    return snapshot;
};

/**
 * Create group link
 * @param id String
 * @returns Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>
 */
export const createGroupLink = (id: string) => {
    const now = Date.now();
    const expiresAt = new Date(now + 24 * 60 * 60 * 1000);
    return db.collection(COLLECTION).add({
        groupId: id,
        created: firebase.firestore.Timestamp.now(),
        expiresAt: firebase.firestore.Timestamp.fromDate(expiresAt),
    });
};

/**
 * Get valid group link
 * @param id String
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const getValidGroupLink = async (id: string) => {
    const now = Date.now();
    const groupLink = db
        .collection(COLLECTION)
        .where('groupId', '==', id)
        .where(
            'expiresAt',
            '>',
            firebase.firestore.Timestamp.fromDate(new Date(now))
        );
    const snapshot = await groupLink.get();
    return snapshot;
};
