import * as React from 'react';

function CocktailSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 18.967c6.791-.948 12-5.162 12-12.384V4.792C32 3.806 31.214 3 30.25 3H5.75C4.788 3 4 3.806 4 4.792v1.791c0 7.222 5.227 11.436 12 12.384V28H9.02C7.91 28 7 28.9 7 30v1c0 .56.445 1 1.01 1h20.98A.995.995 0 0030 31v-1c0-1.1-.908-2-2.019-2h-7.98v-9.033zM7.456 6.412c0 5.93 4.707 8.958 10.5 8.958 5.792 0 10.5-3.028 10.5-8.958h-21z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default CocktailSvg;
