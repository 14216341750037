import { Contact } from '@capacitor-community/contacts';
import {
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { UserInterface } from '../../types/UserInterface';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import './index.css';

type AddNewContactProps = {
    contactInfo: UserInterface;
    closeModal: () => void;
};

const AddNewContact = ({ contactInfo, closeModal }: AddNewContactProps) => {
    const [name, setName] = useState<string>('');
    useEffect(() => {
        if (contactInfo.name) {
            setName(contactInfo.name);
        }
    }, [contactInfo]);
    console.log('contactInfo', contactInfo.email);

    return (
        <IonPage>
            <Header
                title="Add a new contact"
                rightComponent={
                    <HeaderActionButton
                        title="Done"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
            />
            {/* <IonContent fullscreen>
                <IonItem>
                    <IonLabel position="floating">Name*</IonLabel>
                    <IonInput name="name" value={name} />
                </IonItem>
                {contactInfo.emails.length ||
                contactInfo.phoneNumbers.length ? (
                    <IonList>
                        <IonListHeader className="ion-custom-list-header">
                            Select contact method
                        </IonListHeader>
                        {contactInfo.emails.map(({ address, label }) => (
                            <IonItem>
                                <IonLabel>
                                    {address}{' '}
                                    {label ? <small>{label}</small> : <></>}
                                </IonLabel>
                            </IonItem>
                        ))}
                        {contactInfo.phoneNumbers.map(({ number, label }) => (
                            <IonItem>
                                <IonLabel>
                                    {number}{' '}
                                    {label ? <small>{label}</small> : <></>}
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                ) : (
                    <></>
                )}
                <IonList>
                    <IonListHeader className="ion-custom-list-header">
                        {contactInfo.emails.length ||
                        contactInfo.phoneNumbers.length
                            ? 'Or add new'
                            : 'Add new'}
                    </IonListHeader>
                </IonList>
                <IonItem>
                    <IonLabel position="floating">Add new email</IonLabel>
                    <IonInput name="email" value="" />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">
                        Add new phone number
                    </IonLabel>
                    <IonInput name="phoneNumber" value="" />
                </IonItem>
            </IonContent> */}
        </IonPage>
    );
};

export default AddNewContact;
