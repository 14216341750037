import * as React from 'react';

interface HelpTabSvgInterface extends React.SVGProps<SVGSVGElement> {
    active: number;
}

function HelpSvg(props: HelpTabSvgInterface) {
    return (
        <svg
            style={{ transform: 'scale(0.75)' }}
            width={32}
            height={32}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm-6.67-4.95l3.445-3.445a8.28 8.28 0 006.45 0l3.446 3.445a12.927 12.927 0 01-13.342 0zM21.16 16A5.167 5.167 0 0116 21.161 5.167 5.167 0 0110.839 16 5.167 5.167 0 0116 10.839 5.167 5.167 0 0121.161 16zm5.89 6.67l-3.446-3.445a8.28 8.28 0 000-6.45l3.445-3.446a12.927 12.927 0 010 13.342zM22.67 4.95l-3.446 3.446a8.28 8.28 0 00-6.45 0L9.329 4.95a12.927 12.927 0 0113.342 0zM4.95 9.33l3.446 3.445a8.28 8.28 0 000 6.45L4.95 22.671a12.927 12.927 0 010-13.342z"
                fill={props.active ? '#1868DF' : '#B0B4C2'}
            />
        </svg>
    );
}

export default HelpSvg;
