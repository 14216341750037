import * as React from 'react';

function WifiSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M34.535 11.552C30.455 6.922 24.517 4 17.934 4 11.35 4 5.412 6.922 1.332 11.552c-.683.778-.607 2.068.133 2.808l.02.038c.72.74 1.802.645 2.485-.152 3.415-3.947 8.423-6.451 13.964-6.451 5.54 0 10.548 2.504 13.964 6.45.683.779 1.745.893 2.485.153l.038-.038c.702-.74.797-2.03.114-2.808z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M17.933 11.59c-4.534 0-8.594 2.087-11.326 5.35-.664.797-.531 2.125.19 2.865l.075.076c.721.74 1.708.588 2.353-.228 2.05-2.599 5.18-4.25 8.708-4.25 3.53 0 6.66 1.67 8.709 4.25.645.816 1.632.968 2.353.228l.075-.076c.721-.74.873-2.068.19-2.865-2.75-3.263-6.81-5.35-11.327-5.35z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M17.934 19.18a9.15 9.15 0 00-6.489 2.694c-.74.72-.607 2.049.114 2.789l.076.095c.72.74 1.688.55 2.428-.171a5.514 5.514 0 013.87-1.594c1.5 0 2.866.607 3.871 1.594.74.72 1.689.91 2.429.17l.075-.094c.721-.74.854-2.05.114-2.79-1.688-1.67-3.965-2.694-6.488-2.694zM17.933 26.332c-1.27 0-2.276 1.044-2.276 2.334S16.68 31 17.933 31c1.253 0 2.277-1.044 2.277-2.334s-1.024-2.334-2.277-2.334z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default WifiSvg;
