import { useIonToast } from '@ionic/react';
import GroupForm from './GroupForm';
import { saveGroup as saveNewGroup } from '../../firebase/GroupCollection';
import useManageDefaultGroup from '../../hooks/useManageDefaultGroup';
import useSyncGroups from '../../hooks/useSyncGroups';
import useLoading from '../../hooks/useLoading';
import { closeCircleOutline } from 'ionicons/icons';
import { GroupCollectionInterface } from '../../types/GroupInterface';
import useTrack from '../../hooks/useTrack';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type CreateGroupType = {
    onSuccess: (id: string, name: string) => void;
    closeModal: () => void;
};

const CreateGroup = ({ onSuccess, closeModal }: CreateGroupType) => {
    const [present, dismiss] = useIonToast();
    const { markAsDefaultGroup, syncDefaultGroup } = useManageDefaultGroup();
    const { syncGroups } = useSyncGroups();
    const { isLoading, stopLoading, startLoading } = useLoading();
    const { hitAnalytics } = useTrack();
    const {
        auth: { authUser },
    } = useSelector((state: RootState) => state);

    const saveGroup = (data: GroupCollectionInterface) => {
        try {
            startLoading();
            const { isDefault, ...rest } = data;
            saveNewGroup(rest).then(async (res) => {
                if (isDefault) {
                    markAsDefaultGroup(res.id);
                }
                if (authUser) {
                    hitAnalytics('GROUP_CREATE', {
                        id: authUser.id,
                        email: authUser.email,
                    });
                }
                present({
                    color: 'success',
                    duration: 5000,
                    buttons: [
                        {
                            icon: closeCircleOutline,
                            handler: () => dismiss(),
                        },
                    ],
                    message: 'The group was successfully created',
                });
                await syncGroups();
                syncDefaultGroup();
                stopLoading();
                onSuccess(res.id, rest.name);
            });
        } catch (error) {
            stopLoading();
        }
    };

    return (
        <GroupForm
            loading={isLoading}
            createNewGroup={saveGroup}
            closeModal={closeModal}
        />
    );
};

export default CreateGroup;
