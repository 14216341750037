import db from './db';

const COLLECTION = 'bank_accounts';
const HISTORY_COLLECTION = 'plaid_logs';

/**
 * Fetch Users bank accounts
 * @param userId String
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchBankAccounts = async (userId: string) => {
    const bankAccountsRef = db
        .collection(COLLECTION)
        .where('userId', '==', userId);
    const snapshot = await bankAccountsRef.get();
    return snapshot;
};

/**
 * Update bank account collection
 * @param data Any
 * @param id String
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const updateBankAccount = async (data: any, id: string) => {
    var bankAccountsRef = db.collection(COLLECTION).doc(id);
    return bankAccountsRef.update(data);
};

export const fetchAccountSyncHistory = async (itemIds: Array<string>) => {
    const bankAccountsRef = db
        .collection(HISTORY_COLLECTION)
        .where('plaidItemId', 'in', itemIds);
    const snapshot = await bankAccountsRef.get();
    return snapshot;
};
