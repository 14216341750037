import * as React from 'react';

function BMLogo(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={75}
            height={100}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#prefix__clip0_2818_10907)">
                <path
                    d="M6.395 68.276c19.083 9.877 26.243 16.757 54.753 22.344 0 0-4.811 3.63-11.56 9.431C4.002 86.767 5.173 72.8 5.173 72.8l1.22-4.524z"
                    fill="url(#prefix__paint0_linear_2818_10907)"
                />
                <path
                    d="M23.808 59.302a2.444 2.444 0 101.114-4.761l-1.114 4.761zm45.778 26.465c-.103.251-.591.897-2.555 1.318-1.873.403-4.543.466-7.897.103-6.676-.722-15.5-3.066-24.837-6.913l-1.862 4.521c9.641 3.972 18.922 6.469 26.173 7.253 3.61.391 6.86.374 9.449-.183 2.498-.536 5.016-1.728 6.05-4.237l-4.521-1.862zm-35.29-5.492c-9.337-3.847-17.252-8.398-22.5-12.588-2.637-2.106-4.488-4.03-5.534-5.636-1.097-1.683-.99-2.485-.886-2.736l-4.52-1.862c-1.033 2.509-.085 5.129 1.311 7.27 1.447 2.219 3.742 4.52 6.58 6.786 5.7 4.55 14.047 9.315 23.688 13.287l1.862-4.521zm-28.92-20.96c.07-.17.296-.517 1.111-.873.828-.362 2.07-.64 3.768-.743 3.387-.205 8.056.316 13.553 1.603l1.114-4.761c-5.737-1.343-10.919-1.968-14.962-1.723-2.017.122-3.884.468-5.43 1.143-1.559.681-2.972 1.786-3.674 3.492l4.52 1.862zm49.287 12.353c5.377 3.134 9.559 6.285 12.16 9.039 1.306 1.382 2.118 2.565 2.524 3.501.403.93.313 1.38.239 1.56l4.52 1.861c.748-1.815.446-3.707-.274-5.367-.717-1.653-1.939-3.308-3.455-4.913-3.044-3.222-7.66-6.646-13.253-9.906l-2.462 4.225z"
                    fill="url(#prefix__paint1_linear_2818_10907)"
                />
                <path
                    d="M32.266 47.442a2.445 2.445 0 01-3.833 3.035l3.833-3.035zm9.71-44.987L40.347.633a2.444 2.444 0 014.075 1.822h-2.444zm0 46.504v2.445a2.445 2.445 0 01-2.444-2.445h2.445zM60.63 65.65l2.437-.192.09 1.13-.807.8-1.72-1.738zM43.696 85.857a2.444 2.444 0 11-3.439-3.475l3.44 3.475zM30.35 48.959c-1.917 1.518-1.918 1.516-1.92 1.515 0 0 0-.002-.002-.003l-.006-.009-.018-.022-.052-.069c-.042-.056-.1-.132-.169-.23-.139-.193-.329-.468-.554-.82a27.092 27.092 0 01-1.658-3.013 31.018 31.018 0 01-2.874-10.9c-.701-9.311 2.59-21.65 17.25-34.775l3.26 3.643C29.922 16.53 27.4 27.445 27.972 35.041a26.128 26.128 0 002.418 9.175 22.234 22.234 0 001.355 2.467 13.934 13.934 0 00.508.742l.018.023c.001.002.002.002 0 0l-.001-.002-.002-.002s-.002-.002-1.918 1.515zM44.42 2.455v46.504h-4.889V2.455h4.889zm-2.444 44.06c9.648 0 15.054 4.73 17.927 9.533 1.403 2.346 2.186 4.67 2.62 6.396.219.867.353 1.596.432 2.118a16.223 16.223 0 01.108.863l.002.019v.007l.001.003c0 .002 0 .003-2.437.195-2.437.191-2.437.192-2.436.193v.006l-.001-.015a11.335 11.335 0 00-.069-.532 18.06 18.06 0 00-.34-1.663c-.354-1.404-.982-3.252-2.075-5.079-2.119-3.541-6.04-7.155-13.732-7.155v-4.89zM62.35 67.385l-18.654 18.47-3.439-3.474L58.911 63.91l3.439 3.475z"
                    fill="url(#prefix__paint2_linear_2818_10907)"
                />
                <path
                    d="M41.656 60.88a2.445 2.445 0 100-4.89 2.445 2.445 0 000 4.89z"
                    fill="#162399"
                />
            </g>
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_2818_10907"
                    x1={28.551}
                    y1={57.045}
                    x2={44.598}
                    y2={95.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3DC2FF" />
                    <stop offset={1} stopColor="#162399" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_2818_10907"
                    x1={43.291}
                    y1={60.785}
                    x2={36.409}
                    y2={81.36}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3DC2FF" />
                    <stop offset={1} stopColor="#162399" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint2_linear_2818_10907"
                    x1={54.795}
                    y1={14.386}
                    x2={30.334}
                    y2={79.861}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB3896" />
                    <stop offset={0.698} stopColor="#162398" />
                </linearGradient>
                <clipPath id="prefix__clip0_2818_10907">
                    <path
                        fill="#fff"
                        transform="translate(.467)"
                        d="M0 0h74.066v100H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BMLogo;
