import { useEffect } from 'react';
import {
    createExperiment,
    fetchExperiment,
} from '../firebase/experimentCollection';
import { EXPERIMENTS, EXP_ACTIVE } from '../utils/constant';

const useExperiment = () => {
    const syncExperiments = () => {
        EXPERIMENTS.forEach((exp) => {
            if (exp.status === EXP_ACTIVE) {
                fetchExperiment(exp.id).then((doc) => {
                    if (!doc.exists) {
                        createExperiment(
                            {
                                status: exp.status,
                            },
                            exp.id
                        );
                    }
                });
            }
        });
    };

    const isExperimentLive = async (id: string) => {
        const exp = await fetchExperiment(id);
        if (exp.exists && exp.data()?.status === EXP_ACTIVE) {
            return true;
        }
        return false;
    };

    return { isExperimentLive, syncExperiments };
};

export default useExperiment;
