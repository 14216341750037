import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTransaction } from '../../firebase/TransactionCollection';
import useAssignGroup from '../../hooks/useAssignGroup';
import useGroupMembers from '../../hooks/useGroupMembers';
import useSplit from '../../hooks/useSplit';
import {
    setAmount,
    setDate,
    setEquallySplittedMembers,
    setExactSplittedMembers,
    setGroupId,
    setMembers,
    setMemo,
    setName,
    setOriginalAmount,
    setPaidBy,
    setSplitMethod,
    setTnxType,
} from '../../redux/slices/expenseFormSlice';
import { RootState } from '../../redux/store';
import { GroupInterface } from '../../types/GroupInterface';
import {
    SplitMethodEnum,
    TransactionInterface,
} from '../../types/TransactionInterface';
import TransactionForm from './TransactionForm';

interface UpdateTransactionInterface {
    transaction: TransactionInterface;
    openGroups?: () => void;
    group?: GroupInterface;
    onSuccessSave: () => void;
}

const UpdateTransaction = ({
    transaction,
    openGroups,
    group,
    onSuccessSave,
}: UpdateTransactionInterface) => {
    const { setGroupMembers, members } = useGroupMembers();
    const { equallySplit } = useSplit();

    const {
        auth: { authUser },
        expenseForm: {
            name,
            amount,
            date,
            splitMethod,
            paidBy,
            originalAmount,
            equallySplittedMembers,
            exactSplittedMembers,
            memo,
        },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { assign } = useAssignGroup();

    useEffect(() => {
        if (members.length) {
            dispatch(setMembers([...members]));
        }
    }, [members]);

    useEffect(() => {
        if (members.length && transaction) {
            const { amount, groupSplittingValues } = transaction;
            const splittedArray = equallySplit(members, amount).map(
                ({ id, value }) => {
                    return { id, value };
                }
            );
            if (groupSplittingValues?.splitAmounts) {
                if (
                    groupSplittingValues?.splitMethod ===
                    SplitMethodEnum.Equally
                ) {
                    dispatch(setExactSplittedMembers([...splittedArray]));
                } else if (
                    groupSplittingValues?.splitMethod ===
                    SplitMethodEnum.SplitSpecific
                ) {
                    dispatch(setEquallySplittedMembers([...splittedArray]));
                }
            } else {
                dispatch(setExactSplittedMembers([...splittedArray]));
                dispatch(setEquallySplittedMembers([...splittedArray]));
            }
        }
    }, [members, transaction]);

    useEffect(() => {
        if (group) {
            setGroupMembers(group.id);
            dispatch(setGroupId(group.id));
        }
    }, [group]);

    useEffect(() => {
        if (transaction) {
            const {
                name,
                amount,
                date,
                groupSplittingValues,
                originalAmount,
                createdBy,
                type,
                memo: memoText,
            } = transaction;

            if (memoText) {
                dispatch(setMemo(memoText));
            } else if (groupSplittingValues?.memo) {
                dispatch(setMemo(groupSplittingValues.memo));
            }

            dispatch(setName(name));
            dispatch(setAmount(amount));
            dispatch(setTnxType(type));
            dispatch(
                setOriginalAmount(originalAmount ? originalAmount : amount)
            );
            //@ts-ignore
            dispatch(setDate(date));
            dispatch(setPaidBy(groupSplittingValues?.paidBy ?? createdBy));
            dispatch(
                setSplitMethod(
                    groupSplittingValues?.splitMethod ?? SplitMethodEnum.Equally
                )
            );

            if (groupSplittingValues && groupSplittingValues.splitAmounts) {
                const splittedArray = groupSplittingValues.splitAmounts.map(
                    ({ userId, value }) => {
                        return {
                            id: userId,
                            value: value ? Number(value) : 0,
                        };
                    }
                );
                if (
                    groupSplittingValues?.splitMethod ===
                    SplitMethodEnum.Equally
                ) {
                    dispatch(setEquallySplittedMembers([...splittedArray]));
                } else {
                    dispatch(setExactSplittedMembers([...splittedArray]));
                }
            }
        }
    }, [transaction]);

    const saveTransaction = () => {
        if (transaction) {
            let data: any = {};
            if (group) {
                data = {
                    name: name,
                    amount: amount ? Number(amount) : 0,
                    date,
                    groupSplittingValues: {
                        splitMethod,
                        paidBy,
                        splitAmounts: [
                            ...(splitMethod === SplitMethodEnum.Equally
                                ? equallySplittedMembers
                                : exactSplittedMembers),
                        ].map(({ id, value }) => {
                            return { userId: id, value };
                        }),
                    },
                    assignedGroupId: group.id,
                    involvedUsers: group.memberIds,
                    groupColor: group.groupColor ? group.groupColor : null,
                };
                if (originalAmount) {
                    data.originalAmount = originalAmount;
                }
            }
            if (memo) {
                data.memo = memo;
            }
            return new Promise((resolve, reject) => {
                updateTransaction(transaction.id, {
                    ...data,
                })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        }
    };

    return authUser ? (
        <>
            <TransactionForm
                authUser={authUser}
                closeModal={onSuccessSave}
                groupMembers={members}
                group={group}
                fetchTransactions={() => {}}
                openGroups={openGroups}
                saveTransaction={saveTransaction}
                transaction={transaction}
            />
        </>
    ) : (
        <></>
    );
};

export default UpdateTransaction;
