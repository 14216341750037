import { IonButton, IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import { FC, ReactNode, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Split from './assets/Split.gif';
import Hide from './assets/Hide.gif';
import { closeOutline } from 'ionicons/icons';
import Pagination from '../../components/on-board/Pagination';
import './index.css';
import { HOW_TNX_WORKS_SKIPPED } from '../../utils/constant';

interface ScreenItemInterface {
    id: number;
    url: string;
    title: string;
    description?: string | ReactNode;
}

interface ScreenInterface extends ScreenItemInterface {}

interface TxnScreenWorksInterface {
    closeModal: () => void;
}

const screens: ScreenItemInterface[] = [
    {
        id: 1,
        url: Split,
        title: 'Split with one swipe',
        description: (
            <>
                Swipe left on any expense to <br /> quickly split that expense
                with your default group.
            </>
        ),
    },
    {
        id: 2,
        url: Hide,
        title: 'Hide expenses',
        description: (
            <>Swipe right on any expense to hide that expense from the list.</>
        ),
    },
];

const Screen: FC<ScreenInterface> = ({ url, title, description }) => {
    return (
        <div className="tnx-screen-works-screen-wrapper">
            <img src={url} />
            <div className="intro">
                <IonText className="ion-text-center">
                    <h1>{title}</h1>
                    <p>{description}</p>
                </IonText>
            </div>
        </div>
    );
};

const TxnScreenWorks: FC<TxnScreenWorksInterface> = ({ closeModal }) => {
    const [current, setCurrent] = useState<number>(0);
    const onClickHandler = () => {
        if (current === screens.length - 1) {
            skipScreens();
        } else {
            setCurrent(current + 1);
        }
    };

    const skipScreens = () => {
        localStorage.setItem(HOW_TNX_WORKS_SKIPPED, 'DONE');
        closeModal();
    };
    return (
        <IonPage>
            <IonContent fullscreen scrollY={false}>
                <div className="tnx-screen-works-screen-header">
                    <IonIcon
                        color="medium"
                        onClick={skipScreens}
                        icon={closeOutline}
                    />
                </div>

                <SwipeableViews
                    index={current}
                    onChangeIndex={(e: number) => setCurrent(e)}
                >
                    {screens.map((item) => (
                        <Screen key={item.id} {...item} />
                    ))}
                </SwipeableViews>
                <Pagination current={current} size={screens.length} />
                <IonButton
                    className="tnx-screen-works-next-btn"
                    onClick={onClickHandler}
                    shape="round"
                    expand="block"
                    size="large"
                >
                    {current === screens.length - 1 ? 'Got it' : 'Next'}
                </IonButton>
            </IonContent>
        </IonPage>
    );
};

export default TxnScreenWorks;
