import { useEffect, useState } from 'react';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import Main from '../../components/experiment-ab-1/Main';
import './Home.css';

const Home: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(true);
    }, []);
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Home</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent></IonContent>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
                backdropDismiss={false}
            >
                <>
                    <Main />
                </>
            </IonModal>
        </IonPage>
    );
};

export default Home;
