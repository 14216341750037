import { Fragment, useEffect, useState } from 'react';
import {
    IonAvatar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonModal,
    IonSkeletonText,
    IonText,
    useIonAlert,
} from '@ionic/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { updateTransaction } from '../../firebase/TransactionCollection';
import useAssignGroup from '../../hooks/useAssignGroup';
import useModal from '../../hooks/useModal';
import useSplit from '../../hooks/useSplit';
import { RootState } from '../../redux/store';
import { colors } from '../../theme/colors';
import {
    FilterRefData,
    SplitMethodEnum,
    TransactionInterface,
} from '../../types/TransactionInterface';
import { colorMap, makeTnxList } from '../../utils/common';
import {
    DATE_TIME_FORMAT,
    GROUP_LIST_IN_TNX,
    TNX_DETAILS_IN_TNX,
} from '../../utils/constant';
import DefaultSplitGroup from '../settings/DefaultSplitGroup';
import Item from './Item';
import TnxLeftAvatar from './TnxLeftAvatar';
import { updateGroup } from '../../firebase/GroupCollection';
import useTrack from '../../hooks/useTrack';
import currency from 'currency.js';

type TransactionListProps = {
    setSelectedTransaction: (i: TransactionInterface) => void;
    setShowModal: (data: number) => void;
    showLoading: boolean | null;
    filteringData: Array<FilterRefData>;
};

const BLOCK = 20;

const TransactionList = ({
    setSelectedTransaction,
    setShowModal,
    showLoading,
    filteringData,
}: TransactionListProps) => {
    const [present] = useIonAlert();
    const [items, setItems] = useState<TransactionInterface[]>([]);
    const [itemCount, setItemCount] = useState<number>(BLOCK);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
        false
    );

    const { showModal, setShowModal: openModal } = useModal();
    const { assign, removeFromGroup } = useAssignGroup();
    const { equallySplit } = useSplit();

    const {
        auth: { authUser, defaultGroup },
        tnx: { transactions },
        group: { groupList },
    } = useSelector((state: RootState) => state);
    const { hitAnalytics } = useTrack();

    const getFilteredTnx = () => {
        const bankFiltered = filteringData.filter((f) => f.ref === 'bank');

        const customSplitFiltered = filteringData.filter(
            (f) => f.ref === 'custom' && f.refId == 'showSplit'
        );

        const customHiddenFiltered = filteringData.filter(
            (f) => f.ref === 'custom' && f.refId == 'showHidden'
        );

        return transactions.filter((t) => {
            if (bankFiltered.length) {
                if (!customSplitFiltered.length) {
                    return !!bankFiltered.find(
                        ({ refId }) => refId === t.account_id
                    );
                }
                return (
                    !!bankFiltered.find(
                        ({ refId }) => refId === t.account_id
                    ) && !!t.assignedGroupId
                );
            }
            if (customSplitFiltered.length) {
                return !!t.assignedGroupId;
            }
            if (t.hidden === true && !customHiddenFiltered.length) {
                return false;
            }

            if (t.removed) {
                return false;
            }

            return true;
        });
    };

    useEffect(() => {
        setItems(() => {
            return getFilteredTnx().slice(0, itemCount);
        });
    }, [transactions, filteringData]);

    var itemArray = makeTnxList(items);

    const assignToDefaultGroup = (i: any) => {
        const {
            id,
            createdBy,
            amount,
            assignedGroupId,
        }: {
            id: string;
            type: string;
            createdBy: string;
            amount: number;
            assignedGroupId: string;
        } = i;
        if (assignedGroupId) {
            removeFromGroup(i.id);
            return;
        }
        if (id && authUser) {
            if (!defaultGroup) {
                present({
                    header: 'One more thing',
                    message: 'Please set a Default Group.',
                    buttons: [
                        {
                            text: `I'll do this later`,
                        },
                        {
                            text: 'Go to Settings',
                            handler: () => {
                                openModal(true);
                            },
                        },
                    ],
                    onDidDismiss: (e) => {},
                });
                return;
            }
            assign(defaultGroup, i);

            const members = defaultGroup.memberIds.map((userId) => {
                return { userId };
            });

            const splitAmounts = equallySplit(members, amount);

            updateTransaction(id, {
                assignedGroupId: authUser.defaultGroup,
                involvedUsers: defaultGroup.memberIds,
                assignedDate: moment().format(DATE_TIME_FORMAT),
                groupColor: defaultGroup.groupColor
                    ? defaultGroup.groupColor
                    : null,
                groupSplittingValues: {
                    splitMethod: SplitMethodEnum.Equally,
                    paidBy: createdBy,
                    splitAmounts,
                },
            }).then(() => {
                if (authUser.defaultGroup) {
                    updateGroup(authUser.defaultGroup, {});
                }
            });
        }
    };
    const hideTnx = ({ id, hidden }: TransactionInterface) => {
        updateTransaction(id, {
            hidden: !hidden,
        }).then(() => {});
    };

    const assignToGroup = (i: any, type: number) => {
        setShowModal(type);
        setSelectedTransaction(i);
    };

    const searchNext = (e: CustomEvent<void>) => {
        const newCount = itemCount + BLOCK;
        setItemCount(newCount);

        if (newCount > getFilteredTnx().length) {
            setDisableInfiniteScroll(true);
        }

        setItems(() => {
            return getFilteredTnx().slice(0, newCount);
        });
        // @ts-ignore
        e.target.complete();
    };

    const getGroupName = (gId: string) => {
        const group = groupList.filter((g) => !!g).find(({ id }) => id === gId);

        if (group) {
            return group.name;
        }
        return '';
    };

    const swipe = (side: string, transaction: TransactionInterface) => {
        if (side === 'left') {
            hitAnalytics('swipeDefaultSplit', {
                id: authUser?.id,
            });
            assignToDefaultGroup(transaction);
        } else if (side === 'right') {
            hideTnx(transaction);
        }
    };

    if (showLoading) {
        return (
            <>
                <IonList>
                    <IonItemDivider className="divider tnx-list-divider">
                        <IonSkeletonText animated style={{ width: '40%' }} />
                    </IonItemDivider>
                    {[...Array(20)].map((i, key) => (
                        <IonItem key={key}>
                            <IonAvatar className="tnx-item-avatar" slot="start">
                                <IonSkeletonText animated />
                            </IonAvatar>
                            <IonLabel>
                                <h3>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '50%' }}
                                    />
                                </h3>
                                <p>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '80%' }}
                                    />
                                </p>
                            </IonLabel>
                            <IonText slot="end">
                                <IonSkeletonText
                                    animated
                                    style={{ width: '100%' }}
                                />
                            </IonText>
                        </IonItem>
                    ))}
                </IonList>
            </>
        );
    }

    return (
        <IonList>
            {itemArray.map((i, key) => {
                return (
                    <Fragment key={key}>
                        <IonItemDivider className="divider tnx-list-divider">
                            {i.month}
                        </IonItemDivider>
                        {i.element.map((i) => {
                            const {
                                name,
                                amount,
                                date,
                                id,
                                groupColor,
                                assignedGroupId,
                                merchant_name,
                                groupSplittingValues,
                                memo,
                                pending,
                                hidden,
                                removed,
                                missingSplit,
                            } = i;

                            const colorProp: any = {};
                            const fillProps: any = {};
                            var _groupColor = colorMap(groupColor);
                            // @ts-ignore
                            if (groupColor && colors[groupColor]) {
                                colorProp.style = {
                                    // @ts-ignore
                                    backgroundColor:
                                        // @ts-ignore
                                        colors[_groupColor].background,
                                };
                                // @ts-ignore
                                fillProps.fill = colors[_groupColor].icon;
                            }

                            let memoText = '';
                            if (memo) {
                                memoText = memo;
                            } else if (groupSplittingValues?.memo) {
                                memoText = groupSplittingValues.memo;
                            }

                            let subTitle = moment(
                                date,
                                DATE_TIME_FORMAT
                            ).format('MMM DD');

                            if (pending) {
                                subTitle = `${subTitle} . Pending`;
                            }

                            if (removed) {
                                subTitle = `${subTitle} . Removed`;
                            }

                            return (
                                <Item
                                    onClickItem={() => {
                                        hitAnalytics('expensesClicked', {
                                            id: authUser?.id,
                                        });
                                        // if (!pending) {
                                        assignToGroup(i, TNX_DETAILS_IN_TNX);
                                        // }
                                    }}
                                    icon={<TnxLeftAvatar {...i} />}
                                    // enableSwipe={pending ? false : true}
                                    enableSwipe={true}
                                    groupColor={groupColor ? groupColor : null}
                                    assignedGroup={getGroupName(
                                        assignedGroupId
                                    )}
                                    swipe={(type) => swipe(type, i)}
                                    onClickSplit={() => {
                                        hitAnalytics('swipeSplit', {
                                            id: authUser?.id,
                                        });
                                        assignToGroup(i, GROUP_LIST_IN_TNX);
                                    }}
                                    key={id}
                                    title={merchant_name ? merchant_name : name}
                                    subTitle={subTitle}
                                    rightValue={currency(amount).format() ?? ''}
                                    hidden={!!hidden}
                                    memo={
                                        memoText && (
                                            <p className="memo-text">
                                                {memoText}
                                            </p>
                                        )
                                    }
                                    missingSplit={missingSplit}
                                />
                            );
                        })}
                    </Fragment>
                );
            })}
            <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
            >
                <IonInfiniteScrollContent loadingText="Loading..." />
            </IonInfiniteScroll>
            <IonModal
                isOpen={showModal}
                cssClass="my-custom-class"
                onDidDismiss={() => openModal(false)}
            >
                <DefaultSplitGroup closeModal={() => openModal(false)} />
            </IonModal>
        </IonList>
    );
};

export default TransactionList;
