import * as React from 'react';

function CoffeeCupSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={37}
            height={36}
            viewBox="0 0 37 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.117 9.593c.935 0 1.7-.77 1.7-1.71v-1.71c0-.94-.765-1.71-1.7-1.71-.934 0-1.699.77-1.699 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#000"
            />
            <path
                d="M17.117 9.593c.935 0 1.7-.77 1.7-1.71v-1.71c0-.94-.765-1.71-1.7-1.71-.934 0-1.699.77-1.699 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#B0B4C2"
            />
            <path
                d="M22.211 9.593c.935 0 1.7-.77 1.7-1.71v-1.71c0-.94-.765-1.71-1.7-1.71-.934 0-1.699.77-1.699 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#000"
            />
            <path
                d="M22.211 9.593c.935 0 1.7-.77 1.7-1.71v-1.71c0-.94-.765-1.71-1.7-1.71-.934 0-1.699.77-1.699 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#B0B4C2"
            />
            <path
                d="M27.31 9.593c.934 0 1.7-.77 1.7-1.71v-1.71c0-.94-.766-1.71-1.7-1.71-.934 0-1.7.77-1.7 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#000"
            />
            <path
                d="M27.31 9.593c.934 0 1.7-.77 1.7-1.71v-1.71c0-.94-.766-1.71-1.7-1.71-.934 0-1.7.77-1.7 1.71v1.71c0 .94.748 1.71 1.7 1.71z"
                fill="#B0B4C2"
            />
            <path
                d="M30.708 11.302H13.716c-.934 0-1.7.77-1.7 1.71v1.71H10.37c-2.838 0-5.149 2.308-5.149 5.13v3.419c0 2.821 2.294 5.13 5.098 5.13h2.005c.696 1.983 2.565 3.419 4.791 3.419H27.31c2.804 0 5.098-2.308 5.098-5.13V13.012c0-.94-.765-1.71-1.7-1.71zM8.618 23.271v-3.42c0-.923.8-1.71 1.75-1.71h1.649v6.84h-1.7c-.934 0-1.698-.77-1.698-1.71zM27.31 28.4H17.114c-.934 0-1.699-.77-1.699-1.71V14.72h13.593v11.97c0 .94-.764 1.71-1.699 1.71z"
                fill="#000"
            />
            <path
                d="M30.708 11.302H13.716c-.934 0-1.7.77-1.7 1.71v1.71H10.37c-2.838 0-5.149 2.308-5.149 5.13v3.419c0 2.821 2.294 5.13 5.098 5.13h2.005c.696 1.983 2.565 3.419 4.791 3.419H27.31c2.804 0 5.098-2.308 5.098-5.13V13.012c0-.94-.765-1.71-1.7-1.71zM8.618 23.271v-3.42c0-.923.8-1.71 1.75-1.71h1.649v6.84h-1.7c-.934 0-1.698-.77-1.698-1.71zM27.31 28.4H17.114c-.934 0-1.699-.77-1.699-1.71V14.72h13.593v11.97c0 .94-.764 1.71-1.699 1.71z"
                fill="#B0B4C2"
            />
        </svg>
    );
}

export default CoffeeCupSvg;
