import { IonAvatar } from '@ionic/react';
import { TransactionInterface } from '../../types/TransactionInterface';
import { tnxItemAvatarColor } from '../../utils/common';
import TnxCategoryIcon from './TnxCategoryIcon';

const TnxLeftAvatar = (props: TransactionInterface | any) => {
    const { groupColor } = props;

    return (
        <IonAvatar
            className="tnx-item-avatar"
            {...tnxItemAvatarColor(groupColor)}
            slot="start"
        >
            <TnxCategoryIcon {...props} />
        </IonAvatar>
    );
};

export default TnxLeftAvatar;
