import * as React from 'react';
import { SVGProps } from 'react';

const WalletSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#da)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27 16.333h1.167a1.17 1.17 0 0 1 1.166 1.167v11.667a1.17 1.17 0 0 1-1.166 1.166h-14a3.51 3.51 0 0 1-3.5-3.5V15.167a3.51 3.51 0 0 1 3.5-3.5h11.666A1.17 1.17 0 0 1 27 12.833v3.5ZM24.667 14h-10.5c-.654 0-1.167.525-1.167 1.167a1.17 1.17 0 0 0 1.167 1.166h10.5V14Zm-10.5 14H27v-9.333H14.167c-.409 0-.805-.082-1.167-.21v8.376A1.17 1.17 0 0 0 14.167 28Zm11.083-3.5h-1.167a.578.578 0 0 1-.583-.583V22.75c0-.327.256-.583.583-.583h1.167c.326 0 .583.256.583.583v1.167a.578.578 0 0 1-.583.583Z"
            fill="url(#db)"
        />
        <defs>
            <linearGradient
                id="da"
                x1={20.053}
                y1={3.547}
                x2={20.053}
                y2={37.389}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#036B9B" />
                <stop offset={0.591} stopColor="#FE95B4" />
            </linearGradient>
            <linearGradient
                id="db"
                x1={20.025}
                y1={13.322}
                x2={20.025}
                y2={29.115}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#036B9B" />
                <stop offset={1} stopColor="#FE95B4" />
            </linearGradient>
        </defs>
    </svg>
);

export default WalletSvg;
