import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonPage,
    useIonToast,
} from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteAccount, loginUser } from '../../firebase/AuthCollections';
import { RootState } from '../../redux/store';
import './index.css';

type DeleteConfirmationType = {
    closeModal: () => void;
    clearStore: () => void;
};

const DeleteConfirmation = ({
    closeModal,
    clearStore,
}: DeleteConfirmationType) => {
    const [showReLogin, setShowReLogin] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');

    const { authUser } = useSelector((state: RootState) => state.auth);
    const [present, dismiss] = useIonToast();

    const onDelete = async () => {
        try {
            await deleteAccount();
            clearStore();
        } catch (error) {
            //@ts-ignore
            if (error && error.code === 'auth/requires-recent-login') {
                //Re login
                setShowReLogin(true);
            }
        }
    };

    const loginAndDelete = () => {
        if (authUser) {
            loginUser(authUser.email, password)
                .then(() => {
                    onDelete();
                })
                .catch((e) => {
                    present({
                        color: 'danger',
                        duration: 5000,
                        buttons: [
                            {
                                icon: closeCircleOutline,
                                handler: () => dismiss(),
                            },
                        ],
                        message: 'Incorrect password',
                    });
                });
        }
    };

    const reLoginContent = () => {
        return (
            <div className="re-login-content">
                <div className="re-login-content-wrapper">
                    <h3>Please enter your password </h3>
                    <IonItem lines="full">
                        <IonInput
                            type="password"
                            placeholder="Password"
                            onIonChange={(e: CustomEvent) =>
                                setPassword(e.detail.value)
                            }
                        />
                    </IonItem>
                </div>
                <div className="re-login-btn-wrapper">
                    <IonButton
                        size="large"
                        color="danger"
                        fill="outline"
                        className="re-login-btn"
                        disabled={password ? false : true}
                        onClick={loginAndDelete}
                    >
                        Delete
                    </IonButton>
                </div>
            </div>
        );
    };

    return (
        <IonPage>
            <IonContent fullscreen scrollY={false}>
                {!showReLogin ? (
                    <>
                        <div className="delete-alert-content">
                            <h3>Delete Account ?</h3>
                            <p>
                                This will permanently delete your account. This
                                action cannot be undone.
                            </p>
                        </div>
                        <div className="delete-alert-btn-wrapper">
                            <div className="btn-cancel-wrapper">
                                <IonButton
                                    size="large"
                                    fill="outline"
                                    className="alert-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </IonButton>
                            </div>
                            <div>
                                <IonButton
                                    size="large"
                                    color="danger"
                                    fill="outline"
                                    className="alert-btn"
                                    onClick={onDelete}
                                >
                                    Delete
                                </IonButton>
                            </div>
                        </div>
                    </>
                ) : (
                    reLoginContent()
                )}
            </IonContent>
        </IonPage>
    );
};

export default DeleteConfirmation;
