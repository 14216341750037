import { IonButtons, IonButton } from '@ionic/react';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface IHeaderActionButton {
    color?: string;
    title: string | ReactNode;
    onClick: () => void;
    slot?: string;
    disabled?: boolean;
}

const HeaderActionButton = ({
    color,
    title,
    onClick,
    slot = 'end',
    disabled = false,
}: IHeaderActionButton) => {
    return (
        <IonButtons slot={slot}>
            <IonButton
                disabled={disabled}
                color={color ? color : ''}
                onClick={() => onClick()}
            >
                {title}
            </IonButton>
        </IonButtons>
    );
};

export default HeaderActionButton;
