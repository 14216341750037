import * as React from 'react';

function ReceiptSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 30.259V4h20v26.259l-.482-.562-1.276-1.489-1.513 1.247L22 31.702l-2.729-2.247L18 28.408l-1.271 1.047L14 31.702l-2.729-2.247-1.513-1.247-1.276 1.489-.482.562zM28.738 4.016a.041.041 0 010 0z"
                stroke={props.fill ? props.fill : '#B0B4C2'}
                strokeWidth={4}
            />
            <path
                d="M23 17H13v4h10v-4zM23 10H13v4h10v-4z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default ReceiptSvg;
