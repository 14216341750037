import {
    IonContent,
    IonListHeader,
    IonList,
    IonPage,
    IonModal,
} from '@ionic/react';
import HeaderActionButton from '../HeaderActionButton';
import Header from '../Header';
import IonListItem from '../IonListItem';
import ArrowRight from '../../svg/ArrowRight';
import useModal from '../../hooks/useModal';
import EditGroup from '../group-form/EditGroup';
import { GroupInterface } from '../../types/GroupInterface';
import useNativeShare from '../../hooks/useNativeShare';
import useGroupLink from '../../hooks/useGroupLink';

type GroupSettingsProps = {
    group: GroupInterface;
    closeModal: () => void;
    onClickAddMembers: () => void;
    fetchData: () => void;
    onDeleteSuccess: () => void;
};

const GroupSettings = ({
    group,
    closeModal,
    onClickAddMembers,
    fetchData,
    onDeleteSuccess,
}: GroupSettingsProps) => {
    const { showModal, setShowModal } = useModal();
    const { onShare } = useNativeShare();
    const { link } = useGroupLink(group.id);

    const onPressGetGroupLin = () => {
        onShare({
            title: 'BillMagic group',
            text: `Hi! Join the group ${group.name} on BillMagic to share expenses`,
            url: link,
            dialogTitle: 'Share with friends',
        });
    };

    return (
        <IonPage>
            <Header
                title="Group settings"
                rightComponent={
                    <HeaderActionButton
                        title="Done"
                        slot="end"
                        color="secondary"
                        onClick={() => {
                            closeModal();
                        }}
                    />
                }
            />
            <IonContent fullscreen>
                <IonList>
                    <IonListHeader>{group.name}</IonListHeader>
                    <IonListItem
                        title="Edit group details"
                        rightContent={<ArrowRight />}
                        onClick={() => {
                            setShowModal(true);
                        }}
                    />
                    <IonListItem
                        title="Get group invite link"
                        rightContent={<ArrowRight />}
                        onClick={onPressGetGroupLin}
                    />
                    <IonListItem
                        title="Add / edit members"
                        rightContent={<ArrowRight />}
                        onClick={onClickAddMembers}
                    />
                </IonList>
            </IonContent>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                <EditGroup
                    onSuccess={() => {
                        fetchData();
                        setShowModal(false);
                    }}
                    closeModal={() => {
                        setShowModal(false);
                    }}
                    onDeleteSuccess={() => {
                        setShowModal(false);
                        onDeleteSuccess();
                    }}
                    group={group}
                />
            </IonModal>
        </IonPage>
    );
};

export default GroupSettings;
