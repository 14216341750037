import { useEffect, useState } from 'react';
import {
    createGroupLink,
    getValidGroupLink,
} from '../firebase/GroupLinkCollection';
import useQuerySnapshot from './useQuerySnapshot';

const useGroupLink = (id: string) => {
    const [link, setLink] = useState<string>('');
    const { querySnapshot } = useQuerySnapshot();

    useEffect(() => {
        getLinks();
    }, []);

    const getLinks = async () => {
        try {
            const snapshot = await getValidGroupLink(id);
            querySnapshot(snapshot, (data) => {
                // If not exist valid link, Create new one

                if (!data.length) {
                    createGroupLink(id).then(() => getLinks());
                } else {
                    setLink(
                        () =>
                            `${process.env.REACT_APP_FE_BASE_URL}glink/${data[0].id}`
                    );
                }
            });
        } catch (error) {
            //@ts-ignore
            throw new Error(error);
        }
    };

    return { link };
};

export default useGroupLink;
