import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    TransactionInterface,
    TransactionReducerInterface,
} from '../../types/TransactionInterface';

const initialState: TransactionReducerInterface = {
    transactions: [],
    isSyncingStart: false,
    isEmpty: null,
    isFetching: null,
};

export const tnxSlice = createSlice({
    name: 'tnx',
    initialState,
    reducers: {
        setTransactions: (
            state,
            action: PayloadAction<TransactionInterface[]>
        ) => {
            state.transactions = action.payload;
        },
        removeTransactions: (state) => {
            state.transactions = [];
        },
        toggleStartSyncing: (state, action: PayloadAction<boolean>) => {
            state.isSyncingStart = action.payload;
        },
        setIsEmpty: (state, action: PayloadAction<boolean>) => {
            state.isEmpty = action.payload;
        },
        setIsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
    },
});

export const {
    setTransactions,
    removeTransactions,
    toggleStartSyncing,
    setIsEmpty,
    setIsFetching,
} = tnxSlice.actions;
export default tnxSlice.reducer;
