import { IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import usePlaid from '../../hooks/usePlaid';
import * as Sentry from '@sentry/browser';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const PlaidOAutClient = () => {
    const history = useHistory();
    // const { plaidLinkToken } = useSelector((state: RootState) => state.app);
    const { onSuccess } = usePlaid({ generateLinkToken: false });

    const success = (public_token: any, metadata: any) => {
        onSuccess(public_token, metadata).then(() => {
            exit();
            history.push('/transactions');
        });
    };

    const onExit = (err: any, metadata: any) => {
        Sentry.captureException(err?.error_code);
    };

    //@ts-ignore
    const config: any = {
        token: localStorage.getItem('linkToken'),
        receivedRedirectUri: window.location.href,
        onSuccess: success,
        onExit,
    };

    const { open, ready, exit, error } = usePlaidLink(config);

    useEffect(() => {
        if (error) {
            Sentry.captureException(error);
            Sentry.captureMessage(error.message);
        }
        Sentry.captureMessage(window.location.href);
    }, [error, window.location.href]);

    // automatically reinitialize Link
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    console.log('linkToken', localStorage.getItem('linkToken'));
    console.log('window.location.href', window.location.href);

    return <IonPage></IonPage>;
};

export default PlaidOAutClient;

// link-production-34e5caa6-398b-404b-b50c-ea068252ce9d
// 46930fe4-e778-4f11-8e21-47755b2f3802
