import { useState, useEffect, FC, useCallback } from 'react';
import { Contact } from '@capacitor-community/contacts';
import {
    IonAvatar,
    IonCheckbox,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
} from '@ionic/react';
import useNativeContact from '../../hooks/useNativeContact';
import EmptyProfilePictureSvg from '../../svg/EmptyProfilePictureSvg';

const BLOCK = 20;

interface MobileContactListInterface {
    onChangeList: (data: Contact[]) => void;
    mobileContacts: Contact[];
    searchText: string;
}

const MobileContactList: FC<MobileContactListInterface> = ({
    mobileContacts,
    onChangeList,
    searchText,
}) => {
    const { data: mobileContactList } = useNativeContact();
    const [itemCount, setItemCount] = useState<number>(BLOCK);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
        false
    );
    const [items, setItems] = useState<Contact[]>([]);
    const [nativeContactList, setNativeContactList] = useState<Contact[]>([]);

    useEffect(() => {
        setItems(() => {
            return nativeContactList.slice(0, itemCount);
        });
    }, [nativeContactList]);

    useEffect(() => {
        setNativeContactList(mobileContactList);
    }, [mobileContactList]);

    useEffect(() => {
        searchContact();
    }, [searchText]);

    const searchContact = useCallback(() => {
        var text = searchText.toUpperCase();
        const filteredContacts: any[] = [];

        for (let index = 0; index < mobileContactList.length; index++) {
            const element = mobileContactList[index];
            if (
                element.displayName &&
                element.displayName.toUpperCase().indexOf(text) > -1
            ) {
                filteredContacts.push(element);
            }
        }
        setNativeContactList(() => [...filteredContacts]);
    }, [searchText, mobileContactList]);

    const searchNext = (e: CustomEvent<void>) => {
        const newCount = itemCount + BLOCK;
        setItemCount(newCount);
        if (newCount > nativeContactList.length) {
            setDisableInfiniteScroll(true);
        }
        setItems(() => {
            return nativeContactList.slice(0, newCount);
        });
        // @ts-ignore
        e.target.complete();
    };

    const clickItem = (contactObj: Contact) => {
        const contact = mobileContacts.find(
            ({ contactId }) => contactId === contactObj.contactId
        );

        if (contact) {
            const newContactList = mobileContacts.filter(
                ({ contactId }) => contactId !== contactObj.contactId
            );
            onChangeList(newContactList);
        } else {
            onChangeList([...mobileContacts, contactObj]);
        }
    };

    const isChecked = (id: string) => {
        return !!mobileContacts.find(({ contactId }) => contactId === id);
    };

    if (!nativeContactList.length) {
        return <></>;
    }

    return (
        <>
            <IonList>
                <IonItemDivider className="divider">
                    <IonLabel>Your contacts</IonLabel>
                </IonItemDivider>
                {items.map((contactObj, key) => {
                    const {
                        displayName,
                        phoneNumbers,
                        photoThumbnail,
                        contactId,
                    } = contactObj;
                    return (
                        <IonItem
                            key={'native-contact' + key}
                            onClick={() => clickItem(contactObj)}
                        >
                            <IonAvatar slot="start">
                                {!photoThumbnail ? (
                                    <EmptyProfilePictureSvg />
                                ) : (
                                    <img src={photoThumbnail} />
                                )}
                            </IonAvatar>
                            <IonLabel>
                                <h2>{displayName}</h2>
                                <h3>
                                    {phoneNumbers.length
                                        ? phoneNumbers[0].number
                                        : ''}
                                </h3>
                            </IonLabel>
                            <IonCheckbox
                                color="dark"
                                slot="end"
                                checked={isChecked(contactId)}
                            />
                        </IonItem>
                    );
                })}
            </IonList>
            <IonInfiniteScroll
                threshold="100px"
                disabled={disableInfiniteScroll}
                onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
            >
                <IonInfiniteScrollContent loadingText="Loading..." />
            </IonInfiniteScroll>
        </>
    );
};

export default MobileContactList;
