import * as React from 'react';

function MountainSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.976 25.094l1.424 1.444 1.424-1.444 5.393-5.47 4.169 4.229c.085.086.258.33.41.701.151.37.204.676.204.825V32H3V18.483c0-.143.05-.447.196-.812.147-.367.319-.614.418-.715 0 0 0 0 0 0l4.169-4.228 12.193 12.366zM28 19.404h0s0 0 0 0l.217-.214-.217.214zM8 12.508s0 0 0 0h0zM31 8a4 4 0 11-8 0 4 4 0 018 0z"
                stroke="#000"
                strokeWidth={4}
            />
        </svg>
    );
}

export default MountainSvg;
