import * as React from 'react';

function AnchorSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M31.616 7.923h-7.898V3.974A1.98 1.98 0 0021.744 2h-7.898a1.98 1.98 0 00-1.974 1.974v3.95H3.974A1.98 1.98 0 002 9.897v21.72a1.98 1.98 0 001.974 1.974h27.642a1.98 1.98 0 001.974-1.975V9.898a1.98 1.98 0 00-1.975-1.975zM15.82 5.95h3.948v1.974h-3.948V5.95zM5.949 29.643v-17.77h1.974v17.77H5.95zm5.923 0v-17.77h11.846v17.77H11.872zm17.77 0h-1.975v-17.77h1.974v17.77z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default AnchorSvg;
