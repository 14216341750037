import firebase from 'firebase';
import db from './db';

const COLLECTION = 'messages';

export const createChat = ({
    isNew,
    name,
    email,
    docId,
    threadName,
    client,
    ...data
}: {
    threadName?: string;
    client?: string;
    message: string;
    userId: string;
    isNew: boolean;
    name: string;
    email: string;
    docId: string;
}) => {
    var groupRef = db.collection(COLLECTION).doc(docId);
    if (isNew) {
        groupRef.set({
            name: threadName ? threadName : name,
            email,
            client,
            created: firebase.firestore.Timestamp.now(),
            createdBy: data.userId,
            messages: firebase.firestore.FieldValue.arrayUnion({
                ...data,
                created: firebase.firestore.Timestamp.now(),
            }),
        });
    } else {
        groupRef.update({
            name: threadName ? threadName : name,
            email,
            messages: firebase.firestore.FieldValue.arrayUnion({
                ...data,
                created: firebase.firestore.Timestamp.now(),
            }),
        });
    }
    return groupRef;
};

export function chatSnap(userId: string) {
    return db.collection(COLLECTION).doc(userId);
    // .orderBy('created', 'asc');
}
