import firebase from 'firebase';
import { IonLoading, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { authCheck } from './firebase/AuthCollections';
import Login from './pages/login/Login';

import Groups from './pages/groups/Groups';
import Transactions from './pages/transactions/Transactions';
import TabBar from './components/TabBar';
import SignUp from './pages/sign-up/SignUp';
import Settings from './pages/settings/Settings';
import GroupLink from './pages/groups/GroupLink';
import { LastLocationProvider } from 'react-router-last-location';
import {
    EXP_ACTIVE_GROUP,
    EXP_INPROGRESS,
    EXP_ONBOARDING_QUESTIONS,
    LINK_TOKEN,
    LINK_TOKEN_TYPE,
} from './utils/constant';
import { fetchGroup } from './firebase/GroupCollection';
import { fetchUser, fetchUserExp } from './firebase/UserCollection';
import { RootState } from './redux/store';
import {
    setAuthenticated,
    setAuthUser,
    setDefaultGroup,
    setUnAuthenticated,
} from './redux/slices/authSlice';
import TemporaryContactLink from './pages/temporary-contacts/TemporaryContactLink';
import AppSticky from './pages/Sticky';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import AppUrlListener from './AppUrlListener';
import Help from './pages/support/Help';
import SupportChat from './components/support/SupportChat';
import OnBoard from './pages/on-board/OnBoard';
import SignUpMain from './pages/sign-up/SignUpMain';
import Home from './pages/home/Home';
import usePurchases from './hooks/usePurchases';
import GroupRouteWrapper from './pages/groups/GroupRouteWrapper';
import useExperiment from './hooks/useExperiment';
import useTransaction from './hooks/useTransaction';
import PlaidOAutClient from './pages/plaid-o-auth/PlaidOAuthClient';

interface RouterPropsInterface {
    children: ReactNode;
    path: string;
}

const PrivateRoute = ({ children, ...rest }: RouterPropsInterface) => {
    const { authenticated: isLoggedIn } = useSelector(
        (state: RootState) => state.auth
    );

    return (
        <Route
            exact
            {...rest}
            render={() =>
                isLoggedIn === true ? children : <Redirect to={'/on-board'} />
            }
        />
    );
};

const PublicRoute = ({ children, ...rest }: RouterPropsInterface) => {
    const { authenticated: isLoggedIn } = useSelector(
        (state: RootState) => state.auth
    );

    return (
        <Route exact {...rest}>
            {isLoggedIn === false ? children : <Redirect to="/" />}
        </Route>
    );
};

const Routes: React.FC = () => {
    const dispatch = useDispatch();
    const { authenticated: isLoggedIn, authUser } = useSelector(
        (state: RootState) => state.auth
    );

    const { loginPurchase } = usePurchases();
    useTransaction();
    const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);
    const { isExperimentLive } = useExperiment();

    useEffect(() => {
        authCheck().onAuthStateChanged(async (checkedUser) => {
            if (checkedUser) {
                fetchUser(checkedUser.uid)
                    .then(async (doc) => {
                        const user: any = {
                            name: doc.data()?.name,
                            email: doc.data()?.email,
                            defaultGroup: doc.data()?.defaultGroup ?? null,
                            phoneNumber: doc.data()?.phoneNumber ?? null,
                            id: doc.id,
                        };

                        // Check EXP_ONBOARDING_QUESTIONS is live
                        const isExpLive = await isExperimentLive(
                            EXP_ONBOARDING_QUESTIONS
                        );

                        if (isExpLive) {
                            const exp = await fetchUserExp(
                                checkedUser.uid,
                                EXP_ONBOARDING_QUESTIONS
                            );

                            if (
                                exp.exists &&
                                exp.data()?.group === EXP_ACTIVE_GROUP &&
                                exp.data()?.status === EXP_INPROGRESS
                            ) {
                                user.expStatus = 1;
                            }
                        }

                        loginPurchase(user.id, user.email, user.name);

                        // Segment setup
                        //@ts-ignore
                        window.analytics.identify(user.id, {
                            ...user,
                        });
                        // End Segment setup

                        if (user.defaultGroup) {
                            fetchGroup(user.defaultGroup)
                                .then(
                                    (
                                        res: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                                    ) => {
                                        const group = {
                                            id: res.id,
                                            name: res.data()?.name,
                                            ownerId: res.data()?.ownerId,
                                            description: res.data()
                                                ?.description,
                                            memberIds: res.data()?.memberIds,
                                            groupColor: res.data()?.groupColor,
                                        };

                                        if (group) {
                                            dispatch(
                                                setDefaultGroup({
                                                    ...group,
                                                })
                                            );
                                        }
                                    }
                                )
                                .catch((error) => {
                                    throw new Error(error);
                                });
                        }
                        dispatch(setAuthUser(user));
                    })
                    .catch((error) => {
                        throw new Error(error);
                    });
                dispatch(setAuthenticated());
            } else {
                // Remove analytics
                //@ts-ignore
                if (window.analytics) {
                    //@ts-ignore
                    window.analytics.reset();
                }
                dispatch(setUnAuthenticated());
            }
        });
    }, []);

    if (isLoggedIn === null) {
        return (
            <IonLoading
                isOpen={true}
                onDidDismiss={() => {}}
                message={'Please wait...'}
            />
        );
    }

    if (authUser?.expStatus === 1) {
        return <Home />;
    }

    return (
        <IonReactRouter>
            <AppUrlListener />
            <LastLocationProvider>
                <TabBar isLoggedIn={isLoggedIn}>
                    <IonRouterOutlet ref={routerRef}>
                        <Switch>
                            <PublicRoute path="/on-board">
                                <OnBoard />
                            </PublicRoute>
                            <PublicRoute path="/login">
                                <Login />
                            </PublicRoute>
                            <PublicRoute path="/forget-password">
                                <ForgotPassword />
                            </PublicRoute>
                            <Route exact path="/o-auth-client">
                                <PlaidOAutClient />
                            </Route>
                            <PublicRoute path="/sign-up/:email">
                                <SignUp />
                            </PublicRoute>
                            <PublicRoute path="/sign-up">
                                <SignUpMain />
                            </PublicRoute>

                            <PrivateRoute path="/settings">
                                <Settings />
                            </PrivateRoute>
                            <PrivateRoute path="/help">
                                <Help />
                            </PrivateRoute>
                            <PrivateRoute path="/groups">
                                <Groups router={routerRef.current} />
                            </PrivateRoute>
                            <PrivateRoute path="/chat">
                                <SupportChat />
                            </PrivateRoute>
                            <PrivateRoute path="/transactions">
                                <Transactions router={routerRef.current} />
                            </PrivateRoute>
                            {/* <PrivateRoute path="/transaction/:id">
                                <TransactionDetail />
                            </PrivateRoute> */}
                            <PrivateRoute path="/group/:id">
                                <GroupRouteWrapper router={routerRef.current} />
                            </PrivateRoute>
                            <PrivateRoute path="/glink/:id">
                                <GroupLink />
                            </PrivateRoute>
                            <PrivateRoute path="/tc-link/:id">
                                <TemporaryContactLink />
                            </PrivateRoute>
                            <PrivateRoute path="/sticky">
                                <AppSticky />
                            </PrivateRoute>
                            <PrivateRoute path="/">
                                {localStorage.getItem(LINK_TOKEN) ? (
                                    <Redirect
                                        to={`/${localStorage.getItem(
                                            LINK_TOKEN_TYPE
                                        )}/${localStorage.getItem(LINK_TOKEN)}`}
                                    />
                                ) : (
                                    <Redirect to="/transactions" />
                                )}
                            </PrivateRoute>
                        </Switch>
                    </IonRouterOutlet>
                </TabBar>
            </LastLocationProvider>
        </IonReactRouter>
    );
};

export default Routes;
