import firebase from 'firebase';
import db from './db';

const COLLECTION = 'experiment';

export const createExperiment = (data: any, id: string) => {
    return db
        .collection(COLLECTION)
        .doc(id)
        .set({ ...data, created: firebase.firestore.Timestamp.now() });
};

export const fetchExperiment = (id: string) => {
    const expRef = db.collection(COLLECTION).doc(id);
    return expRef.get();
};
