import React, { useEffect, useState } from 'react';
import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
} from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import FormButtonWrapper from '../shared/FormButtonWrapper';
import { updateUser } from '../../firebase/UserCollection';
import { setAuthUser } from '../../redux/slices/authSlice';

type UserDetailsType = {
    closeModal: () => void;
};

const UserDetails = ({ closeModal }: UserDetailsType) => {
    const [name, setName] = useState<string>('');

    const { authUser } = useSelector(
        (state: RootReducerInterface) => state.auth
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (authUser) {
            setName(authUser.name);
        }
    }, [authUser]);

    const onSave = () => {
        if (authUser) {
            dispatch(setAuthUser({ ...authUser, name }));
            updateUser(authUser.id, { name });
            closeModal();
        }
    };

    return (
        <IonPage>
            <Header
                title="User Details"
                rightComponent={
                    <HeaderActionButton
                        title="Exit"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                <IonItem>
                    <IonLabel position="floating">Name</IonLabel>
                    <IonInput
                        autocapitalize="on"
                        name="name"
                        value={name}
                        onIonChange={(e: any) => setName(e.target.value)}
                    />
                </IonItem>
                <FormButtonWrapper>
                    <IonButton
                        onClick={onSave}
                        size="default"
                        color="success"
                        expand="block"
                        disabled={name ? false : true}
                    >
                        Save
                    </IonButton>
                </FormButtonWrapper>
            </IonContent>
        </IonPage>
    );
};

export default UserDetails;
