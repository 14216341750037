const useTrack = () => {
    const hitAnalytics = (trackEvent: string, trackingData: any) => {
        //@ts-ignore
        window.analytics.track(trackEvent, {
            userId: trackingData.id,
            ...trackingData,
        });
    };

    return { hitAnalytics };
};

export default useTrack;
