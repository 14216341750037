import { useDispatch, useSelector } from 'react-redux';
import { RootReducerInterface } from '../types/RootReducerInterface';

import { fetchGroup } from '../firebase/GroupCollection';
import { updateUser } from '../firebase/UserCollection';
import { setAuthUser, setDefaultGroup } from '../redux/slices/authSlice';

const useManageDefaultGroup = () => {
    const { authUser } = useSelector(
        (state: RootReducerInterface) => state.auth
    );
    const dispatch = useDispatch();

    const syncDefaultGroup = () => {
        if (authUser?.defaultGroup) {
            fetchGroup(authUser.defaultGroup)
                .then((res: any) => {
                    const group = {
                        id: res.id,
                        name: res.data()?.name,
                        ownerId: res.data()?.ownerId,
                        description: res.data()?.description,
                        memberIds: res.data()?.memberIds,
                        groupColor: res.data()?.groupColor,
                    };

                    if (group) {
                        dispatch(
                            setDefaultGroup({
                                ...group,
                            })
                        );
                    }
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
    };

    const markAsDefaultGroup = (id: string) => {
        if (authUser) {
            dispatch(
                setAuthUser({
                    ...authUser,
                    defaultGroup: id,
                })
            );
            fetchGroup(id)
                .then((res) => {
                    const group = {
                        id: res.id,
                        groupColor: res.data()?.groupColor,
                        name: res.data()?.name,
                        ownerId: res.data()?.ownerId,
                        description: res.data()?.description,
                        memberIds: res.data()?.memberIds,
                    };
                    dispatch(
                        setDefaultGroup({
                            ...group,
                        })
                    );
                })
                .catch((error) => {
                    throw new Error(error);
                });
            updateUser(authUser.id, { defaultGroup: id });
        }
    };

    const unmarkedAsDefaultGroup = (id: string) => {
        if (authUser && authUser.defaultGroup === id) {
            dispatch(
                setAuthUser({
                    ...authUser,
                    defaultGroup: '',
                })
            );
            dispatch(setDefaultGroup(null));
            updateUser(authUser.id, { defaultGroup: '' });
        }
    };
    return { markAsDefaultGroup, unmarkedAsDefaultGroup, syncDefaultGroup };
};

export default useManageDefaultGroup;
