import { useEffect, useState } from 'react';
import {
    Contact,
    Contacts,
    PermissionStatus,
} from '@capacitor-community/contacts';

const useNativeContact = () => {
    const [data, setData] = useState<Array<Contact>>([]);
    useEffect(() => {
        let contactList: Contact[] = [];
        Contacts.getPermissions()
            .then(({ granted }: PermissionStatus) => {
                if (granted) {
                    Contacts.getContacts().then(
                        (result: { contacts: Contact[] }) => {
                            for (const contact of result.contacts) {
                                const {
                                    contactId,
                                    displayName,
                                    phoneNumbers,
                                    emails,
                                    photoThumbnail,
                                    organizationName,
                                    organizationRole,
                                    birthday,
                                } = contact;
                                contactList.push({
                                    contactId,
                                    displayName,
                                    phoneNumbers,
                                    emails,
                                    photoThumbnail,
                                    organizationName,
                                    organizationRole,
                                    birthday,
                                });
                            }
                            setData(contactList);
                        }
                    );
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }, []);

    return {
        data,
    };
};

export default useNativeContact;
