import * as React from 'react';

function HeartSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={22}
            height={17}
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.882 16.428a1.264 1.264 0 01-1.79 0L2.127 8.517C.09 6.572.09 3.414 2.127 1.458c2.024-1.944 5.318-1.944 7.355 0L11 3.216l1.517-1.758c2.037-1.944 5.319-1.944 7.355 0 2.037 1.944 2.037 5.102 0 7.058l-7.99 7.912zM11.856 5.83a1.182 1.182 0 01-1.739 0L7.64 3.216a2.663 2.663 0 00-3.671 0 2.43 2.43 0 000 3.53l7.03 7.045 7.031-7.046a2.43 2.43 0 000-3.529 2.663 2.663 0 00-3.67 0L11.855 5.83z"
                fill="#fff"
            />
        </svg>
    );
}

export default HeartSvg;
