import currency from 'currency.js';
import { useState, useEffect } from 'react';
import { TransactionsType } from '../types/enum';

const getMin = (arr: Array<any>, N: number) => {
    var minInd = 0;
    for (let i = 1; i < N; i++) {
        if (arr[i] < arr[minInd]) {
            minInd = i;
        }
    }
    return minInd;
};

const getMax = (arr: Array<any>, N: number) => {
    var maxInd = 0;
    for (let i = 1; i < N; i++) {
        if (arr[i] > arr[maxInd]) {
            maxInd = i;
        }
    }

    return maxInd;
};

const minOf2 = (x: number, y: number) => {
    return x < y ? x : y;
};

const useTnxCalculate = () => {
    const [tnxDataArray, setTnxDataArray] = useState<Array<any>>([]);
    const [simplifiedArray, setSimplifiedArray] = useState<
        Array<Array<number>>
    >([]);
    const [gMembers, setGMembers] = useState<Array<any>>([]);

    useEffect(() => {
        if (simplifiedArray.length) {
            var tnxObjArray = [];
            for (let i = 0; i < simplifiedArray.length; i++) {
                const element = simplifiedArray[i];
                tnxObjArray.push({
                    from: gMembers[element[0]],
                    to: gMembers[element[2]],
                    balance: element[1] / 100,
                });
            }
            setTnxDataArray([...tnxObjArray]);
        } else {
            setTnxDataArray([]);
        }
    }, [simplifiedArray, gMembers]);

    const calculateTnx = (
        transactions: Array<any>,
        groupMembers: Array<any>
    ) => {
        const members = groupMembers.map(({ id }) => id);
        setGMembers(groupMembers);
        const graph = makeGraph(members, transactions);
        if (graph.length && members.length) {
            minCashFlow(graph, members.length);
        }
    };

    const makeGraph = (members: Array<string>, transactions: Array<any>) => {
        const g: Array<any> = [];
        for (let i = 0; i < members.length; i++) {
            const d = [];
            for (let j = 0; j < members.length; j++) {
                d.push(simplify(members[i], members[j], transactions));
            }

            g[i] = d;
        }

        return g;
    };

    const simplify = (i: string, j: string, transactions: Array<any>) => {
        var amount: number = 0;
        for (let index = 0; index < transactions.length; index++) {
            const element = transactions[index];
            if (
                element.groupSplittingValues &&
                element.groupSplittingValues.paidBy === j
            ) {
                element.groupSplittingValues.splitAmounts.forEach((el: any) => {
                    if (el.userId === i && i !== j) {
                        amount += currency(el.value).intValue;
                    }
                });
            }
            if (element.type === TransactionsType.Settled) {
                if (
                    element.createdBy === j &&
                    element.involvedUsers.find((id: string) => id === i)
                ) {
                    amount += currency(element.amount).intValue;
                }
            }
        }

        return amount;
    };

    var arx: Array<Array<number>> = [];

    const minCashFlowRec = (amount: Array<any>, N: number) => {
        var mxCredit = getMax(amount, N),
            mxDebit = getMin(amount, N);

        if (amount[mxCredit] == 0 && amount[mxDebit] == 0) {
            setSimplifiedArray([...arx]);
            return;
        }

        var min = minOf2(-amount[mxDebit], amount[mxCredit]);
        amount[mxCredit] -= min;
        amount[mxDebit] += min;

        //@ts-ignore
        arx.push([mxDebit, min, mxCredit]);
        minCashFlowRec(amount, N);
    };

    const minCashFlow = (graph: Array<any>, N: number) => {
        var amount = Array.from({ length: N }, (_, i) => 0);

        for (let p = 0; p < N; p++) {
            for (let i = 0; i < N; i++) {
                amount[p] += graph[i][p] - graph[p][i];
            }
        }

        minCashFlowRec(amount, N);
    };

    return { calculateTnx, tnxDataArray };
};

export default useTnxCalculate;
