import { useEffect, useState } from 'react';
import {
    IonButton,
    IonCard,
    IonContent,
    IonProgressBar,
    IonIcon,
    IonPage,
    IonText,
} from '@ionic/react';
import { addCircle } from 'ionicons/icons';
import { lockClosedOutline } from 'ionicons/icons';
import { phonePortraitOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import PiggyBankSvg from '../../svg/PiggyBankSvg';
import CreditCardSvg from '../../svg/CreditCardSvg';
import BankSvg from '../../svg/tnx-category-icons/BankSvg';

import { RootState } from '../../redux/store';
import useTrack from '../../hooks/useTrack';
import usePurchases from '../../hooks/usePurchases';
import SkipSection from './SkipSection';
import ProBranding from './ProBranding';

import { isUserSkippedPro } from '../../utils/common';
import PlaidLinkButton from '../plaid-o-auth/PlaidLinkButton';

import Benefits from '../../components/tnx-empty/Benefits';
import OnBoardSocialProof from '../../components/social-proof/OnBoardSocialProof';
import './index.css';

interface EmptyScreenProps {}

interface LinkButtonInterface {
    onClickLink?: () => void;
}

type OnClickPropsType = {
    onClick?: () => void;
};

interface CardsInterface extends EmptyScreenProps {
    linkToken: string;
    onSuccess: Function;
}

const LinkButton = ({ onClickLink }: LinkButtonInterface) => {
    const onClickProps: OnClickPropsType = {};
    if (onClickLink) {
        onClickProps.onClick = onClickLink;
    }
    return (
        <IonButton
            {...onClickProps}
            color="color"
            fill="outline"
            className="onboard-screen-main-card-btn"
        >
            <>
                Add{' '}
                <IonIcon className="add-btn-icon" icon={addCircle}></IonIcon>
            </>
        </IonButton>
    );
};

const Cards = () => {
    return (
        <div className="onboard-screen-main-action-wrapper">
            <IonCard>
                <div className="onboard-screen-main-card-wrapper">
                    <div className="onboard-screen-main-card-section-1">
                        <PiggyBankSvg />
                    </div>
                    <div className="onboard-screen-main-card-section-2">
                        <h3>Checking</h3>
                    </div>
                    <div className="onboard-screen-main-card-section-3">
                        <PlaidLinkButton>
                            <LinkButton />
                        </PlaidLinkButton>
                    </div>
                </div>
            </IonCard>
            <IonCard>
                <div className="onboard-screen-main-card-wrapper">
                    <div className="onboard-screen-main-card-section-1">
                        <CreditCardSvg />
                    </div>
                    <div className="onboard-screen-main-card-section-2">
                        <h3>Credit Card</h3>
                    </div>
                    <div className="onboard-screen-main-card-section-3">
                        <PlaidLinkButton>
                            <LinkButton />
                        </PlaidLinkButton>
                    </div>
                </div>
            </IonCard>
        </div>
    );
};

const WaitForSync = () => {
    const history = useHistory();
    return (
        <div className="tnx-loading-main-wrapper">
            <div className="onboard-title-wrapper">
                <p>We are syncing your account</p>
            </div>

            <div className="onboard-title-wrapper">
                <h1 className="tnx-loading-modal-header">
                    This will take a minute. Come back soon.{' '}
                </h1>
            </div>
            <div className="tnx-loading-wrapper">
                <div>
                    <IonIcon
                        ios={phonePortraitOutline}
                        className="tnx-loading-phone"
                    />
                </div>
                <IonProgressBar
                    className="tnx-loading-progress-bar"
                    // value={0.25}
                    buffer={0}
                />
                <div>
                    <BankSvg />
                </div>
            </div>

            <IonButton
                color="primary"
                className="account-get-bill-magic-pro-btn"
                onClick={() => {
                    console.log('here');
                    history.push(`/groups`);
                }}
                expand="block"
                shape="round"
            >
                Create group
            </IonButton>

            {/* <div className="tnx-loading-create-group-wrapper"></div> */}
        </div>
    );
};

const ConnectAccount = () => {
    return (
        <div>
            <div className="onboard-title-wrapper mt-70">
                <p>Connect your account</p>
            </div>
            <div>
                <p className="onboard-sub-title-wrapper ">
                    <IonText>
                        This is where the magic happens. Add a credit card or
                        bank account to start automatically splitting bills.
                    </IonText>
                </p>
            </div>
            <div className="security-icon-wrapper">
                <div>
                    <IonIcon slot="start" icon={lockClosedOutline} /> Bank-level
                    Security
                </div>
            </div>
            <Cards />
        </div>
    );
};

const TnxEmpty = ({
    firstModalRef,
    router,
}: {
    firstModalRef: any;
    router?: any;
}) => {
    const { checkUser, purchaseInfo } = usePurchases();
    const [isPro, setIsPro] = useState<boolean | null>(null);
    const { hitAnalytics } = useTrack();

    const {
        tnx: { isSyncingStart },
        auth: { authUser },
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        checkUserStatus();
    }, []);

    const checkUserStatus = () => {
        checkUser()
            .then((res) => {
                setIsPro(res);
            })
            .catch((e) => {
                console.log('e', e);
            });
    };

    return (
        <IonPage>
            <IonContent>
                {isSyncingStart ? <WaitForSync /> : <ConnectAccount />}

                {isPro === false && (
                    <Benefits
                        onClick={() => {
                            if (authUser) {
                                const { email, name, id } = authUser;
                                hitAnalytics('clickSubscribeButton', {
                                    email,
                                    name,
                                    id,
                                });
                                purchaseInfo(() => {
                                    checkUserStatus();
                                    hitAnalytics('Subscription activated', {
                                        email,
                                        name,
                                        id,
                                    });
                                });
                            }
                        }}
                        router={router}
                        firstModalRef={firstModalRef}
                    />
                )}

                {!isUserSkippedPro() && (
                    <>
                        <SkipSection onlySkip={true} />
                        <OnBoardSocialProof />
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default TnxEmpty;
