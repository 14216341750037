import { FC } from 'react';
import { useHistory, useParams } from 'react-router';
import Group from './Group';

interface ParamsInterface {
    id: string;
}

interface GroupRouteWrapperInterface {
    router: HTMLIonRouterOutletElement | null;
}

const GroupRouteWrapper: FC<GroupRouteWrapperInterface> = (props) => {
    let { id }: ParamsInterface = useParams();
    const { goBack } = useHistory();

    return <Group id={id} goBack={goBack} {...props} />;
};

export default GroupRouteWrapper;
