import * as React from 'react';

function ArrowRight(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.83 4.455a1.125 1.125 0 011.577-.014l.014.014 6.75 6.75c.434.434.439 1.136.013 1.577l-.014.014-6.75 6.75a1.125 1.125 0 01-1.604-1.578l.014-.014L13.784 12 7.829 6.045a1.125 1.125 0 01-.013-1.577l.014-.013z"
                fill="#222428"
            />
        </svg>
    );
}

export default ArrowRight;
