import * as React from 'react';

function GasPumpSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M29.45 6.75c-1.02 0-2.02.281-2.02 1.313v5.624c0 2.438-1.888 4.5-3.776 5.288V4.313C23.654 3.28 23.07 3 22.031 3H7.23c-1.02 0-2.454.281-2.454 1.313V29.25H2.605c-.51 0-1.605-.15-1.605.375V31.5c0 .525 1.095 1.5 1.605 1.5H26.656c.51 0 .774-.975.774-1.5v-1.875c0-.525-.246-.375-.774-.375h-3.002v-6.375c3.776-.863 7.551-4.65 7.551-9.188V10.5h1.945c1.02 0 1.85-.844 1.85-1.875 0-1.031-.83-1.875-1.85-1.875h-3.7zm-9.553 0v5.625H8.57V6.75h11.327zM8.57 29.25V16.125h11.327V29.25H8.57z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default GasPumpSvg;
