import * as React from 'react';

function CalenderSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={37}
            height={36}
            viewBox="0 0 37 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M25.467 21.42h-3.328v3.348h3.328V21.42zM13.823 7.189V5.515a.826.826 0 00-.832-.838h-1.663a.826.826 0 00-.832.838v1.674c0 .469.366.837.832.837h1.663c.45 0 .832-.368.832-.837zM25.467 16.397h-3.328v3.348h3.328v-3.348zM27.135 7.189V5.515a.826.826 0 00-.832-.838H24.64a.826.826 0 00-.832.838v1.674c0 .469.366.837.832.837h1.663c.45 0 .832-.368.832-.837z"
                fill="#B0B4C2"
            />
            <path
                d="M27.966 9.7h-4.992a.826.826 0 01-.832-.837V5.515a.826.826 0 00-.832-.838h-4.99a.826.826 0 00-.833.838v3.348a.826.826 0 01-.832.837H9.664a.826.826 0 01-.832-.837V5.515A.826.826 0 008 4.677h-.832c-.915 0-1.664.754-1.664 1.675V29.79c0 .92.75 1.674 1.664 1.674h23.293c.915 0 1.664-.754 1.664-1.674V6.35c0-.92-.749-1.674-1.664-1.674h-.832a.826.826 0 00-.832.838v3.348a.837.837 0 01-.831.837zm.831 18.416H8.832V13.05h19.965v15.067z"
                fill="#B0B4C2"
            />
            <path
                d="M15.488 16.397h-3.327v3.348h3.327v-3.348zM20.48 16.397h-3.328v3.348h3.327v-3.348zM15.488 21.42h-3.327v3.348h3.327V21.42zM20.48 21.42h-3.328v3.348h3.327V21.42z"
                fill="#B0B4C2"
            />
        </svg>
    );
}

export default CalenderSvg;
