import { useState } from 'react';
import { IonText } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import StrokeSvg from '../../svg/StrokeSvg';
import { TransactionInterface } from '../../types/TransactionInterface';

const MoreInfo = ({ transaction }: { transaction: TransactionInterface }) => {
    const [showMore, setShowMore] = useState(false);
    const { bankList } = useSelector((state: RootState) => state.bank);

    const getBankAccountMask = () => {
        return (
            bankList
                .find(({ accounts }) =>
                    accounts.find(({ id }) => id === transaction.account_id)
                )
                ?.accounts.find(({ id }) => id === transaction.account_id)
                ?.mask || ''
        );
    };

    return (
        <div className="info-wrapper">
            <div className="info-header">
                <div className="info-header-title">
                    <StrokeSvg />
                    <IonText>IMPORTED EXPENSE</IonText>
                </div>
                <div className="show-hide">
                    <IonText onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'Hide' : 'Show'} details
                    </IonText>{' '}
                </div>
            </div>
            {showMore && (
                <>
                    <div className="info-raw">
                        <div className="info-col-left">Account</div>
                        <div className="info-col-right">
                            ...{getBankAccountMask()}
                        </div>
                    </div>
                    <div className="info-raw">
                        <div className="info-col-left">Merchant</div>
                        <div className="info-col-right">
                            {transaction?.merchant_name || ''}
                        </div>
                    </div>
                    <div className="info-raw">
                        <div className="info-col-left">Category</div>
                        <div className="info-col-right">
                            {transaction.category?.toString()}
                        </div>
                    </div>
                    <div className="info-raw">
                        <div className="info-col-left">Method</div>
                        <div className="info-col-right">
                            {transaction?.payment_meta?.payment_method || ''}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MoreInfo;
