export const colors = {
    primary: {
        hex: '#1868df',
        rgb: '24, 104, 223',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#155cc4',
        tint: '#2f77e2',
    },
    secondary: {
        hex: '#3dc2ff',
        rgb: '61, 194, 255',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#36abe0',
        tint: '#50c8ff',
    },
    tertiary: {
        hex: '#5260ff',
        rgb: '82, 96, 255',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#4854e0',
        tint: '#6370ff',
    },
    success: {
        hex: '#2fd36f',
        rgb: '47, 211, 111',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#29ba62',
        icon: '#2FD36F',
        tint: '#44d77d',
    },
    warning: {
        hex: '#fdc40c',
        rgb: '253, 196, 12',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#dfac0b',
        tint: '#fdca24',
    },
    black: {
        hex: '#000000',
        rgb: '0, 0, 0',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#000000',
        tint: '#1a1a1a',
    },
    danger: {
        hex: '#ec445a',
        rgb: '236, 68, 90',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#d03c4f',
        tint: '#ee576b',
    },
    dark: {
        hex: '#222428',
        rgb: '34, 36, 40',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#1e2023',
        tint: '#383a3e',
    },
    medium: {
        hex: '#92949c',
        rgb: '146, 148, 156',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#808289',
        tint: '#9d9fa6',
    },
    light: {
        hex: '#ffffff',
        rgb: '255, 255, 255',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#e0e0e0',
        tint: '#ffffff',
    },
    magenta: {
        hex: '#a62fd2',
        rgb: '166, 47, 210',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#9229b9',
        tint: '#af44d7',
    },
    gcgreen: {
        hex: '#8dd3c7',
        rgb: '141, 211, 199',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#7cbaaf',
        tint: '#98d7cd',
    },
    gcyellow: {
        hex: '#ffffb3',
        rgb: '255, 255, 179',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#e0e09e',
        tint: '#ffffbb',
    },
    gcpurple: {
        hex: '#bebada',
        rgb: '190, 186, 218',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#a7a4c0',
        tint: '#c5c1de',
    },
    gcred: {
        hex: '#fb8072',
        rgb: '251, 128, 114',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#dd7164',
        tint: '#fb8d80',
    },
    gcblue: {
        hex: '#80b1d3',
        rgb: '128, 177, 211',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#719cba',
        tint: '#8db9d7',
    },
    gcorange: {
        hex: '#fdb462',
        rgb: '253, 180, 98',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#df9e56',
        tint: '#fdbc72',
    },
    gcsuccess: {
        hex: '#b3de69',
        rgb: '179, 222, 105',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#9ec35c',
        tint: '#bbe178',
    },
    gcpink: {
        hex: '#fccde5',
        rgb: '252, 205, 229',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#deb4ca',
        tint: '#fcd2e8',
    },
    gcgray: {
        hex: '#d9d9d9',
        rgb: '217, 217, 217',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#bfbfbf',
        tint: '#dddddd',
    },
    gcindigo: {
        hex: '#bc80bd',
        rgb: '188, 128, 189',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#a571a6',
        tint: '#c38dc4',
    },
    gclightgreen: {
        hex: '#ccebc5',
        rgb: '204, 235, 197',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#b4cfad',
        tint: '#d1edcb',
    },
    gclightyellow: {
        hex: '#ffed6f',
        rgb: '255, 237, 111',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#e0d162',
        tint: '#ffef7d',
    },
    purple: {
        hex: '#a630d3',
        rgb: '166, 48, 211',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#922aba',
        tint: '#af45d7',
        label: '#6C51F3',
        icon: '#7B61FF',
        background: '#EFECFF',
    },
    blue_aqua: {
        hex: '#5360ff',
        rgb: '83, 96, 255',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#4854e0',
        tint: '#6470ff',
        label: '#5260FF',
        icon: '#5260FF',
        background: '#EEEFFF',
    },
    teal_pink: {
        hex: '#036b9b',
        rgb: '3, 107, 155',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#035e88',
        tint: '#1c7aa5',
        label: '#036B9B',
        icon: '#036B9B',
        background: '#EBF6FC',
    },
    wine: {
        hex: '#cb3896',
        rgb: '203, 56, 150',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#b33184',
        tint: '#d04ca1',
        label: '#CB3896',
        icon: '#CB3896',
        background: '#F6EAFA',
    },
    sherbert: {
        hex: '#f37171',
        rgb: '243, 113, 113',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#d66363',
        tint: '#f47f7f',
        label: '#BC5656',
        icon: '#F37171',
        background: '#FEF1F1',
    },
    pink_coral: {
        hex: '#e41d88',
        rgb: '228, 29, 136',
        contrastHex: '#ffffff',
        contrastRgb: '255, 255, 255',
        shade: '#c91a78',
        tint: '#e73494',
        label: '#DE1B84',
        icon: '#FF63B7',
        background: '#FCE8F3',
    },
    marine: {
        hex: '#57c5ab',
        rgb: '87, 197, 171',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#4dad96',
        tint: '#68cbb3',
        label: '#3B8170',
        icon: '#57C5AB',
        background: '#EEF9F7',
    },
    lemon_lime: {
        hex: '#4fc97e',
        rgb: '79, 201, 126',
        contrastHex: '#000000',
        contrastRgb: '0, 0, 0',
        shade: '#46b16f',
        tint: '#61ce8b',
        label: '#02893B',
        icon: '#69C96D',
        background: '#EDFAF2',
    },
};
