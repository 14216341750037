import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonPage,
    IonSpinner,
    IonToolbar,
} from '@ionic/react';
import Screen1 from './screens/Screen1';
import Screen2 from './screens/Screen2';
import Screen3 from './screens/Screen3';
import Screen4 from './screens/Screen4';
import Screen5 from './screens/Screen5';
import Screen6 from './screens/Screen6';
import Screen7 from './screens/Screen7';
import Screen8 from './screens/Screen8';
import { ResultInterface } from './types';
import { saveData } from './collections';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAuthUser } from '../../redux/slices/authSlice';
import useTrack from '../../hooks/useTrack';
import './index.css';
import { chevronBackOutline } from 'ionicons/icons';

export const WHO_DO_YOU_SLIPT_EXPENSES_WITH = 'Who do you split expenses with?';
export const WHAT_PAYMENT_METHODS_DO_YOU_PREFER =
    'What payment methods do you prefer?';
export const HOW_MANY_EXPENSES_DO_YOU_SHARE_PER_WEEK =
    'How many expenses do you share per week?';
export const HOW_MANY_SUBSCRIPTION_DO_YOU_HAVE =
    'How many subscriptions do you have?';

const Main = () => {
    const [current, setCurrent] = useState<number>(0);
    const [results, setResults] = useState<ResultInterface[]>([]);
    const [loading, setLoading] = useState(false);
    const { hitAnalytics } = useTrack();

    const {
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const next = () => {
        if (current < 5) {
            setCurrent(current + 1);
        } else if (current === 5) {
            onSubmitResults();
        }
    };

    const getQuestion = () => {
        switch (current) {
            case 0:
            case 1:
                return WHO_DO_YOU_SLIPT_EXPENSES_WITH;
            case 2:
            case 3:
                return WHAT_PAYMENT_METHODS_DO_YOU_PREFER;
            case 4:
            case 5:
                return HOW_MANY_EXPENSES_DO_YOU_SHARE_PER_WEEK;
            case 6:
            case 7:
                return HOW_MANY_SUBSCRIPTION_DO_YOU_HAVE;
            default:
                return '';
        }
    };

    const QUESTION = results.find((i) => i.question === getQuestion()) || {
        question: getQuestion(),
        answers: [],
    };

    const back = () => {
        if (current > 0) {
            setCurrent(current - 1);
        }
    };

    const onSetResults = (data: ResultInterface) => {
        setResults([
            ...results.filter((q) => q.question !== data.question),
            { ...data },
        ]);
    };

    const getEvent = (question: string) => {
        switch (question) {
            case WHO_DO_YOU_SLIPT_EXPENSES_WITH:
                return 'O1_SPLIT_WITH';
            case WHAT_PAYMENT_METHODS_DO_YOU_PREFER:
                return 'O2_PAY_METHODS';
            case HOW_MANY_EXPENSES_DO_YOU_SHARE_PER_WEEK:
                return 'O3_NUM_SPLITS';
            case HOW_MANY_SUBSCRIPTION_DO_YOU_HAVE:
                return 'O4_NUM_SUBS';
            default:
                return '';
        }
    };

    const onSubmitResults = () => {
        if (authUser) {
            setLoading(true);

            // Insert to Amplitude
            results.forEach((result) => {
                const event = getEvent(result.question);
                const selection = result.answers.join();
                hitAnalytics(event, {
                    id: authUser.id,
                    email: authUser.email,
                    selection,
                });
            });
            // Save to db
            saveData({ results }, authUser.id).then(() => {
                setLoading(false);
                dispatch(
                    setAuthUser({
                        ...authUser,
                        expStatus: 0,
                    })
                );
            });
        }
    };

    return (
        <IonPage>
            <IonContent scrollY={true}>
                {current !== 0 && (
                    <IonHeader>
                        <IonToolbar className="header-border-zero">
                            <IonIcon
                                icon={chevronBackOutline}
                                size="large"
                                color="primary"
                                slot="start"
                                onClick={() => back()}
                            />
                        </IonToolbar>
                    </IonHeader>
                )}
                <SwipeableViews
                    disabled
                    index={current}
                    className="screen-swipeable-view"
                    onChangeIndex={(e: number) => setCurrent(e)}
                >
                    <Screen1 onSetResults={onSetResults} QUESTION={QUESTION} />
                    <Screen2 />
                    <Screen3 onSetResults={onSetResults} QUESTION={QUESTION} />
                    <Screen4 />
                    <Screen5 onSetResults={onSetResults} QUESTION={QUESTION} />
                    <Screen6 />
                </SwipeableViews>
            </IonContent>
            <IonFooter>
                <IonToolbar className="on-boarding-screen-footer">
                    <div className="">
                        <IonButton
                            disabled={!QUESTION.answers.length || loading}
                            onClick={next}
                            expand="block"
                            size="default"
                            fill={
                                !QUESTION.answers.length ? 'outline' : 'solid'
                            }
                            className="screen-action-btn margin-top-zero"
                        >
                            {loading === true && (
                                <IonSpinner slot="start" name="lines-small" />
                            )}
                            Next
                        </IonButton>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    );
};

export default Main;
