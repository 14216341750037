import * as React from 'react';
import { SVGProps } from 'react';

const HandSpock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#ya)" />
        <path
            d="m9.906 26.405 5.547 5.221c.255.24.593.374.943.374h10.21c.608 0 1.144-.4 1.317-.984l1.368-4.611c.136-.46.205-.937.205-1.417v-1.583c0-.174.02-.348.06-.518l1.364-5.732a2.88 2.88 0 0 0-2.816-3.546 2.879 2.879 0 0 0-5.633-.225l-.727 4.522-.93-5.671a2.88 2.88 0 0 0-3.451-2.161 2.884 2.884 0 0 0-2.23 2.633 2.88 2.88 0 0 0-2.753 3.517l1.334 5.866a2.88 2.88 0 0 0-3.808 4.315Zm1.378-2.656a.816.816 0 0 1 1.154-.035l2.278 2.144a.688.688 0 0 0 1.159-.5v-2.986a.69.69 0 0 0-.017-.152l-1.467-6.453c-.24-1.058 1.354-1.412 1.593-.362l1.306 5.747a.687.687 0 0 0 .67.535h.37a.688.688 0 0 0 .67-.841l-1.788-7.784c-.242-1.051 1.348-1.421 1.591-.365l1.943 8.457a.687.687 0 0 0 .67.534h.568a.688.688 0 0 0 .667-.521l1.82-7.283c.263-1.05 1.847-.651 1.585.396l-1.639 6.553a.688.688 0 0 0 .667.855h.417a.688.688 0 0 0 .669-.529l1.156-4.86c.25-1.05 1.84-.674 1.589.378L27.55 22.41c-.077.326-.116.66-.116.995v1.583c0 .281-.041.56-.121.83l-1.221 4.12h-9.424l-5.349-5.035a.817.817 0 0 1-.035-1.154Z"
            fill="url(#yb)"
        />
        <defs>
            <linearGradient
                id="ya"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5260FF" />
                <stop offset={0.476} stopColor="#3DC2FF" />
            </linearGradient>
            <linearGradient
                id="yb"
                x1={20.029}
                y1={11.951}
                x2={20.111}
                y2={32.001}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#5260FF" />
                <stop offset={0.476} stopColor="#3DC2FF" />
            </linearGradient>
        </defs>
    </svg>
);

export default HandSpock;
