import { IonItem, IonModal, IonText } from '@ionic/react';
import SelectPayer from './SelectPayer';
import useModal from '../../hooks/useModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type PayerProps = {
    showDisableMessage?: () => void | null;
};

const Payer = ({ showDisableMessage }: PayerProps) => {
    const { setShowModal, showModal } = useModal();
    const { paidBy, members } = useSelector(
        (state: RootState) => state.expenseForm
    );
    const getPaidBy = () => {
        return members.find(({ id }) => id === paidBy)?.name;
    };

    return (
        <>
            <IonItem
                data-testid="payer-item"
                onClick={() => {
                    if (showDisableMessage) {
                        showDisableMessage();
                    } else {
                        setShowModal(true);
                    }
                }}
            >
                <IonText className="list-item2-text">Paid by</IonText>
                <IonText data-testid="paid-by-user" slot="end">
                    {getPaidBy()}
                </IonText>
            </IonItem>
            {showModal ? (
                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <SelectPayer closeModal={() => setShowModal(false)} />)
                </IonModal>
            ) : (
                <></>
            )}
        </>
    );
};
export default Payer;
