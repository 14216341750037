import { Browser } from '@capacitor/browser';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonText,
    IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import ConnectModal from './ConnectModal';
import DiamondSvg from './DiamondSvg';
import WhyGoRroSvg from './WhyGoProSvg';

const LIST_ITEMS = [
    {
        id: 1,
        text: 'Connect credit cards & bank accounts',
        stopColor: {
            stopColorOne: '#CB3896',
            stopColorTwo: '#162398',
        },
    },
    {
        id: 2,
        text: 'Import expenses automatically',
        stopColor: {
            stopColorOne: '#64DDC0',
            stopColorTwo: '#1F6453',
        },
    },
    {
        id: 3,
        text: 'Split expenses with one swipe',
        stopColor: {
            stopColorOne: '#F27171',
            stopColorTwo: '#FDC40C',
        },
    },
];

const Benefits = ({
    firstModalRef,
    router,
    onClick,
}: {
    firstModalRef: any;
    router: any;
    onClick: () => void;
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="benefits-green-box">
                <IonText className="ion-text-center">
                    We’re sure you’ll love BillMagic Pro, that we’re giving it
                    to you free for 2 weeks. You won’t be charged unless you
                    upgrade.
                </IonText>
                <br />
                <IonButton
                    onClick={() => setShowModal(true)}
                    className="benefit-pro-btn"
                    shape="round"
                >
                    Benefits of BillMagic Pro
                </IonButton>
            </div>
            <ConnectModal
                showModal={showModal}
                setShowModal={setShowModal}
                firstModalRef={firstModalRef}
                router={router}
                onClick={onClick}
            />
        </>
    );
};

export default Benefits;
