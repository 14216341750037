import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonIcon,
    IonList,
    IonModal,
    IonPage,
    IonToolbar,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import IonListItem from '../IonListItem';
import CheckMarkSvg from '../../svg/CheckMarkSvg';
import useSyncGroups from '../../hooks/useSyncGroups';

import useManageDefaultGroup from '../../hooks/useManageDefaultGroup';
import { GroupInterface } from '../../types/GroupInterface';
import useModal from '../../hooks/useModal';
import CreateGroup from '../group-form/CreateGroup';

type DefaultSplitGroupType = {
    closeModal: () => void;
};

const DefaultSplitGroup = ({ closeModal }: DefaultSplitGroupType) => {
    const { auth, group } = useSelector((state: RootReducerInterface) => state);
    const { showModal, setShowModal } = useModal();
    const { markAsDefaultGroup } = useManageDefaultGroup();
    const { syncGroups } = useSyncGroups();
    const { authUser } = auth;
    const { groupList } = group;

    useEffect(() => {
        if (authUser && !groupList.length) {
            syncGroups();
        }
    }, [authUser]);

    const onChange = (id: string) => {
        markAsDefaultGroup(id);
    };

    return (
        <IonPage>
            <Header
                title="Default Split Group"
                rightComponent={
                    <HeaderActionButton
                        title="Exit"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                <IonList>
                    {groupList.map(({ name, id }: GroupInterface) => {
                        return (
                            <IonListItem
                                key={id}
                                title={name}
                                onClick={() => {
                                    if (id) {
                                        onChange(id);
                                    }
                                }}
                                rightContent={
                                    authUser?.defaultGroup === id ? (
                                        <CheckMarkSvg />
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        );
                    })}
                </IonList>
            </IonContent>
            {!groupList.length && (
                <>
                    <IonFooter className="ion-no-border">
                        <IonToolbar>
                            <IonButton
                                className="form-button margin-top-zero"
                                size="default"
                                color="primary"
                                fill={groupList.length ? 'outline' : 'solid'}
                                expand="block"
                                onClick={() => {
                                    setShowModal(true);
                                    // setModalType(CREATE_GROUP);
                                }}
                            >
                                <IonIcon icon={add}></IonIcon> Create Group
                            </IonButton>
                        </IonToolbar>
                    </IonFooter>
                    {showModal && (
                        <IonModal isOpen={showModal}>
                            <CreateGroup
                                onSuccess={() => {
                                    setShowModal(false);
                                }}
                                closeModal={() => setShowModal(false)}
                            />
                        </IonModal>
                    )}
                </>
            )}
        </IonPage>
    );
};

export default DefaultSplitGroup;
