import { useEffect, useState } from 'react';
import { IonContent, IonLoading, IonPage, useIonAlert } from '@ionic/react';
import firebase from 'firebase';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { getGroupLink } from '../../firebase/GroupLinkCollection';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import { LINK_TOKEN } from '../../utils/constant';
import {
    assignUsersToGroup,
    fetchUserGroups,
} from '../../firebase/GroupCollection';
import { RootState } from '../../redux/store';

type ParamsType = {
    id: string;
};

const GroupLink = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isUserAssign, setIsUserAssign] = useState<boolean>(false);
    const [groupId, setGroupId] = useState<string>('');
    const [linkExpired, setLinkExpired] = useState<boolean>(false);
    const [present] = useIonAlert();

    let { id }: ParamsType = useParams();
    const { authUser } = useSelector((state: RootState) => state.auth);
    const { querySnapshot } = useQuerySnapshot();

    useEffect(() => {
        getGroupLink(id)
            .then(
                async (
                    doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
                ) => {
                    const { groupId, expiresAt }: any = doc.data();
                    const today = new Date();
                    const expireDate = new Date(expiresAt.toDate());
                    localStorage.removeItem(LINK_TOKEN);
                    if (today > expireDate) {
                        setLoading(false);
                        present({
                            header: 'You link is expired',
                            message: `Please request a new link from your friend`,
                            buttons: [
                                {
                                    text: 'Got it',
                                    handler: () => {
                                        setLinkExpired(true);
                                    },
                                },
                            ],
                            onDidDismiss: (e) => {
                                setLinkExpired(true);
                            },
                        });
                        return;
                    }

                    setGroupId(groupId);
                    if (authUser) {
                        const snapShot = await fetchUserGroups(authUser.id);
                        querySnapshot(snapShot, (groups) => {
                            if (!groups.find(({ id }) => id == groupId)) {
                                // Add to group
                                assignUsersToGroup(groupId, authUser.id).then(
                                    (res) => {
                                        setLoading(false);
                                        setIsUserAssign(true);
                                    }
                                );
                            } else {
                                setLoading(false);
                                setIsUserAssign(true);
                            }
                        });
                    }
                }
            )
            .catch((error) => {
                throw new Error(error);
            });
    }, [id, authUser]);

    if (linkExpired) {
        return <Redirect to={`/`} />;
    }

    if (isUserAssign) {
        return <Redirect to={`/group/${groupId}/name`} />;
    }
    if (loading) {
        return (
            <IonPage>
                <IonContent>
                    <IonLoading
                        isOpen={true}
                        onDidDismiss={() => {}}
                        message={'Please wait...'}
                    />
                </IonContent>
            </IonPage>
        );
    }
    return <></>;
};

export default GroupLink;
