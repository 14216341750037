import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken } from '../firebase/AuthCollections';
import { setPlaidLinkToken } from '../redux/slices/appSlice';
import { toggleStartSyncing } from '../redux/slices/tnxSlice';
import { RootState } from '../redux/store';

interface UsePlaidInterface {
    generateLinkToken?: boolean;
}

const usePlaid = ({ generateLinkToken }: UsePlaidInterface) => {
    const {
        auth: { authUser },
        app: { plaidLinkToken },
    } = useSelector((state: RootState) => state);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const generateToken = async () => {
        if (plaidLinkToken) {
            return;
        }
        try {
            setShowLoading(true);
            const response = await fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}createLinkToken`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: authUser?.id }),
                }
            );
            const data = await response.json();
            localStorage.setItem('linkToken', data.linkToken);
            dispatch(setPlaidLinkToken(data.linkToken));
            setShowLoading(false);
        } catch (error) {
            // throw new Error(error);
        }
    };

    const onSuccess = useCallback(async (public_token, metadata) => {
        try {
            const authToken = await getAuthToken();
            const userData: { userId?: string } = {};
            if (authUser && process.env.REACT_APP_ENV === 'local') {
                userData.userId = authUser.id;
            }
            dispatch(toggleStartSyncing(true));
            // send public_token to server
            return fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}syncBankAccounts`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authtoken: authToken ? authToken : '',
                    },
                    body: JSON.stringify({
                        publicToken: public_token,
                        metadata,
                        ...userData,
                    }),
                }
            );

            // Handle response ...
        } catch (error) {}
    }, []);

    useEffect(() => {
        if (authUser) {
            if (generateLinkToken === true) {
                generateToken();
            }
        }
    }, [authUser, generateLinkToken]);

    return {
        loading: showLoading,
        onSuccess,
    };
};

export default usePlaid;
