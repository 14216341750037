import * as React from 'react';

function UserOutlineSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.153 1.875c-4.57-.062-8.3 3.669-8.239 8.238.061 4.383 3.63 7.95 8.012 8.012 4.57.062 8.3-3.668 8.237-8.238-.06-4.383-3.628-7.951-8.01-8.012zm4.938 12.783a.156.156 0 01-.24-.012 4.856 4.856 0 00-1.263-1.156c-.994-.638-2.255-.99-3.548-.99-1.293 0-2.554.352-3.548.99a4.855 4.855 0 00-1.264 1.155.156.156 0 01-.24.013 6.848 6.848 0 01-1.823-4.54c-.064-3.802 3.055-6.984 6.858-6.993A6.88 6.88 0 0116.915 10a6.848 6.848 0 01-1.823 4.658zm-7.014-8.22c.495-.525 1.191-.813 1.962-.813.765 0 1.46.29 1.957.818.503.534.748 1.253.69 2.024-.115 1.533-1.3 2.783-2.647 2.783-1.347 0-2.535-1.25-2.648-2.784-.056-.778.191-1.504.686-2.028z"
                fill="#222428"
            />
        </svg>
    );
}

export default UserOutlineSvg;
