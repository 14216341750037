import * as React from 'react';

function GroupPeopleSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={24}
            height={20}
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.349 13.315a4.602 4.602 0 013.255-1.348h7.31a4.602 4.602 0 014.603 4.602v1.828a.947.947 0 11-1.894 0V16.57a2.708 2.708 0 00-2.708-2.708H5.604a2.709 2.709 0 00-2.71 2.708v1.828a.947.947 0 11-1.893 0V16.57c0-1.22.485-2.39 1.348-3.254z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.172 13.138a4.853 4.853 0 013.432-1.421h7.31a4.853 4.853 0 014.853 4.852v1.828a1.197 1.197 0 11-2.394 0V16.57a2.458 2.458 0 00-2.458-2.458H5.604a2.459 2.459 0 00-2.46 2.458v1.828a1.197 1.197 0 11-2.393 0V16.57c0-1.286.511-2.52 1.421-3.43zm3.432-.921a4.353 4.353 0 00-4.353 4.352v1.828a.697.697 0 101.394 0V16.57a2.959 2.959 0 012.959-2.958h7.31a2.959 2.959 0 012.96 2.958v1.828a.697.697 0 001.393 0V16.57a4.353 4.353 0 00-4.352-4.352H5.604zM4.406 5.603a4.853 4.853 0 119.705 0 4.853 4.853 0 01-9.705 0zm4.853-2.46a2.459 2.459 0 100 4.918 2.459 2.459 0 000-4.917zM18.395 12.815a.947.947 0 011.153-.681 4.604 4.604 0 012.487 1.63c.625.805.965 1.793.966 2.81v1.823a.947.947 0 01-1.894 0v-1.821c0-.597-.2-1.176-.567-1.648a2.71 2.71 0 00-1.464-.96.947.947 0 01-.68-1.153z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.152 12.753a1.197 1.197 0 011.458-.861 4.854 4.854 0 012.622 1.72 4.832 4.832 0 011.018 2.963v1.822a1.197 1.197 0 01-2.394 0v-1.82m0 0a2.44 2.44 0 00-.514-1.496 2.46 2.46 0 00-1.329-.871 1.197 1.197 0 01-.86-1.457m1.332-.377a.697.697 0 00-.347 1.35 2.96 2.96 0 011.599 1.048c.4.516.619 1.15.62 1.802v1.821a.697.697 0 101.393 0v-1.822c0-.962-.322-1.897-.913-2.657a4.353 4.353 0 00-2.352-1.542zM14.496 1.786a1.197 1.197 0 011.457-.861 4.842 4.842 0 012.625 1.726 4.859 4.859 0 010 5.949 4.842 4.842 0 01-2.625 1.726 1.197 1.197 0 01-.595-2.319 2.448 2.448 0 001.327-.873 2.465 2.465 0 00-1.327-3.89 1.197 1.197 0 01-.862-1.458z"
                fill="#fff"
            />
        </svg>
    );
}

export default GroupPeopleSvg;
