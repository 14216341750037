import * as React from 'react';

function BoltSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={22}
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.318 1.676C1.455.686 2.317 0 3.275 0H8.8c1.247 0 2.261 1.164 1.905 2.446L9.65 6.25h3.374c1.46 0 2.512 1.575 1.7 2.923L7.575 21.048a1.983 1.983 0 01-1.7.952c-1.213 0-2.235-1.113-1.92-2.393L5.52 13.25H1.975c-1.15 0-2.125-.991-1.957-2.199m0 0l1.3-9.375M3.292 2l-1.283 9.25h6.064L5.95 19.87l6.995-11.62H7.02L8.754 2H3.292z"
                fill="#fff"
            />
        </svg>
    );
}

export default BoltSvg;
