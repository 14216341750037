import { FC, Fragment, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IonItemDivider, IonList, IonModal, IonText } from '@ionic/react';
import useModal from '../../hooks/useModal';
import { TransactionsType } from '../../types/enum';
import { RootState } from '../../redux/store';
import { UserInterface } from '../../types/UserInterface';
import { GroupInterface } from '../../types/GroupInterface';
import { TransactionInterface } from '../../types/TransactionInterface';
import currency from 'currency.js';
import { makeTnxList, getFirstName, getLentAmount } from '../../utils/common';
import Item from '../transactions/Item';
import TnxLeftAvatar from '../transactions/TnxLeftAvatar';
import UpdateTransaction from '../expense/UpdateTransaction';
import SettleForm from '../group-form/SettleForm';

interface GroupTransactionsInterface {
    groupMembers: UserInterface[];
    transactions: TransactionInterface[];
    groupInfo: GroupInterface;
    fetchTransactions: () => void;
}

const GroupTransactions: FC<GroupTransactionsInterface> = ({
    groupMembers,
    transactions,
    groupInfo,
    fetchTransactions,
}) => {
    const [selectedTnx, setSelectedTnx] = useState<TransactionInterface | null>(
        null
    );
    const { showModal, setShowModal } = useModal();
    const { authUser } = useSelector((state: RootState) => state.auth);

    const getGroupMemberFromExpense = (userId: string) => {
        if (groupMembers.length) {
            return groupMembers.find(({ id }: { id: string }) => id === userId);
        }

        return null;
    };

    let displayFirstName = getFirstName(authUser?.name);

    const renderRightComponent = (
        tnx: TransactionInterface,
        isSettledType: boolean
    ) => {
        const { name: _name, groupSplittingValues } = tnx;

        return groupSplittingValues ? (
            <>
                {!isSettledType && (
                    <div className="grp-tnx-right-wrapper">
                        <div>
                            {authUser && !!getLentAmount(tnx, authUser) && (
                                <>
                                    {' '}
                                    <IonText className="grp-tnx-right-msg">
                                        {displayFirstName}{' '}
                                        {authUser?.id ===
                                        groupSplittingValues.paidBy
                                            ? 'is owed'
                                            : 'owes'}{' '}
                                    </IonText>
                                    <IonText
                                        color={
                                            authUser?.id ===
                                            groupSplittingValues.paidBy
                                                ? 'green_one'
                                                : 'alert'
                                        }
                                    >
                                        <h3 className="grp-tnx-right-msg-price">
                                            {getLentAmount(tnx, authUser) &&
                                                currency(
                                                    getLentAmount(
                                                        tnx,
                                                        authUser
                                                    ) < 0
                                                        ? -getLentAmount(
                                                              tnx,
                                                              authUser
                                                          )
                                                        : getLentAmount(
                                                              tnx,
                                                              authUser
                                                          )
                                                ).format()}
                                        </h3>
                                    </IonText>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        ) : (
            <></>
        );
    };

    return (
        <>
            <IonList>
                {transactions.length == 0 && (
                    <h3 className="main-card-summary-item-empty ion-text-center ion-margin-top ion-text-sm">
                        Now lets add some expenses 💸
                    </h3>
                )}

                {makeTnxList(transactions).map((i: any, key: number) => {
                    return (
                        <Fragment key={key}>
                            <IonItemDivider className="divider tnx-list-divider">
                                {i.month}
                            </IonItemDivider>
                            {i.element.map((i: any) => {
                                const {
                                    name,
                                    amount,
                                    date,
                                    id,
                                    merchant_name,
                                    type,
                                    groupSplittingValues,
                                } = i;

                                groupSplittingValues;
                                var user = null;
                                if (
                                    groupSplittingValues &&
                                    groupSplittingValues.paidBy
                                ) {
                                    user = getGroupMemberFromExpense(
                                        groupSplittingValues?.paidBy
                                    );
                                }

                                let isSettledType = false;
                                if (type && type === TransactionsType.Settled) {
                                    isSettledType = true;
                                }

                                const bcColors: any = {};

                                if (isSettledType) {
                                    bcColors.groupColor = 'lemon_lime';
                                    i.category = ['Settled'];
                                    i.groupColor = 'lemon_lime';
                                }
                                if (type === TransactionsType.Manual) {
                                    i.category = ['Manual'];
                                }

                                return (
                                    <Item
                                        onClickItem={() => {
                                            setSelectedTnx(i);
                                            setShowModal(true);
                                        }}
                                        {...bcColors}
                                        icon={<TnxLeftAvatar {...i} />}
                                        key={id}
                                        title={
                                            merchant_name ? merchant_name : name
                                        }
                                        subTitle={moment(date).format(
                                            'MMM  DD'
                                        )}
                                        rightValue={renderRightComponent(
                                            i,
                                            isSettledType
                                        )}
                                        enableSwipe={false}
                                        description={
                                            !isSettledType
                                                ? `${
                                                      user && user.name
                                                          ? getFirstName(
                                                                user.name
                                                            )
                                                          : user?.email
                                                  } paid $${amount}`
                                                : ''
                                        }
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}
            </IonList>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                {showModal && selectedTnx ? (
                    <>
                        {selectedTnx.type === TransactionsType.Settled ? (
                            <>
                                <SettleForm
                                    closeModal={() => {
                                        fetchTransactions();
                                        setShowModal(false);
                                    }}
                                    settlement={{
                                        id: selectedTnx.id,
                                        name: selectedTnx.name,
                                        amount: selectedTnx.amount,
                                        createdBy: selectedTnx.createdBy,
                                        assignedGroupId:
                                            selectedTnx.assignedGroupId,
                                        involvedUsers:
                                            selectedTnx?.involvedUsers ?? [],
                                        groupColor: groupInfo.groupColor ?? '',
                                        groupName: groupInfo?.name ?? '',
                                    }}
                                    fetchTransactions={fetchTransactions}
                                    closeParentModal={() => {}}
                                />
                            </>
                        ) : (
                            <UpdateTransaction
                                transaction={selectedTnx}
                                group={groupInfo}
                                onSuccessSave={() => {
                                    fetchTransactions();
                                    setShowModal(false);
                                }}
                            />
                        )}
                    </>
                ) : (
                    <></>
                )}
            </IonModal>
        </>
    );
};

export default GroupTransactions;
