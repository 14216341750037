import { ReactNode } from 'react';
import {
    IonAvatar,
    IonItem,
    IonLabel,
    IonText,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
} from '@ionic/react';
import styled from 'styled-components';

interface IonListItemProps {
    leftIcon?: ReactNode | undefined;
    title: string | null | undefined;
    description?: string | null | undefined | ReactNode;
    rightContent?: ReactNode | undefined;
    onClick?: () => void;
    color?: string;
    rightContentColor?: string;
    enableSwipe?: boolean;
    rightSwipe?: {
        label: string | ReactNode;
        onClick?: () => void;
        onSwipe?: () => void;
    };
    leftSwipe?: {
        label: string | ReactNode;
        onClick?: () => void;
        onSwipe?: () => void;
    };
}

const Text = styled.h3`
    text-decoration: none;
    font-size: 14px;
    color: #92949c;
`;
const Title = styled.h2`
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.7px;
    color: #000000;
    text-transform: capitalize;
`;

const IonListItem = ({
    leftIcon,
    title,
    description,
    rightContent,
    rightContentColor,
    enableSwipe,
    rightSwipe,
    leftSwipe,
    ...rest
}: IonListItemProps) => {
    const _rightContentColor: { color?: string } = {};
    if (rightContentColor) {
        _rightContentColor.color = rightContentColor;
    } else if (rest.color !== 'success') {
        _rightContentColor.color = 'secondary';
    }

    const closeOpened = async () => {
        // @ts-ignore
        await document.querySelector('ion-item-sliding').closeOpened();
    };

    const Item = (
        <IonItem {...rest}>
            {leftIcon && <IonAvatar slot="start">{leftIcon}</IonAvatar>}
            <IonLabel>
                <Title>{title}</Title>
                <Text>{description}</Text>
            </IonLabel>
            {rightContent && (
                <IonText slot="end" {..._rightContentColor}>
                    {rightContent}
                </IonText>
            )}
        </IonItem>
    );

    if (enableSwipe) {
        return (
            <IonItemSliding>
                <IonItemOptions
                    onIonSwipe={async () => {
                        if (leftSwipe && leftSwipe.onSwipe) {
                            leftSwipe.onSwipe();
                            closeOpened();
                        }
                    }}
                    side="start"
                >
                    <IonItemOption expandable={true} color="success">
                        {leftSwipe?.label}
                    </IonItemOption>
                </IonItemOptions>
                {Item}
                <IonItemOptions
                    onIonSwipe={() => {
                        if (rightSwipe && rightSwipe.onSwipe) {
                            rightSwipe.onSwipe();
                            closeOpened();
                        }
                    }}
                    side="end"
                >
                    <IonItemOption expandable={true} color="tertiary">
                        {rightSwipe?.label}
                    </IonItemOption>
                </IonItemOptions>
            </IonItemSliding>
        );
    }

    return Item;
};

export default IonListItem;
