import firebase from 'firebase';
import db from '../../../firebase/db';
import {
    EXP_COMPLETED,
    EXP_ONBOARDING_QUESTIONS,
} from '../../../utils/constant';
import { FirstABTestInterface } from '../types';

const COLLECTION = 'users';

export const saveData = (data: FirstABTestInterface, id: string) => {
    var userRef = db.collection(COLLECTION).doc(id);
    var expRef = userRef
        .collection('experiments')
        .doc(EXP_ONBOARDING_QUESTIONS);

    return expRef.update({
        status: EXP_COMPLETED,
        data: data.results,
    });
};
