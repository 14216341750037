import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTransaction } from '../../firebase/TransactionCollection';
import {
    setGroupId,
    setMembers,
    setPaidBy,
} from '../../redux/slices/expenseFormSlice';
import { RootState } from '../../redux/store';
import { TransactionsType } from '../../types/enum';
import {
    SplitMethodEnum,
    TransactionCollectionInterface,
} from '../../types/TransactionInterface';
import { DATE_TIME_FORMAT } from '../../utils/constant';
import TransactionForm from './TransactionForm';
import { TransactionFromInterface } from './TransactionFromInterface';

const CreateTransaction = (props: TransactionFromInterface) => {
    const { groupMembers, authUser, group } = props;
    const dispatch = useDispatch();
    const {
        name,
        amount,
        date,
        members,
        groupId,
        splitMethod,
        paidBy,
        equallySplittedMembers,
        exactSplittedMembers,
        memo,
    } = useSelector((state: RootState) => state.expenseForm);

    useEffect(() => {
        dispatch(setPaidBy(authUser.id));
    }, []);

    const saveNewTransaction = () => {
        if (groupId) {
            const data: TransactionCollectionInterface = {
                name: name,
                amount: amount ? Number(amount) : 0,
                date,
                createdBy: authUser.id,
                assignedGroupId: groupId,
                type: TransactionsType.Manual,
                involvedUsers: members.map(({ id }) => id),
                assignedDate: moment().format(DATE_TIME_FORMAT),
                groupColor: group?.groupColor ?? null,
                iso_currency_code: 'USD',
                groupSplittingValues: {
                    splitMethod,
                    paidBy,
                    splitAmounts: [
                        ...(splitMethod === SplitMethodEnum.Equally
                            ? equallySplittedMembers
                            : exactSplittedMembers),
                    ].map(({ id, value }) => {
                        return { userId: id, value };
                    }),
                },
                removed: false,
                missingSplit: false,
            };
            if (memo) {
                data.memo = memo;
            }
            return saveTransaction(data);
        }
    };
    useEffect(() => {
        const members = groupMembers.map(({ id, name, email }) => {
            return {
                id,
                name,
                email,
            };
        });
        dispatch(setGroupId(group?.id ?? ''));
        dispatch(setMembers(members));
    }, [groupMembers]);

    return <TransactionForm {...props} saveTransaction={saveNewTransaction} />;
};
export default CreateTransaction;
