import { IonHeader, IonIcon, IonText, IonToolbar } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import Img from '../assets/onboarding-sc3.png';

interface Screen2Props {}

const Screen2 = ({}: Screen2Props) => {
    return (
        <>
            <IonText className="ion-text-center screen-title">
                <h1>Save Time.</h1>
            </IonText>
            <IonText className="ion-text-center screen-sub-title">
                <p>
                    When splitting expenses with multiple people, BillMagic
                    users save over{' '}
                </p>
            </IonText>
            <IonText className="ion-text-center saving-large-number">
                <p className="">80%</p>
            </IonText>
            <IonText className="ion-text-center screen-sub-title">
                <p className="screen-sub-title">
                    of time compared to other ways.
                </p>
            </IonText>
            <img src={Img} />]
        </>
    );
};
export default Screen2;
