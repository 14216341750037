import {
    IonAvatar,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react';
import currency from 'currency.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSplit from '../../hooks/useSplit';
import { setEquallySplittedMembers } from '../../redux/slices/expenseFormSlice';
import { RootState } from '../../redux/store';
import EmptyProfilePictureSvg from '../../svg/EmptyProfilePictureSvg';
import { ExpenseMember } from '../../types/ExpenseFormInterface';

const EquallySplit = ({
    setHasError,
}: {
    setHasError: (hasError: boolean) => void;
}) => {
    const { members, equallySplittedMembers, amount } = useSelector(
        (state: RootState) => state.expenseForm
    );
    const dispatch = useDispatch();
    const currencyValues = currency(amount).distribute(
        equallySplittedMembers.length
    );
    const { equallySplit } = useSplit();

    useEffect(() => {
        setHasError(!equallySplittedMembers.length);
    }, [equallySplittedMembers]);

    const isChecked = (checkingId: string) => {
        return !!equallySplittedMembers.find(({ id }) => id === checkingId);
    };

    const onCheck = (checkingId: string, checked: boolean) => {
        dispatch(
            setEquallySplittedMembers(
                checked
                    ? equallySplit(
                          [...equallySplittedMembers, { id: checkingId }],
                          amount
                      )
                    : equallySplit(
                          equallySplittedMembers.filter(
                              ({ id }) => id !== checkingId
                          ),
                          amount
                      )
            )
        );
    };

    return (
        <>
            <div className="ion-text-center">
                {!!equallySplittedMembers.length ? (
                    <>
                        <h3 data-testid="main-title">
                            $
                            {Number(
                                currencyValues[currencyValues.length - 1].value
                            ).toFixed(2)}{' '}
                            / person
                        </h3>
                        <p data-testid="sub-title">
                            ({equallySplittedMembers.length} people)
                        </p>
                    </>
                ) : (
                    <div data-testid="empty-title">
                        <h3>$0.00 / person</h3>
                        (0 people)
                    </div>
                )}
            </div>
            <br />
            <br />
            <IonList>
                {members.map(({ id, name }: ExpenseMember) => (
                    <IonItem key={id} data-testid={`user-list-item-${id}`}>
                        <IonAvatar slot="start">
                            <EmptyProfilePictureSvg />
                        </IonAvatar>
                        <IonLabel>
                            <h2>{name}</h2>
                        </IonLabel>
                        <IonCheckbox
                            data-testid={`user-list-item-check-box-${id}-${isChecked(
                                id
                            )}`}
                            onIonChange={(e) => onCheck(id, e.detail.checked)}
                            color="medium"
                            slot="end"
                            checked={isChecked(id)}
                        />
                    </IonItem>
                ))}
            </IonList>
        </>
    );
};

export default EquallySplit;
