import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transactionsSnap } from '../firebase/TransactionCollection';
import {
    setIsEmpty,
    setIsFetching,
    setTransactions,
    toggleStartSyncing,
} from '../redux/slices/tnxSlice';
import { RootState } from '../redux/store';
import { DATE_TIME_FORMAT } from '../utils/constant';
import useQuerySnapshot from './useQuerySnapshot';

const useTransaction = () => {
    const { authUser } = useSelector((state: RootState) => state.auth);
    const { querySnapshot } = useQuerySnapshot();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsFetching(true));
        const unSub = authUser
            ? transactionsSnap(authUser.id).onSnapshot((res) => {
                  querySnapshot(res, (transactions) => {
                      dispatch(toggleStartSyncing(false));
                      dispatch(setIsEmpty(!transactions.length));
                      dispatch(
                          setTransactions(
                              transactions.map((transaction: any) => {
                                  return {
                                      ...transaction,
                                      createdDate: moment(
                                          transaction.createdDate.toDate()
                                      ).format(DATE_TIME_FORMAT),
                                      tnxFirestoreDate: moment(
                                          transaction.tnxFirestoreDate.toDate()
                                      ).format(DATE_TIME_FORMAT),
                                  };
                              })
                          )
                      );
                      dispatch(setIsFetching(false));
                  });
              })
            : () => {};

        return () => unSub();
    }, [authUser?.id]);
};

export default useTransaction;
