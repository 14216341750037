import * as React from 'react';

function HandPaceSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={23}
            height={22}
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.927 8.249c-.674-.93-1.904-1.462-3.193-1.304V3.18c0-1.754-1.611-3.18-3.591-3.18C9.162 0 7.55 1.426 7.55 3.18L8 7.219 7.082 3.83C6.358 2.185 4.26 1.38 2.412 2.028.572 2.674-.328 4.51.389 6.138l2.738 6.227c-1.501 1.073-2.16 2.484-1.215 3.963l2.694 4.21C5.182 21.44 6.268 22 7.439 22H17.54c1.51 0 2.827-.921 3.17-2.226l1.347-5.113c.056-.214.085-.433.085-.653V11c0-1.932-2.124-3.323-4.215-2.751zm1.858 5.759a.746.746 0 01-.025.19l-1.347 5.113c-.097.369-.456.627-.873.627H7.44a.921.921 0 01-.776-.406L3.969 15.32c-.224-.35-.129-.803.221-1.053l1.309-.935a.686.686 0 00.262-.356.607.607 0 00-.026-.423L2.588 5.397c-.617-1.403 1.698-2.181 2.294-.826l2.936 6.676a.71.71 0 00.288.32c.131.078.286.12.445.12h.571c.434 0 .786-.307.786-.687V3.18c0-1.477 2.47-1.48 2.47 0V11c0 .38.35.688.785.688h.336c.434 0 .786-.308.786-.688V9.797c0-1.08 1.796-1.081 1.796 0V11c0 .38.352.688.786.688h.336c.434 0 .786-.308.786-.688 0-1.08 1.796-1.081 1.796 0v3.008z"
                fill="#fff"
            />
        </svg>
    );
}

export default HandPaceSvg;
