import { IonButton } from '@ionic/react';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';
import LogoApple from '../../svg/LogoApple';

const AppleLogin = () => {
    const { cfaAppleSignIn } = useFirebaseAuth();
    return (
        <IonButton
            className="ion-margin on-board-sig-up-btn"
            size="default"
            color="medium"
            expand="block"
            fill="outline"
            onClick={cfaAppleSignIn}
        >
            <LogoApple style={{ marginRight: 5 }} /> Sign up with Apple
        </IonButton>
    );
};

export default AppleLogin;
