import {
    IonAvatar,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonText,
} from '@ionic/react';
import currency from 'currency.js';
import { chevronForwardOutline } from 'ionicons/icons';
import { GroupInterface } from '../../types/GroupInterface';
import { TransactionInterface } from '../../types/TransactionInterface';
import { UserInterface } from '../../types/UserInterface';
import { TOTAL_SPENT } from '../../utils/plaidCategories';
import TnxLeftAvatar from '../transactions/TnxLeftAvatar';

interface GroupTotalsInterface {
    groupInfo: GroupInterface;
    groupMembers: UserInterface[];
    transactions: TransactionInterface[];
}

const GroupTotals: React.FC<GroupTotalsInterface> = ({
    groupInfo,
    transactions,
    groupMembers,
}) => {
    const getUsersTotal = () => {
        var groupMembersTotals: any = [];
        groupMembers.forEach((member) => {
            var total = 0;
            transactions.forEach((transaction) => {
                if (transaction.groupSplittingValues?.paidBy === member.id) {
                    total += transaction.amount;
                }
            });
            groupMembersTotals.push({
                ...member,
                total,
            });
        });
        return groupMembersTotals;
    };

    getUsersTotal();
    return (
        <IonList>
            <IonListHeader>
                <p className="tnx-total-list-header">Total Spent Per Person</p>
            </IonListHeader>
            {getUsersTotal().map((i: any) => {
                return (
                    <IonItem>
                        <TnxLeftAvatar
                            category={[TOTAL_SPENT]}
                            {...groupInfo}
                        />
                        <IonLabel>
                            <h2 className="tnx-item-h1">{i.name}</h2>
                            <h3 className="tnx-item-h2">{i.email}</h3>
                        </IonLabel>
                        <IonText className="tnx-list-total" slot="end">
                            {currency(i.total).format()}
                        </IonText>
                    </IonItem>
                );
            })}
        </IonList>
    );
};

export default GroupTotals;
