import { useState } from 'react';
import { Contact } from '@capacitor-community/contacts';
import {
    IonAvatar,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    useIonAlert,
    IonIcon,
    IonModal,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import {
    assignUsersToGroup,
    updateGroup,
} from '../../firebase/GroupCollection';
import {
    createTemporaryContact,
    updateTempContact,
} from '../../firebase/TemporaryContactCollection';
import useManageDefaultGroup from '../../hooks/useManageDefaultGroup';
import EmptyProfilePictureSvg from '../../svg/EmptyProfilePictureSvg';
import { GroupInterface } from '../../types/GroupInterface';
import { TemporaryContactCollectionInterface } from '../../types/TemporaryContactInterface';
import { UserInterface, UserType } from '../../types/UserInterface';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import AddNewContact from './AddNewContact';
import { SMS } from '@ionic-native/sms';
import useGroupLink from '../../hooks/useGroupLink';
import useSyncGroups from '../../hooks/useSyncGroups';

type VerifyProps = {
    closeModal: () => void;
    selectedContacts: Array<UserInterface>;
    goBack: () => void;
    group: GroupInterface;
    fetchData: () => void;
    newContacts?: Array<TemporaryContactCollectionInterface>;
    mobileContacts: Contact[];
    removedContacts: Array<UserInterface>;
};

const Verify = ({
    closeModal,
    selectedContacts,
    goBack,
    group,
    fetchData,
    newContacts,
    mobileContacts,
    removedContacts,
}: VerifyProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [contactInfo, setContactInfo] = useState<UserInterface | null>(null);
    const [present] = useIonAlert();
    const { syncDefaultGroup } = useManageDefaultGroup();
    const { syncGroups } = useSyncGroups();

    const { link } = useGroupLink(group.id);

    const onSubmit = async () => {
        const memberIds = selectedContacts
            .filter(({ useType }) => useType !== UserType.Temporary)
            .map(({ id }) => id);

        var data = { memberIds };

        updateGroup(group.id, { ...data }).then(async () => {
            if (newContacts?.length) {
                newContacts.forEach(async (contact) => {
                    await createTemporaryContact(contact).then((res) => {
                        assignUsersToGroup(group.id, res.id);
                    });
                });
            }

            const removedTemContact = removedContacts.find(
                ({ useType }) => useType === UserType.Temporary
            );

            if (removedContacts.length && !!removedTemContact) {
                //@ts-ignore
                const assignGroups = removedTemContact.assignedGroupIds.filter(
                    (id: string) => id !== group.id
                );
                updateTempContact(removedTemContact.id, {
                    assignedGroupIds: assignGroups,
                });
            }
            syncDefaultGroup();
            await syncGroups();
            fetchData();
            closeModal();
            goBack();
        });
    };

    const sendNativeSms = () => {
        const mobileNumbers: string[] = [];
        mobileContacts.forEach(({ phoneNumbers }) => {
            if (phoneNumbers?.length && phoneNumbers[0].number) {
                mobileNumbers.push(phoneNumbers[0].number);
            }
        });

        return SMS.send(
            mobileNumbers,
            `Hi! Join the group ${group.name} on BillMagic to share expenses.  ${link}`
        );
    };

    return (
        <IonPage>
            <Header
                title="Verify contact info"
                leftComponent={
                    <IonIcon
                        size="large"
                        color="secondary"
                        icon={chevronBackOutline}
                        onClick={() => closeModal()}
                    />
                }
                rightComponent={
                    <HeaderActionButton
                        title="Finish"
                        color="secondary"
                        onClick={() => {
                            if (mobileContacts.length) {
                                sendNativeSms().then(() => {
                                    present({
                                        header: 'Added!',
                                        message: `We sent your friend a link to join the group`,
                                        buttons: [
                                            {
                                                text: 'Got it',
                                                handler: () => {
                                                    onSubmit();
                                                },
                                            },
                                        ],
                                        onDidDismiss: (e) => {},
                                    });
                                });
                            } else {
                                present({
                                    header: 'Added!',
                                    message: `We sent your friend a link to join the group`,
                                    buttons: [
                                        {
                                            text: 'Got it',
                                            handler: () => {
                                                onSubmit();
                                            },
                                        },
                                    ],
                                    onDidDismiss: (e) => {},
                                });
                            }
                        }}
                    />
                }
            />
            <IonContent fullscreen>
                <IonList>
                    {selectedContacts.map((i: UserInterface, index: number) => {
                        return (
                            <IonItem key={index}>
                                <IonAvatar slot="start">
                                    <EmptyProfilePictureSvg />
                                </IonAvatar>
                                <IonLabel>
                                    <h2>{i.name}</h2>
                                    <h3>{i.email ?? ''}</h3>
                                </IonLabel>
                                {/* <IonIcon
                                    size="small"
                                    color="secondary"
                                    icon={pencilOutline}
                                    onClick={() => {
                                        setContactInfo(i);
                                        setShowModal(true);
                                    }}
                                ></IonIcon> */}
                            </IonItem>
                        );
                    })}
                    {!!newContacts?.length &&
                        newContacts.map(
                            (
                                i: TemporaryContactCollectionInterface,
                                index: number
                            ) => {
                                return (
                                    <IonItem key={index}>
                                        <IonAvatar slot="start">
                                            <EmptyProfilePictureSvg />
                                        </IonAvatar>
                                        <IonLabel>
                                            <h2>{i.name}</h2>
                                            <h3>{i.email ?? ''}</h3>
                                        </IonLabel>
                                        {/* <IonIcon
                                            size="small"
                                            color="secondary"
                                            icon={pencilOutline}
                                            onClick={() => {
                                                // setContactInfo(i);
                                                setShowModal(true);
                                            }}
                                        ></IonIcon> */}
                                    </IonItem>
                                );
                            }
                        )}
                    {mobileContacts.map((i: Contact, index: number) => {
                        return (
                            <IonItem key={index}>
                                <IonAvatar slot="start">
                                    {!i.photoThumbnail ? (
                                        <EmptyProfilePictureSvg />
                                    ) : (
                                        <img src={i.photoThumbnail} />
                                    )}
                                </IonAvatar>
                                <IonLabel>
                                    <h2>{i.displayName}</h2>
                                    <h3>{i.phoneNumbers[0].number}</h3>
                                </IonLabel>
                                {/* <IonIcon
                                            size="small"
                                            color="secondary"
                                            icon={pencilOutline}
                                            onClick={() => {
                                                // setContactInfo(i);
                                                setShowModal(true);
                                            }}
                                        ></IonIcon> */}
                            </IonItem>
                        );
                    })}
                </IonList>
            </IonContent>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                {contactInfo ? (
                    <AddNewContact
                        contactInfo={contactInfo}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <></>
                )}
            </IonModal>
        </IonPage>
    );
};

export default Verify;
