import {
    IonAvatar,
    IonCheckbox,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonToolbar,
} from '@ionic/react';
import HandPeaceSvg from '../assets/svg-icons/HandPeaceSvg';
import HandSpock from '../assets/svg-icons/HandSpockSvg';
import Hart from '../assets/svg-icons/Hart';
import HomeSvg from '../assets/svg-icons/HomeSvg';
import OthersSvg from '../assets/svg-icons/OthersSvg';
import SuitCaseSvg from '../assets/svg-icons/SuitCaseSvg';
import { ResultInterface } from '../types';

const options = [
    {
        id: 1,
        name: 'My Parents/Spouse',
        icon: <Hart />,
    },
    {
        id: 2,
        name: 'Roommate(s)',
        icon: <HomeSvg />,
    },
    {
        id: 3,
        name: 'Friends',
        icon: <HandSpock />,
    },
    {
        id: 4,
        name: 'Travel buddies',
        icon: <SuitCaseSvg />,
    },
    {
        id: 5,
        name: 'Family members',
        icon: <HandPeaceSvg />,
    },
    { id: 6, name: 'Others', icon: <OthersSvg /> },
];

interface Screen1Props {
    onSetResults: (data: ResultInterface) => void;
    QUESTION: ResultInterface;
}

const Screen1 = ({ onSetResults, QUESTION }: Screen1Props) => {
    const isChecked = (text: string) => {
        if (QUESTION) {
            return !!QUESTION.answers.find((answer) => answer === text);
        }
        return false;
    };

    return (
        <>
            <IonHeader>
                <IonToolbar className="header-border-zero"></IonToolbar>
            </IonHeader>
            <IonText className="ion-text-center screen-title">
                <h1>
                    Who do you split <br /> expenses with?
                </h1>
            </IonText>
            <IonText className="ion-text-center screen-sub-title">
                <p>Select as many as you’d like.</p>
            </IonText>
            <IonList>
                {options.map(({ id, name, icon }) => (
                    <IonItem
                        key={id}
                        lines="none"
                        className="screen-list-item"
                        onClick={() => {
                            QUESTION.answers = isChecked(name)
                                ? QUESTION?.answers.filter(
                                      (answer) => answer !== name
                                  ) || []
                                : [...(QUESTION?.answers ?? []), name];
                            onSetResults(QUESTION);
                        }}
                    >
                        <IonAvatar slot="start">{icon ?? ''}</IonAvatar>
                        <IonLabel>
                            <h1>{name}</h1>
                        </IonLabel>
                        {isChecked(name) && (
                            <IonCheckbox
                                checked={isChecked(name)}
                                color="success"
                                slot="end"
                            />
                        )}
                    </IonItem>
                ))}
            </IonList>
        </>
    );
};

export default Screen1;
