import { ReactNode } from 'react';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';

interface HeaderProps {
    title: string | ReactNode;
    rightComponent?: ReactNode;
    leftComponent?: ReactNode;
    slot?: string;
    color?: string;
    ionToolBarClasses?: string;
    ionToolBarTitleClasses?: string;
    ionToolBarTitleStyles?: object;
}

const Header = ({
    title,
    rightComponent,
    leftComponent,
    slot,
    color,
    ionToolBarClasses,
    ionToolBarTitleClasses,
    ionToolBarTitleStyles,
}: HeaderProps) => {
    const slotD: any = {};
    if (slot) {
        //@ts-ignore
        slotD.slot = slot;
    }
    const colorProps: any = {};
    if (color) {
        colorProps.color = color;
    }
    const toolBarTitleStyles: any = {};
    if (ionToolBarTitleStyles) {
        toolBarTitleStyles.style = ionToolBarTitleStyles;
    }

    return (
        <IonHeader {...slotD}>
            <IonToolbar
                className={`header ${
                    ionToolBarClasses ? ionToolBarClasses : ''
                }`}
                {...colorProps}
            >
                {leftComponent && leftComponent}
                <IonTitle
                    // slot="center"
                    className={ionToolBarTitleClasses}
                    {...toolBarTitleStyles}
                >
                    {title}
                </IonTitle>
                {rightComponent && rightComponent}
            </IonToolbar>
        </IonHeader>
    );
};

export default Header;
