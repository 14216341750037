import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import CheckMarkSvg from '../../svg/CheckMarkSvg';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setPaidBy } from '../../redux/slices/expenseFormSlice';

type SelectPayerProps = {
    closeModal: () => void;
};

const SelectPayer = ({ closeModal }: SelectPayerProps) => {
    const { paidBy, members } = useSelector(
        (state: RootState) => state.expenseForm
    );
    const dispatch = useDispatch();

    const isCheck = (id: string) => {
        if (paidBy) {
            return paidBy === id;
        }
        return false;
    };

    return (
        <IonPage data-testid="select-payer-screen">
            <Header
                title="Select Payer"
                leftComponent={
                    <IonIcon
                        size="large"
                        color="secondary"
                        icon={chevronBackOutline}
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                <IonList>
                    {members.map(({ id, name }) => {
                        return (
                            <IonItem
                                key={id}
                                data-testid={`user-item-${id}`}
                                onClick={() => dispatch(setPaidBy(id))}
                            >
                                <IonLabel>{name}</IonLabel>
                                {isCheck(id) && (
                                    <IonText
                                        data-testid={`selected-user-checked-icon-${id}`}
                                        slot="end"
                                    >
                                        <CheckMarkSvg />
                                    </IonText>
                                )}
                            </IonItem>
                        );
                    })}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SelectPayer;
