import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonText,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import HeaderActionButton from '../HeaderActionButton';
import Header from '../Header';
import {
    GroupInterface,
    SettlementInterface,
} from '../../types/GroupInterface';
import SettleForm from '../group-form/SettleForm';
import { getFirstName } from '../../utils/common';
import currency from 'currency.js';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { closeOutline } from 'ionicons/icons';

interface IBalance {
    closeModal: () => void;
    data: Array<any>;
    group: GroupInterface;
    fetchTransactions: () => void;
}

const Balance = ({ closeModal, data, group, fetchTransactions }: IBalance) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [settlement, setSettlement] = useState<SettlementInterface | null>(
        null
    );
    const {
        auth: { authUser },
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        return () => {
            setShowModal(false);
            setSettlement(null);
        };
    }, []);

    const onClickItem = (transaction: any) => {
        const name = `${getFirstName(
            transaction.from.name
        )} paid ${getFirstName(transaction.to.name)} `;
        setSettlement({
            name,
            amount: transaction.balance,
            createdBy: transaction.from.id,
            assignedGroupId: group.id,
            involvedUsers: [transaction.to.id, transaction.from.id],
            groupColor: group.groupColor ?? '',
            groupName: group.name,
        });
        setShowModal(true);
    };

    return (
        <IonPage>
            <Header
                title="Select Balance To Settle"
                rightComponent={
                    <HeaderActionButton
                        color="dark"
                        title={<IonIcon size="large" icon={closeOutline} />}
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent>
                <IonList>
                    {data.map((i, index: number) => {
                        let textColorObj: any = {};
                        if (i.from.id === authUser?.id) {
                            textColorObj.color = 'danger';
                        } else if (i.to.id === authUser?.id) {
                            textColorObj.color = 'green_one';
                        }
                        return (
                            <IonItem
                                lines="none"
                                key={index}
                                onClick={() => {
                                    onClickItem(i);
                                }}
                            >
                                <IonLabel>
                                    {getFirstName(i.from.name)} owes{' '}
                                    {getFirstName(i.to.name)}
                                </IonLabel>
                                <IonText {...textColorObj} slot="end">
                                    {currency(i.balance).format()}
                                </IonText>
                            </IonItem>
                        );
                    })}
                </IonList>
            </IonContent>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                {settlement ? (
                    <SettleForm
                        closeModal={() => setShowModal(false)}
                        settlement={settlement}
                        fetchTransactions={fetchTransactions}
                        closeParentModal={closeModal}
                    />
                ) : (
                    <></>
                )}
            </IonModal>
        </IonPage>
    );
};

export default Balance;
