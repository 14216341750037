import './pagination.css';

interface PaginationInterface {
    current: number;
    size: number;
}

const Pagination = ({ current, size }: PaginationInterface) => {
    return (
        <div className="pg-wrapper">
            {[...Array(size)].map((_, index) => (
                <div
                    key={`pag-${index}`}
                    className={current === index ? 'active' : ''}
                />
            ))}
        </div>
    );
};

export default Pagination;
