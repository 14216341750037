import { useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonLabel,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { TransactionInterface } from '../../types/TransactionInterface';
import { GroupInterface } from '../../types/GroupInterface';
import { add, close } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useModal from '../../hooks/useModal';
import CreateGroup from '../group-form/CreateGroup';
import GroupList from '../group/GroupList';
import './index.css';
import UpdateTransaction from '../expense/UpdateTransaction';
import EmptyGroupImage from '../../assets/groups-empty.png';

type ITransactionView = {
    selectedTransaction: TransactionInterface;
    closeModal: () => void;
    onClickGroupItem?: (group: GroupInterface) => void;
    showNativeHeader?: boolean;
};

const CREATE_GROUP = 1;
const TNX_VIEW = 2;

const TransactionView = ({
    closeModal,
    selectedTransaction,
    onClickGroupItem,
    showNativeHeader,
}: ITransactionView) => {
    const {
        group: { groupList },
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const { showModal, setShowModal, modalType, setModalType } = useModal();
    const [group, setGroup] = useState<GroupInterface | null>(null);

    const onClickItem = (i: GroupInterface) => {
        if (onClickGroupItem) {
            onClickGroupItem(i);
            return;
        }
        setGroup(i);
        setModalType(TNX_VIEW);
        setShowModal(true);
    };

    const renderModalContent = () => {
        switch (modalType) {
            case CREATE_GROUP:
                return showModal ? (
                    <CreateGroup
                        onSuccess={() => {
                            setShowModal(false);
                        }}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <></>
                );
            case TNX_VIEW:
                return showModal && group && authUser ? (
                    <UpdateTransaction
                        transaction={selectedTransaction}
                        group={group}
                        onSuccessSave={() => setShowModal(false)}
                    />
                ) : (
                    <></>
                );
            default:
                break;
        }
    };

    return (
        <IonPage>
            {showNativeHeader === true ? (
                <IonHeader>
                    <IonToolbar className="custom-modal-header">
                        <IonTitle>Select Group</IonTitle>
                        <IonIcon
                            onClick={closeModal}
                            className="close-icon"
                            slot="end"
                            icon={close}
                        />
                    </IonToolbar>
                </IonHeader>
            ) : (
                <div className="modal-header">
                    <IonLabel className="modal-header-title">
                        Select Group
                    </IonLabel>
                    <IonIcon
                        onClick={closeModal}
                        className="close-icon"
                        slot="end"
                        icon={close}
                    />
                </div>
            )}
            <IonContent fullscreen>
                {!groupList.length ? (
                    <>
                        <div className="circle-wrapper">
                            <img src={EmptyGroupImage} />
                        </div>
                        <div className="group-modal-empty-message-wrapper">
                            Create your first group to start splitting expenses
                        </div>
                    </>
                ) : (
                    <GroupList
                        onClickListItem={onClickItem}
                        showSubtitle={false}
                        showRightArrow={false}
                    />
                )}
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <IonButton
                        className="form-button margin-top-zero"
                        size="default"
                        color="primary"
                        fill={groupList.length ? 'outline' : 'solid'}
                        expand="block"
                        onClick={() => {
                            setShowModal(true);
                            setModalType(CREATE_GROUP);
                        }}
                    >
                        <IonIcon icon={add}></IonIcon> Create Group
                    </IonButton>
                </IonToolbar>
            </IonFooter>
            {showModal && (
                <IonModal isOpen={showModal}>{renderModalContent()}</IonModal>
            )}
        </IonPage>
    );
};

export default TransactionView;
