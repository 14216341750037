import * as React from 'react';
import { SVGProps } from 'react';

const ManualSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={36}
        height={36}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 2C7 2 6 2.177 6 3.002v29.996a1 1 0 0 0 1.6.8l2.4-2.8 3.4 2.8a.994.994 0 0 0 1.2 0l3.4-2.8 3.4 2.8a.994.994 0 0 0 1.2 0l3.4-2.8 2.4 2.8a1 1 0 0 0 1.6-.8V3.002C30 2.177 29.26 2 28.5 2h-21ZM26 25.817V6H10v19.817l4 3.294 4-3.294 4 3.294 4-3.294ZM7.5 6h.015Zm.015 0H7.5m21 0c.072.007.079 0 0 0Z"
            fill={props.fill ? props.fill : '#B0B4C2'}
        />
        <path
            d="M23 17H13v4h10v-4ZM23 10H13v4h10v-4Z"
            fill={props.fill ? props.fill : '#B0B4C2'}
        />
    </svg>
);

export default ManualSvg;
