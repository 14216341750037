import {
    IonCard,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
} from '@ionic/react';
import currency from 'currency.js';
import { chevronDown, chevronUp } from 'ionicons/icons';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { TRANSACTION_CONTENT } from '../../pages/groups/Group';
import { RootState } from '../../redux/store';
import { TransactionsType } from '../../types/enum';
import { TransactionInterface } from '../../types/TransactionInterface';
import { UserInterface } from '../../types/UserInterface';
import { getFirstName, getLentAmount } from '../../utils/common';

type GroupSummaryCardProps = {
    tnxDataArray: any;
    recordPayment: () => void;
    transactions: TransactionInterface[];
    groupMembers: UserInterface[];
    content: number;
};

const GroupSummaryCard = ({
    tnxDataArray,
    recordPayment,
    groupMembers,
    transactions,
    content,
}: GroupSummaryCardProps) => {
    const {
        auth: { authUser },
    } = useSelector((state: RootState) => state);

    const authUserHasNoBalance = () => {
        if (authUser) {
            return !tnxDataArray.find(
                ({ from, to }: any) =>
                    authUser.id === from.id || authUser.id === to.id
            );
        }
    };

    let displayFirstName = getFirstName(authUser?.name);

    const getGroupTotalSpent = () => {
        let total = 0;
        transactions.forEach((transaction) => {
            if (transaction.type !== TransactionsType.Settled) {
                total += transaction.amount;
            }
        });

        return total;
    };

    const getUserTotalLent = () => {
        let total = 0;
        if (authUser) {
            transactions.forEach((tnx) => {
                if (tnx.type === TransactionsType.Settled) {
                    const toMe =
                        tnx.involvedUsers?.find(
                            (userId) => userId === authUser.id
                        ) && tnx.createdBy !== authUser?.id;

                    if (toMe) {
                        total -= parseFloat(tnx.amount.toString());
                    }
                    const fromMine =
                        tnx.involvedUsers?.find(
                            (userId) => userId === authUser.id
                        ) && tnx.createdBy === authUser.id;

                    if (fromMine) {
                        total += parseFloat(tnx.amount.toString());
                    }
                }

                total += getLentAmount(tnx, authUser);
            });
        }
        return total;
    };

    return (
        <div className="main-card-wrapper">
            <IonCard className="main-card">
                <IonCardContent>
                    <IonList>
                        {content === TRANSACTION_CONTENT ? (
                            <>
                                {authUserHasNoBalance() && (
                                    <IonItem
                                        lines="none"
                                        className="main-card-item"
                                    >
                                        <IonLabel className="main-card-summary-item-empty">
                                            <h2>
                                                {displayFirstName} has no
                                                balances
                                            </h2>
                                        </IonLabel>
                                    </IonItem>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </IonList>
                    <div className="">
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <IonText className="ion-text-center ion-text-uppercase">
                                        {content === TRANSACTION_CONTENT ? (
                                            <>
                                                {!authUserHasNoBalance() && (
                                                    <p className="summary-card-title">
                                                        TOTAL {displayFirstName}{' '}
                                                        {getUserTotalLent() < 0
                                                            ? 'OWES'
                                                            : 'OWED'}
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className="summary-card-title">
                                                TOTAL GROUP SPEND
                                            </p>
                                        )}
                                    </IonText>
                                </IonCol>
                                <IonCol size="12">
                                    <IonText
                                        color={
                                            getUserTotalLent() < 0 &&
                                            content === TRANSACTION_CONTENT
                                                ? 'danger'
                                                : 'green_one'
                                        }
                                        className="ion-text-center"
                                    >
                                        <h1 className="summary-total-amount">
                                            {content === TRANSACTION_CONTENT ? (
                                                <>
                                                    {!authUserHasNoBalance() &&
                                                        currency(
                                                            getUserTotalLent() <
                                                                0
                                                                ? -getUserTotalLent()
                                                                : getUserTotalLent()
                                                        ).format()}
                                                </>
                                            ) : (
                                                currency(
                                                    getGroupTotalSpent()
                                                ).format()
                                            )}
                                        </h1>
                                    </IonText>
                                </IonCol>
                                {content === TRANSACTION_CONTENT && (
                                    <IonCol offset="3" size="6">
                                        <IonButton
                                            className="margin-top-zero record-payment-btn"
                                            size="small"
                                            color="primary"
                                            fill={'outline'}
                                            expand="block"
                                            onClick={recordPayment}
                                        >
                                            Record Payment
                                        </IonButton>
                                    </IonCol>
                                )}
                            </IonRow>
                        </>
                    </div>
                </IonCardContent>
            </IonCard>
        </div>
    );
};
export default GroupSummaryCard;
