import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAccountSyncHistory,
    fetchBankAccounts,
} from '../firebase/BankAccountsCollection';
import { setBankList } from '../redux/slices/bankSlice';
import { RootState } from '../redux/store';
import { shortByDate } from '../utils/common';
import useQuerySnapshot from './useQuerySnapshot';

const useSyncBanks = () => {
    const {
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { querySnapshot } = useQuerySnapshot();

    const fetchBanks = async () => {
        try {
            if (authUser) {
                var snapshot = await fetchBankAccounts(authUser.id);
                querySnapshot(snapshot, (payload) => {
                    const bankData = payload.map((i: any) => {
                        return {
                            id: i.id,
                            name: i.institution.name,
                            userId: i.userId,
                            softDelete: !!i.softDelete,
                            accounts: i.accounts,
                            itemId: i.itemId,
                        };
                    });

                    const itemIds = payload.map((i: any) => i.itemId);
                    if (!itemIds.length) {
                        dispatch(setBankList(bankData));
                        return;
                    }
                    fetchAccountSyncHistory(itemIds).then((res) => {
                        if (res.empty) {
                            dispatch(setBankList(bankData));
                            return;
                        }
                        querySnapshot(res, (history) => {
                            const bData = bankData.map((bank) => {
                                const date = history.filter(
                                    ({ plaidItemId }) =>
                                        plaidItemId === bank.itemId
                                );
                                const lastUpdatedObj: any = {
                                    lastUpdated: null,
                                };
                                if (date.length) {
                                    const data = shortByDate(
                                        date.map((i) => {
                                            return {
                                                ...i,
                                                createdDate: i.createdDate.toDate(),
                                            };
                                        }),
                                        'createdDate'
                                    );

                                    lastUpdatedObj.lastUpdated = moment(
                                        data[0].createdDate
                                    ).fromNow();
                                }

                                return {
                                    ...bank,
                                    ...lastUpdatedObj,
                                };
                            });

                            dispatch(setBankList(bData));
                        });
                    });
                });
            }
        } catch (error) {
            //@ts-ignore
            throw new Error(error);
        }
    };
    return { fetchBanks };
};

export default useSyncBanks;
