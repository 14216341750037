export const BANK_FEES = 'Bank Fees';
export const COMMUNITY = 'Community';
export const FOOD_ADN_DRINK = 'Food and Drink';
export const BAR = 'Bar';
export const WINE_BAR = 'Wine Bar';
export const SPORT_BAR = 'Sports Bar';
export const HOTEL_LOUNGE = 'Hotel Lounge';
export const BREWERIES = 'Breweries';
export const COFFEE_SHOP = 'Coffee Shop';
export const TRAVEL = 'Travel';
export const BOAT = 'Boat';
export const GAS_STATIONS = 'Gas Stations';
export const HOME_IMPROVEMENT = 'Home Improvement';
export const COMPUTERS = 'Computers';
export const OUTDOORS = 'Outdoors';
export const SHOPS = 'Shops';
export const INTERNET_SERVICES = 'Internet Services';
export const INTERNET = 'Internet';
export const AUTOMOTIVE = 'Automotive';
export const PAYMENT = 'Payment';
export const TRANSFER = 'Transfer';
export const RECREATION = 'Recreation';
export const TAXI = 'Taxi';
export const CREDIT_CARD = 'Credit Card';
export const SETTLED = 'Settled'; // This is a settled category
export const MANUAL = 'Manual';
export const TOTAL_SPENT = 'TOTAL_SPENT';
