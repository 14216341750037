import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonModal } from '@ionic/react';
import useModal from '../../hooks/useModal';
import { TransactionInterface } from '../../types/TransactionInterface';
import TransactionView from '../../components/transaction-view/TransactionView';
import { RootState } from '../../redux/store';
import { Redirect } from 'react-router';
import { GroupInterface } from '../../types/GroupInterface';
import UpdateTransaction from '../../components/expense/UpdateTransaction';
import { setMembers } from '../../redux/slices/expenseFormSlice';

const TransactionDetail = ({
    id,
    closeModal,
    firstModalRef,
}: {
    id: string;
    closeModal: () => void;
    firstModalRef: any;
}) => {
    const [transaction, setTransaction] = useState<TransactionInterface | null>(
        null
    );
    const [hasTnx, setHasTnx] = useState<boolean | null>(null);
    const [group, setGroup] = useState<GroupInterface>();

    const { setShowModal, showModal } = useModal();

    const {
        auth: { authUser },
        tnx: { transactions },
        group: { groupList },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const goBack = () => closeModal();

    useEffect(() => {
        const tnx = transactions.find((t: TransactionInterface) => t.id === id);
        setHasTnx(!!tnx);
        if (!!tnx) {
            if (tnx.assignedGroupId) {
                const grp = groupList.find(
                    (g: GroupInterface) => g.id === tnx.assignedGroupId
                );
                if (!!grp) {
                    setGroup(grp);
                }
            }
            setTransaction(tnx);
        }
    }, [id]);

    useEffect(() => {
        if (!group && authUser) {
            const { id, name, email } = authUser;
            dispatch(
                setMembers([
                    {
                        id,
                        name,
                        email,
                    },
                ])
            );
        }
    }, [group, authUser]);

    const onClickGroupItem = (grp: GroupInterface) => {
        setGroup(grp);
        setShowModal(false);
    };

    if (hasTnx === false) {
        return <Redirect to="/transactions" />;
    }

    if (!transaction || !authUser) {
        return <></>;
    }

    return (
        <>
            {transaction && (
                <UpdateTransaction
                    transaction={transaction}
                    openGroups={() => setShowModal(true)}
                    group={group}
                    onSuccessSave={goBack}
                />
            )}
            {showModal && (
                <IonModal
                    isOpen={showModal}
                    swipeToClose={true}
                    cssClass="tnx-group-assign-modal"
                    onDidDismiss={() => setShowModal(false)}
                    presentingElement={firstModalRef.current}
                >
                    {showModal && (
                        <TransactionView
                            showNativeHeader={true}
                            onClickGroupItem={onClickGroupItem}
                            selectedTransaction={transaction}
                            closeModal={() => setShowModal(false)}
                        />
                    )}
                </IonModal>
            )}
        </>
    );
};

export default TransactionDetail;
