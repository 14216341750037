import React, { useState } from 'react';
import {
    IonContent,
    IonItem,
    IonAvatar,
    IonLabel,
    IonSkeletonText,
    IonListHeader,
    IonIcon,
    IonThumbnail,
    IonList,
} from '@ionic/react';
import './index.css';

const GroupSkeleton = () => {
    return (
        <>
            <div className="skelton-container">
                <div
                    className="group-created-date-wrapper"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // marginTop: 10,
                    }}
                >
                    <IonSkeletonText
                        animated
                        slot="center"
                        style={{
                            width: '50%',
                            height: '9px',
                            textAlign: 'center',
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IonSkeletonText
                        className="skelton-circle"
                        animated
                        style={{
                            width: '56.60px',
                            height: '56.60px',
                            marginTop: 10,
                        }}
                    />
                </div>

                <IonSkeletonText
                    animated
                    style={{ width: '100%', height: '100px', marginTop: 30 }}
                />
            </div>
            <div style={{ marginTop: 40 }}>
                <IonList>
                    {[...Array(8)].map((i, key) => (
                        <IonItem key={key}>
                            <IonThumbnail slot="start">
                                <IonSkeletonText
                                    animated
                                    className="skelton-circle"
                                />
                            </IonThumbnail>
                            <IonLabel>
                                <h3>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '50%' }}
                                    />
                                </h3>
                                <p>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '80%' }}
                                    />
                                </p>
                                <p>
                                    <IonSkeletonText
                                        animated
                                        style={{ width: '60%' }}
                                    />
                                </p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </div>
        </>
    );
};
export default GroupSkeleton;
