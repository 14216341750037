import { Purchases } from '@ionic-native/purchases';
import * as Sentry from '@sentry/browser';

const usePurchases = () => {
    const checkUser = async () => {
        try {
            const pInfo = await Purchases.getPurchaserInfo();

            if (typeof pInfo.entitlements.active['Pro'] !== 'undefined') {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            Sentry.captureMessage('Error with Purchases.getPurchaserInfo');
            return false;
        }
    };
    const purchaseInfo = async (callBack?: () => void) => {
        try {
            const offerings = await Purchases.getOfferings();

            if (offerings.current !== null) {
                const { purchaserInfo } = await Purchases.purchasePackage(
                    offerings.current.availablePackages[0]
                );
                if (
                    typeof purchaserInfo.entitlements.active['Pro'] !==
                    'undefined'
                ) {
                    if (!!callBack) {
                        callBack();
                    }
                }
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const getPurchaseInfo = async () => {
        return Purchases.getPurchaserInfo();
    };

    const restorePurchase = async () => {
        Purchases.restoreTransactions()
            .then((data) => {
                alert('Done');
            })
            .catch((e) => {
                alert('Error on restore');
            });
    };

    /**
     * Logout
     */
    const resetPurchase = async (email: string | undefined) => {
        try {
            await Purchases.logOut();
            Sentry.captureMessage(`RevenueCat logout email=${email}`);
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    /**
     * Login
     */
    const loginPurchase = async (
        id: string,
        email: string,
        name: string | null
    ) => {
        try {
            await Purchases.logIn(id);
            await Purchases.setEmail(email);
            await Purchases.setDisplayName(name);
            Sentry.captureMessage(
                `Save user info to RevenueCat id=${id} email=${email} name=${name}`
            );
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    return {
        purchaseInfo,
        restorePurchase,
        getPurchaseInfo,
        resetPurchase,
        loginPurchase,
        checkUser,
    };
};

export default usePurchases;

// const dd = {
//     error: {
//         code: 1,
//         message: 'Purchase was cancelled.',
//         readable_error_code: 'PURCHASE_CANCELLED',
//         underlyingErrorMessage:
//             'The operation couldn’t be completed. (SKErrorDomain error 2.)',
//         readableErrorCode: 'PURCHASE_CANCELLED',
//     },
//     userCancelled: true,
// };
