import * as React from 'react';

interface SettingTabSvgInterface extends React.SVGProps<SVGSVGElement> {
    active: number;
    pro: boolean;
}

function SettingTabSvg(props: SettingTabSvgInterface) {
    if (props.pro) {
        if (props.active) {
            return (
                <svg
                    width={33}
                    height={26}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    {...props}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.536 17.465c.168.168.323.347.464.535a9.956 9.956 0 0 0 2-6c0-5.523-4.477-10-10-10S2 6.477 2 12c0 2.251.744 4.33 2 6a4.995 4.995 0 0 1 4-2h8a5 5 0 0 1 3.536 1.465ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-20a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
                        fill="url(#a)"
                    />
                    <path
                        d="M14 21.5a4.5 4.5 0 0 1 4.5-4.5h10a4.5 4.5 0 1 1 0 9h-10a4.5 4.5 0 0 1-4.5-4.5Z"
                        fill="#162398"
                    />
                    <path
                        d="M17.32 23.5h1.075v-1.24h.726c.944 0 1.553-.58 1.553-1.485v-.005c0-.906-.61-1.497-1.553-1.497H17.32V23.5Zm1.543-3.404c.454 0 .724.234.724.674v.005c0 .44-.27.677-.724.677h-.468v-1.356h.468Zm2.788 3.404h1.076v-1.456h.547l.727 1.456h1.207l-.847-1.617c.44-.19.721-.66.721-1.201v-.006c0-.897-.577-1.403-1.6-1.403h-1.83V23.5Zm1.076-2.235v-1.181h.63c.377 0 .63.234.63.589v.006c0 .357-.244.586-.625.586h-.635Zm5.366 2.311c1.289 0 2.089-.84 2.089-2.188v-.006c0-1.345-.803-2.186-2.09-2.186-1.277 0-2.085.841-2.085 2.186v.006c0 1.347.803 2.188 2.086 2.188Zm0-.89c-.604 0-.99-.496-.99-1.298v-.006c0-.803.39-1.295.99-1.295.606 0 .996.495.996 1.295v.006c0 .794-.381 1.297-.996 1.297Z"
                        fill="#fff"
                    />
                    <defs>
                        <linearGradient
                            id="a"
                            x1={12.032}
                            y1={2.128}
                            x2={11.576}
                            y2={30.493}
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#CB3896" />
                            <stop offset={0.967} stopColor="#162398" />
                        </linearGradient>
                    </defs>
                </svg>
            );
        }
        return (
            <svg
                width={33}
                height={26}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22c2.53 0 4.842-.94 6.604-2.49A2.997 2.997 0 0 0 16 18H8a3 3 0 0 0-2.604 1.51A9.962 9.962 0 0 0 12 22Zm7.536-4.535c.168.168.323.347.464.535a9.956 9.956 0 0 0 2-6c0-5.523-4.477-10-10-10S2 6.477 2 12c0 2.251.744 4.33 2 6a4.995 4.995 0 0 1 4-2h8a5 5 0 0 1 3.536 1.465ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM9 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
                    fill="#B0B4C2"
                />
                <path
                    d="M14 21.5a4.5 4.5 0 0 1 4.5-4.5h10a4.5 4.5 0 1 1 0 9h-10a4.5 4.5 0 0 1-4.5-4.5Z"
                    fill="#162398"
                />
                <path
                    d="M17.32 23.5h1.075v-1.24h.726c.944 0 1.553-.58 1.553-1.485v-.005c0-.906-.61-1.497-1.553-1.497H17.32V23.5Zm1.543-3.404c.454 0 .724.234.724.674v.005c0 .44-.27.677-.724.677h-.468v-1.356h.468Zm2.788 3.404h1.076v-1.456h.547l.727 1.456h1.207l-.847-1.617c.44-.19.721-.66.721-1.201v-.006c0-.897-.577-1.403-1.6-1.403h-1.83V23.5Zm1.076-2.235v-1.181h.63c.377 0 .63.234.63.589v.006c0 .357-.244.586-.625.586h-.635Zm5.366 2.311c1.289 0 2.089-.84 2.089-2.188v-.006c0-1.345-.803-2.186-2.09-2.186-1.277 0-2.085.841-2.085 2.186v.006c0 1.347.803 2.188 2.086 2.188Zm0-.89c-.604 0-.99-.496-.99-1.298v-.006c0-.803.39-1.295.99-1.295.606 0 .996.495.996 1.295v.006c0 .794-.381 1.297-.996 1.297Z"
                    fill="#fff"
                />
            </svg>
        );
    }

    if (props.active) {
        return (
            <svg
                width={24}
                height={24}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.536 17.465c.168.168.323.347.464.535a9.956 9.956 0 0 0 2-6c0-5.523-4.477-10-10-10S2 6.477 2 12c0 2.251.744 4.33 2 6a4.995 4.995 0 0 1 4-2h8a5 5 0 0 1 3.536 1.465ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm0-20a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
                    fill="#1868DF"
                />
            </svg>
        );
    }
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22c2.53 0 4.842-.94 6.604-2.49A2.997 2.997 0 0 0 16 18H8a3 3 0 0 0-2.604 1.51A9.962 9.962 0 0 0 12 22Zm7.536-4.535c.168.168.323.347.464.535a9.956 9.956 0 0 0 2-6c0-5.523-4.477-10-10-10S2 6.477 2 12c0 2.251.744 4.33 2 6a4.995 4.995 0 0 1 4-2h8a5 5 0 0 1 3.536 1.465ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM9 9a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
                fill="#B0B4C2"
            />
        </svg>
    );
}

export default SettingTabSvg;
