import currency from 'currency.js';

const useSplit = () => {
    const equallySplit = (members: Array<any>, amount: any) => {
        const currencyValues = currency(amount).distribute(members.length);
        return members.map((i, index) => {
            return {
                ...i,
                value: Number(currencyValues[index].value),
            };
        });
    };
    return { equallySplit };
};

export default useSplit;
