import React, { useEffect, useState } from 'react';
import { IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import TransactionTabSvg from '../svg/TransactionTabSvg';
import GroupTabSvg from '../svg/GroupTabSvg';
import { useLocation } from 'react-router';
import SettingTabSvg from '../svg/SettingTabSvg';
import HelpSvg from '../svg/HelpSvg';
import useTrack from '../hooks/useTrack';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { isUserSkippedPro } from '../utils/common';
import usePurchases from '../hooks/usePurchases';
import { fetchUser } from '../firebase/UserCollection';
import { setAuthUser } from '../redux/slices/authSlice';

interface ITabBar {
    children: React.ReactNode;
    isLoggedIn: boolean | null;
}

const TabBar = ({ children, isLoggedIn }: ITabBar) => {
    const {
        auth: { authUser },
        tnx: { transactions },
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const [isPro, setIsPro] = useState(false);

    const { checkUser } = usePurchases();

    useEffect(() => {
        checkUser().then((res) => {
            setIsPro(res);
        });
    }, []);

    const { pathname } = useLocation();
    if (!isLoggedIn || pathname === '/help') {
        return <>{children}</>;
    }

    if (
        !isUserSkippedPro() &&
        !transactions.length &&
        pathname === '/transactions'
    ) {
        return <>{children}</>;
    }

    const { hitAnalytics } = useTrack();

    const trackTabChange = (e: CustomEvent) => {
        if (authUser && (!authUser?.email || !authUser.name)) {
            fetchUser(authUser?.id).then((doc) => {
                const user: any = {
                    name: doc.data()?.name,
                    email: doc.data()?.email,
                    defaultGroup: doc.data()?.defaultGroup ?? null,
                    phoneNumber: doc.data()?.phoneNumber ?? null,
                    id: doc.id,
                };
                dispatch(setAuthUser(user));
            });
        }

        checkUser().then((res) => {
            setIsPro(res);
        });

        if (authUser) {
            const trackingData = { ...authUser, ...e.detail };
            hitAnalytics('Tab bar', trackingData);
        }
    };

    return (
        <IonTabs onIonTabsDidChange={trackTabChange}>
            {children}
            <IonTabBar slot="bottom" className="custom-ion-tab-bar">
                <IonTabButton tab="transactions" href="/transactions">
                    <TransactionTabSvg
                        active={pathname === '/transactions' ? 1 : 0}
                    />
                    <IonLabel className="custom-ion-tab-bar-label">
                        Expenses
                    </IonLabel>
                </IonTabButton>
                <IonTabButton tab="groups" href="/groups">
                    <GroupTabSvg
                        active={
                            pathname === '/groups' ||
                            pathname.split('/')[1] === 'group'
                                ? 1
                                : 0
                        }
                    />
                    <IonLabel
                        color={
                            !!['groups', 'group'].find(
                                (id) => id === pathname.split('/')[1]
                            )
                                ? 'primary'
                                : ''
                        }
                    >
                        Groups
                    </IonLabel>
                </IonTabButton>
                <IonTabButton tab="settings" href="/settings">
                    <SettingTabSvg
                        pro={isPro}
                        active={pathname === '/settings' ? 1 : 0}
                    />
                    <IonLabel>Account</IonLabel>
                </IonTabButton>
                <IonTabButton tab="help" href="/help">
                    <HelpSvg active={pathname === '/help' ? 1 : 0} />
                    <IonLabel>Help</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};
export default TabBar;
