import * as React from 'react';
import { SVGProps } from 'react';

const Hart = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#a)" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.882 29.428a1.264 1.264 0 0 1-1.79 0l-7.964-7.912c-2.037-1.944-2.037-5.101 0-7.058 2.023-1.944 5.318-1.944 7.354 0L20 16.216l1.518-1.758c2.036-1.944 5.318-1.944 7.355 0 2.036 1.944 2.036 5.102 0 7.058l-7.99 7.913Zm-.026-10.599a1.182 1.182 0 0 1-1.738 0l-2.478-2.613a2.663 2.663 0 0 0-3.67 0 2.43 2.43 0 0 0 0 3.53L20 26.79l7.03-7.046a2.43 2.43 0 0 0 0-3.529 2.663 2.663 0 0 0-3.67 0l-2.504 2.613Z"
            fill="url(#b)"
        />
        <defs>
            <linearGradient
                id="a"
                x1={20.053}
                y1={3.547}
                x2={20.042}
                y2={40}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A62FD2" />
                <stop offset={0.526} stopColor="#7B61FF" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={20.028}
                y1={14.49}
                x2={20.024}
                y2={29.8}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A62FD2" />
                <stop offset={0.526} stopColor="#7B61FF" />
            </linearGradient>
        </defs>
    </svg>
);

export default Hart;
