import React, { useEffect, useRef, useState } from 'react';
import {
    IonPage,
    IonContent,
    IonModal,
    IonPopover,
    IonButtons,
    IonButton,
    IonFab,
    IonFabButton,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import Header from '../../components/Header';
import TransactionView from '../../components/transaction-view/TransactionView';
import {
    FilterRefData,
    TransactionInterface,
} from '../../types/TransactionInterface';
import TransactionList from '../../components/transactions/TransactionList';
import { RootState } from '../../redux/store';
import useSyncGroups from '../../hooks/useSyncGroups';
import TnxEmpty from './TnxEmpty';
import usePageViews from '../../hooks/usePageViews';
import FilterSvg from '../../svg/FilterSvg';
import TransactionFilterMenu from '../../components/transactions/TransactionFilterMenu';
import useSyncBanks from '../../hooks/useSyncBanks';
import useModal from '../../hooks/useModal';
import {
    GROUP_LIST_IN_TNX,
    HOW_TNX_WORKS,
    HOW_TNX_WORKS_SKIPPED,
    TNX_DETAILS_IN_TNX,
    GROUP_LIST,
} from '../../utils/constant';
import TransactionDetail from './TransactionDetail';
import useTrack from '../../hooks/useTrack';
import './index.css';
import TxnScreenWorks from '../explainers/TxnScreenWorks';
import UpgradeAlert from '../../components/transactions/UpgradeAlert';
import AddMemberImage from '../../assets/add-member.png';
import SelectGroup from '../../components/group/SelectGroup';

interface TransactionsPageProps {
    router: HTMLIonRouterOutletElement | null;
}

const Transactions: React.FC<TransactionsPageProps> = ({ router }) => {
    const [
        selectedTransaction,
        setSelectedTransaction,
    ] = useState<TransactionInterface | null>(null);
    const { showModal, setShowModal, modalType, setModalType } = useModal();
    const {
        auth: { authUser },
        tnx: { isEmpty, isFetching },
    } = useSelector((state: RootState) => state);

    const firstModalRef = useRef(null);

    usePageViews();
    const { fetchBanks } = useSyncBanks();

    const { syncGroups } = useSyncGroups();
    const [popoverState, setShowPopover] = useState({
        showPopover: false,
        event: undefined,
    });
    const [filteringData, setFilteringData] = useState<Array<FilterRefData>>(
        []
    );

    const { hitAnalytics } = useTrack();
    useEffect(() => {
        const showModal = !localStorage.getItem(HOW_TNX_WORKS_SKIPPED);
        if (isEmpty === false && showModal) {
            setTimeout(() => {
                setModalType(HOW_TNX_WORKS);
                setShowModal(true);
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (authUser?.id) {
            syncGroups();
            fetchBanks();
        }
    }, [authUser]);

    if (isEmpty === true) {
        return (
            <TnxEmpty
                firstModalRef={firstModalRef}
                router={router || undefined}
            />
        );
    }

    const renderModal = () => {
        switch (modalType) {
            case GROUP_LIST_IN_TNX:
                return selectedTransaction ? (
                    <TransactionView
                        selectedTransaction={selectedTransaction}
                        closeModal={() => setShowModal(false)}
                    />
                ) : (
                    <></>
                );
            case TNX_DETAILS_IN_TNX:
                if (authUser) {
                    hitAnalytics('OPEN_DETAIL_SCREEN', {
                        id: authUser.id,
                        email: authUser.email,
                    });
                }
                return selectedTransaction ? (
                    <TransactionDetail
                        id={selectedTransaction.id}
                        closeModal={() => setShowModal(false)}
                        firstModalRef={firstModalRef}
                    />
                ) : (
                    <></>
                );
            case HOW_TNX_WORKS:
                return (
                    <TxnScreenWorks closeModal={() => setShowModal(false)} />
                );
            case GROUP_LIST:
                return (
                    <SelectGroup
                        closeModal={() => setShowModal(false)}
                        screen={'TRANSACTIONS_SCREEN'}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <IonPage>
            <Header
                title="Expenses"
                rightComponent={
                    <IonButtons slot={'end'}>
                        <IonButton
                            onClick={(e: any) => {
                                e.persist();

                                hitAnalytics('clickFilter', {
                                    id: authUser?.id,
                                });
                                setShowPopover({ showPopover: true, event: e });
                            }}
                        >
                            <FilterSvg />
                        </IonButton>
                    </IonButtons>
                }
            />
            <IonPopover
                cssClass="tnx-filter-popover"
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() =>
                    setShowPopover({ showPopover: false, event: undefined })
                }
            >
                <TransactionFilterMenu
                    setFilteringData={(data: Array<FilterRefData>) =>
                        setFilteringData(() => [...data])
                    }
                    filteringData={filteringData}
                />
            </IonPopover>
            <IonContent fullscreen>
                <UpgradeAlert
                    firstModalRef={firstModalRef}
                    router={router || undefined}
                />
                <TransactionList
                    showLoading={isFetching}
                    setSelectedTransaction={setSelectedTransaction}
                    setShowModal={(type: number) => {
                        setShowModal(true);
                        setModalType(type);
                    }}
                    filteringData={filteringData}
                />
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton
                        className="add-memeber-fab-button"
                        size="small"
                        onClick={() => {
                            setModalType(GROUP_LIST);
                            setShowModal(true);
                        }}
                    >
                        <img
                            className="add-memeber-image"
                            src={AddMemberImage}
                        />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            {showModal && (
                <IonModal
                    ref={firstModalRef}
                    isOpen={showModal}
                    cssClass={
                        modalType === GROUP_LIST_IN_TNX
                            ? 'tnx-group-assign-modal'
                            : modalType === GROUP_LIST
                            ? 'select-group-modal'
                            : ''
                    }
                    onDidDismiss={() => setShowModal(false)}
                    presentingElement={router || undefined}
                    swipeToClose={true}
                >
                    {showModal && renderModal()}
                </IonModal>
            )}
        </IonPage>
    );
};

export default Transactions;
