import * as React from 'react';
import { SVGProps } from 'react';

const SuitCaseSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#pa)" />
        <path
            d="M29.077 14.282h-5.265V11.65a1.32 1.32 0 0 0-1.316-1.317H17.23a1.32 1.32 0 0 0-1.317 1.317v2.632H10.65A1.32 1.32 0 0 0 9.332 15.6v14.48a1.32 1.32 0 0 0 1.316 1.316h18.428a1.32 1.32 0 0 0 1.316-1.317v-14.48a1.32 1.32 0 0 0-1.316-1.316Zm-10.53-1.316h2.633v1.316h-2.633v-1.316Zm-6.581 15.796V16.915h1.316v11.847h-1.316Zm3.949 0V16.915h7.897v11.847h-7.898Zm11.846 0h-1.317V16.915h1.317v11.847Z"
            fill="url(#pb)"
        />
        <defs>
            <linearGradient
                id="pa"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={0.625} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="pb"
                x1={19.891}
                y1={12.201}
                x2={19.969}
                y2={31.396}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={0.625} stopColor="#FDC40C" />
            </linearGradient>
        </defs>
    </svg>
);

export default SuitCaseSvg;
