import { FC, useEffect, useState } from 'react';
import {
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonDatetime,
    IonButton,
    IonSpinner,
    useIonToast,
} from '@ionic/react';
import Header from '../Header';
import { closeCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import { DATE_FORMAT, TODAY } from '../../utils/constant';
import {
    saveTransaction,
    updateTransaction,
} from '../../firebase/TransactionCollection';
import { TransactionsType } from '../../types/enum';
import { SettlementInterface } from '../../types/GroupInterface';
import IonDecimalInput from '../IonDecimalInput';
import HeaderActionButton from '../HeaderActionButton';
import currency from 'currency.js';
import TnxDelete from './TnxDelete';

interface SettleFormInterface {
    closeModal: () => void;
    settlement: SettlementInterface;
    fetchTransactions: () => void;
    closeParentModal: () => void;
}

const SettleForm: FC<SettleFormInterface> = ({
    closeModal,
    settlement,
    fetchTransactions,
    closeParentModal,
}) => {
    const [amount, setAmount] = useState<number>(0);
    const [date, setDate] = useState<string>(TODAY);
    const [isLoading, setLoading] = useState(false);
    const [present, dismiss] = useIonToast();

    useEffect(() => {
        setAmount(settlement.amount);
        if (settlement?.date) {
            setDate(settlement.date);
        }
        return () => {
            setAmount(0);
            setDate(TODAY);
        };
    }, [settlement]);

    const onSave = () => {
        setLoading(true);

        if (settlement.id) {
            updateTransaction(settlement.id, {
                name: `${settlement.name} ${currency(amount).format()}`,
                amount: Number(amount),
                date: moment(date).format(DATE_FORMAT),
            })
                .then(() => {
                    present({
                        color: 'success',
                        duration: 5000,
                        buttons: [
                            {
                                icon: closeCircleOutline,
                                handler: () => dismiss(),
                            },
                        ],
                        message: 'The transaction was successfully updated',
                    });
                    closeModal();
                })
                .catch(onErrorSave);
        } else {
            saveTransaction({
                name: `${settlement.name} ${currency(amount).format()}`,
                amount: Number(amount),
                date: moment(date).format(DATE_FORMAT),
                createdBy: settlement.createdBy,
                assignedGroupId: settlement.assignedGroupId,
                type: TransactionsType.Settled,
                involvedUsers: settlement.involvedUsers,
                assignedDate: moment().format(DATE_FORMAT),
                groupColor: settlement.groupColor,
                iso_currency_code: 'USD',
                removed: false,
                missingSplit: false,
            })
                .then(() => {
                    present({
                        color: 'success',
                        duration: 5000,
                        buttons: [
                            {
                                icon: closeCircleOutline,
                                handler: () => dismiss(),
                            },
                        ],
                        message: 'The transaction was successfully created',
                    });
                    fetchTransactions();
                    closeParentModal();
                })
                .catch(onErrorSave);
        }
    };

    const onErrorSave = () => {
        setLoading(false);
    };

    return (
        <IonPage>
            <Header
                color={settlement.groupColor ?? ''}
                title={settlement.groupName}
                leftComponent={
                    <HeaderActionButton
                        slot="start"
                        onClick={() => closeModal()}
                        title="Cancel"
                    />
                }
            />
            <IonContent fullscreen>
                <h3 style={{ paddingLeft: 20 }}>{settlement.name}</h3>
                <IonItem>
                    <IonLabel position="floating">Amount</IonLabel>
                    <IonDecimalInput
                        name="amount"
                        // type="number"
                        value={amount}
                        onIonChange={(e: CustomEvent) =>
                            setAmount(e.detail.value)
                        }
                    />
                </IonItem>
                <IonItem>
                    <IonLabel>Date</IonLabel>
                    <IonDatetime
                        displayFormat="DDDD MMM D, YYYY"
                        min={moment().format('YYYY')}
                        max={moment().format(DATE_FORMAT)}
                        value={date}
                        onIonChange={(e) => {
                            setDate(() =>
                                moment(e.detail.value).format(DATE_FORMAT)
                            );
                        }}
                    />
                </IonItem>

                <IonButton
                    className="form-button"
                    onClick={() => onSave()}
                    size="default"
                    expand="block"
                    disabled={!amount || !date || isLoading}
                >
                    {isLoading === true && (
                        <IonSpinner slot="start" name="lines-small" />
                    )}
                    Save
                </IonButton>
                {settlement.id && (
                    <TnxDelete close={closeModal} settlement={settlement} />
                )}
            </IonContent>
        </IonPage>
    );
};

export default SettleForm;
