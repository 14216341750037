import { useEffect } from 'react';
import {
    IonAvatar,
    IonButton,
    IonContent,
    IonDatetime,
    IonInput,
    IonItem,
    useIonAlert,
    IonLabel,
    IonPage,
    IonSpinner,
    useIonToast,
} from '@ionic/react';
import moment from 'moment';
import Header from '../Header';
import Payer from './Payer';
import Split from './Split';
import useLoading from '../../hooks/useLoading';
import { DATE_FORMAT, groupIcons } from '../../utils/constant';
import { TransactionsType } from '../../types/enum';
import { closeCircleOutline } from 'ionicons/icons';
import { TransactionFromInterface } from './TransactionFromInterface';
import CoffeeCupSvg from '../../svg/CoffeeCupSvg';
import CalenderSvg from '../../svg/CalenderSvg';
import useAssignGroup from '../../hooks/useAssignGroup';
import { colorMap } from '../../utils/common';
import HeaderActionButton from '../HeaderActionButton';
import TnxAmountField from './TnxAmountField';
import { updateGroup } from '../../firebase/GroupCollection';
import FormGroupIconSvg from '../../svg/FormGroupIconSvg';
import MoreInfo from './MoreInfo';
import '../../components/transaction-view/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
    setName,
    setAmount,
    setDate,
    setExactSplittedMembers,
    clearExpense,
    setEquallySplittedMembers,
    setMemo,
} from '../../redux/slices/expenseFormSlice';
import useSplit from '../../hooks/useSplit';

const TransactionForm = ({
    closeModal,
    fetchTransactions,
    group,
    saveTransaction,
    transaction,
    openGroups,
}: TransactionFromInterface) => {
    const { isLoading, startLoading, stopLoading } = useLoading();
    const [present, dismiss] = useIonToast();
    const [presentAlert] = useIonAlert();
    const { removeFromGroup } = useAssignGroup();
    const { equallySplit } = useSplit();

    const {
        name,
        amount,
        date,
        members,
        paidBy,
        groupId,
        tnxType,
        equallySplittedMembers,
        memo,
    } = useSelector((state: RootState) => state.expenseForm);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearExpense());
        };
    }, []);

    const onSaveTnx = () => {
        if (saveTransaction && validForm()) {
            startLoading();
            saveTransaction()
                .then(() => {
                    present({
                        color: 'success',
                        duration: 5000,
                        buttons: [
                            {
                                icon: closeCircleOutline,
                                handler: () => dismiss(),
                            },
                        ],
                        message: 'The transaction was successfully created',
                    });
                    if (group) {
                        updateGroup(group.id, {});
                    }
                    stopLoading();
                    fetchTransactions();
                    closeModal();
                })
                .catch(() => {
                    stopLoading();
                });
        }
    };

    const isSubmitBtnDisable = () => {
        if (!isLoading) {
            return false;
        }
        return true;
    };

    const getPaidBy = () => {
        return members.find(({ id }) => id === paidBy)?.name;
    };

    const editable = tnxType !== TransactionsType.Bank;

    const showDisableMessage = () => {
        presentAlert({
            header: 'Automatic Transaction',
            message: `This expense was automatically imported from an account owned by ${getPaidBy()}`,
            buttons: [
                {
                    text: 'Got it',
                },
            ],
            onDidDismiss: (e) => {},
        });
    };

    const validForm = () => {
        if (!groupId) {
            present({
                color: 'warning',
                buttons: [{ text: 'Hide', handler: () => dismiss() }],
                message: 'No group selected',
                duration: 2000,
            });
            return false;
        } else if (!name) {
            present({
                color: 'warning',
                buttons: [{ text: 'Hide', handler: () => dismiss() }],
                message: 'Name is required',
                duration: 2000,
            });
            return false;
        } else if (!amount) {
            present({
                color: 'warning',
                buttons: [{ text: 'Hide', handler: () => dismiss() }],
                message: 'Amount is required',
                duration: 2000,
            });
            return false;
        } else if (!paidBy) {
            present({
                color: 'warning',
                buttons: [{ text: 'Hide', handler: () => dismiss() }],
                message: 'Paid by is required',
                duration: 2000,
            });
            return false;
        }
        return true;
    };

    var item: any = <FormGroupIconSvg />;
    if (group?.groupIcon) {
        item = groupIcons({ width: 29, height: 29 }).find(
            ({ id }) => id === group.groupIcon
        )?.icon;
    }

    return (
        <IonPage>
            <Header
                title="Split Expense"
                leftComponent={
                    <HeaderActionButton
                        slot="start"
                        title="Cancel"
                        color="primary"
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                {transaction?.missingSplit && transaction.groupSplittingValues && (
                    <div className="missing-transaction-wrapper">
                        <IonLabel className="missing-transaction">
                            ⚠️ This previously pending transaction has been
                            removed by the bank. It may have been replaced by an
                            updated transaction you may need to split. Please
                            check expenses tab.
                        </IonLabel>
                        <br></br>
                        <br></br>
                        <IonLabel
                            className="missing-transaction-link"
                            onClick={() => {
                                removeFromGroup(transaction.id);
                                fetchTransactions();
                                closeModal();
                            }}
                        >
                            Remove expense from group to resolve
                        </IonLabel>
                    </div>
                )}

                <div className="list-body">
                    <IonItem
                        onClick={() => {
                            openGroups && openGroups();
                        }}
                        className="list-item"
                    >
                        <IonAvatar slot="start">
                            <div
                                className={`group-form-icon-ellipse2 ${colorMap(
                                    group?.groupColor
                                )}-linear-gradient-icon-bg`}
                            >
                                {item}
                            </div>
                        </IonAvatar>
                        <IonLabel color="primary" className="list-title2">
                            {group?.name ?? 'No group selected'}
                        </IonLabel>
                    </IonItem>

                    <IonItem
                        onClick={() => {
                            if (!editable) {
                                showDisableMessage();
                            }
                        }}
                        className="list-item"
                    >
                        <IonAvatar slot="start">
                            <CoffeeCupSvg />
                        </IonAvatar>
                        <IonInput
                            autocapitalize="on"
                            readonly={!editable}
                            className="list-item-input"
                            data-testid="expense-name"
                            value={name}
                            placeholder="Name expense"
                            onIonChange={(e: CustomEvent) =>
                                dispatch(setName(e.detail.value))
                            }
                        />
                    </IonItem>

                    <TnxAmountField
                        className="list-item-input"
                        data-testid="expense-amount"
                        type="number"
                        placeholder="Amount"
                        value={amount}
                        onIonChange={(e: CustomEvent) => {
                            dispatch(setAmount(e.detail.value));
                            dispatch(
                                setExactSplittedMembers([
                                    ...equallySplit(
                                        members,
                                        e.detail.value
                                    ).map(({ id, value }) => {
                                        return { id, value };
                                    }),
                                ])
                            );
                            dispatch(
                                setEquallySplittedMembers([
                                    ...equallySplit(
                                        equallySplittedMembers.length
                                            ? equallySplittedMembers
                                            : members,
                                        e.detail.value
                                    ).map(({ id, value }) => {
                                        return { id, value };
                                    }),
                                ])
                            );
                        }}
                        onPressRevert={(val) => {
                            dispatch(setAmount(Number(val)));
                        }}
                        readonly={!group?.name}
                    />
                    <IonItem
                        className="list-item"
                        onClick={() => {
                            if (!editable) {
                                showDisableMessage();
                            }
                        }}
                    >
                        <IonAvatar slot="start">
                            <CalenderSvg />
                        </IonAvatar>
                        <IonDatetime
                            data-testid="expense-date"
                            readonly={!editable}
                            className="custom-date-picker list-item-input"
                            displayFormat="MMM D, YYYY"
                            max={moment().format(DATE_FORMAT)}
                            value={date}
                            onIonChange={(e) => {
                                dispatch(
                                    setDate(
                                        moment(e.detail.value).format(
                                            DATE_FORMAT
                                        )
                                    )
                                );
                            }}
                        />
                    </IonItem>
                </div>
                {tnxType === TransactionsType.Bank && transaction && (
                    <MoreInfo transaction={transaction} />
                )}
                <div className="list-item-body2">
                    <>
                        <Payer
                            showDisableMessage={
                                !editable ? showDisableMessage : undefined
                            }
                        />
                        <Split disabled={!Number(amount) || !group?.name} />
                    </>
                    <IonItem>
                        <IonInput
                            autocapitalize="on"
                            value={memo}
                            placeholder="Memo (Optional)"
                            onIonChange={(e: CustomEvent) =>
                                dispatch(setMemo(e.detail.value))
                            }
                        />
                    </IonItem>
                </div>

                <div className="btn-wrapper">
                    <IonButton
                        data-testid="expense-form-submit-btn"
                        className="form-button add-tnx-from-btn"
                        onClick={onSaveTnx}
                        size="default"
                        color="primary"
                        expand="block"
                        disabled={isSubmitBtnDisable()}
                        fill={!group ? 'outline' : 'solid'}
                    >
                        {isLoading === true && (
                            <IonSpinner slot="start" name="lines-small" />
                        )}
                        Save
                    </IonButton>
                </div>
                {group && transaction && transaction.groupSplittingValues && (
                    <div className="delete-wrapper">
                        <IonLabel
                            onClick={() => {
                                removeFromGroup(transaction.id);
                                fetchTransactions();
                                closeModal();
                            }}
                            color="alert"
                        >
                            Remove expense from group
                        </IonLabel>
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default TransactionForm;
