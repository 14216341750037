import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserGroups } from '../firebase/GroupCollection';
import { setGroupList, setIsFetching } from '../redux/slices/groupSlice';
import { RootState } from '../redux/store';
import { shortByDate } from '../utils/common';
import { DATE_TIME_FORMAT } from '../utils/constant';
import useQuerySnapshot from './useQuerySnapshot';

const useSyncGroups = () => {
    const { authUser, defaultGroup } = useSelector(
        (state: RootState) => state.auth
    );
    const { querySnapshot } = useQuerySnapshot();
    const dispatch = useDispatch();

    const syncGroups = async () => {
        if (authUser) {
            var snapshot = await fetchUserGroups(authUser.id);

            if (snapshot.empty) {
                dispatch(setIsFetching(false));
                return;
            }

            querySnapshot(snapshot, (payload) => {
                const groupsPayload = payload.map((g) => {
                    if (!g.updatedAt) {
                        g.updatedAt = moment(g.created.toDate()).format(
                            DATE_TIME_FORMAT
                        );
                    } else {
                        g.updatedAt = moment(g.updatedAt.toDate()).format(
                            DATE_TIME_FORMAT
                        );
                    }
                    return {
                        ...g,
                        created: moment(g.created.toDate()).format(
                            DATE_TIME_FORMAT
                        ),
                    };
                });
                if (defaultGroup) {
                    const dGroup = groupsPayload.find(
                        ({ id }) => id === defaultGroup.id
                    );
                    const sottedGroups = shortByDate(
                        groupsPayload.filter(
                            ({ id }) => id !== defaultGroup.id
                        ),
                        'updatedAt'
                    );
                    const all = [dGroup, ...sottedGroups];

                    dispatch(setGroupList(all));
                    dispatch(setIsFetching(false));
                } else {
                    dispatch(
                        setGroupList(shortByDate(groupsPayload, 'updatedAt'))
                    );
                    dispatch(setIsFetching(false));
                }
            });
        }
    };

    return { syncGroups };
};

export default useSyncGroups;
