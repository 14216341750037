import * as React from 'react';
import { SVGProps } from 'react';

const Person = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.977 4.657c-1.459 0-2.665 1.22-2.665 2.754s1.206 2.754 2.665 2.754c1.458 0 2.664-1.22 2.664-2.754s-1.206-2.754-2.664-2.754Zm-4.44 2.754c0-2.5 1.974-4.552 4.44-4.552 2.465 0 4.44 2.051 4.44 4.552 0 2.5-1.975 4.551-4.44 4.551-2.466 0-4.44-2.05-4.44-4.551ZM5.28 15.155a4.386 4.386 0 0 1 3.144-1.338h7.105c1.182 0 2.313.483 3.144 1.338a4.61 4.61 0 0 1 1.296 3.214v1.826a.894.894 0 0 1-.888.899.894.894 0 0 1-.888-.9V18.37a2.8 2.8 0 0 0-.786-1.953 2.62 2.62 0 0 0-1.878-.801H8.424a2.62 2.62 0 0 0-1.878.801 2.8 2.8 0 0 0-.786 1.953v1.826a.894.894 0 0 1-.888.899.894.894 0 0 1-.888-.9V18.37a4.61 4.61 0 0 1 1.296-3.214Z"
            fill="url(#a)"
        />
        <defs>
            <linearGradient
                id="a"
                x1={11.998}
                y1={4.476}
                x2={12.075}
                y2={21.095}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={props.fill ? props.fill : '#B0B4C2'} />
                <stop
                    offset={0.638}
                    stopColor={props.fill ? props.fill : '#B0B4C2'}
                />
            </linearGradient>
        </defs>
    </svg>
);

export default Person;
