import { Share, ShareOptions } from '@capacitor/share';

const useNativeShare = () => {
    const onShare = async (data: ShareOptions) => {
        try {
            await Share.share(data);
        } catch (error) {
            console.log('Error', error);
        }
    };
    return {
        onShare,
    };
};

export default useNativeShare;
