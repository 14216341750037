import * as React from 'react';

function ForkKnifeSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.313 4.57c-.928 0-1.688.76-1.688 1.688v6.75h-1.687v-6.75c0-.928-.76-1.688-1.688-1.688-.928 0-1.687.76-1.687 1.688v6.75H7.875v-6.75c0-.928-.76-1.688-1.687-1.688-.929 0-1.688.76-1.688 1.688v8.438c0 .928.76 1.688 1.688 1.688h3.375v14.345c0 .473.37.844.843.844h1.688a.836.836 0 00.844-.844V16.384h3.375c.928 0 1.687-.76 1.687-1.688V6.258c0-.928-.759-1.688-1.687-1.688zM30.656 4.62c-3.325.423-5.906 3.258-5.906 6.7v3.376c0 2.498 1.366 4.641 3.375 5.823v10.21c0 .472.37.844.843.844h1.688a.836.836 0 00.844-.844V5.414c0-.473-.388-.844-.844-.793z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default ForkKnifeSvg;
