import * as React from 'react';

function GroupIconSVG(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={35}
            height={35}
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.473 322.109a97.211 97.211 0 0168.739-28.473h154.424a97.212 97.212 0 0197.212 97.212v38.607c0 11.045-8.954 20-20 20-11.045 0-20-8.955-20-20v-38.607a57.211 57.211 0 00-57.212-57.212H121.212A57.215 57.215 0 0064 390.848v38.607c0 11.045-8.954 20-20 20s-20-8.955-20-20v-38.607a97.212 97.212 0 0128.473-68.739zM198.425 102c-31.597 0-57.212 25.615-57.212 57.212 0 31.598 25.615 57.212 57.212 57.212 31.597 0 57.212-25.614 57.212-57.212 0-31.597-25.615-57.212-57.212-57.212zm-97.212 57.212c0-53.689 43.523-97.212 97.212-97.212s97.212 43.523 97.212 97.212-43.523 97.212-97.212 97.212-97.212-43.523-97.212-97.212zM391.389 311.548c2.752-10.697 13.656-17.138 24.353-14.385a97.235 97.235 0 0152.523 34.451 96.783 96.783 0 0120.402 59.351v38.49c0 11.045-8.954 20-20 20s-20-8.955-20-20v-38.469a56.783 56.783 0 00-11.975-34.813 57.234 57.234 0 00-30.918-20.272c-10.697-2.753-17.138-13.656-14.385-24.353zM314.174 79.923c2.746-10.7 13.646-17.146 24.345-14.4a96.994 96.994 0 0152.574 34.584 97.345 97.345 0 0120.362 59.588 97.345 97.345 0 01-20.362 59.588 96.994 96.994 0 01-52.574 34.583c-10.699 2.747-21.599-3.7-24.345-14.399-2.747-10.699 3.7-21.598 14.399-24.345a56.99 56.99 0 0030.891-20.326 57.342 57.342 0 0011.991-35.101 57.342 57.342 0 00-11.991-35.101 56.99 56.99 0 00-30.891-20.326c-10.699-2.747-17.146-13.647-14.399-24.345z"
                fill={props.fill ?? '#fff'}
            />
        </svg>
    );
}

export default GroupIconSVG;
