import { useState } from 'react';

const useModal = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<number | null>(null);
    return {
        showModal,
        modalType,
        setShowModal,
        setModalType,
    };
};

export default useModal;
