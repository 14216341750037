export interface UserCollectionInterface {
    id: string;
    name: string;
    email: string;
    defaultGroup?: string | null;
    phoneNumber?: string;
    useType?: UserType;
    expStatus?: number;
}

export interface UserInterface extends UserCollectionInterface {
    id: string;
}

export interface UsersReducerInterface {
    userList: Array<UserInterface>;
}

export enum UserType {
    Temporary = 'Temporary',
    Real = 'Real',
}
