import * as React from 'react';

function CarSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M33.25 12.5h-3.427l-.49-1.309a7.964 7.964 0 00-2.934-3.766A7.964 7.964 0 0021.842 6h-7.684a7.964 7.964 0 00-4.557 1.425 7.964 7.964 0 00-2.934 3.766l-.49 1.309H2.75a.75.75 0 00-.712.987l.5 1.5a.75.75 0 00.712.513h1.8l-.003.009A3.998 3.998 0 003 19v3c0 1.014.378 1.94 1 2.644V28.5A1.5 1.5 0 005.5 30h3a1.5 1.5 0 001.5-1.5V26h16v2.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-3.856c.622-.705 1-1.63 1-2.644v-3c0-1.5-.826-2.807-2.048-3.491l-.004-.009h1.801a.75.75 0 00.712-.513l.5-1.5a.75.75 0 00-.712-.987zm-22.837.095A4.019 4.019 0 0114.158 10h7.684a4.019 4.019 0 013.745 2.595L26.49 15H9.511l.902-2.405zM7.5 22.5a2 2 0 110-4c1.105 0 3 1.895 3 3s-1.895 1-3 1zm21 0c-1.105 0-3 .105-3-1s1.895-3 3-3a2 2 0 110 4z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default CarSvg;
