import moment from 'moment';
import Message from './Message';

const MessageListWrapper = ({ authUser, thread }: any) => {
    // console.log('thread', thread);
    const messages = thread.messages;

    const renderMessages = () => {
        let i = 0;

        let messageCount = messages.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = messages[i - 1];
            let current = messages[i];
            let next = messages[i + 1];
            let isMine = messages[i].userId === authUser.id;
            let currentMoment = current.created
                ? moment(current.created.toDate())
                : moment();
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                let previousMoment = moment(previous.created.toDate());
                let previousDuration = moment.duration(
                    currentMoment.diff(previousMoment)
                );
                prevBySameAuthor = previous.author === current.author;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                let nextMoment = moment(next.created.toDate());
                let nextDuration = moment.duration(
                    nextMoment.diff(currentMoment)
                );
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            }

            tempMessages.push(
                <Message
                    key={i}
                    isMine={isMine}
                    startsSequence={startsSequence}
                    endsSequence={endsSequence}
                    showTimestamp={showTimestamp}
                    data={messages[i]}
                />
            );

            i += 1;
        }

        return tempMessages;
    };
    return <>{messages && renderMessages()}</>;
};
export default MessageListWrapper;
