import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionsType } from '../../types/enum';
import {
    ExpenseFormInterface,
    ExpenseMember,
    SplitAmountsInterface,
} from '../../types/ExpenseFormInterface';
import { SplitMethodEnum } from '../../types/TransactionInterface';
import { TODAY } from '../../utils/constant';

const initialState: ExpenseFormInterface = {
    groupId: null,
    name: '',
    amount: '',
    date: TODAY,
    paidBy: '',
    splitMethod: SplitMethodEnum.Equally,
    members: [],
    equallySplittedMembers: [],
    exactSplittedMembers: [],
    tnxType: '',
    originalAmount: null,
    memo: '',
};

export const expenseForm = createSlice({
    name: 'expenseForm',
    initialState,
    reducers: {
        setGroupId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            state.groupId = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setAmount: (state, action: PayloadAction<number>) => {
            state.amount = action.payload;
        },
        setDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
        },
        setPaidBy: (state, action: PayloadAction<string>) => {
            state.paidBy = action.payload;
        },
        setSplitMethod: (state, action: PayloadAction<SplitMethodEnum>) => {
            state.splitMethod = action.payload;
        },
        setMembers: (state, action: PayloadAction<ExpenseMember[]>) => {
            state.members = action.payload;
        },
        setEquallySplittedMembers: (
            state,
            action: PayloadAction<SplitAmountsInterface[]>
        ) => {
            state.equallySplittedMembers = action.payload;
        },
        setExactSplittedMembers: (
            state,
            action: PayloadAction<SplitAmountsInterface[]>
        ) => {
            state.exactSplittedMembers = action.payload;
        },
        setTnxType: (state, action: PayloadAction<TransactionsType>) => {
            state.tnxType = action.payload;
        },
        setOriginalAmount: (state, action: PayloadAction<number | null>) => {
            state.originalAmount = action.payload;
        },
        setMemo: (state, action: PayloadAction<string>) => {
            state.memo = action.payload;
        },
        clearExpense: (state) => {
            state.groupId = null;
            state.name = '';
            state.amount = '';
            state.date = TODAY;
            state.paidBy = '';
            state.splitMethod = SplitMethodEnum.Equally;
            state.members = [];
            state.equallySplittedMembers = [];
            state.exactSplittedMembers = [];
            state.tnxType = '';
            state.originalAmount = null;
            state.memo = '';
        },
    },
});

export const {
    setGroupId,
    setName,
    setAmount,
    setDate,
    setPaidBy,
    setSplitMethod,
    setMembers,
    setEquallySplittedMembers,
    setExactSplittedMembers,
    clearExpense,
    setOriginalAmount,
    setTnxType,
    setMemo,
} = expenseForm.actions;

export default expenseForm.reducer;
