import moment from 'moment';
import { DATE_FORMAT, SKIPPED } from './constant';

import { colors } from '../theme/colors';
import currency from 'currency.js';

export const colorMap = (color) => {
    switch (color) {
        case 'purple':
        case 'gcpurple':
            return 'purple';
        case 'blue_aqua':
        case 'gcblue':
            return 'blue_aqua';
        case 'teal_pink':
        case 'gcpink':
            return 'teal_pink';
        case 'wine':
        case 'gcred':
            return 'wine';
        case 'sherbert':
        case 'gcorange':
            return 'sherbert';
        case 'pink_coral':
        case 'gcyellow':
        case 'gcindigo':
            return 'pink_coral';
        case 'marine':
        case 'gcgreen':
            return 'marine';
        case 'lemon_lime':
        case 'gcsuccess':
            return 'lemon_lime';
        default:
            return color;
    }
};

export const makeTnxList = (itemsArray) => {
    const newArray = [];
    const missingSplitItems = [];

    const sortItems = shortByDate(itemsArray, 'date', 'date');

    const items = sortItems.filter((item) => {
        if (item.missingSplit) {
            missingSplitItems.push(item);
        } else {
            return item;
        }
    });

    for (let index = 0; index < items.length; index++) {
        var element = items[index];

        if (!element.date) {
            continue;
        }

        if (index) {
            var current = moment(items[index].date);
            var prev = moment(items[index - 1].date);

            if (moment(current).isSame(prev, 'month')) {
                var d = newArray.find((i) => {
                    return moment(moment(i.month, 'MMM YYYY')).isSame(
                        items[index].date,
                        'month'
                    );
                });

                if (!d) {
                    newArray.push({
                        month: moment(items[index].date).format('MMM YYYY'),
                        element: [
                            {
                                ...element,
                                date: moment(element.date).format(DATE_FORMAT),
                            },
                        ],
                    });
                } else {
                    d.element.push({
                        ...element,
                        date: moment(element.date).format(DATE_FORMAT),
                    });
                }
            } else {
                newArray.push({
                    month: moment(items[index].date).format('MMM YYYY'),
                    element: [
                        {
                            ...element,
                            date: moment(element.date).format(DATE_FORMAT),
                        },
                    ],
                });
            }
        } else {
            newArray.push({
                month: moment(items[index].date).format('MMM YYYY'),
                element: [
                    {
                        ...element,
                        date: moment(element.date).format(DATE_FORMAT),
                    },
                ],
            });
        }
    }
    //group missing split items speratly
    for (let index = 0; index < missingSplitItems.length; index++) {
        var element = missingSplitItems[index];

        if (index == 0) {
            newArray.unshift({
                month: '⚠️ Resolve Missing Splits',
                element: [
                    {
                        ...element,
                        date: moment(element.date).format(DATE_FORMAT),
                    },
                ],
            });
        } else {
            newArray[0].element.push({
                ...element,
                date: moment(element.date).format(DATE_FORMAT),
            });
        }
    }

    return newArray;
};

export const getFirstName = (authName) => {
    let displayFirstName = authName;
    if (authName) {
        displayFirstName = authName.split(' ')[0];
    }
    return !displayFirstName ? '' : displayFirstName;
};

export const tnxItemAvatarColor = (groupColor) => {
    const colorProp = {};
    var gpColor = colorMap(groupColor);
    if (gpColor && colors[gpColor]) {
        colorProp.style = {
            backgroundColor: colors[gpColor].background,
        };
    }
    return colorProp;
};

export const tnxItemIconColor = (groupColor) => {
    const fillProps = {};
    var gpColor = colorMap(groupColor);
    if (gpColor && colors[gpColor]) {
        fillProps.fill = colors[gpColor].icon;
    }
    return fillProps;
};

export const addMailOpenTagToEmailAddressInAString = (text) => {
    if (!text) {
        return '';
    }
    var content = text;

    const email = content.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    );

    if (email && email[0]) {
        content = content.replace(
            email[0],
            `<a href = "mailto: ${email}">${email}</a>`
        );
    }

    return content;
};

export const shortByDate = (itemsArray, dateKey) => {
    return itemsArray.sort((a, b) => {
        return moment(a[dateKey]).isBefore(b[dateKey])
            ? 1
            : moment(b[dateKey]).isBefore(a[dateKey])
            ? -1
            : 0;
    });
};
export const isUserSkippedPro = () => !!localStorage.getItem(SKIPPED);

export const getLentAmount = (tnx, authUser) => {
    const sumFromArray = (propertyName, array) => {
        let sum = 0;
        array.forEach((item) => {
            sum += Number(item[propertyName]) ?? 0;
        });
        return currency(sum);
    };

    const { amount, groupSplittingValues } = tnx;
    if (!groupSplittingValues || !groupSplittingValues.splitAmounts) {
        return 0;
    }

    if (authUser.id === groupSplittingValues.paidBy) {
        const owenSplitAmounts = sumFromArray(
            'value',
            groupSplittingValues.splitAmounts.filter(
                ({ userId }) => userId === authUser.id
            )
        );

        const totalAmount = currency(amount).subtract(owenSplitAmounts).value;

        return totalAmount;
    } else {
        return -sumFromArray(
            'value',
            groupSplittingValues.splitAmounts.filter(
                ({ userId }) => userId === authUser?.id
            )
        ).value;
    }
};
