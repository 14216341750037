import SupportChat from '../../components/support/SupportChat';
import usePageViews from '../../hooks/usePageViews';
import './index.css';

const Help = () => {
    usePageViews();
    return <SupportChat />;
};

export default Help;
