import { IonContent, IonInput, IonItem, IonLabel, IonPage } from '@ionic/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupInterface } from '../../types/GroupInterface';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import { TemporaryContactCollectionInterface } from '../../types/TemporaryContactInterface';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';

type AddNewMemberProps = {
    closeModal: () => void;
    addNewUsers: (user: TemporaryContactCollectionInterface) => void;
    group: GroupInterface;
};

const AddNewMember = ({
    closeModal,
    addNewUsers,
    group,
}: AddNewMemberProps) => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const { authUser } = useSelector(
        (state: RootReducerInterface) => state.auth
    );

    const onSave = () => {
        if (authUser) {
            addNewUsers({
                name,
                email,
                createdBy: authUser.id,
                assignedGroupIds: [group.id],
                isActive: true,
            });
        }
        closeModal();
    };
    return (
        <IonPage>
            <Header
                title="Add group members"
                leftComponent={
                    <HeaderActionButton
                        slot="start"
                        title="Cancel"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
                rightComponent={
                    <HeaderActionButton
                        slot="end"
                        title="Add"
                        color="secondary"
                        disabled={!name && !email}
                        onClick={() => onSave()}
                    />
                }
            />
            <IonContent fullscreen>
                <IonItem>
                    <IonLabel position="floating">Name*</IonLabel>
                    <IonInput
                        autocapitalize="on"
                        name="name"
                        value={name}
                        onIonChange={(e: any) => setName(e.target.value)}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                        type="email"
                        name="email"
                        value={email}
                        onIonChange={(e: any) => setEmail(e.target.value)}
                    />
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default AddNewMember;
