import * as React from 'react';

function LapTopSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.222 22.89V5.868c0-1.083-.774-1.867-1.889-1.867H6.308c-1.096 0-1.53.784-1.53 1.867V22.89L1 28.267v1.866C1 31.16 1.85 32 2.889 32H33.11C34.15 32 35 31.16 35 30.133v-1.866l-3.778-5.376zm-9.444 5.377h-7.556V26.4h7.556v1.867zm5.666-5.6H8.556V7.733h18.888v14.934z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default LapTopSvg;
