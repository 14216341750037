import { useEffect } from 'react';
import { IonInput } from '@ionic/react';

export default function IonDecimalInput(props) {
    const slot = Math.random();
    useEffect(() => {
        addInputMode();
    }, []);

    const addInputMode = () => {
        const num = document.getElementById(`IonDecimalInput-${slot}`);
        num?.firstElementChild?.setAttribute('inputmode', 'decimal');
    };

    return (
        <IonInput
            {...props}
            id={`IonDecimalInput-${slot}`}
            onIonFocus={addInputMode}
        />
    );
}
