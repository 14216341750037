import { UserInterface } from '../types/UserInterface';
import {
    EXP_ACTIVE,
    EXP_ACTIVE_GROUP,
    EXP_CONTROL_GROUP,
    EXP_INPROGRESS,
    EXP_ONBOARDING_QUESTIONS,
} from '../utils/constant';
import db from './db';
import { fetchExperiment } from './experimentCollection';

const COLLECTION = 'users';

/**
 * Create user
 * @param data UserInterface
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const createUser = async (data: UserInterface) => {
    var userRef = db.collection(COLLECTION).doc(data.id);

    const exp = await fetchExperiment(EXP_ONBOARDING_QUESTIONS);

    if (exp.exists && exp.data()?.status === EXP_ACTIVE) {
        var expRef = userRef
            .collection('experiments')
            .doc(EXP_ONBOARDING_QUESTIONS);
        expRef.set({
            group: Math.random() > 0.5 ? EXP_ACTIVE_GROUP : EXP_CONTROL_GROUP,
            status: EXP_INPROGRESS,
        });
    }

    return userRef.set({
        ...data,
    });
};

/**
 * Update user
 * @param id String
 * @param data Any
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const updateUser = (id: string, data: any) => {
    var userRef = db.collection(COLLECTION).doc(id);
    return userRef.update(data);
};

/**
 * Fetch users
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchUsers = async () => {
    const userRef = db.collection(COLLECTION);
    const snapshot = await userRef.get();
    return snapshot;
};

/**
 * Fetch users by id array
 * @param ids Array<string>
 * @returns firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
 */
export const fetchUsersByUserIds = async (ids: Array<string>) => {
    const userRef = db.collection(COLLECTION).where('id', 'in', ids);
    const snapshot = await userRef.get();
    return snapshot;
};

/**
 * Get user by id
 * @param id String
 * @returns firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
 */
export const fetchUser = (id: string) => {
    const userRef = db.collection(COLLECTION).doc(id);
    return userRef.get();
};

export const fetchUserExp = (id: string, expId: string) => {
    const userRef = db
        .collection(COLLECTION)
        .doc(id)
        .collection('experiments')
        .doc(expId);
    return userRef.get();
};
