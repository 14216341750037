import * as React from 'react';
import { SVGProps } from 'react';

const HandPeaceSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#qa)" />
        <path
            d="M26.927 18.249c-.674-.93-1.904-1.462-3.192-1.304V13.18c0-1.754-1.612-3.18-3.592-3.18s-3.592 1.426-3.592 3.18L17 17.219l-.917-3.388c-.724-1.646-2.822-2.451-4.671-1.803-1.84.646-2.74 2.482-2.023 4.11l2.738 6.227c-1.5 1.073-2.16 2.484-1.215 3.963l2.694 4.21c.576.902 1.662 1.462 2.833 1.462H26.54c1.51 0 2.827-.921 3.17-2.226l1.347-5.113c.057-.214.085-.433.085-.653V21c0-1.932-2.124-3.323-4.215-2.751Zm1.858 5.759a.75.75 0 0 1-.025.19l-1.347 5.113c-.097.369-.456.627-.873.627H16.44a.921.921 0 0 1-.776-.406l-2.694-4.211c-.224-.35-.128-.803.221-1.053l1.309-.935a.686.686 0 0 0 .262-.356.607.607 0 0 0-.026-.423l-3.147-7.157c-.617-1.403 1.698-2.181 2.294-.826l2.936 6.676a.71.71 0 0 0 .288.32c.131.078.286.12.445.12h.572c.433 0 .785-.307.785-.687v-7.82c0-1.477 2.47-1.48 2.47 0V21c0 .38.351.688.785.688h.337c.434 0 .785-.308.785-.688v-1.203c0-1.08 1.796-1.081 1.796 0V21c0 .38.352.688.786.688h.337c.433 0 .785-.308.785-.688 0-1.08 1.796-1.081 1.796 0v3.008Z"
            fill="url(#qb)"
        />
        <defs>
            <linearGradient
                id="qa"
                x1={20.053}
                y1={3.547}
                x2={20.053}
                y2={37.389}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#036B9B" />
                <stop offset={0.591} stopColor="#FE95B4" />
            </linearGradient>
            <linearGradient
                id="qb"
                x1={20.172}
                y1={11.951}
                x2={20.172}
                y2={30.564}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#036B9B" />
                <stop offset={0.591} stopColor="#FE95B4" />
            </linearGradient>
        </defs>
    </svg>
);

export default HandPeaceSvg;
