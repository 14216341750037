import { IonIcon, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import useTrack from '../../hooks/useTrack';
import { RootState } from '../../redux/store';
import { BankInterface } from '../../types/BankInterface';
import { FilterRefData } from '../../types/TransactionInterface';

type TransactionFilterMenuType = {
    setFilteringData: (data: Array<FilterRefData>) => void;
    filteringData: Array<FilterRefData>;
};

const TransactionFilterMenu = ({
    setFilteringData,
    filteringData,
}: TransactionFilterMenuType) => {
    const {
        bank: { bankList },
        auth: { authUser },
    } = useSelector((state: RootState) => state);
    const { hitAnalytics } = useTrack();

    const onClickMenuItem = (card: any, ref: string) => {
        if (!!filteringData.find(({ refId }) => refId === card.id)) {
            setFilteringData(
                filteringData.filter(({ refId }) => refId !== card.id)
            );
            return;
        }
        const data: FilterRefData = {
            ref,
            refId: card.id,
        };
        const newArray: Array<FilterRefData> = filteringData;
        newArray.push(data);
        setFilteringData(newArray);
    };

    const isExist = (id: string) =>
        !!filteringData.find(({ refId }) => refId === id);

    const getBankList = (bList: BankInterface[]) => {
        var bankItems = [];

        for (let i = 0; i < bList.length; i++) {
            const cards = { ...bList[i].accounts };
            var linkedStatus: any = {};
            if (bList[i]?.softDelete === true) {
                linkedStatus.status = 'Unlinked';
            }

            for (let j = 0; j < bList[i].accounts.length; j++) {
                const element = bList[i].accounts[j];
                bankItems.push({
                    ...element,
                    ...linkedStatus,
                });
            }
        }

        return bankItems;
    };

    return (
        <IonList>
            {getBankList(bankList).map((card) => (
                <IonItem
                    key={card.id}
                    onClick={() => {
                        hitAnalytics('selectFilter', {
                            id: authUser?.id,
                        });
                        onClickMenuItem(card, 'bank');
                    }}
                >
                    {isExist(card.id) ? (
                        <IonIcon
                            color="primary"
                            slot="start"
                            icon={checkmark}
                        />
                    ) : (
                        <IonIcon
                            color="light"
                            slot="start"
                            icon={checkmark}
                        ></IonIcon>
                    )}
                    <IonLabel>
                        <span className="credit-card-title">
                            {`${card.name}   ${
                                bankList.filter(
                                    ({ name }) => name === card.name
                                ).length > 1
                                    ? ' - ' + card.count
                                    : ''
                            }`}
                        </span>
                    </IonLabel>
                    <IonText className="credit-card-mask" slot="end">
                        {`...${card.mask}`}
                        {card.status ? (
                            <>
                                <br />
                                {card.status}
                            </>
                        ) : (
                            <></>
                        )}
                    </IonText>
                </IonItem>
            ))}

            <IonItem
                key={'showSplit'}
                onClick={() =>
                    onClickMenuItem(
                        {
                            id: 'showSplit',
                        },
                        'custom'
                    )
                }
            >
                {isExist('showSplit') ? (
                    <IonIcon
                        color="primary"
                        slot="start"
                        icon={checkmark}
                    ></IonIcon>
                ) : (
                    <IonIcon
                        color="light"
                        slot="start"
                        icon={checkmark}
                    ></IonIcon>
                )}
                <IonLabel>
                    {' '}
                    <span className="credit-card-title">
                        Show Split Only
                    </span>{' '}
                </IonLabel>
            </IonItem>

            <IonItem
                key={'showHidden'}
                onClick={() =>
                    onClickMenuItem(
                        {
                            id: 'showHidden',
                        },
                        'custom'
                    )
                }
            >
                {isExist('showHidden') ? (
                    <IonIcon
                        color="primary"
                        slot="start"
                        icon={checkmark}
                    ></IonIcon>
                ) : (
                    <IonIcon
                        color="light"
                        slot="start"
                        icon={checkmark}
                    ></IonIcon>
                )}
                <IonLabel>
                    {' '}
                    <span className="credit-card-title">Show Hidden</span>{' '}
                </IonLabel>
            </IonItem>
        </IonList>
    );
};

export default TransactionFilterMenu;
