import * as React from 'react';

function HandSpock(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={22}
            height={22}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.906 16.405l5.547 5.221c.255.24.592.374.943.374h10.21c.608 0 1.144-.4 1.317-.984l1.368-4.611c.136-.46.205-.937.205-1.417v-1.583c0-.174.02-.348.06-.518l1.364-5.732a2.88 2.88 0 00-2.816-3.546 2.879 2.879 0 00-5.633-.225l-.726 4.522-.932-5.671A2.88 2.88 0 008.363.074a2.884 2.884 0 00-2.23 2.633A2.88 2.88 0 003.38 6.224l1.334 5.866a2.88 2.88 0 00-3.808 4.315zm1.378-2.656a.816.816 0 011.154-.035l2.278 2.144a.688.688 0 001.159-.5v-2.986a.69.69 0 00-.017-.152L5.39 5.766c-.24-1.058 1.354-1.412 1.593-.362l1.306 5.747a.687.687 0 00.67.535h.37a.688.688 0 00.67-.841L8.212 3.062C7.97 2.011 9.56 1.641 9.803 2.696l1.943 8.458a.687.687 0 00.67.534h.568a.688.688 0 00.667-.521l1.82-7.283c.263-1.05 1.847-.651 1.585.396l-1.639 6.553a.688.688 0 00.667.854h.417a.688.688 0 00.669-.528l1.156-4.86c.25-1.05 1.84-.674 1.589.378L18.55 12.41c-.077.326-.116.66-.116.995v1.583c0 .281-.041.56-.121.83l-1.221 4.12H7.668L2.32 14.902a.817.817 0 01-.035-1.154z"
                fill="#fff"
            />
        </svg>
    );
}

export default HandSpock;
