import { useState, useEffect } from 'react';
import {
    IonContent,
    IonList,
    IonModal,
    IonPage,
    useIonAlert,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import IonListItem from '../../components/IonListItem';
import ArrowRight from '../../svg/ArrowRight';
import UserDetails from '../../components/settings/UserDetails';
import Accounts from '../../components/settings/Accounts';
import DefaultSplitGroup from '../../components/settings/DefaultSplitGroup';
import HeaderActionButton from '../../components/HeaderActionButton';
import { signOut } from '../../firebase/AuthCollections';
import { removeAuthUser } from '../../redux/slices/authSlice';
import { removeBankList } from '../../redux/slices/bankSlice';
import { removeGroupList } from '../../redux/slices/groupSlice';
import { removeTransactions } from '../../redux/slices/tnxSlice';
import { removeUserList } from '../../redux/slices/useSlice';
import { RootState } from '../../redux/store';
import DeleteConfirmation from '../../components/settings/DeleteConfirmation';
import usePageViews from '../../hooks/usePageViews';
import usePurchases from '../../hooks/usePurchases';
import { Browser } from '@capacitor/browser';

const USER_DETAILS = 1;
const LINKED_ACCOUNTS = 2;
const DEFAULT_SPLIT_GROUP = 3;
const DELETE_ACCOUNT = 4;
const EXTERNAL_LINK = 5;

const SETTING_ITEMS = [
    { name: 'User details', modal: USER_DETAILS },
    { name: 'Linked accounts', modal: LINKED_ACCOUNTS },
    { name: 'Default split group', modal: DEFAULT_SPLIT_GROUP },
    { name: 'Delete my account', modal: DELETE_ACCOUNT },
    {
        name: 'Terms of Service',
        modal: EXTERNAL_LINK,
        url: 'https://www.billmagic.app/terms',
    },
    {
        name: 'Privacy Policy',
        modal: EXTERNAL_LINK,
        url: 'https://www.billmagic.app/privacy',
    },
];

const Settings = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<number>(0);
    const { authUser } = useSelector((state: RootState) => state.auth);
    const [present] = useIonAlert();
    const dispatch = useDispatch();
    const { resetPurchase } = usePurchases();

    usePageViews();

    const closeModal = async () => {
        setShowModal(false);
    };

    const openConfirmation = () => {
        setModalType(DELETE_ACCOUNT);
        setShowModal(true);
    };

    const onClickDeleteAccount = () => {
        present({
            header: 'Delete  account!',
            message: `You are going to delete your account permanently`,
            buttons: [
                {
                    text: 'Got it',
                    handler: () => {
                        openConfirmation();
                    },
                },
                {
                    text: 'Cancel',
                    handler: () => {},
                },
            ],
            onDidDismiss: (e) => {},
        });
    };
    const renderModalContent = () => {
        switch (modalType) {
            case USER_DETAILS:
                return <UserDetails closeModal={closeModal} />;

            case LINKED_ACCOUNTS:
                return <Accounts closeModal={closeModal} />;

            case DEFAULT_SPLIT_GROUP:
                return <DefaultSplitGroup closeModal={closeModal} />;
            case DELETE_ACCOUNT:
                return (
                    <DeleteConfirmation
                        clearStore={clearStore}
                        closeModal={closeModal}
                    />
                );

            default:
                return <></>;
        }
    };

    const clearStore = () => {
        dispatch(removeAuthUser());
        dispatch(removeBankList());
        dispatch(removeGroupList());
        dispatch(removeTransactions());
        dispatch(removeUserList());
    };

    const logOut = () => {
        signOut();
        clearStore();
        resetPurchase(authUser?.email);
    };

    return (
        <IonPage>
            <Header
                title={`Hi ${authUser?.name ?? ''} !`}
                rightComponent={
                    <HeaderActionButton
                        title="Logout"
                        color="secondary"
                        onClick={logOut}
                    />
                }
            />
            <IonContent fullscreen>
                <IonList>
                    {SETTING_ITEMS.map(
                        (
                            {
                                name,
                                modal,
                                url,
                            }: {
                                name: string;
                                modal: number;
                                url?: string;
                            },
                            key: number
                        ) => {
                            return (
                                <IonListItem
                                    key={`item-${key}`}
                                    title={name}
                                    rightContent={<ArrowRight />}
                                    onClick={async () => {
                                        if (modal === EXTERNAL_LINK && url) {
                                            await Browser.open({
                                                presentationStyle: 'popover',
                                                url,
                                            });
                                        } else if (modal !== DELETE_ACCOUNT) {
                                            setModalType(modal);
                                            setShowModal(true);
                                        } else {
                                            onClickDeleteAccount();
                                        }
                                    }}
                                />
                            );
                        }
                    )}
                </IonList>
            </IonContent>

            <IonModal
                isOpen={showModal}
                cssClass={
                    modalType === DELETE_ACCOUNT
                        ? 'alert-modal'
                        : 'my-custom-class'
                }
                onDidDismiss={() => setShowModal(false)}
            >
                {renderModalContent()}
            </IonModal>
        </IonPage>
    );
};

export default Settings;
