import * as React from 'react';

function PaperPlaneSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.184.253L.936 9.624c-1.344.777-1.215 2.766.222 3.356l4.465 1.847v3.297c0 1.813 2.312 2.559 3.382 1.117l1.711-2.308 4.371 1.805a1.879 1.879 0 002.57-1.45l2.32-15.125c.238-1.566-1.441-2.687-2.793-1.91zM7.498 18.124v-2.523l1.43.59-1.43 1.933zm8.304-1.12l-6.008-2.481 5.477-7.903c.417-.605-.372-1.308-.926-.828l-8.261 7.2-4.21-1.743 16.248-9.375-2.32 15.13z"
                fill="#fff"
            />
        </svg>
    );
}

export default PaperPlaneSvg;
