import React, { useState } from 'react';
import { IonContent, IonIcon, IonSkeletonText } from '@ionic/react';
import {
    chevronBackOutline,
    ellipsisHorizontal,
    ellipsisVertical,
} from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import { colorMap } from '../../utils/common';

type GroupContentWrapperProps = {
    groupInfo: any;
    loading: boolean;
    children: React.ReactNode;
    onPressHeaderMenu: () => void;
    goBack: () => void;
};

const GroupContentWrapper = ({
    groupInfo,
    loading,
    children,
    onPressHeaderMenu,
    goBack,
}: GroupContentWrapperProps) => {
    const [scrolled, setScrolled] = useState(0);

    var headerColorProps: any = {};
    var bcColorProps: any = {};
    let title = '';
    if (groupInfo?.groupColor) {
        var groupColor = colorMap(groupInfo.groupColor);

        if (scrolled > 0) {
            headerColorProps.color = groupColor;
            title = groupInfo.name;
        }
        bcColorProps.className = `${groupColor}-linear-gradient-bg`;
    } else {
        if (!loading) {
            bcColorProps.className = `purple-linear-gradient-bg`;
            headerColorProps.color = 'purple';
        }
    }

    var titleFontSize = 28 - scrolled / 15;
    if (titleFontSize >= 28) {
        titleFontSize = 28;
    }
    if (titleFontSize <= 17) {
        titleFontSize = 17;
    }

    return (
        <>
            <Header
                ionToolBarClasses={
                    !(scrolled > 0) ? 'header-border-zero group-header' : ''
                }
                ionToolBarTitleClasses={
                    !(scrolled > 0) ? 'group-header-title' : ''
                }
                ionToolBarTitleStyles={{
                    color: '#ffff',
                    // fontSize: titleFontSize,
                }}
                {...headerColorProps}
                title={!loading && title}
                leftComponent={
                    <IonIcon
                        size="large"
                        color="light"
                        icon={chevronBackOutline}
                        onClick={() => goBack()}
                    />
                }
                rightComponent={
                    <HeaderActionButton
                        slot="primary"
                        title={
                            <IonIcon
                                style={{
                                    zIndex: 1000,
                                    color: 'red',
                                    opacity: 100,
                                }}
                                color="light"
                                ios={ellipsisHorizontal}
                                md={ellipsisVertical}
                                slot="icon-only"
                            />
                        }
                        onClick={onPressHeaderMenu}
                    />
                }
            />
            <IonContent
                {...bcColorProps}
                fullscreen
                scrollEvents={true}
                onIonScroll={(e) => {
                    setScrolled(e.detail.scrollTop);
                }}
            >
                {children}
            </IonContent>
        </>
    );
};

export default GroupContentWrapper;
