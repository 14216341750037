import * as React from 'react';

function DollarSignSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            viewBox="0 0 12 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.292 8.705l-3.797-1.11a1.037 1.037 0 01.292-2.032h2.331c.429 0 .851.13 1.203.369a.548.548 0 00.685-.07l1.224-1.196a.57.57 0 00-.063-.861A4.973 4.973 0 007.125 2.75V1.062A.564.564 0 006.562.5H5.437a.564.564 0 00-.562.563V2.75h-.088A3.852 3.852 0 00.954 6.955C1.1 8.575 2.339 9.894 3.9 10.35l3.604 1.054a1.037 1.037 0 01-.292 2.032H4.882c-.43 0-.851-.13-1.203-.369a.548.548 0 00-.686.07L1.77 14.335a.57.57 0 00.063.861 4.973 4.973 0 003.042 1.055v1.688c0 .309.253.562.562.562h1.125c.31 0 .563-.253.563-.563v-1.694c1.639-.032 3.175-1.005 3.717-2.556.756-2.165-.514-4.387-2.55-4.982z"
                fill={props.fill ? props.fill : '#2FD36F'}
            />
        </svg>
    );
}

export default DollarSignSvg;
