import { PurchasesPackage } from '@ionic-native/purchases';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppReducerInterface } from '../../types/AppReducerInterface';

const initialState: AppReducerInterface = {
    purchasePackages: [],
    plaidLinkToken: undefined,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPurchasePackages: (
            state,
            action: PayloadAction<PurchasesPackage[]>
        ) => {
            state.purchasePackages = action.payload;
        },
        setPlaidLinkToken: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.plaidLinkToken = action.payload;
        },
    },
});

export const { setPurchasePackages, setPlaidLinkToken } = appSlice.actions;

export default appSlice.reducer;
