import * as React from 'react';
import { SVGProps } from 'react';

const CashSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={40}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect opacity={0.2} width={40} height={40} rx={20} fill="url(#ca)" />
        <path
            d="M27.167 24.333H25.5a.949.949 0 0 1-.59-.195.608.608 0 0 1-.243-.471c0-.177.087-.347.244-.472A.95.95 0 0 1 25.5 23h.833v-4H25.5a.949.949 0 0 1-.59-.195.608.608 0 0 1-.243-.472c0-.176.087-.346.244-.471a.95.95 0 0 1 .589-.195h1.667c.11 0 .218.016.32.05a.862.862 0 0 1 .27.144.673.673 0 0 1 .181.216.548.548 0 0 1 .062.256v5.334c0 .087-.02.174-.062.256a.672.672 0 0 1-.18.217.866.866 0 0 1-.271.144c-.102.033-.21.05-.32.05Z"
            fill="url(#cb)"
        />
        <path
            d="M14.5 24.309h-1.667c-.11 0-.218-.016-.32-.05a.867.867 0 0 1-.27-.143.67.67 0 0 1-.181-.216.545.545 0 0 1-.062-.255V18.33c0-.087.02-.174.062-.255a.671.671 0 0 1 .18-.216.865.865 0 0 1 .271-.144c.102-.033.21-.05.32-.05H14.5c.11 0 .218.018.319.051a.873.873 0 0 1 .27.144.678.678 0 0 1 .18.216.55.55 0 0 1 0 .508.678.678 0 0 1-.18.216.873.873 0 0 1-.27.144 1.02 1.02 0 0 1-.319.05h-.833v4.01h.833c.221 0 .433.07.59.195a.605.605 0 0 1 .243.47.605.605 0 0 1-.244.469.952.952 0 0 1-.589.194v-.024Z"
            fill="url(#cc)"
        />
        <path
            d="M22.667 21a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z"
            fill="url(#cd)"
        />
        <path
            d="M31.2 13.667H8.8a.806.806 0 0 0-.8.8v12.8a.806.806 0 0 0 .8.8h22.4a.806.806 0 0 0 .8-.8v-12.8a.806.806 0 0 0-.8-.8ZM29.733 25.8H10.267v-9.867h19.466V25.8Z"
            fill="url(#ce)"
        />
        <defs>
            <linearGradient
                id="ca"
                x1={20.053}
                y1={3.547}
                x2={20.201}
                y2={40.002}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={0.625} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="cb"
                x1={20.032}
                y1={14.944}
                x2={20.064}
                y2={28.068}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={1} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="cc"
                x1={20.032}
                y1={14.944}
                x2={20.064}
                y2={28.068}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={1} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="cd"
                x1={20.032}
                y1={14.944}
                x2={20.064}
                y2={28.068}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={1} stopColor="#FDC40C" />
            </linearGradient>
            <linearGradient
                id="ce"
                x1={20.032}
                y1={14.944}
                x2={20.064}
                y2={28.068}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4FC97E" />
                <stop offset={1} stopColor="#FDC40C" />
            </linearGradient>
        </defs>
    </svg>
);

export default CashSvg;
