import * as React from 'react';

function BuildingSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={36}
            height={36}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M33 8H23V4c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v28.002c0 1.1.9 2 2 2h30c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-14 2v20.002H5V6h14v4zm12 20.002h-8V12h8v18z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
            <path
                d="M11 8H7v4h4V8zM17 8h-4.001v4h4V8zM11 14H7v4h4v-4zM17 14h-4.001v4h4v-4zM11 20.001H7v4h4v-4zM17 20.001h-4.001v4h4v-4zM29 14h-4v4h4v-4zM29 20.001h-4v4h4v-4z"
                fill={props.fill ? props.fill : '#B0B4C2'}
            />
        </svg>
    );
}

export default BuildingSvg;
