import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonSpinner,
    IonToggle,
    useIonAlert,
} from '@ionic/react';
import Header from '../Header';
import HeaderActionButton from '../HeaderActionButton';
import { RootReducerInterface } from '../../types/RootReducerInterface';
import { GroupCollectionInterface } from '../../types/GroupInterface';
import { groupColors, groupIcons as iconList } from '../../utils/constant';
import CheckMarkSvg from '../../svg/CheckMarkSvg';
import { deleteGroup } from '../../firebase/GroupCollection';
import { unassignTransactionsByAssignedGroupId } from '../../firebase/TransactionCollection';
import { setGroupList } from '../../redux/slices/groupSlice';
import './index.css';

type FormProps = {
    closeModal: () => void;
    createNewGroup: (data: GroupCollectionInterface) => void;
    loading?: boolean;
    onDeleteSuccess?: () => void;
    initialData?: {
        name: string;
        description?: string;
        groupColor?: string | null;
        isDefault?: boolean;
        groupIcon?: string;
        id: string;
    };
};

const groupIcons = iconList({});

const GroupForm = ({
    closeModal,
    createNewGroup,
    initialData,
    loading,
    onDeleteSuccess,
}: FormProps) => {
    const {
        auth: { authUser },
        group: { groupList },
    } = useSelector((state: RootReducerInterface) => state);
    const dispatch = useDispatch();

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);
    const [groupColor, setGroupColor] = useState<string>(groupColors[0]);
    const [groupIcon, setGroupIcon] = useState<any>(groupIcons[0].id);
    const [deleting, setDeleting] = useState<boolean>(false);

    useEffect(() => {
        setInitialData();
    }, []);

    const [present] = useIonAlert();

    const setInitialData = useCallback(() => {
        if (initialData) {
            const {
                name,
                description,
                groupColor,
                isDefault,
                groupIcon,
            } = initialData;
            setName(name);
            setDescription(() => (description ? description : ''));
            setGroupColor(() => (groupColor ? groupColor : ''));
            setGroupIcon(() => (groupIcon ? groupIcon : ''));
            if (isDefault) {
                setChecked(isDefault);
            }
        } else {
            setChecked(!groupList.length);
        }
    }, [initialData]);

    const onSaveGroup = () => {
        if (authUser)
            createNewGroup({
                name,
                description,
                memberIds: [authUser?.id],
                ownerId: authUser?.id,
                groupColor,
                isDefault: checked,
                groupIcon,
            });
    };

    const removeGroup = () => {
        if (initialData) {
            present({
                header: 'Delete  group!',
                message: `This action cannot be undone`,
                cssClass: 'buttonCss',
                buttons: [
                    {
                        text: 'Cancel',
                        handler: () => {
                            return true;
                        },
                    },
                    {
                        text: 'Delete',
                        cssClass: 'delete-btn-danger',
                        handler: () => {
                            setDeleting(true);
                            deleteGroup(initialData.id)
                                .then(() => {
                                    // Remove group from store
                                    const newGroups = groupList.filter(
                                        (i) => i && i.id !== initialData.id
                                    );
                                    dispatch(setGroupList(newGroups));
                                    // End removing

                                    // Unassign assigned transactions
                                    unassignTransactionsByAssignedGroupId(
                                        initialData.id
                                    );
                                    setDeleting(false);
                                    onDeleteSuccess && onDeleteSuccess();
                                    // closeModal();
                                    // history.push(`/groups`);
                                })
                                .catch((e) => {
                                    closeModal();
                                    setDeleting(false);
                                });
                        },
                    },
                ],
                onDidDismiss: (e) => {},
            });
        }
    };

    return (
        <IonPage>
            <Header
                title={!initialData ? 'Create Group' : 'Edit Group'}
                leftComponent={
                    <HeaderActionButton
                        slot="start"
                        title="Cancel"
                        color="secondary"
                        onClick={() => closeModal()}
                    />
                }
            />
            <IonContent fullscreen>
                {deleting && (
                    <IonLoading
                        isOpen={true}
                        onDidDismiss={() => {}}
                        message={'Please wait...'}
                    />
                )}
                <IonItem className="grp-form-item" lines="full">
                    <IonLabel position="floating">Group Name</IonLabel>
                    <IonInput
                        autocapitalize="on"
                        name="name"
                        value={name}
                        onIonChange={(e: any) => setName(e.target.value)}
                    />
                </IonItem>

                <IonItem className="grp-form-item" lines="full">
                    <IonLabel>Make Default Group</IonLabel>
                    <IonToggle
                        checked={checked}
                        onIonChange={(e) => setChecked(e.detail.checked)}
                    />
                </IonItem>

                <>
                    <IonItem lines="none">
                        <IonLabel>Group Color</IonLabel>
                    </IonItem>
                    <div className="color-btn-wrapper">
                        {groupColors.map((color, key) => {
                            return (
                                <IonButton
                                    key={key}
                                    onClick={() => setGroupColor(color)}
                                    className={`circle-btn ${color}-linear-check-box-gradient-bg`}
                                >
                                    {groupColor === color && (
                                        <CheckMarkSvg
                                            width={56}
                                            height={56}
                                            fill="#FFFFFF"
                                        />
                                    )}
                                </IonButton>
                            );
                        })}
                    </div>
                    <IonItem lines="full" className="grp-item-color-item" />
                </>

                <>
                    <IonItem lines="none">
                        <IonLabel>Group Icon</IonLabel>
                    </IonItem>
                    <div className="grp-icon-list-wrapper">
                        {groupIcons.map((i, key) => {
                            return (
                                <IonButton
                                    key={key}
                                    className="circle-icon-btn"
                                    onClick={() => setGroupIcon(i.id)}
                                    color={
                                        groupIcon == i.id
                                            ? groupColor
                                            : 'medium'
                                    }
                                >
                                    {i.icon}
                                </IonButton>
                            );
                        })}
                    </div>
                    <IonItem lines="full" className="grp-item-color-item" />
                </>

                <div className="grp-form-btn-wrapper">
                    <IonButton
                        onClick={onSaveGroup}
                        size="default"
                        fill={loading == true || !name ? 'outline' : 'solid'}
                        expand="block"
                        color={loading == true || !name ? 'medium' : 'primary'}
                        className="form-button add-tnx-from-btn margin-top-zero"
                        disabled={loading == true || !name ? true : false}
                    >
                        {loading === true && (
                            <IonSpinner slot="start" name="lines-small" />
                        )}
                        Save
                    </IonButton>
                </div>
                {initialData?.id && (
                    <div className="grp-form-btn-wrapper">
                        <IonLabel onClick={removeGroup} color="alert">
                            Delete Group
                        </IonLabel>
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default GroupForm;
