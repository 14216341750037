import {
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonText,
} from '@ionic/react';
import { ReactNode } from 'react';
import { colors } from '../../theme/colors';
import { colorMap } from '../../utils/common';

interface ItemPropsInterface {
    title: string;
    subTitle: string;
    description?: string;
    rightValue: string | ReactNode;
    onClickSplit?: () => void;
    groupColor: string | null;
    assignedGroup?: string;
    enableSwipe: boolean;
    onClickItem?: () => void;
    icon?: any;
    memo?: ReactNode;
    swipe?: (side: string) => void;
    hidden?: boolean;
    missingSplit?: boolean;
}

const Item = ({
    title,
    subTitle,
    rightValue,
    onClickSplit,
    groupColor: _groupColor,
    assignedGroup,
    enableSwipe,
    onClickItem,
    icon,
    description,
    memo,
    swipe,
    hidden,
    missingSplit,
}: ItemPropsInterface) => {
    const closeOpened = async () => {
        // @ts-ignore
        return await document.querySelector('ion-item-sliding').closeOpened();
    };

    const colorProp: any = {};
    const fillProps: any = {};
    var groupColor = colorMap(_groupColor);
    // @ts-ignore
    if (groupColor && colors[groupColor]) {
        colorProp.style = {
            // @ts-ignore
            backgroundColor: colors[groupColor].background,
        };
        // @ts-ignore
        fillProps.fill = colors[groupColor].icon;
    }

    const listItem = (
        <IonItem
            onClick={() => {
                onClickItem && onClickItem();
            }}
            className={missingSplit ? 'item-content' : ''}
        >
            {icon}
            <IonLabel>
                <h2 className="tnx-item-h1">{title}</h2>
                <h3 className="tnx-item-h2">
                    {subTitle}{' '}
                    {groupColor && assignedGroup && (
                        <>
                            .{' '}
                            <IonText
                                //@ts-ignore
                                style={{ color: colors[groupColor].label }}
                            >
                                {assignedGroup}
                            </IonText>
                        </>
                    )}
                </h3>
                {description && (
                    <h3 className="tnx-item-h2">
                        {description}
                        {groupColor && (
                            <IonText
                                //@ts-ignore
                                style={{ color: colors[groupColor].label }}
                            >
                                {assignedGroup}
                            </IonText>
                        )}
                    </h3>
                )}
                {memo}
            </IonLabel>
            <IonText className="tnx-item-price" color="primary" slot="end">
                {rightValue}
            </IonText>
        </IonItem>
    );

    if (!enableSwipe) {
        return listItem;
    }

    return (
        <IonItemSliding id="item100">
            {listItem}
            <IonItemOptions
                side="start"
                onIonSwipe={() => {
                    closeOpened().then(() => {
                        swipe && swipe('right');
                    });
                }}
            >
                <IonItemOption
                    onClick={() => {
                        closeOpened();
                    }}
                    className="ion-item-option-btn"
                    color="gray2"
                    expandable
                >
                    {hidden ? 'Undo' : 'Hide'}
                </IonItemOption>
            </IonItemOptions>
            <IonItemOptions
                side="end"
                onIonSwipe={() => {
                    closeOpened().then(() => {
                        swipe && swipe('left');
                    });
                }}
            >
                <IonItemOption
                    onClick={() => {
                        closeOpened().then(() => {
                            onClickSplit && onClickSplit();
                        });
                    }}
                    className="ion-item-option-btn"
                    color="primary"
                >
                    Split
                </IonItemOption>
                <IonItemOption
                    onClick={() => {
                        swipe && swipe('left');
                        closeOpened();
                    }}
                    color="magenta"
                    expandable
                    className="ion-item-option-btn"
                >
                    {!assignedGroup ? (
                        <>
                            {' '}
                            Default <br /> Split
                        </>
                    ) : (
                        <>Undo</>
                    )}
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>
    );
};
export default Item;
